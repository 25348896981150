import { Avatar, Box, Skeleton, SxProps } from '@mui/material';
import { varFadeIn } from 'components/animate';
import { motion, useInView } from 'framer-motion';
import { useRef, useState } from 'react';

export type BaseStartupAvatarSize =
  | 'extra-tiny'
  | 'tiny'
  | 'small'
  | 'medium'
  | 'large'
  | 'huge'
  | 'x-huge'
  | 'xx-huge';

const SIZE_MAPPING: Record<BaseStartupAvatarSize, string> = {
  'extra-tiny': '20px',
  tiny: '25px',
  small: '30px',
  medium: '40px',
  large: '48px',
  huge: '54px',
  'x-huge': '64px',
  'xx-huge': '66px',
};

const BaseStartupAvatar = ({
  startup: { logo_url, name, domain },
  size,
  sx = {},
}: {
  startup: {
    logo_url: string | null | undefined;
    name: string;
    domain: string;
  };
  size: BaseStartupAvatarSize;
  sx?: SxProps;
}) => {
  const avatarSize = SIZE_MAPPING[size];
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const [loaded, setLoaded] = useState(false);

  const [logoUrl, setLogoUrl] = useState(logo_url);

  return (
    <Box ref={ref}>
      {isInView || !loaded ? (
        <motion.div {...varFadeIn}>
          <Avatar
            src={logoUrl || `https://logo.clearbit.com/${domain}?size=80`}
            alt={name}
            sx={{ height: avatarSize, width: avatarSize, ...sx }}
            imgProps={{
              onLoad: () => {
                setLoaded(true);
              },
              onError: () => {
                setLogoUrl(`https://logo.clearbit.com/${domain}?size=80`);
              },
            }}
          >
            {name[0] || domain[0] || ''}
          </Avatar>
        </motion.div>
      ) : (
        <Skeleton variant='circular' width={avatarSize} height={avatarSize} />
      )}
    </Box>
  );
};

export { BaseStartupAvatar };
