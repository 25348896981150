import { Link, Stack, Tooltip, Typography } from '@mui/material';
import { captureMessage } from '@sentry/core';
import { useGetAllPeopleQuery } from 'apollo/generated/sdkInnovationManager';
import { keyBy } from 'lodash';
import { ReactElement } from 'react';
import { DecoratedStartup } from '../shared/StartupsTableContext';

const VotersInfoTooltip = ({
  children,
  decoratedStartup,
  title,
}: {
  children: ReactElement | undefined | null;
  decoratedStartup: DecoratedStartup;
  title: string;
}) => {
  const { data: peopleData, loading: peopleLoading } = useGetAllPeopleQuery();

  const loading = peopleLoading;

  if (!children) return null;

  if (loading) return null;
  if (!peopleData?.people) {
    captureMessage('Failed to load GetAllStakeholders');
    return null;
  }

  const collaboratorsByEmail = {
    ...keyBy(peopleData.people, 'email'),
  };

  return (
    <Tooltip
      arrow
      placement='bottom'
      title={
        <Stack>
          <Typography variant='caption'>{title}</Typography>
          {decoratedStartup.voters.length > 0 && (
            <>
              <br />
              <Typography>
                <b>Voted by</b>
              </Typography>
              {decoratedStartup.voters.map(email => {
                const collaborator = collaboratorsByEmail[email];

                if (!collaborator) {
                  captureMessage(`Cannot map collaborator vote for ${email}`);
                  return (
                    <Typography key={email} variant='body2'>
                      {email}
                    </Typography>
                  );
                }

                return (
                  <Stack
                    key={`${decoratedStartup.id}-${collaborator.full_name}`}
                  >
                    <Typography key={`vote-${email}`} variant='caption'>
                      {collaborator.full_name}
                      {'<'}
                      <Link href={`mailto:${email}`} target='_blank'>
                        {email}
                      </Link>
                      {'>'}
                    </Typography>
                  </Stack>
                );
              })}
            </>
          )}
        </Stack>
      }
    >
      {children}
    </Tooltip>
  );
};

export default VotersInfoTooltip;
