import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, SxProps } from '@mui/material';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(() => ({
  '& .MuiPaper-root': {
    minWidth: 180,
  },
}));

export type MenuItemLabel = string | React.ReactNode;
type MenuItemType = {
  label: MenuItemLabel;
  onClick: () => void;
  disabled?: boolean;
};

export default function MenuButton({
  title,
  items,
  disabled,
  fullWidth = false,
  buttonProps,
  containerSx = {},
  sx,
}: {
  title: string | React.ReactNode;
  items: MenuItemType[];
  disabled?: boolean;
  fullWidth?: boolean;
  buttonProps?: ButtonProps & { 'data-testid'?: string };
  containerSx?: SxProps;
  sx?: SxProps;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={containerSx}>
      <Button
        fullWidth={fullWidth}
        variant='text'
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        disabled={disabled}
        sx={sx}
        {...buttonProps}
      >
        {title}
      </Button>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {items.map((item, index) => {
          return (
            <MenuItem
              key={index}
              onClick={async () => {
                item.onClick();
                handleClose();
              }}
              disableRipple
              sx={{ fontSize: '14px' }}
              disabled={item.disabled}
            >
              {item.label}
            </MenuItem>
          );
        })}
      </StyledMenu>
    </Box>
  );
}
