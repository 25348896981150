import {
  Autocomplete,
  Box,
  Button,
  Card,
  Chip,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Popper,
  Stack,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';

import differenceBy from 'lodash/differenceBy';
import { useSnackbar } from 'notistack';

import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useTheme } from '@mui/material/styles';
import { BaseDialog } from './BaseDialog';

export type Tag = {
  id: number;
  value: string;
  description: string;
};

export type ValueTag = {
  id: number;
  value: string;
  description: string;
  option_id?: number | string;
};

export type BaseMultiselectProps = {
  options: Tag[];
  value: ValueTag[];
  sx?: SxProps;
  // Add or remove Tags from the attached entity
  onAdd: (name: string) => Promise<void>;
  onRemove: (tag: Tag) => Promise<void>;
  // Tag management functions
  onInsert?: (name: string) => Promise<void>;
  onDelete?: (tag_id: number) => Promise<void>;
  onUpdate?: (tag_id: number, value: string) => Promise<void>;
  addedTagIds: number[];
};

export const BaseMultiselect = ({
  options,
  value,
  onAdd,
  onInsert,
  // Removing a Tag from the value
  onRemove,
  onDelete,
  onUpdate,
  sx,
}: BaseMultiselectProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [inputTextValue, setInputTextValue] = useState('');
  const filteredOptions = useMemo(
    () =>
      differenceBy(
        options.filter(option =>
          option.description
            .toLocaleLowerCase()
            .includes(inputTextValue.toLocaleLowerCase()),
        ),
        value,
        'value',
      ),
    [inputTextValue, options, value],
  );

  const handleCreateTag = async () => {
    if (!inputTextValue || !onInsert) {
      return;
    }

    try {
      await onInsert(inputTextValue);
      setInputTextValue('');
      enqueueSnackbar('Tag created', {
        variant: 'success',
      });
    } catch (e) {
      console.error(e);
      enqueueSnackbar('Error creating tag', {
        variant: 'error',
      });
    }
  };

  const [selectedOption, setSelectedOption] = useState<Tag | null>(null);

  const [openDeleteTagDialog, setOpenDeleteTagDialog] = useState(false);

  const onDeleteOption = onDelete
    ? (option_id: number) => {
        const option = options.find(o => o.id === option_id);
        if (!option) return;
        setSelectedOption(option);
        setOpenDeleteTagDialog(true);
      }
    : undefined;

  const handleCloseDeleteTagDialog = () => {
    setOpenDeleteTagDialog(false);
    setSelectedOption(null);
  };

  const [isEditingOption, setIsEditingOption] = useState(false);

  const onUpdateOption = onUpdate
    ? (option_id: number) => {
        const option = options.find(o => o.id === option_id);
        if (!option) return;

        setSelectedOption(option);
        setIsEditingOption(true);
      }
    : undefined;

  const handleStopEditing = () => {
    setIsEditingOption(false);
    setSelectedOption(null);
  };

  const handleOnKeyDown = async (e: React.KeyboardEvent) => {
    // On enter if the there is no Tag with the same name as the input
    if (
      e.key === 'Enter' &&
      !value.find(v => v.description === inputTextValue)
    ) {
      // If the user is writing "Manag" and there is only a tag starting with
      // that prefix like "Management", we want to add this if the user press enter
      const matchingOption = options.find(o =>
        o.value.startsWith(inputTextValue),
      );
      if (matchingOption) {
        await onAdd(matchingOption.value);
      } else {
        await handleCreateTag();
      }
    }
  };

  const handleOnChange = async (_e: unknown, newValue: ValueTag[]) => {
    const valueToAdd = differenceBy(newValue, value)[0];
    await onAdd(valueToAdd.value);
  };
  return (
    <>
      {isEditingOption && selectedOption && onUpdate ? (
        <UpdateTagField
          selectedOption={selectedOption}
          onUpdate={onUpdate}
          handleStopEditing={handleStopEditing}
        />
      ) : (
        <Autocomplete<ValueTag, true, true, false>
          sx={sx}
          options={filteredOptions}
          value={value}
          getOptionLabel={option => option.description}
          size='small'
          multiple
          filterSelectedOptions
          disableCloseOnSelect
          disableClearable
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
          onClose={() => {
            setInputTextValue('');
          }}
          noOptionsText={
            <CustomNoOptions
              value={value}
              inputTextValue={inputTextValue}
              handleCreateTag={handleCreateTag}
              onInsert={onInsert}
            />
          }
          renderInput={({ ...rest }) => (
            <TextField
              {...rest}
              variant='standard'
              label={'Tags'}
              size='medium'
              onChange={e => {
                setInputTextValue(e.target.value);
              }}
            />
          )}
          renderTags={(tags, getTagProps) =>
            tags.map((tag, index) => {
              return (
                <CustomChip
                  getTagProps={getTagProps}
                  index={index}
                  valueTag={tag}
                  optionId={tag.option_id}
                  onRemove={onRemove}
                  onDelete={onDeleteOption}
                  onUpdate={onUpdateOption}
                  key={`custom-chip-${tag.id}`}
                />
              );
            })
          }
          renderOption={(props, option) => {
            return (
              <CustomOption
                props={props}
                option={option}
                onDelete={onDeleteOption}
                onUpdate={onUpdateOption}
                key={`custom-option-${option.id}`}
              />
            );
          }}
        />
      )}

      {openDeleteTagDialog && selectedOption && (
        <DeleteTagDialog
          handleCloseDeleteTagDialog={handleCloseDeleteTagDialog}
          onDelete={onDelete}
          selectedOption={selectedOption}
        />
      )}
    </>
  );
};

export const DeleteTagDialog = ({
  handleCloseDeleteTagDialog,
  onDelete,
  selectedOption,
}: {
  handleCloseDeleteTagDialog: () => void;
  onDelete: ((tag_id: number) => Promise<void>) | undefined;
  selectedOption: Tag;
}) => {
  return (
    <BaseDialog
      maxWidth='xs'
      heading={
        <Typography variant='h6' component='span' sx={{ textAlign: 'center' }}>
          Delete Tag
        </Typography>
      }
      onHide={handleCloseDeleteTagDialog}
      onConfirm={() => {
        onDelete && onDelete(selectedOption.id);
        handleCloseDeleteTagDialog();
      }}
      confirmCTA='Delete'
      cancelCTA='Close'
    >
      <Typography marginBottom={2}>
        Are you sure you want to delete the{' '}
        <strong>{selectedOption.description}</strong> tag everywhere?
      </Typography>
    </BaseDialog>
  );
};

const UpdateTagField = ({
  selectedOption,
  onUpdate,
  handleStopEditing,
}: {
  selectedOption: Tag;
  onUpdate: (tag_id: number, value: string) => Promise<void>;
  handleStopEditing: () => void;
}) => {
  return (
    <TextField
      autoFocus
      id='outlined-basic'
      variant='standard'
      defaultValue={selectedOption.value}
      fullWidth
      label='Tags'
      helperText='Press Enter to submit'
      onKeyDown={async e => {
        // On enter if the there is no Tag with the same name as the input
        if (e.key === 'Enter') {
          await onUpdate(
            selectedOption.id,
            (e.target as HTMLInputElement).value,
          );
          handleStopEditing();
        } else if (e.key === 'Escape') {
          handleStopEditing();
        }
      }}
    />
  );
};

export function CustomOption({
  props,
  option,
  onDelete,
  onUpdate,
  onAddNew,
  sx,
}: {
  props: React.HTMLAttributes<HTMLLIElement>;
  option: Tag;
  onDelete?: ((option_id: number) => void) | undefined;
  onUpdate?: ((option_id: number) => void) | undefined;
  onAddNew?: ((name: string) => void) | undefined;
  sx?: SxProps;
}) {
  return (
    <Stack
      sx={{
        borderRadius: '8px',
        margin: '5px',
        ...(sx && { ...sx }),
      }}
      component='li'
      direction={'row'}
      justifyContent={'space-between'}
      {...props}
      {...(onAddNew ? { onClick: () => onAddNew(option.description) } : {})}
    >
      <Box>{option.description}</Box>
      <Stack
        direction={'row'}
        sx={{
          marginLeft: 'auto',
        }}
      >
        {onDelete && (
          <IconButton
            onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              e.preventDefault();
              e.stopPropagation();
              onDelete(option.id);
            }}
            sx={{
              marginLeft: 'auto',
            }}
            component={'div'}
          >
            <DeleteIcon fontSize='small' />
          </IconButton>
        )}
        {onUpdate && (
          <IconButton
            onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              e.preventDefault();
              e.stopPropagation();
              onUpdate(option.id);
            }}
            component={'div'}
          >
            <EditIcon fontSize='small' />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
}

export const CustomNoOptions = ({
  onInsert,
  value,
  inputTextValue,
  handleCreateTag,
  sx,
}: Pick<BaseMultiselectProps, 'onInsert' | 'value'> & {
  inputTextValue: string;
  handleCreateTag: () => Promise<void>;
  sx?: SxProps;
}) => {
  if (inputTextValue && value.find(v => v.value === inputTextValue)) {
    return <Typography>Tag already attached</Typography>;
  }
  if (onInsert) {
    if (inputTextValue) {
      return (
        <Button
          color='primary'
          sx={{
            justifyContent: 'flex-start',
            paddingLeft: 2,
            textTransform: 'none',
            ...(sx && { ...sx }),
          }}
          onMouseDown={handleCreateTag}
        >
          + Create new tag &ldquo;{inputTextValue}&rdquo;
        </Button>
      );
    } else {
      return (
        <Typography sx={{ ...sx }}>
          Write and press Enter to create a tag
        </Typography>
      );
    }
  } else {
    return <Typography sx={{ ...sx }}>No tag found</Typography>;
  }
};
function CustomChip({
  getTagProps,
  index,
  valueTag,
  optionId,
  onRemove,
  onDelete,
  onUpdate,
}: {
  getTagProps: unknown;
  index: number;
  valueTag: Tag;
  onRemove: (tag: Tag) => Promise<void>;
  optionId?: number | string;
  onDelete?: (tag_id: number) => void;
  onUpdate?: (tag_id: number) => void;
}) {
  // @ts-expect-error: TODO: FIXME
  // eslint-disable-next-line
  const { onDelete: _onDelete, ...props } = getTagProps({ index });
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const theme = useTheme();
  return (
    <Box sx={{ position: 'relative' }}>
      <Chip
        {...props}
        key={index}
        // eslint-disable-next-line
        size={props.size || 'small'}
        sx={{
          backgroundColor: theme.palette.grey[300],
        }}
        label={
          <Stack
            direction={'row'}
            spacing={0.5}
            alignItems={'center'}
            justifyContent={'center'}
            sx={theme => ({
              position: 'relative',
              minWidth: theme.spacing(5),
              '& .iconButtonStack': {
                display: 'none',
                position: 'absolute',
                right: 0,
                backgroundColor: theme.palette.grey[300],
              },
              '&:hover .iconButtonStack': {
                display: 'flex',
              },
            })}
          >
            <Typography variant='inherit'>{valueTag.description}</Typography>
            <Stack className='iconButtonStack' direction={'row'} spacing={0.2}>
              {/* All the actions related with an Option (e.g. Tag) need the ID of it */}
              {optionId && (onDelete || onUpdate) && (
                <IconButton
                  disableRipple
                  size='small'
                  onClick={e => {
                    setAnchorEl(anchorEl ? null : e.currentTarget);
                    e.stopPropagation();
                  }}
                  sx={{
                    padding: 0,
                  }}
                >
                  <MoreHorizIcon fontSize='small' />
                </IconButton>
              )}
              {
                <IconButton
                  disableRipple
                  size='small'
                  onClick={() => {
                    onRemove(valueTag);
                  }}
                  sx={{
                    padding: 0,
                  }}
                >
                  <CloseIcon fontSize='small' />
                </IconButton>
              }
            </Stack>
          </Stack>
        }
      />
      <Popper
        open={open}
        anchorEl={anchorEl}
        role={undefined}
        placement='bottom-start'
        transition
        disablePortal
        sx={theme => ({ zIndex: theme.zIndex.modal })}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Card sx={theme => ({ zIndex: theme.zIndex.modal })}>
              <ClickAwayListener
                onClickAway={() => {
                  setAnchorEl(null);
                }}
              >
                <MenuList
                  autoFocusItem={open}
                  id='composition-menu'
                  aria-labelledby='composition-button'
                  sx={theme => ({
                    color: theme.palette.grey[600],
                  })}
                >
                  {onDelete && optionId && (
                    <MenuItem
                      onClick={() => {
                        onDelete(optionId as number);
                        setAnchorEl(null);
                      }}
                    >
                      <DeleteIcon sx={{ marginRight: 1 }} /> Delete
                    </MenuItem>
                  )}
                  {onUpdate && optionId && (
                    <MenuItem
                      onClick={() => {
                        onUpdate(optionId as number);
                        setAnchorEl(null);
                      }}
                    >
                      <EditIcon sx={{ marginRight: 1 }} /> Update
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Card>
          </Grow>
        )}
      </Popper>
    </Box>
  );
}
