import { captureAnalyticsEvent } from 'plugins/Analytics';
import { useCallback } from 'react';
import { StartupListForShare } from './ShareStartupList';

export const useCaptureCopyLink = (currentStartupList: StartupListForShare) => {
  const captureCopyLinkEvent = useCallback(() => {
    if (!currentStartupList?.id) return;

    captureAnalyticsEvent('Copy share link', {
      startupListId: currentStartupList.id,
      startupListSource: currentStartupList.source,
      startupListDeliveredByVE:
        !!currentStartupList.sourcing_order?.delivered_date,
      startupListDeliveryDate:
        currentStartupList.sourcing_order?.delivered_date,
    });
  }, [
    currentStartupList.id,
    currentStartupList.source,
    currentStartupList.sourcing_order?.delivered_date,
  ]);

  return captureCopyLinkEvent;
};
