import { Typography } from '@mui/material';
import { BaseDialog } from 'components/base/BaseDialog';

const GrantAccessDialog = ({
  loading,
  onConfirm,
  onHide,
  requesterName,
  entityType,
  title,
}: {
  loading: boolean;
  onConfirm: () => void;
  onHide: () => void;
  requesterEmail: string;
  requesterName: string;
  entityType?: string;
  title: string;
}) => {
  return (
    <BaseDialog
      maxWidth='xs'
      loading={loading}
      heading='Grant access'
      onConfirm={onConfirm}
      confirmCTA='Grant access'
      onHide={onHide}
      cancelCTA='Decline'
    >
      <Typography>
        Do you want to grant <b>{requesterName}</b> access to{' '}
        {entityType ? `the ${entityType} ` : ''} <b>{title}</b>?
      </Typography>
    </BaseDialog>
  );
};

export { GrantAccessDialog };
