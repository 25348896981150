import { Stack, Typography, useTheme } from '@mui/material';
import { EnumTableEntityVisibilityEnum } from 'apollo/generated/sdkInnovationManager';
import { StartupListForShare } from 'components/dashboard/startupList/details/share/ShareStartupList';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import { CSSProperties } from 'react';
import OrganizationIcon from './assets/organization.svg?react';
import PrivateIcon from './assets/private.svg?react';
import TeamIcon from './assets/team.svg?react';
import { ProjectForDetail } from '../../@types/startupList';

export const ShareVisibilityIcon = ({
  visibility,
  cssProps = {},
}: {
  visibility: EnumTableEntityVisibilityEnum;
  cssProps?: CSSProperties;
}) => {
  switch (visibility) {
    case 'organization':
      return <OrganizationIcon style={cssProps} />;
    case 'team':
      return <TeamIcon style={cssProps} />;
    case 'private':
      return <PrivateIcon style={cssProps} />;
  }
};

const OptionInnerContent = ({
  mainText,
  subText,
  isMainGreenSelector = false,
  visibility,
}: {
  mainText: string;
  subText: string;
  isMainGreenSelector?: boolean;
  visibility: EnumTableEntityVisibilityEnum;
}) => {
  const { palette } = useTheme();
  const mainTextColor = isMainGreenSelector
    ? palette.primary.main
    : palette.text.primary;

  const subTextColor = isMainGreenSelector
    ? palette.primary.main
    : palette.text.secondary;

  return (
    <Stack direction='row' alignItems='center' gap={1}>
      <ShareVisibilityIcon
        cssProps={{ fill: subTextColor }}
        visibility={visibility}
      />
      <Stack>
        <Typography
          textAlign='left'
          color={mainTextColor}
          fontSize={14}
          fontWeight={isMainGreenSelector ? 'bold' : 'normal'}
        >
          {mainText}
        </Typography>
        <Typography variant='caption' color={subTextColor}>
          {subText}
        </Typography>
      </Stack>
    </Stack>
  );
};

export const OptionContent = ({
  entity,
  isMainGreenSelector = false,
}: {
  entity: StartupListForShare | ProjectForDetail;
  isMainGreenSelector?: boolean;
}) => {
  const { name } = useCurrentOrganizationFromContext();

  switch (entity.visibility) {
    case 'organization':
      return (
        <OptionInnerContent
          isMainGreenSelector={isMainGreenSelector}
          visibility={entity.visibility}
          mainText={`Everyone at ${name}`}
          subText={isMainGreenSelector ? '' : 'Can view and edit'}
        />
      );
    case 'team':
      return (
        <OptionInnerContent
          isMainGreenSelector={isMainGreenSelector}
          visibility={entity.visibility}
          mainText={entity.team?.name || 'Cross-functional'}
          subText={
            isMainGreenSelector
              ? ''
              : 'Only you, selected team and  people invited can access'
          }
        />
      );
    case 'private':
      return (
        <OptionInnerContent
          isMainGreenSelector={isMainGreenSelector}
          visibility={entity.visibility}
          mainText='Invite only'
          subText={
            isMainGreenSelector ? '' : 'Only you and people invited can access'
          }
        />
      );
  }
};
