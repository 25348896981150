import { useFormikContext } from 'formik';
import { useEffect } from 'react';

export const ConfirmRequestFormLeave = ({
  always,
  isFormSaved = false,
}: {
  always?: boolean;
  isFormSaved?: boolean;
}) => {
  const formik = useFormikContext();
  useEffect(() => {
    function listener(e: BeforeUnloadEvent) {
      if (
        always ||
        (formik.dirty && formik.submitCount === 0 && !isFormSaved)
      ) {
        e.preventDefault();
        // This message is ignored in some browsers but that shouldn't be too important
        e.returnValue = 'Changes that you made may not be saved.';
      }
    }

    window.addEventListener('beforeunload', listener);

    return () => {
      window.removeEventListener('beforeunload', listener);
    };
  });
  return null;
};
