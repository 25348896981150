// material
import { Box, BoxProps } from '@mui/material';
// ----------------------------------------------------------------------

export default function LogoLong(props: { sx?: BoxProps; png?: boolean }) {
  const { sx } = props;
  return (
    <Box sx={{ width: 150, height: 20, ...sx }}>
      <svg viewBox='0 0 772.02 131.15' xmlns='http://www.w3.org/2000/svg'>
        <title>GD Logo </title>
        <g id='Layer_2' data-name='Layer 2'>
          <g id='Layer_1-2' data-name='Layer 1'>
            <path
              className='cls-1'
              d='M100.58,131.15H35.23c-2.79,0-4.44-2.26-3.69-5L53.16,45.41a7.06,7.06,0,0,1,6.39-5H124.9c2.79,0,4.44,2.26,3.69,5L107,126.11A7.06,7.06,0,0,1,100.58,131.15Z'
            />
            <path
              className='cls-2'
              d='M84.9,111H19.55c-2.79,0-4.44-2.26-3.69-5L37.48,25.23a7.06,7.06,0,0,1,6.39-5h65.35c2.79,0,4.44,2.26,3.69,5L91.29,105.92A7.06,7.06,0,0,1,84.9,111Z'
            />
            <path
              className='cls-3'
              d='M69.22,90.78H3.87c-2.79,0-4.44-2.26-3.69-5L21.8,5A7.06,7.06,0,0,1,28.2,0H93.54C96.33,0,98,2.26,97.24,5L75.61,85.74A7.06,7.06,0,0,1,69.22,90.78Z'
            />
            <path d='M229.67,91.86q-4.75,5.68-13.42,8.82A56.21,56.21,0,0,1,197,103.82,38.07,38.07,0,0,1,177.61,99a32.24,32.24,0,0,1-12.89-14q-4.54-9.2-4.66-21.62v-5.8q0-12.77,4.31-22.12a31.86,31.86,0,0,1,12.42-14.3,35.78,35.78,0,0,1,19-5q15.18,0,23.73,7.24t10.14,21.06H212.56q-1.17-7.32-5.19-10.72t-11-3.4q-9,0-13.65,6.74t-4.75,20v5.45q0,13.42,5.1,20.27T198,89.7q9.9,0,14.12-4.22V70.77h-16V57.82h33.57Z' />
            <path d='M260.67,102.64h-17v-90h17Z' />
            <path d='M311.35,102.64a18.79,18.79,0,0,1-1.7-5.68,20.49,20.49,0,0,1-16,6.86q-9.32,0-15.44-5.39a17.33,17.33,0,0,1-6.12-13.59q0-10.08,7.47-15.47t21.59-5.45H309V60.28a10.45,10.45,0,0,0-2.26-7q-2.26-2.64-7.12-2.64a10.09,10.09,0,0,0-6.71,2.05,7,7,0,0,0-2.43,5.63H273.5a17,17,0,0,1,3.4-10.2,22.47,22.47,0,0,1,9.61-7.35,35,35,0,0,1,13.95-2.67q11.72,0,18.6,5.89t6.88,16.55V88q.06,9,2.52,13.65v1Zm-14-11.78a14.59,14.59,0,0,0,6.91-1.67A11,11,0,0,0,309,84.71V73.82h-6.33q-12.72,0-13.54,8.79l-.06,1a6.78,6.78,0,0,0,2.23,5.21A8.65,8.65,0,0,0,297.35,90.87Z' />
            <path d='M373.29,85.13a5.46,5.46,0,0,0-3.08-4.89q-3.08-1.79-9.87-3.19-22.62-4.75-22.62-19.22,0-8.44,7-14.09T363,38.07q12.07,0,19.31,5.68a17.91,17.91,0,0,1,7.24,14.77H372.64a8.19,8.19,0,0,0-2.34-6Q368,50.14,363,50.14a10.13,10.13,0,0,0-6.62,1.93A6.1,6.1,0,0,0,354,57a5.22,5.22,0,0,0,2.67,4.54q2.67,1.73,9,3a73.53,73.53,0,0,1,10.66,2.84q13.42,4.92,13.42,17.05,0,8.67-7.44,14t-19.22,5.36A33.53,33.53,0,0,1,348.94,101a23.73,23.73,0,0,1-9.7-7.79,18.11,18.11,0,0,1-3.52-10.69h16.05a8.84,8.84,0,0,0,3.34,6.91,13.24,13.24,0,0,0,8.32,2.4q4.86,0,7.35-1.85A5.74,5.74,0,0,0,373.29,85.13Z' />
            <path d='M435,85.13a5.46,5.46,0,0,0-3.08-4.89Q428.83,78.45,422,77q-22.62-4.75-22.62-19.22,0-8.44,7-14.09t18.31-5.65q12.07,0,19.31,5.68a17.91,17.91,0,0,1,7.24,14.77H434.34a8.19,8.19,0,0,0-2.34-6q-2.34-2.37-7.32-2.37a10.13,10.13,0,0,0-6.62,1.93A6.1,6.1,0,0,0,415.71,57a5.22,5.22,0,0,0,2.67,4.54q2.67,1.73,9,3A73.53,73.53,0,0,1,438,67.37q13.42,4.92,13.42,17.05,0,8.67-7.44,14t-19.22,5.36A33.54,33.54,0,0,1,410.64,101a23.73,23.73,0,0,1-9.7-7.79,18.11,18.11,0,0,1-3.52-10.69h16.05a8.84,8.84,0,0,0,3.34,6.91,13.24,13.24,0,0,0,8.32,2.4q4.86,0,7.35-1.85A5.74,5.74,0,0,0,435,85.13Z' />
            <path d='M463.46,102.64V17.33h26.25a39.87,39.87,0,0,1,20.13,5.07A35.35,35.35,0,0,1,523.7,36.81a44.46,44.46,0,0,1,5,21.24V62a44.58,44.58,0,0,1-4.89,21.15A35,35,0,0,1,510,97.49a40.16,40.16,0,0,1-20.1,5.16ZM481,31.57v57h8.5q10.31,0,15.76-6.74t5.57-19.28V58q0-13-5.39-19.72t-15.76-6.71Z' />
            <path d='M537.7,70.36a37.6,37.6,0,0,1,3.63-16.82,26.59,26.59,0,0,1,10.46-11.43,30.51,30.51,0,0,1,15.85-4q12.83,0,20.95,7.85t9.05,21.33l.12,4.34q0,14.59-8.14,23.41t-21.86,8.82q-13.71,0-21.88-8.79T537.7,71.12Zm16.93,1.2q0,9,3.4,13.82a12.21,12.21,0,0,0,19.34.06q3.46-4.72,3.46-15.11,0-8.86-3.46-13.73a11.24,11.24,0,0,0-9.73-4.87A11.06,11.06,0,0,0,558,56.57Q554.63,61.41,554.63,71.56Z' />
            <path d='M626.06,102.64h-17v-90h17Z' />
            <path d='M657.88,102.64h-17v-90h17Z' />
            <path d='M708.56,102.64a18.8,18.8,0,0,1-1.7-5.68,20.49,20.49,0,0,1-16,6.86q-9.32,0-15.44-5.39a17.34,17.34,0,0,1-6.12-13.59q0-10.08,7.47-15.47t21.59-5.45h7.79V60.28a10.45,10.45,0,0,0-2.26-7q-2.26-2.64-7.12-2.64a10.09,10.09,0,0,0-6.71,2.05,7,7,0,0,0-2.43,5.63H670.71a17,17,0,0,1,3.4-10.2,22.47,22.47,0,0,1,9.61-7.35,35,35,0,0,1,13.95-2.67q11.72,0,18.6,5.89t6.88,16.55V88q.06,9,2.52,13.65v1Zm-14-11.78a14.58,14.58,0,0,0,6.91-1.67,11,11,0,0,0,4.69-4.48V73.82h-6.33q-12.71,0-13.54,8.79l-.06,1a6.78,6.78,0,0,0,2.23,5.21A8.65,8.65,0,0,0,694.56,90.87Z' />
            <path d='M771.78,55.13a46.09,46.09,0,0,0-6.09-.47q-9.61,0-12.6,6.5v41.48H736.16V39.25h16l.47,7.56q5.1-8.73,14.12-8.73a17.76,17.76,0,0,1,5.27.76Z' />
          </g>
        </g>
      </svg>
    </Box>
  );
}
