import { Stack, Typography } from '@mui/material';
import { PATH_ROOT, buildProjectDetailsPath } from 'routes/paths';
import { ListedInStartupLists } from './StartupInfoSidePanel';
import { captureMessage } from '@sentry/react';
import { uniqBy } from 'lodash';
import { RouterLink } from 'components/RouterLink';
import LightTooltip from 'components/LightTooltip';
import BaseEllipsisTypographyOneLine from 'components/base/BaseEllipsisTypographyOneline';
import { EnumTableProjectStagesEnum } from 'apollo/generated/sdkInnovationManager';

export type CurrentStartupForListedInSummary = {
  selected_in_startup_lists: ListedInStartupLists[];
  shortlisted_in_startup_lists: ListedInStartupLists[];
  listed_in_startup_lists: ListedInStartupLists[];
};

export const ListedInSummary = ({
  currentStartup,
}: {
  currentStartup: CurrentStartupForListedInSummary;
}) => {
  const selectedInProjects = currentStartup.selected_in_startup_lists
    .map(list => {
      const projectId = list.project?.id;
      const project = list.project;

      if (!projectId || !project) {
        captureMessage(`Project id is null for List ${list.id}`);
        return null;
      }

      return {
        id: projectId,
        type: 'selected',
        title: project.title,
        stage: project.stage,
        listId: list.id,
      };
    })
    .filter(Boolean) as Array<{
    id: number;
    type: 'selected';
    title: string;
    stage: EnumTableProjectStagesEnum;
    listId: number;
  }>;

  const shortlistedInProjects = currentStartup.shortlisted_in_startup_lists
    .map(list => {
      const projectId = list.project?.id;
      const project = list.project;

      if (!projectId || !project) {
        captureMessage(`Project id is null for List ${list.id}`);
        return null;
      }

      return {
        id: projectId,
        type: 'shortlisted',
        title: project.title,
        stage: project.stage,
        listId: list.id,
      };
    })
    .filter(Boolean) as Array<{
    id: number;
    type: 'shortlisted';
    title: string;
    stage: EnumTableProjectStagesEnum;
    listId: number;
  }>;

  const includedInProjects = uniqBy(
    [...selectedInProjects, ...shortlistedInProjects],
    'id',
  );

  const includedInLists = currentStartup.listed_in_startup_lists.filter(
    list => !includedInProjects.map(p => p.listId).includes(list.id),
  );

  if (includedInProjects.length === 0 && includedInLists.length === 0) {
    return null;
  }

  return (
    <Stack direction='row' spacing={0.5} alignItems='center'>
      <Typography variant='caption' color='textSecondary'>
        Part of
      </Typography>
      {includedInProjects.length > 0 && (
        <LightTooltip
          placement='right'
          title={
            <Stack>
              {includedInProjects.map(project => (
                <BaseEllipsisTypographyOneLine
                  variant='caption'
                  key={`project-${project.id}`}
                >
                  <RouterLink
                    to={buildProjectDetailsPath({
                      id: project.id,
                      stage: project.stage,
                    })}
                  >
                    {project.title}
                  </RouterLink>
                </BaseEllipsisTypographyOneLine>
              ))}
            </Stack>
          }
        >
          <Typography
            variant='caption'
            sx={{
              cursor: 'default',
            }}
          >
            {includedInProjects.length} PoC
            {includedInProjects.length > 1 && 's'}
          </Typography>
        </LightTooltip>
      )}
      {includedInProjects.length > 0 && includedInLists.length > 0 && (
        <Stack alignItems='center' textAlign='center'>
          <Typography variant='caption' color='textSecondary'>
            &
          </Typography>
        </Stack>
      )}

      {includedInLists.length > 0 && (
        <LightTooltip
          placement='right'
          title={
            <Stack>
              {includedInLists.map(list => (
                <BaseEllipsisTypographyOneLine
                  variant='caption'
                  key={`list${list.id}`}
                >
                  <RouterLink to={PATH_ROOT.lists.details(list.id)}>
                    {list.title}
                  </RouterLink>
                </BaseEllipsisTypographyOneLine>
              ))}
            </Stack>
          }
        >
          <Typography
            variant='caption'
            sx={{
              cursor: 'default',
            }}
          >
            {includedInLists.length} List
            {includedInLists.length > 1 && 's'}
          </Typography>
        </LightTooltip>
      )}
    </Stack>
  );
};
