import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
//
import enLocales from './en.json';
import deLocales from './de.json';

// ----------------------------------------------------------------------

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: { translations: enLocales },
      de: { translations: deLocales },
    },
    detection: {
      order: [
        'querystring',
        // The cookie contained invalid value of "o"
        // 'cookie',
        'localStorage',
        'sessionStorage',
        'navigator',
      ],
    },
    fallbackLng: 'en',
    debug: true,
    ns: ['translations'],
    defaultNS: 'translations',
  });

export default i18n;
