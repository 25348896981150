import {
  useGetDiscoveryItemsMinimalLazyQuery,
  useGetOrganizationStartupsSearchMinimalLazyQuery,
  useGetProjectSearchMinimalLazyQuery,
  useGetStartupListsMinimalLazyQuery,
} from 'apollo/generated/sdkInnovationManager';
import { Suppliers } from 'apollo/generated/sdkShared';
import { ChangeEvent } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { E2E_STAGES } from 'utils/projectStageEnum';

export type StartupResultForGoToSearch = Pick<
  Suppliers,
  'id' | 'domain' | 'name'
>;

const useSearch = () => {
  const [searchStartups, { data: startupsData, loading }] =
    useGetOrganizationStartupsSearchMinimalLazyQuery();
  const [searchProjects, { data: projectsData, loading: projectsLoading }] =
    useGetProjectSearchMinimalLazyQuery();
  const [
    searchStartupLists,
    { data: startupListsData, loading: startupListsLoading },
  ] = useGetStartupListsMinimalLazyQuery();
  const [
    searchDiscoveryItems,
    { data: discoveryItemsData, loading: discoveryItemsLoading },
  ] = useGetDiscoveryItemsMinimalLazyQuery();

  const handleSearch = useDebouncedCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = e.target;

      if (!value || value.length <= 2) return;

      // It is important to use a percentage sign here, otherwise the search will not work properly

      Promise.all([
        searchProjects({
          variables: { search: `%${value}%`, inStages: E2E_STAGES },
        }),
        searchStartups({ variables: { search: `%${value}%` } }),
        searchStartupLists({ variables: { search: `%${value}%` } }),
        searchDiscoveryItems({ variables: { search: `%${value}%` } }),
      ]);
    },
    300,
  );

  return {
    handleSearch,
    loading:
      loading ||
      projectsLoading ||
      startupListsLoading ||
      discoveryItemsLoading,
    projectsData,
    startupsData,
    startupListsData,
    discoveryItemsData,
  };
};

export { useSearch };
