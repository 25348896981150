import { useGridApiContext } from '@mui/x-data-grid-pro';
import { CustomFeatureHeader } from '../../@types/shared';
import { useEffect } from 'react';
import { TREND_CHART_WIDTH_WITH_PADDING } from './fields';

const useSetInitialHeaderWidth = ({
  textRef,
  type,
  field,
  readonly,
}: {
  textRef: React.RefObject<HTMLDivElement>;
  type: CustomFeatureHeader['type'];
  field: string;
  readonly?: boolean;
}) => {
  const apiRef = useGridApiContext();
  const additionalSpace = readonly ? 20 : 60;

  useEffect(() => {
    if (!textRef.current || !apiRef.current) return;

    const width = textRef.current?.clientWidth + additionalSpace || 0;

    if (type === 'line') {
      apiRef.current?.setColumnWidth(
        field,
        Math.max(width, TREND_CHART_WIDTH_WITH_PADDING),
      );
    } else {
      apiRef.current?.setColumnWidth(
        field,
        textRef.current?.clientWidth + additionalSpace || 0,
      );
    }
    // Don't re-run this on textRef and apiRef changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalSpace, field, type]);
};

export { useSetInitialHeaderWidth };
