import { Stack } from '@mui/material';
import { BusinessUnitPersonRow } from 'contexts/PersonTableContext';
import { buildProjectDetailsPath } from 'routes/paths';
import LinkToPocOrLead from './LinkToPocOrLead';

export function LeadsInvolvement({
  items,
}: {
  items: BusinessUnitPersonRow['projects']['projects_involved_in'];
}) {
  return (
    <Stack overflow='hidden' gap={1}>
      {items.map(lead => {
        const project = lead?.project;
        if (!project) return null;

        return (
          <Stack
            key={`${lead.id}-${lead.role}`}
            direction='row'
            spacing={0.5}
            alignItems='center'
          >
            <LinkToPocOrLead
              item={project}
              to={buildProjectDetailsPath({
                id: project.id,
                stage: project.stage,
              })}
            />
          </Stack>
        );
      })}
    </Stack>
  );
}
