import {
  useGetDeliverablesAndMilestonesQuery,
  useGetPocImpactedMetricsQuery,
  useGetPocKpisQuery,
} from 'apollo/generated/sdkInnovationManager';
import { useMemo } from 'react';

export const useGetKpisMetricsAndDeliverables = ({
  projectId,
  startupId,
}: {
  projectId: number;
  startupId?: number | null;
}) => {
  const {
    data: deliverablesData,
    loading: deliverablesLoading,
    error: deliverablesError,
  } = useGetDeliverablesAndMilestonesQuery({
    variables: {
      projectId: projectId,
      startupId: startupId!,
    },
    skip: !startupId,
  });
  const {
    data: kpisData,
    loading: kpisLoading,
    error: kpisError,
  } = useGetPocKpisQuery({
    variables: {
      projectId: projectId,
      startupId: startupId!,
    },
    skip: !startupId,
  });
  const {
    data: impactMetricsData,
    loading: impactMetricsLoading,
    error: impactMetricsError,
  } = useGetPocImpactedMetricsQuery({
    variables: {
      projectId: projectId,
      startupId: startupId!,
    },
    skip: !startupId,
  });

  const pocKpis = useMemo(() => kpisData?.poc_kpis || [], [kpisData?.poc_kpis]);

  const pocDeliverables = useMemo(
    () => deliverablesData?.poc_deliverables_and_milestones || [],
    [deliverablesData?.poc_deliverables_and_milestones],
  );

  const pocImpactMetrics = useMemo(
    () => impactMetricsData?.poc_impacted_metrics || [],
    [impactMetricsData?.poc_impacted_metrics],
  );

  return {
    pocKpis,
    pocDeliverables,
    pocImpactMetrics,
    loading: kpisLoading || deliverablesLoading || impactMetricsLoading,
    error: !!kpisError || !!deliverablesError || !!impactMetricsError,
    errorData: kpisError
      ? kpisError
      : deliverablesError
        ? deliverablesError
        : impactMetricsError,
  };
};
