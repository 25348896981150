import { List, PersonSearch } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Divider,
  Fade,
  IconButton,
  ListItem,
  ListItemAvatar,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { GetStartupListsMinimalQuery } from 'apollo/generated/sdkInnovationManager';
import { AnimatePresence, motion } from 'framer-motion';
import { useSharedPagesContext } from 'layouts/SharedPagesLayout';
import { take } from 'lodash';
import { ReactNode, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { PATH_ROOT } from 'routes/paths';
import DiscoverIcon from '../assets/binoculars.svg?react';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import TypographyWithEllipsis from 'components/TypographyWithEllipsis';
import LightTooltip from 'components/LightTooltip';

type ResultType = 'Lists' | 'Discovery Items';

type Props = {
  onClose: () => void;
  startupLists: GetStartupListsMinimalQuery['startup_lists'];
  label: ResultType;
  navigateTo: (id?: number, public_uuid?: string) => void;
};

function StartupListResults({
  onClose,
  startupLists,
  label,
  navigateTo,
}: Props) {
  const { shadows, spacing, palette } = useTheme();
  const navigate = useNavigate();
  const results = useMemo(() => take(startupLists || [], 5), [startupLists]);
  const { isSharedPage } = useSharedPagesContext();
  const iconstMap: {
    [key in ResultType]: ReactNode;
  } = {
    'Discovery Items': <DiscoverIcon style={{ fill: palette.grey[600] }} />,
    Lists: <List />,
  };
  const ResultsIcon = iconstMap[label];

  return (
    <Fade in={results.length > 0} unmountOnExit mountOnEnter>
      <Box>
        <Divider />
        <MenuList>
          <ListItem sx={{ background: palette.grey[200] }}>
            <ListItemAvatar>
              <Avatar
                sx={{
                  width: spacing(4),
                  height: spacing(4),
                  background: palette.grey[0],
                  boxShadow: shadows[1],
                }}
              >
                {ResultsIcon}
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={label} />
          </ListItem>
          <AnimatePresence>
            {results.map(({ id, sourcing_order, title, public_uuid }) => {
              return (
                <motion.div
                  key={id}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <MenuItem
                    //Children elements is stopping this event from being triggered onClick
                    onMouseUp={e => {
                      e.stopPropagation();
                      captureAnalyticsEvent('App Search > Result clicked', {
                        type:
                          label === 'Lists' ? 'startup_list' : 'discovery_item',
                        recordId: id,
                      });
                      onClose();
                      navigateTo(id, public_uuid);
                    }}
                  >
                    <ListItemText
                      primaryTypographyProps={{
                        style: {
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        },
                      }}
                      sx={{
                        '& .MuiListItemText-primary': {
                          display: 'flex',
                        },
                      }}
                    >
                      <TypographyWithEllipsis
                        tooltipTitle={
                          <Typography variant='body2'>{title}</Typography>
                        }
                        TooltipComponent={props => <LightTooltip {...props} />}
                        placement='right'
                      >
                        {title}
                      </TypographyWithEllipsis>
                      {!isSharedPage && sourcing_order && (
                        <Tooltip title='View sourcing order'>
                          <IconButton
                            size='small'
                            sx={{ marginLeft: 'auto' }}
                            onClick={e => {
                              e.stopPropagation();
                              navigate(PATH_ROOT.sourcings.orderOnList(id!));
                            }}
                          >
                            <PersonSearch fontSize='small' />
                          </IconButton>
                        </Tooltip>
                      )}
                    </ListItemText>
                  </MenuItem>
                </motion.div>
              );
            })}
          </AnimatePresence>
        </MenuList>
      </Box>
    </Fade>
  );
}

export { StartupListResults };
