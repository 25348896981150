import { SxProps } from '@mui/material';
import {
  usePersonTagsMultiselectDeletePersonTagMutation,
  usePersonTagsMultiselectDeleteTagMutation,
  usePersonTagsMultiselectFindTagsQuery,
  usePersonTagsMultiselectInsertPersonTagMutation,
  usePersonTagsMultiselectInsertTagMutation,
  usePersonTagsMultiselectUpdateTagMutation,
} from 'apollo/generated/sdkShared';
import { Tag, ValueTag } from 'components/base/BaseMultiselect';
import { BaseTagsSelect } from 'components/base/BaseTagsSelect';
import { useState } from 'react';

export const PersonTagsMultiselect = ({
  stakeholderId,
  stakeholderTags,
  sx,
}: {
  stakeholderId: number;
  stakeholderTags: { id: number; name: string }[];
  sx: SxProps;
}) => {
  const { data } = usePersonTagsMultiselectFindTagsQuery();
  const [insertPersonTags] = usePersonTagsMultiselectInsertPersonTagMutation();
  const [deletePersonTags] = usePersonTagsMultiselectDeletePersonTagMutation();

  const [insertTag] = usePersonTagsMultiselectInsertTagMutation();
  const [deleteTag] = usePersonTagsMultiselectDeleteTagMutation();
  const [updateTag] = usePersonTagsMultiselectUpdateTagMutation();

  const options: Tag[] = (data?.stakeholder_tags || []).map(t => ({
    id: t.id,
    value: t.name,
    description: t.name,
  }));

  const [addedTagIds, setAddedTagIds] = useState<number[]>(
    (data?.stakeholder_tags || []).map(st => st.id),
  );

  const value: ValueTag[] = (stakeholderTags || []).map(t => ({
    id: t.id,
    value: t.name,
    description: t.name,
    option_id: options.find(o => o.value === t.name)?.id,
  }));

  const addPersonTag = async (stakeholderId: number, newTagId: number) => {
    const res = await insertPersonTags({
      variables: {
        person_id: stakeholderId,
        tag_id: newTagId,
      },
    });

    const personTag = res.data?.insert_people_stakeholder_tags_one;
    personTag?.id && setAddedTagIds(prev => [...prev, personTag.id]);
  };

  const onAdd = async (tag_name: string) => {
    const option = options.find(o => o.value === tag_name);

    if (!option) return;
    await addPersonTag(stakeholderId, option.id);
  };

  const onInsert = async (name: string) => {
    const res = await insertTag({
      variables: { name },
      update(cache, result) {
        const newPersonTag = result.data?.insert_stakeholder_tags_one;

        if (!newPersonTag) return;

        cache.modify({
          fields: {
            stakeholder_tags(existingTags = [], { toReference }) {
              return [...existingTags, toReference(newPersonTag)];
            },
          },
        });
      },
    });

    const newTag = res.data?.insert_stakeholder_tags_one;
    if (newTag?.id) {
      await addPersonTag(stakeholderId, newTag.id);
    }
  };

  const onRemove = async (tag: Tag) => {
    await deletePersonTags({
      variables: {
        id: tag.id as number,
      },
      update: (cache, { data }) => {
        if (!data?.delete_people_stakeholder_tags_by_pk?.id) {
          return;
        }

        cache.evict({
          id: cache.identify({
            __typename: 'people_stakeholder_tags',
            id: data.delete_people_stakeholder_tags_by_pk.id,
          }),
        });
        cache.gc();
      },
    });
  };

  const onDelete = async (tag_id: number) => {
    await deleteTag({
      variables: {
        id: tag_id,
      },
      update: (cache, { data }) => {
        if (!data?.delete_stakeholder_tags_by_pk?.id) {
          return;
        }

        cache.evict({
          id: cache.identify({
            __typename: 'stakeholder_tags',
            id: data.delete_stakeholder_tags_by_pk.id,
          }),
        });
        cache.gc();
      },
    });
  };

  const onUpdate = async (tag_id: number, name: string) => {
    await updateTag({
      variables: {
        id: tag_id,
        name: name,
      },
    });
  };

  return (
    <BaseTagsSelect
      sx={sx}
      options={options}
      value={value}
      onAdd={onAdd}
      onRemove={onRemove}
      // Tag management actions
      onInsert={onInsert}
      onDelete={onDelete}
      onUpdate={onUpdate}
      addedTagIds={addedTagIds}
    />
  );
};
