import { Link, Text } from '@react-pdf/renderer';
import { parseLinksInText } from '../utils';

export const TextFeatureValue = ({ value }: { value: string }) => {
  const normalizedValue = (value || '-')
    .trim()
    .replace(/\r\n/g, ' ')
    .replace(/\n/g, ' ');
  const enrichedValue = parseLinksInText({
    textToParse: normalizedValue,
    renderLink: (link, domain) => (
      <Link src={link} key={domain}>
        <Text>{domain}</Text>
      </Link>
    ),
  });

  // maxHeight: 30pt, assuming each text feature is a single line and longest one is up to 240 characters
  return (
    <Text style={{ textAlign: 'left', maxHeight: 30 }}>{enrichedValue}</Text>
  );
};
