import { styled } from '@mui/material/styles';
import { DataGridPro, DataGridProProps } from '@mui/x-data-grid-pro';
import { DecoratedStartup } from './shared/StartupsTableContext';

const _DataGridComparisonMatrix = (
  params: DataGridProProps<DecoratedStartup>,
) => <DataGridPro {...params} />;

export const TOP_OFFSET_DEFAULT = 62;
export const TOP_OFFSET_FULL_SCREEN = 72;
export const TOP_OFFSET_ZERO = 0;

export const StyledDataGridForStartupComparison = styled(
  _DataGridComparisonMatrix,
)<{
  isReadonly: boolean;
}>(({ theme, isReadonly }) => ({
  '& .MuiDataGrid-renderingZone': {
    maxHeight: 'fit-content !important',
  },

  '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
    outline: 'none',
    border: 'none',
  },

  '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
    outline: 'none',
    border: 'none',
  },

  ...(!isReadonly && {
    '&.MuiDataGrid-root .MuiDataGrid-cell.custom-column-cell:focus': {
      outline: theme.typography.pxToRem(1),
      border: `1px solid ${theme.palette.primary.main}`,
    },
  }),

  '& .MuiDataGrid-columnHeader': {
    paddingLeft: '5px',
  },

  '& .MuiDataGrid-cellCheckbox .MuiCheckbox-root.Mui-disabled': {
    color: theme.palette.primary.main,
  },

  '& .MuiDataGrid-cell': {
    overflow: 'visible !important',
    maxHeight: 'fit-content !important',
    minHeight: '-webkit-fill-available !important',
    padding: '10px 5px',
    border: '1px solid rgba(145, 158, 171, 0.08) ',
    '&:is([data-field="__reorder__"])': {
      background: theme.palette.grey['50012'],
    },
    '&:is([data-field="referenced_customers"])': {
      background: theme.palette.grey['50012'],
    },
    '&:is([data-field="short_description"])': {
      background: theme.palette.grey['50012'],
    },
    '&:is([data-field="hq"])': { background: theme.palette.grey['50012'] },
  },

  '& .MuiDataGrid-row': {
    maxHeight: 'none !important',
    height: '80px',
  },

  '& .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },

  '&.MuiDataGrid-root .MuiDataGrid-columnHeaders': {
    zIndex: 2,
  },

  '& .MuiDataGrid-pinnedColumnHeaders--right': {
    display: 'none !important',
  },

  '& .MuiDataGrid-pinnedColumns--right': {
    boxShadow: 'unset',
    background: 'transparent',

    '& .MuiDataGrid-row': {
      background: 'transparent !important',
      padding: '0px !important',
    },

    '& .MuiDataGrid-cell': {
      borderLeft: 'none',
      background: 'transparent !important',
    },
  },

  '& .MuiDataGrid-pinnedColumns': {
    boxShadow: 'unset',
  },

  '& .actions-header': {
    '& > .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
  },
  '& .key-highlight-cell': {
    padding: '5px',
    cursor: 'pointer',
  },

  '& .company-details': {
    cursor: 'pointer',
    padding: '0px !important',
    '& > div': {
      paddingLeft: theme.spacing(2),
      display: 'flex',
      flex: 1,
      cursor: 'pointer',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
    },
  },

  '& .MuiDataGrid-cell:hover .company-name': {
    textDecoration: 'underline',
  },
}));
