const PDF_FORMAT = 'application/pdf';
const CSV_FORMAT = 'text/csv;charset=utf-8;';

const downloadFile = ({
  content,
  title,
  format,
}: {
  content: string | Blob;
  title: string;
  format: string;
}) => {
  const dataBlob =
    typeof content === 'string'
      ? new Blob([content], { type: format })
      : content;

  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(dataBlob);
  link.setAttribute('download', title);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export { downloadFile, PDF_FORMAT, CSV_FORMAT };
