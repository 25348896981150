// check if the date is between 19 october and 20 october

import { Stack, Typography } from '@mui/material';
import MaintenanceIllustration from 'assets/illustration_maintenance';

// if it is, show the maintenance page
import { isAfter, isBefore } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export const isEnabledPeriod = () => {
  const timeZone = 'Europe/Berlin'; // CET timezone

  // Get the current date/time in the user's local timezone
  const today = new Date();

  // Convert user's local time to CET
  const todayInCET = utcToZonedTime(today, timeZone);

  // Define the start date in CET: October 20, 2024, 10:00 AM
  const start = utcToZonedTime(new Date(2024, 9, 20, 10, 0, 0), timeZone);

  // Define the end date in CET: October 21, 2024, midnight (00:00)
  const end = utcToZonedTime(new Date(2024, 9, 20, 13, 0, 0), timeZone);

  // Check if the current time (in CET) is within the maintenance window
  return isAfter(todayInCET, start) && isBefore(todayInCET, end);
};

export const MaintenancePage = () => (
  <Stack
    gap={10}
    width='100%'
    height='100%'
    alignContent='center'
    justifyContent='center'
    alignItems='center'
  >
    <Typography variant='h6' textAlign='center' fontWeight='100'>
      We are currently undergoing maintenance. <br /> Please check back later.
    </Typography>
    <MaintenanceIllustration sx={{ width: '30%' }} />
  </Stack>
);
