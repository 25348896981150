import { useApolloClient } from '@apollo/client';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
} from '@mui/material';
import { captureException } from '@sentry/react';
import {
  useArchiveChallengeListConnectionMutation,
  useArchiveChallengeProjectConnectionMutation,
} from 'apollo/generated/sdkShared';
import { useSnackbar } from 'notistack';

export const UnlinkChallengeConnectionModal = ({
  open,
  onHide,
  challengeId,
  startupListId,
  projectId,
  title = 'Connection',
  type,
}: {
  open: boolean;
  onHide: () => void;
  challengeId: number;
  startupListId?: number;
  projectId?: number;
  title?: string;
  type: 'startup_list' | 'project';
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [archiveChallengeListConnection] =
    useArchiveChallengeListConnectionMutation();
  const [archiveChallengeProjectConnection] =
    useArchiveChallengeProjectConnectionMutation();

  const { cache: apolloCache } = useApolloClient();

  const handleUnlinkChallenge = async () => {
    try {
      let archiveResponse;
      type === 'startup_list'
        ? (archiveResponse = await archiveChallengeListConnection({
            variables: {
              challengeId: challengeId,
              startupListId: startupListId!,
            },
          }))
        : (archiveResponse = await archiveChallengeProjectConnection({
            variables: {
              challengeId: challengeId,
              projectId: projectId!,
            },
          }));

      if (type === 'startup_list') {
        apolloCache.evict({
          id: apolloCache.identify({
            __typename: 'startup_lists',
            id: startupListId,
          }),
        });
      } else {
        apolloCache.evict({
          id: apolloCache.identify({
            __typename: 'projects',
            id: projectId,
          }),
        });
      }

      apolloCache.evict({
        id: apolloCache.identify({
          __typename: 'challenge_connections',
          id: archiveResponse.data?.update_challenge_connections?.returning[0]
            .id,
        }),
      });

      apolloCache.gc();

      enqueueSnackbar('Unlinking successful', {
        variant: 'success',
      });
      onHide();
    } catch (error) {
      captureException(error);
      enqueueSnackbar(`Unlinking failed`, {
        variant: 'error',
      });
    }
  };
  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ pb: 0 }}>
        <Typography sx={{ marginTop: 2 }}>
          Are you sure you want to unlink {title}?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant='text' color='inherit' onClick={onHide}>
          Cancel
        </Button>
        <Button
          onClick={() => handleUnlinkChallenge()}
          variant='contained'
          color='error'
        >
          Unlink
        </Button>
      </DialogActions>
    </Dialog>
  );
};
