import { Typography } from '@mui/material';
import { Dispatch, SetStateAction, useEffect } from 'react';
import {
  GetStartupOutboundRequestsDocument,
  useInitiateStartupOutreachByGdMutation,
} from 'apollo/generated/sdkShared';
import { useNavigate } from 'react-router';
import { PATH_ROOT } from 'routes/paths';
import useAuth from 'hooks/useAuth';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import { useSnackbar } from 'notistack';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { useNotifySlackForConnectionRequestMutation } from 'apollo/generated/sdkInnovationManager';
import { captureException } from '@sentry/react';
import { OutreachStartupProps } from './types';

export const GDOutreachTab = ({
  startup,
  setActionButtonProps,
  project,
}: {
  startup: OutreachStartupProps;
  setActionButtonProps: Dispatch<
    SetStateAction<{
      label: string;
      disabled: boolean;
      onClick: () => void;
    }>
  >;
  project?: { id?: number; name?: string };
}) => {
  const navigate = useNavigate();
  const { user: currentUser } = useAuth();
  const currentOrganization = useCurrentOrganizationFromContext();
  const { enqueueSnackbar } = useSnackbar();

  const [initiateStartupOutreachByGD] =
    useInitiateStartupOutreachByGdMutation();
  const [sendSlackNotification] = useNotifySlackForConnectionRequestMutation();

  useEffect(() => {
    setActionButtonProps({
      label: 'Request introduction',
      disabled: false,
      onClick: async () => {
        try {
          await initiateStartupOutreachByGD({
            variables: {
              startup_id: startup.id,
            },
            refetchQueries: [{ query: GetStartupOutboundRequestsDocument }],
          });

          await sendSlackNotification({
            variables: {
              payload: {
                connectionRequest: {
                  requesterName: currentUser.displayName,
                  requesterEmail: currentUser.email,
                  organizationName: currentOrganization.name,
                  startupDomain: startup.domain,
                  requestedFrom: 'startup_profile',
                  ...(project?.name ? { projectName: project.name } : {}),
                  startupName: startup.name,
                },
              },
            },
          });

          captureAnalyticsEvent('Outreach by GD requested.', {
            startupId: startup.id,
            startupName: startup.name,
          });
          navigate(PATH_ROOT.startups.requests),
            enqueueSnackbar(
              'Connection request added successfully. We will connect you via e-mail.',
              {
                variant: 'success',
              },
            );
        } catch (error) {
          captureException(error);
          enqueueSnackbar(
            'Error requesting connection. Please try again later.',
            { variant: 'error' },
          );
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Typography marginX={3} marginTop={3} marginBottom={2}>
        We&lsquo;ll introduce you to{' '}
        <Typography
          component={'span'}
          sx={{ fontWeight: 'bold', display: 'inline' }}
        >
          {startup.name}
        </Typography>{' '}
        via email and get back to you within 7 days.
      </Typography>
      <Typography marginX={3}>
        You can continue the conversation directly from there.
      </Typography>
    </>
  );
};
