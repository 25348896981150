import { useMemo } from 'react';
import { CategorizedStartupForMatrix } from './shared/StartupsTableContext';

export const useCategoryShortAndLongListSplit = (
  categorizedStartups: CategorizedStartupForMatrix[],
) => {
  return useMemo(
    () => ({
      longlist: (categorizedStartups || []).filter(
        x => !x.is_in_matrix && !x.is_archived,
      ),
      shortlist: (categorizedStartups || []).filter(
        x => x.is_in_matrix && !x.is_archived,
      ),
    }),
    [categorizedStartups],
  );
};
