import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
// components
import { Box } from '@mui/material';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(() => ({
  justifyContent: 'center',
  display: 'flex',
}));

export default function AuthHeader() {
  const organization = useCurrentOrganizationFromContext();

  return (
    <HeaderStyle>
      <RouterLink to='/'>
        <Box
          sx={{ width: 100, objectFit: 'contain', borderRadius: 0.5 }}
          component={'img'}
          src={organization.logo_url}
        ></Box>
      </RouterLink>
    </HeaderStyle>
  );
}
