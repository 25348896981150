import { PlaylistRemove } from '@mui/icons-material';
import {
  Avatar,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import { GridEnrichedColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useRightTableActions } from 'components/base/useRightTableActions';
import { useContext, useMemo } from 'react';
import { StyledDataGridForStartupComparison } from '../StyledDataGridForStartupComparison';
import { AddNewStartupField } from './AddNewStartupField';
import { DecoratedStartup, StartupsTableContext } from './StartupsTableContext';
import { FALLBACK_SUPPLIER_LOGO_URL } from 'components/AvatarGroup';

const StyledStack = styled(Stack)({
  '& >:not(style)+:not(style)': {
    marginLeft: '0px !important',
  },
});

const useGetColumns = ({
  onRemoveStartupRow,
}: {
  onRemoveStartupRow: () => void;
}) => {
  const { currentStartupList, category } = useContext(StartupsTableContext)!;
  const { row, pinnedRightColumn, getActionsColumn } = useRightTableActions();
  const columns = useMemo(
    () => [
      {
        field: 'name',
        flex: 2,
        renderCell: (
          params: GridRenderCellParams<string, DecoratedStartup>,
        ) => {
          const { order, logo_url } = params.row;
          return (
            <StyledStack
              direction='row'
              gap={0}
              alignItems='center'
              width='100%'
              sx={{
                cursor: 'pointer',
              }}
            >
              <Stack
                direction='row'
                alignItems='center'
                alignContent='center'
                spacing={2}
                sx={{
                  marginRight: 2,
                }}
              >
                <Typography
                  sx={{
                    margin: '5px',
                    color: ({ palette }) => palette.grey['50048'],
                  }}
                  variant='h5'
                >
                  {order}
                </Typography>
                <Avatar
                  sx={{ height: '30px', width: '30px' }}
                  src={logo_url || FALLBACK_SUPPLIER_LOGO_URL!}
                ></Avatar>
              </Stack>
              <AddNewStartupField
                projectCategoryId={category.id}
                startupListId={currentStartupList.id}
                onRemoveStartupRow={onRemoveStartupRow}
              />
            </StyledStack>
          );
        },
        disableColumnMenu: true,
        sortable: false,
        hideable: false,
        disableReorder: true,
        filterable: false,
        pinnable: false,
      },
      getActionsColumn({
        minWidth: 140,
        renderCellNode: () => (
          <Stack
            direction='row'
            justifyContent='center'
            alignItems='center'
            width='100%'
          >
            <Tooltip title='Remove row'>
              <IconButton size='small' onClick={() => onRemoveStartupRow()}>
                <PlaylistRemove fontSize='small' color='inherit' />
              </IconButton>
            </Tooltip>
          </Stack>
        ),
      }) as GridEnrichedColDef<DecoratedStartup, unknown, unknown>,
    ],
    [getActionsColumn, currentStartupList, category, onRemoveStartupRow],
  );
  return { row, columns, pinnedRightColumn };
};

export const NewStartupEmptyRowGrid = ({
  rows,
  onRemoveStartupRow,
  isEmbeddedInProject,
}: {
  rows: DecoratedStartup[];
  onRemoveStartupRow: () => void;
  isEmbeddedInProject?: boolean;
}) => {
  const { columns, row, pinnedRightColumn } = useGetColumns({
    onRemoveStartupRow,
  });

  return (
    <StyledDataGridForStartupComparison
      initialState={{
        pinnedColumns: {
          right: pinnedRightColumn,
        },
      }}
      rows={rows}
      sx={{
        border: 'none',
        borderBottom: '1px solid rgba(241, 243, 244, 1)',
        '& .MuiDataGrid-cell': {
          border: 'none',
        },
        '& .MuiDataGrid-row': {
          height: '0px !important',
          border: 'none !important',
        },
        '& .MuiDataGrid-columnHeaders': {
          display: 'none',
        },
        '& .MuiDataGrid-row .MuiDataGrid-rowReorderCell--draggable': {
          color: ({ palette }) => palette.grey['50048'],
        },
        '& .MuiDataGrid-cell:hover': {
          backgroundColor: 'transparent !important',
        },
      }}
      columns={columns}
      autoHeight
      componentsProps={{ row }}
      isRowSelectable={() => false}
      rowHeight={60}
      rowReordering={isEmbeddedInProject ? false : true}
      headerHeight={0}
      isReadonly={false}
      hideFooter
      disableSelectionOnClick
    />
  );
};
