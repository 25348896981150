import { KeyboardArrowUp } from '@mui/icons-material';
import {
  Card,
  Collapse,
  Fade,
  IconButton,
  Skeleton,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';
import { useGetStartupsForListChartsQuery } from 'apollo/generated/sdkInnovationManager';
import { Suppliers } from 'apollo/generated/sdkShared';
import FoundingYearBarChart, {
  getFoundingYearChartDefinition,
} from 'components/charts/FoundingYearBarChart';
import TotalFundingBarChart, {
  getTotalFundingChartDefinition,
} from 'components/charts/TotalFundingBarChart';
import TrendLineChart, {
  DEFAULT_TREND_HEIGHT,
} from 'components/charts/TrendLineChart';
import sumBy from 'lodash/sumBy';
import { useEffect, useMemo, useRef, useState } from 'react';
import { formatMoney } from 'utils/formatNumber';
import { calculateFundingTotal } from 'utils/projects';
import CategoryReferenceCustomers from './CategoryReferenceCustomers';
import { CorporateCustomer } from './StartupInfoSidePanel/useStartupDetailsFormik';

export type StartupForLandscapeCharts = Pick<
  Suppliers,
  'id' | 'name' | 'hq' | 'funding' | 'founded_year' | 'logo_url'
> & {
  startup_corporate_customers: {
    corporate_customer: CorporateCustomer;
  }[];
};

const trendSx = {
  flexGrow: 1,
  marginX: 1,
  width: '100%',
  height: DEFAULT_TREND_HEIGHT,
};

export const StartupsCharts = ({
  startupIds,
  height = 250,
  setHeight,
}: {
  startupIds: number[];
  height?: number;
  setHeight?: (height: number) => void;
}) => {
  const { data: startupsData, loading } = useGetStartupsForListChartsQuery({
    variables: { startupIds },
  });

  const startups = startupsData?.suppliers;

  if (loading || !startups?.length) {
    return (
      <Stack height={height} width='50%' paddingTop={4} spacing={1}>
        <Skeleton height={40} variant='rounded' />
        <Skeleton height={40} variant='rounded' />
        <Skeleton height={40} variant='rounded' />
      </Stack>
    );
  }

  return (
    <StartupsChartsLoaded
      startups={startups}
      height={height}
      setHeight={setHeight}
    />
  );
};

const StartupsChartsLoaded = ({
  height,
  startups,
  setHeight,
}: {
  startups: StartupForLandscapeCharts[];
  height: number;
  setHeight?: (height: number) => void;
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const fundingTotal = calculateFundingTotal(startups);
  const foundingYearCharDefinition = useMemo(
    () => getFoundingYearChartDefinition(startups),
    [startups],
  );
  const totalFundingCharDefinition = useMemo(
    () => getTotalFundingChartDefinition(startups),
    [startups],
  );

  const refCustomersCount = useMemo(
    () =>
      sumBy(startups, ({ startup_corporate_customers }) => {
        if (startup_corporate_customers?.length)
          return startup_corporate_customers.length;
        return 0;
      }),
    [startups],
  );

  useEffect(() => {
    const calculateTotalHeight = () => {
      const offset = 10;
      if (setHeight && ref.current) {
        const children = ref.current.children;
        let newTotalHeight = 0;
        for (let i = 0; i < children.length; i++) {
          newTotalHeight += children[i].getBoundingClientRect().height;
        }
        setHeight(newTotalHeight + offset);
      }
    };

    calculateTotalHeight();
  }, [startups.length, fundingTotal, refCustomersCount, setHeight]);

  return (
    <Stack gap={1} flexGrow={1} flexBasis={0} minWidth={0} flex={1} ref={ref}>
      {/**
       * Startups in list
       */}
      <SummaryCard
        count={startups.length}
        CountLabel={`startup${startups.length > 1 ? 's' : ''} in this list`}
        EndTextContent={
          <TrendLineChart
            sx={trendSx}
            series={foundingYearCharDefinition.lineChartSeries}
          />
        }
      >
        <FoundingYearBarChart
          series={foundingYearCharDefinition.series}
          width='100%'
          height={height}
        />
      </SummaryCard>
      {/**
       * Total funding
       */}
      <SummaryCard
        count={formatMoney(fundingTotal)}
        CountLabel='total funding'
        EndTextContent={
          <TrendLineChart
            sx={trendSx}
            series={totalFundingCharDefinition.lineChartSeries}
          />
        }
      >
        <TotalFundingBarChart
          series={totalFundingCharDefinition.series}
          width='100%'
          height={height}
        />
      </SummaryCard>

      {refCustomersCount > 0 && (
        <SummaryCard
          count={refCustomersCount}
          CountLabel='implementations'
          sx={{ justifyContent: 'center' }}
          labelSx={{ marginRight: 1 }}
          renderTextContent={isOpen => (
            <Fade in={!isOpen}>
              <Stack direction='row' width='100%'>
                <CategoryReferenceCustomers
                  containerSx={{ justifyContent: 'end' }}
                  gap={4}
                  startups={startups}
                  maxCap={4}
                  allowHoverDropDown={false}
                />
              </Stack>
            </Fade>
          )}
          renderChildren={isOpen => (
            <Collapse
              in={isOpen}
              sx={{ width: '100%', marginTop: 2 }}
              mountOnEnter
              unmountOnExit
            >
              <CategoryReferenceCustomers
                gap={8}
                renderAllSx={{ justifyContent: 'space-evenly' }}
                startups={startups}
              />
            </Collapse>
          )}
        />
      )}
    </Stack>
  );
};

const SummaryCard = ({
  count,
  CountLabel,
  EndTextContent,
  children,
  sx,
  labelSx,
  renderChildren,
  renderTextContent,
}: {
  count?: string | number;
  CountLabel: string | JSX.Element;
  EndTextContent?: string | JSX.Element;
  renderTextContent?: (isOpen: boolean) => string | JSX.Element;
  renderChildren?: (isOpen: boolean) => string | JSX.Element;
  children?: JSX.Element;
  sx?: SxProps;
  labelSx?: SxProps;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Card
      sx={{
        minWidth: 250,
        display: 'flex',
        flexDirection: 'column',
        padding: 2,
        justifyContent: isOpen ? 'start' : 'center',
        overflow: 'visible',
        ...sx,
      }}
    >
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='center'
        onClick={() => setIsOpen(!isOpen)}
        sx={{ cursor: 'pointer' }}
      >
        <Stack
          flexWrap='nowrap'
          direction='row'
          alignItems='center'
          justifyContent='center'
          sx={labelSx}
        >
          <Typography variant='h4' marginRight={1}>
            {count}
          </Typography>
          {CountLabel && (
            <Typography noWrap marginRight={1} variant='body2'>
              {CountLabel}
            </Typography>
          )}
        </Stack>
        {EndTextContent && (
          <Stack direction='row' flexGrow={1} overflow='hidden' width='100%'>
            {EndTextContent}
          </Stack>
        )}
        {renderTextContent && (
          <Stack direction='row' flexGrow={1} overflow='hidden' width='100%'>
            {renderTextContent(isOpen)}
          </Stack>
        )}
        <IconButton size='small' onClick={() => setIsOpen(!isOpen)}>
          <KeyboardArrowUp
            sx={{
              transform: isOpen ? 'rotate(0deg)' : 'rotate(180deg)',
            }}
          />
        </IconButton>
      </Stack>
      <Collapse in={isOpen} mountOnEnter unmountOnExit>
        {children}
      </Collapse>
      {renderChildren && renderChildren(isOpen)}
    </Card>
  );
};
