import { ThumbUp, ThumbUpOutlined } from '@mui/icons-material';
import { Stack, IconButton } from '@mui/material';

export const VotingButtonBase = ({
  userVoted,
  votesCount,
  onClick,
  disabled,
}: {
  userVoted: boolean;
  votesCount: number;
  onClick: () => void;
  disabled: boolean;
}) => {
  return (
    <Stack direction='row' alignItems='center'>
      <IconButton size='small' onClick={onClick} disabled={disabled}>
        {userVoted ? (
          <ThumbUp fontSize='small' />
        ) : (
          <ThumbUpOutlined fontSize='small' />
        )}
      </IconButton>
      {votesCount}
    </Stack>
  );
};
