import { Avatar, SxProps, Theme } from '@mui/material';
import * as colors from '@mui/material/colors';

const muiColorArray = [
  colors.red,
  colors.pink,
  colors.purple,
  colors.deepPurple,
  colors.indigo,
  colors.blue,
  colors.lightBlue,
  colors.cyan,
  colors.teal,
  colors.green,
  colors.lightGreen,
  colors.lime,
  colors.yellow,
  colors.amber,
  colors.orange,
  colors.deepOrange,
  colors.brown,
  colors.grey,
  colors.blueGrey,
];

function stringToColor(name: string) {
  let hash = 0;
  for (let i = 0; i < name.length; i += 1) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }

  const index = Math.abs(hash) % muiColorArray.length;
  return muiColorArray[index];
}

function stringAvatar(name: string, doubleInitials = true) {
  const colorObject = stringToColor(name);
  const backgroundColor = colorObject[100]; // Light shade for background
  const textColor = colorObject[700]; // Darker shade for text

  const splitName = name.split(' ');
  const firstLetter = splitName[0] ? splitName[0][0] : 'A';
  const lastLetter = splitName[1] ? splitName[1][0] : '';

  return {
    sx: {
      bgcolor: backgroundColor,
      color: textColor,
      marginRight: 1,
      fontWeight: 500,
    },
    children: `${firstLetter}${doubleInitials ? lastLetter : ''}`.toUpperCase(),
  };
}

const BaseInitialsAvatar = ({
  full_name,
  sx,
  src,
  doubleInitials,
}: {
  full_name: string;
  sx?: SxProps<Theme>;
  src?: string;
  doubleInitials?: boolean;
}) => {
  const avatarSx = stringAvatar(full_name, doubleInitials).sx;
  return (
    <Avatar
      {...stringAvatar(full_name, doubleInitials)}
      {...(src ? { src, children: null } : {})}
      sx={{
        ...avatarSx,
        ...sx,
      }}
    />
  );
};

export default BaseInitialsAvatar;
