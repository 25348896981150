import { Box, Button, Typography, useTheme } from '@mui/material';
import { useDispatchEventMutation } from 'apollo/generated/sdkShared';
import { textButtonStyles } from 'components/base/typography/constants';
import { NAV_TEXT_GREY } from 'components/NavSection';
import { usePersonContext } from 'contexts/PersonContext';
import useLocalStorage from 'hooks/useLocalStorage';
import { ReactNode } from 'react';
import { PATH_ROOT } from 'routes/paths';
import PrivateIcon from '../assets/private.svg?react';
import { useParams } from 'react-router';

const blurredStyle = {
  filter: 'blur(3px)',
  pointerEvents: 'none',
  position: 'absolute',
  whiteSpace: 'nowrap',
  fontSize: '14px',
};

export const ContainerWithBlurredText = ({
  children,
}: {
  children: ReactNode;
}) => (
  <Box
    sx={{
      position: 'relative',
      minWidth: 220,
      display: 'flex',
      justifyContent: 'center',
    }}
  >
    <Typography sx={blurredStyle} variant='caption' color='grey.500'>
      You don&apos;t have access to this project
    </Typography>
    {children}
  </Box>
);

const RequestProjectPermissionsButton = (
  props:
    | {
        projectId: number;
      }
    | {
        projectPublicUUID?: string;
      },
) => {
  const projectId = 'projectId' in props ? props.projectId : null;
  const projectPublicUUID =
    'projectPublicUUID' in props ? props.projectPublicUUID : null;
  const { person } = usePersonContext();
  const [dispatchEvent] = useDispatchEventMutation();
  const [requested, setRequested] = useLocalStorage(
    `requestedProjectPermissions-${projectId}`,
    false,
  );
  const { palette } = useTheme();
  const { teamSlug } = useParams<{ teamSlug: string }>();

  let actionURL = '';

  if ('projectId' in props) {
    actionURL = PATH_ROOT.projectLeads._details(props.projectId);
  }

  if ('projectPublicUUID' in props && props.projectPublicUUID) {
    actionURL = PATH_ROOT.projectLeads.publicDetailsView(
      props.projectPublicUUID,
      teamSlug,
    );
  }

  const handleRequest = async () => {
    if (!person) return;

    await dispatchEvent({
      variables: {
        event: {
          name: 'AccessRequestForProject',
          payload: {
            projectId: projectId || null,
            actionURL,
            projectPublicUUID: projectPublicUUID || null,
            stakeholderUrl: PATH_ROOT.personProfile.details(Number(person.id)),
            requesterPersonId: person.id,
          },
        },
      },
    });
  };

  if (requested) {
    return (
      <Button
        startIcon={
          <PrivateIcon style={{ fill: NAV_TEXT_GREY }} fontSize='small' />
        }
        sx={{ ...textButtonStyles, color: `${NAV_TEXT_GREY} !important` }}
        disabled
      >
        Permissions Requested
      </Button>
    );
  }

  return (
    <Button
      sx={textButtonStyles}
      variant='text'
      startIcon={
        <PrivateIcon style={{ fill: palette.primary.main }} fontSize='small' />
      }
      onClick={e => {
        e.preventDefault();
        handleRequest();
        setRequested(true);
      }}
    >
      Request Permissions
    </Button>
  );
};

export default RequestProjectPermissionsButton;
