import { captureException } from '@sentry/react';
import {
  ListMatrixSettingsSetInput,
  useToggleStartupListTableColumnsMutation,
} from 'apollo/generated/sdkInnovationManager';
import { useSnackbar } from 'notistack';
import { useCallback, useContext } from 'react';
import { StartupsTableContext } from './StartupsTableContext';
import { useUpdateStartupListActivities } from 'components/dashboard/startupList/useUpdateStartupListActivities';

export const useUpdateListMatrixSettings = () => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    currentStartupList: { id: startupListId },
  } = useContext(StartupsTableContext)!;

  const [toggle] = useToggleStartupListTableColumnsMutation();
  const { logStartupListActivity } = useUpdateStartupListActivities();

  return useCallback(
    async (payload: ListMatrixSettingsSetInput) => {
      try {
        await toggle({ variables: { startupListId, payload } });
        await logStartupListActivity({
          logs: [
            {
              action: 'updated',
              entityIds: [startupListId],
              entityType: 'list_matrix_settings',
            },
          ],
          startupListId,
        });

        enqueueSnackbar('Column settings updated', {
          variant: 'success',
        });
      } catch (e) {
        captureException(e);
        enqueueSnackbar('Error while updating column settings', {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, startupListId, toggle, logStartupListActivity],
  );
};
