import { Box, TextField } from '@mui/material';
import { ChangeEvent } from 'react';
import {
  GridCellParams,
  GridFilterOperator,
  GridFilterItem,
  GridFilterInputValueProps,
} from '@mui/x-data-grid-pro';

export function TextFieldFilterComponent(props: GridFilterInputValueProps) {
  const { item, applyValue } = props;

  const handleFilterChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    applyValue({ ...item, value: event.target.value });
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 48,
        paddingLeft: '20px',
      }}
    >
      <TextField
        id='text-input-filter'
        label='Value'
        variant='standard'
        onChange={handleFilterChange}
        value={item.value}
      />
    </Box>
  );
}

const sourcingStatuses = [
  {
    value: 'not_ordered',
    label: 'Not ordered',
  },
  {
    value: 'in_progress',
    label: 'In Progress',
  },
  {
    value: 'delivered',
    label: 'Completed',
  },
];

function CustomDataGridSelect({
  item,
  applyValue,
  options,
}: {
  item: GridFilterItem;
  applyValue: (value: GridFilterItem) => void;
  options: {
    value: string;
    label: string;
  }[];
}) {
  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    applyValue({ ...item, value: event.target.value });
  };
  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 48,
        paddingLeft: '20px',
      }}
    >
      <TextField
        id='standard-select-currency'
        select
        label='Value'
        value={item.value || 'empty'}
        onChange={handleChange}
        variant='standard'
        SelectProps={{ native: true }}
      >
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </TextField>
    </Box>
  );
}

function SelectSourcingStatusFilterComponent(props: GridFilterInputValueProps) {
  const { item, applyValue } = props;
  return (
    <CustomDataGridSelect
      item={item}
      applyValue={applyValue}
      options={sourcingStatuses}
    />
  );
}

function FormatFilterComponent(props: GridFilterInputValueProps) {
  const { item, applyValue } = props;
  return (
    <CustomDataGridSelect
      item={item}
      applyValue={applyValue}
      options={[
        { value: '', label: '' },
        {
          value: 'benchmark',
          label: 'Benchmark',
        },
        {
          value: 'landscape',
          label: 'Landscape',
        },
      ]}
    />
  );
}

export const FormatFilterOperator: GridFilterOperator = {
  label: 'is',
  value: 'is',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (!filterItem.columnField || !filterItem.operatorValue) {
      return null;
    }

    return (params: GridCellParams): boolean => {
      return params.value === filterItem.value;
    };
  },

  InputComponent: FormatFilterComponent,
  InputComponentProps: { type: 'string' },
};

export const tagsFilterOperator: GridFilterOperator = {
  label: 'contains',
  value: 'contains',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (
      !filterItem.columnField ||
      !filterItem.value ||
      !filterItem.operatorValue
    ) {
      return null;
    }

    return (params: GridCellParams): boolean => {
      if (!params.value) return false;

      const tags = params.value as Array<string>;

      return tags.some(tag =>
        tag.toLowerCase().includes(filterItem.value.toLowerCase()),
      );
    };
  },
  InputComponent: TextFieldFilterComponent,
  InputComponentProps: { type: 'string' },
};

export const assigneeFilterOperator: GridFilterOperator = {
  label: 'contains',
  value: 'contains',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (
      !filterItem.columnField ||
      !filterItem.value ||
      !filterItem.operatorValue
    ) {
      return null;
    }

    return (params: GridCellParams): boolean => {
      const assigneeFullName = params.value || '';

      const assigneeName = assigneeFullName.toLowerCase();
      const filterValue = filterItem.value.toLowerCase();

      if (!assigneeName && 'not assigned'.includes(filterValue)) {
        return true;
      }

      if (!assigneeName?.includes(filterValue)) {
        return false;
      }

      return true;
    };
  },
  InputComponent: TextFieldFilterComponent,
  InputComponentProps: { type: 'string' },
};

export const sourcingStatusFilterOperator: GridFilterOperator = {
  label: 'is',
  value: 'is',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (!filterItem.columnField || !filterItem.operatorValue) {
      return null;
    }

    return (params: GridCellParams): boolean => {
      if (!params.value) return true;

      return params.value === filterItem.value;
    };
  },

  InputComponent: SelectSourcingStatusFilterComponent,
  InputComponentProps: { type: 'string' },
};
