import { Typography } from '@mui/material';
import { useDispatchEventMutation } from 'apollo/generated/sdkShared';
import { BaseDialog } from 'components/base/BaseDialog';
import { usePersonContext } from 'contexts/PersonContext';
import { useNavigateBack } from 'hooks/useNavigateBack';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router';
import { PATH_ROOT } from 'routes/paths';

const RequestProjectAccessDialog = (
  props:
    | {
        projectId?: number;
      }
    | {
        projectPublicUUID?: string;
      },
) => {
  const [dispatchEvent] = useDispatchEventMutation();
  const { person } = usePersonContext();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { navigateBack } = useNavigateBack(PATH_ROOT.root);
  const submitEvent = async ({
    projectId,
    projectPublicUUID,
  }: {
    projectId?: number | null;
    projectPublicUUID?: string | null;
  }) => {
    if (!person) return;

    await dispatchEvent({
      variables: {
        event: {
          name: 'AccessRequestForProject',
          payload: {
            projectId: projectId || null,
            actionURL: location.pathname,
            projectPublicUUID: projectPublicUUID || null,
            stakeholderUrl: PATH_ROOT.personProfile.details(Number(person.id)),
            requesterPersonId: person.id,
          },
        },
      },
    });
  };

  return (
    <BaseDialog
      maxWidth='xs'
      heading={`You don't have access to this project`}
      onHide={navigateBack}
      onConfirm={async () => {
        if (!person) {
          enqueueSnackbar('Error trying to request access to this list', {
            variant: 'error',
          });
          return;
        }

        const projectId = 'projectId' in props ? props.projectId : null;
        const projectPublicUUID =
          'projectPublicUUID' in props ? props.projectPublicUUID : null;

        if (projectId || projectPublicUUID) {
          await submitEvent({ projectId, projectPublicUUID });
        }

        enqueueSnackbar('Access request sent', {
          variant: 'success',
        });
      }}
      cancelCTA='Close'
      confirmCTA='Request access'
    >
      <Typography fontSize={14} color='grey.500'>
        To view this project, request access from its owner.
      </Typography>
    </BaseDialog>
  );
};

export default RequestProjectAccessDialog;
