import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import {
  GetOrganizationStartupContactsDocument,
  useAddContactModalGetContactEmailsQuery,
  useCreateOrganizationStartupContactMutation,
} from 'apollo/generated/sdkShared';
import { Form, FormikProvider, useFormik } from 'formik';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { useMemo } from 'react';
import { formatApiDate } from 'utils/datetime';
import { isValidLinkedInUrl } from 'utils/url';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';

type AddContactModalProps = {
  open: boolean;
  onHide: () => void;
  startupId: number;
  onSubmit?: () => Promise<void>;
};

export const AddContactModal = (props: AddContactModalProps) => {
  const { open, onHide, startupId, onSubmit } = props;
  const { data } = useAddContactModalGetContactEmailsQuery();
  const existingContacts = useMemo(
    () => data?.organization_startup_contacts || [],
    [data],
  );

  const STARTUP_CONTACT_VALIDATION_SCHEMA = useMemo(
    () =>
      z.object({
        full_name: z.string({
          required_error: 'Name is required',
        }),
        title: z.string().optional(),
        connected_since: z.string({
          required_error: 'Connection date is required',
        }),
        email: z
          .string({
            required_error: 'Email is required',
          })
          .email()
          .refine(
            email =>
              !existingContacts
                .map(c => c.email?.toLowerCase())
                .includes(email.toLowerCase()),
            {
              message: 'A contact with that email already exists',
            },
          ),
        linkedin_url: z
          .string()
          .refine(value => value === '' || isValidLinkedInUrl(value!), {
            message: 'Invalid LinkedIn URL format',
          })
          .optional(),
      }),
    [existingContacts],
  );

  const [createOrganizationStartupContact] =
    useCreateOrganizationStartupContactMutation({
      refetchQueries: [
        {
          query: GetOrganizationStartupContactsDocument,
          variables: { startupId },
        },
      ],
    });

  const formik = useFormik({
    initialValues: {
      full_name: '',
      email: '',
      connected_since: new Date().toISOString(),
      title: '',
      linkedin_url: '',
      note: '',
      is_primary_contact: false,
    },
    validationSchema: toFormikValidationSchema(
      STARTUP_CONTACT_VALIDATION_SCHEMA,
    ),
    onSubmit: async (values, { resetForm }) => {
      const addContactResponse = await createOrganizationStartupContact({
        variables: {
          object: {
            full_name: values.full_name,
            email: values.email,
            connected_since: formatApiDate(values.connected_since),
            title: values.title,
            linkedin_url: values.linkedin_url,
            note: values.note,
            is_primary_contact: values.is_primary_contact,
            startup_id: startupId,
          },
        },
      });

      const addedContact =
        addContactResponse.data?.insert_organization_startup_contacts_one;

      if (addedContact) {
        captureAnalyticsEvent('Organization Startup Contact added.', {
          contactId: addedContact.id,
          contactName: addedContact.full_name,
          contactEmail: addedContact.email,
        });

        if (onSubmit) await onSubmit();
      }

      resetForm();
      onHide();
    },
  });
  const { errors, touched, handleSubmit, getFieldProps, setFieldValue } =
    formik;

  return (
    <Dialog open={open} fullWidth onClose={onHide}>
      <FormikProvider value={formik}>
        <Form autoComplete='off' onSubmit={handleSubmit}>
          <DialogTitle>Add new contact</DialogTitle>
          <DialogContent sx={{ marginTop: 3 }}>
            <Stack direction='column' spacing={2} sx={{ marginTop: 1 }}>
              <TextField
                {...getFieldProps('full_name')}
                error={Boolean(touched.full_name && errors.full_name)}
                helperText={touched.full_name && errors.full_name}
                fullWidth
                label='Name *'
              />
              <TextField
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                fullWidth
                label='Email *'
              />
              <Grid
                container
                alignItems='center'
                justifyContent='space-between'
              >
                <Grid item xs={6} sx={{ paddingRight: 1.5 }}>
                  <TextField
                    {...formik.getFieldProps('title')}
                    error={Boolean(formik.touched.title && formik.errors.title)}
                    helperText={formik.touched.title && formik.errors.title}
                    fullWidth
                    label='Job Title'
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    {...getFieldProps('connected_since')}
                    label='Connected since *'
                    inputFormat='dd/MM/yyyy'
                    maxDate={new Date()}
                    onChange={date => {
                      if (date) {
                        setFieldValue('connected_since', String(date));
                      }
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        fullWidth
                        error={Boolean(
                          formik.touched.connected_since &&
                            formik.errors.connected_since,
                        )}
                        helperText={
                          formik.touched.connected_since &&
                          formik.errors.connected_since
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <TextField
                {...formik.getFieldProps('linkedin_url')}
                error={Boolean(
                  formik.touched.linkedin_url && formik.errors.linkedin_url,
                )}
                helperText={
                  formik.touched.linkedin_url && formik.errors.linkedin_url
                }
                fullWidth
                label='LinkedIn URL'
              />
              <TextField
                {...formik.getFieldProps('note')}
                error={Boolean(formik.touched.note && formik.errors.note)}
                helperText={formik.touched.note && formik.errors.note}
                fullWidth
                label='Note'
                multiline
                rows={4}
              />
              <Stack
                direction='row'
                spacing={1}
                alignItems='center'
                justifyContent='space-between'
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.is_primary_contact}
                      onChange={formik.handleChange}
                      name='is_primary_contact'
                      color='primary'
                    />
                  }
                  label={<Typography>Is primary contact?</Typography>}
                />
                <Typography>* Required fields</Typography>
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button variant='text' color='inherit' onClick={onHide}>
              Cancel
            </Button>
            <Button
              type='submit'
              variant='contained'
              disabled={!formik.isValid || !formik.dirty}
            >
              Add
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};
