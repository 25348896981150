import { FilterListOff } from '@mui/icons-material';
import {
  Box,
  Divider,
  IconButton,
  Stack,
  SxProps,
  Tooltip,
  useTheme,
} from '@mui/material';
import {
  ServerFilter,
  useServerFiltersContext,
} from 'components/base/serverFilters/BaseServerFiltersContext';
import { ReactNode } from 'react';

export function BaseServerFilterMenu({
  scope,
  children,
  sxProps = {},
}: {
  scope: keyof ServerFilter;
  children: ReactNode;
  sxProps?: SxProps;
}) {
  const { spacing } = useTheme();
  const { resetFilter, isFilterEmpty } = useServerFiltersContext(scope);

  return (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='flex-end'
      sx={{
        minWidth: 0,
        position: 'relative',
        ...sxProps,
      }}
    >
      <Stack
        direction='row'
        gap={1}
        sx={{
          position: 'relative',
          overflowX: 'auto',
          width: '100%',
          minWidth: 0,
          scrollbarWidth: 'thin',
          paddingBottom: 2,
          marginBottom: -2,
          paddingX: 2,
        }}
      >
        {children}
      </Stack>
      <Divider
        sx={{
          height: spacing(2),
          marginTop: 'auto',
          marginBottom: 'auto',
        }}
        orientation='vertical'
        flexItem
      />
      <Tooltip title='Clear filters'>
        <Box component='span'>
          <IconButton
            disabled={isFilterEmpty}
            size='small'
            onClick={() => resetFilter(scope)}
            sx={{ marginLeft: 1 }}
          >
            <FilterListOff fontSize='small' />
          </IconButton>
        </Box>
      </Tooltip>
    </Stack>
  );
}
