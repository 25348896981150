import { Typography, useTheme } from '@mui/material';
import { EnumTableProjectStagesEnum } from 'apollo/generated/sdkShared';
import { RouterLink } from 'components/RouterLink';
import { buildProjectDetailsPath } from 'routes/paths';
import { indexOfStage } from 'utils/projectStageEnum';

export const ConnectedProjectLink = ({
  project,
  onClick,
  children,
}: {
  project: {
    id: number;
    stage: EnumTableProjectStagesEnum;
  };
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  children?: React.ReactNode;
}) => {
  const { palette } = useTheme();

  return (
    <RouterLink
      color='secondary'
      variant='body2'
      state={{ backToPage: window.location.pathname + window.location.search }}
      onClick={onClick}
      to={buildProjectDetailsPath({
        id: project.id,
        stage: project.stage,
      })}
    >
      {children ? (
        children
      ) : (
        <Typography sx={{ color: palette.info.main }} variant='body2'>
          Connected{' '}
          {indexOfStage(project.stage) < indexOfStage('assessment')
            ? 'Lead'
            : 'Project'}
        </Typography>
      )}
    </RouterLink>
  );
};
