import { Auth0ContextType } from '../@types/authentication';
import { useContext } from 'react';
import { AuthContext } from '../contexts/Auth0Context';

// ----------------------------------------------------------------------

const useAuth = (): Auth0ContextType => {
  const context = useContext(AuthContext);

  if (!context)
    throw new Error('Auth context must be used inside AuthProvider');

  return context;
};

export default useAuth;
