import { useApolloClient } from '@apollo/client';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { captureException } from '@sentry/react';
import {
  GetChallengeDetailsDocument,
  GetProjectsForChallengesQuery,
  useGetProjectsForChallengesQuery,
  useLinkElementToChallengeMutation,
} from 'apollo/generated/sdkShared';
import BaseErrorMessage from 'components/base/BaseErrorMessage';
import { BaseSnackbarActionNavigateToList } from 'components/base/BaseSnackbarActions';
import { useServerFiltersContext } from 'components/base/serverFilters/BaseServerFiltersContext';
import { useSnackbar } from 'notistack';
import { Fragment, HTMLAttributes, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { PATH_ROOT } from 'routes/paths';
import { ChallengeConnectedProjectType, LinkConnectionType } from '../../types';

type ProjectType = GetProjectsForChallengesQuery['projects'][number];

const RenderOption = ({
  htmlProps,
  option,
  type,
  connectedLeads,
  connectedProjects,
}: {
  htmlProps: HTMLAttributes<HTMLLIElement>;
  option: ProjectType;
  type: LinkConnectionType;
  connectedLeads: ChallengeConnectedProjectType[];
  connectedProjects: ChallengeConnectedProjectType[];
}) => {
  // @ts-expect-error: data-option-index is not a valid prop
  // eslint-disable-next-line react/prop-types
  const index = htmlProps?.['data-option-index'];
  const navigate = useNavigate();

  const isAlreadyLinked = useMemo(() => {
    if (type === 'lead') {
      return connectedLeads.some(lead => lead.id === option.id);
    } else if (type === 'project') {
      return connectedProjects.some(project => project.id === option.id);
    }
    return false;
  }, [type, connectedLeads, connectedProjects, option.id]);

  const tooltipTitle = isAlreadyLinked ? (
    <Typography fontSize={12}>
      {`This ${type} is already linked to the current challenge.`}
    </Typography>
  ) : (
    ''
  );

  const detailsPageLink =
    type === 'lead'
      ? PATH_ROOT.projectLeads._details(option.id)
      : PATH_ROOT.projects._pocDetails(option.id);

  return (
    <Fragment key={`option-challenge-to-${type}-${index}`}>
      <Box
        component='li'
        sx={({ spacing, palette }) => ({
          height: spacing(5),
          ...(isAlreadyLinked && {
            backgroundColor: palette.action.disabledBackground,
            cursor: 'not-allowed !important',
            color: palette.action.disabled,
            '&:hover': {
              backgroundColor: `${palette.action.disabledBackground} !important`,
            },
          }),
        })}
        {...htmlProps}
      >
        <Tooltip
          sx={{
            whiteSpace: 'pre-line',
          }}
          title={tooltipTitle}
          placement='bottom'
        >
          <Typography fontSize={14} noWrap>
            {option.title}
          </Typography>
        </Tooltip>

        <Stack direction='row' sx={{ marginLeft: 'auto' }} alignItems='center'>
          <Button
            sx={{ marginLeft: 'auto' }}
            size='small'
            onClick={e => {
              e.stopPropagation();
              navigate(detailsPageLink, {
                state: {
                  backToPage: `${window.location.pathname}${window.location.hash}`,
                },
              });
            }}
          >
            Open
          </Button>
        </Stack>
      </Box>
    </Fragment>
  );
};

const LinkProjectToChallengeModal = ({
  open,
  challengeId,
  onHide,
  type,
  connectedLeads,
  connectedProjects,
}: {
  open: boolean;
  challengeId: number;
  onConfirm?: (startupListId: number) => void;
  onHide: () => void;
  type: LinkConnectionType;
  connectedLeads: ChallengeConnectedProjectType[];
  connectedProjects: ChallengeConnectedProjectType[];
}) => {
  const [selectedProject, setSelectedProject] = useState<ProjectType | null>(
    null,
  );

  const { normalizedDefaultFilter: leadsFilter } = useServerFiltersContext(
    'projectLeadsFilters',
  );

  const { normalizedDefaultFilter: projectsFilter } =
    useServerFiltersContext('projectPoCFilters');

  const FILTER = type === 'lead' ? leadsFilter : projectsFilter;

  const [linkProjectToChallenge, { loading: linkingInProgress }] =
    useLinkElementToChallengeMutation({
      refetchQueries: [
        {
          query: GetChallengeDetailsDocument,
          variables: { challengeID: challengeId, challengeUUID: '' },
        },
      ],
    });

  const {
    data: projectsData,
    loading: loadingProjectsData,
    previousData,
    refetch: refetchProjects,
    error,
  } = useGetProjectsForChallengesQuery({
    fetchPolicy: 'cache-and-network',
    variables: { where: FILTER },
  });

  const options = useMemo(
    () =>
      projectsData?.projects.slice().sort((a, b) => (a ? 1 : b ? -1 : 0)) || [],
    [projectsData?.projects],
  );

  const containerRef = useRef(null);

  const { enqueueSnackbar } = useSnackbar();

  const { cache: apolloCache } = useApolloClient();

  const handleConnectProjectToChallenge = async () => {
    if (!selectedProject) return;

    try {
      await linkProjectToChallenge({
        variables: {
          challengeId: challengeId,
          projectId: selectedProject.id,
        },
      });

      apolloCache.evict({
        id: apolloCache.identify({ __typename: 'challenges', id: challengeId }),
      });

      apolloCache.gc();

      enqueueSnackbar(`Successfully linked ${type}`, {
        variant: 'success',
        action: (
          <BaseSnackbarActionNavigateToList listId={selectedProject.id} />
        ),
        autoHideDuration: 5000,
      });
    } catch (error) {
      captureException(error);
      enqueueSnackbar(`Error linking challenge with ${type}.`, {
        variant: 'error',
      });
    } finally {
      await refetchProjects();
      onHide();
    }
  };

  if (!previousData && loadingProjectsData)
    return (
      <Dialog open={open} fullWidth>
        <DialogContent>
          <Skeleton height={400} />
        </DialogContent>
      </Dialog>
    );

  if (error) return <BaseErrorMessage />;

  return (
    <>
      <Dialog open={open} fullWidth ref={containerRef} onClose={onHide}>
        <DialogTitle sx={{ marginBottom: 5 }}>{`Link ${type}`}</DialogTitle>
        <DialogContent
          sx={({ spacing }) => ({
            paddingTop: `${spacing(1)} !important`,
          })}
        >
          <Box sx={{ width: '100%' }}>
            <Stack direction='row' sx={{ width: '100%' }}>
              <Autocomplete
                blurOnSelect
                fullWidth
                options={options}
                autoHighlight
                getOptionLabel={option => option.title}
                onChange={(_e, selected) => {
                  setSelectedProject(selected);
                }}
                renderOption={(htmlProps, option) => (
                  <RenderOption
                    key={`option-challenge-to-${type}-${option.id}`}
                    htmlProps={htmlProps}
                    option={option}
                    type={type}
                    connectedLeads={connectedLeads}
                    connectedProjects={connectedProjects}
                  />
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={`Choose ${type}`}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant='text' color='inherit' onClick={onHide}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (challengeId) {
                handleConnectProjectToChallenge();
              }
            }}
            disabled={
              !challengeId ||
              !selectedProject ||
              linkingInProgress ||
              loadingProjectsData ||
              type === 'lead'
                ? connectedLeads.some(lead => lead.id === selectedProject?.id)
                : connectedProjects.some(
                    project => project.id === selectedProject.id,
                  )
            }
            variant='contained'
          >
            Link
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LinkProjectToChallengeModal;
