import {
  Stack,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';

import OptionCard from '../../pages/request-form/OptionCard';
import { FieldProps } from '../../pages/request-form/types';
import { BORDER_COLOR } from 'theme/palette';
import { SourcingOrderState } from 'pages/request-form/sourcingOrderModel';
import { EditModeTitle } from './Shared';

type Schema = Pick<SourcingOrderState, 'requested_result_type'>;

const BENCHMARK_TITLE_CONTENT = (
  <Typography variant='body2' color='text.secondary'>
    Find startup companies that fit your requirements and <b>compare</b> them to
    help you identify the best solutions
  </Typography>
);

const LANDSCAPE_TITLE_CONTENT = (
  <Typography variant='body2' color='text.secondary'>
    Get an <b>overview of a topic</b> of interest to identify the various ways
    to address your specific needs.
  </Typography>
);

export function ProjectResultTypeField({
  getFieldProps,
  setFieldValue,
  isEditMode,
}: FieldProps<Pick<Schema, 'requested_result_type'>>) {
  return (
    <>
      {isEditMode ? (
        <EditModeTitle>Expected results format*</EditModeTitle>
      ) : (
        <Typography variant='h5'>Expected results format*</Typography>
      )}
      {!isEditMode && (
        <Typography
          variant='body2'
          color='text.secondary'
          gutterBottom
          sx={{ marginBottom: '20px' }}
        >
          Please select the format in which you expect to receive your results
        </Typography>
      )}
      <RadioGroup
        {...getFieldProps('requested_result_type')}
        sx={{ marginBottom: isEditMode ? '20px' : 0 }}
      >
        <Stack direction='row' spacing={1}>
          <OptionCard
            border={`1px solid ${BORDER_COLOR}`}
            title='Benchmark'
            content={BENCHMARK_TITLE_CONTENT}
            onClick={() => {
              setFieldValue('requested_result_type', 'benchmark');
            }}
          >
            <FormControlLabel value='benchmark' control={<Radio />} label='' />
          </OptionCard>
          <OptionCard
            border={`1px solid ${BORDER_COLOR}`}
            title='Landscape'
            content={LANDSCAPE_TITLE_CONTENT}
            onClick={() => {
              setFieldValue('requested_result_type', 'landscape');
            }}
          >
            <FormControlLabel value='landscape' control={<Radio />} label='' />
          </OptionCard>
        </Stack>
      </RadioGroup>
    </>
  );
}
