import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  GridCellParams,
  GridColDef,
  GridColumns,
  GridCsvGetRowsToExportParams,
  GridFilterItem,
  GridFilterOperator,
  GridSelectionModel,
  GridSortModel,
  GridToolbarExport,
  gridPaginatedVisibleSortedGridRowEntriesSelector,
} from '@mui/x-data-grid-pro';
import {
  GetOrganizationStartupsQuery,
  InputMaybe,
  OrganizationStartupsOrderBy,
  Suppliers,
  SuppliersBoolExp,
  useGetLabelsCountForStartupsPageQuery,
  useGetOrganizationStartupsQuery,
} from 'apollo/generated/sdkShared';
import EmptyContent from 'components/EmptyContent';
import { ExpandedGridCell } from 'components/ExpandedGridCell';
import LightTooltip from 'components/LightTooltip';
import { StandardSearchBar } from 'components/StyledSearchInput';
import TypographyWithEllipsis from 'components/TypographyWithEllipsis';
import { BaseStartupAvatar } from 'components/base/BaseStartupAvatar';
import CompanyDetails from 'components/shared/table/CompanyDetails';
import StartupFunnelLabelChip, {
  getStartupFunnelLabelOrderWithoutInLibrary,
} from 'components/startups/StartupFunnelLabelChip';

import { StartupFunnelLabel } from 'components/dashboard/StartupInfoSidePanel/StartupLabelChipSelector';
import {
  StartupSignal,
  getStartupSignalLabelByOrder,
} from 'components/startups/StartupSignalLabel';
import { useStartupSidePanel } from 'contexts/StartupSidePanelContext';
import useAuth from 'hooks/useAuth';
import useLocalStorage from 'hooks/useLocalStorage';
import Page500 from 'pages/Page500';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { StartupConnectionStatus } from '../../@types/shared';
import { ListsLabel } from '../../components/startupsPage/ListsLabel';
import { StartupsDataGrid } from '../../components/startupsPage/StartupsDataGrid';
import { TextFieldFilterComponent } from '../dashboard/filters/StartupListsListViewFilters';
import { captureException } from '@sentry/react';
import {
  BaseAddStartupToListMenu,
  SelectedStartupsForAddToList,
} from 'components/base/BaseAddStartupToListMenu';

type RelatedList =
  | Array<{
      id: number;
      title: string;
    }>
  | null
  | undefined;

type DecoratedOrganizationStartup = Pick<
  Suppliers,
  | 'id'
  | 'domain'
  | 'website'
  | 'name'
  | 'health_status'
  | 'hq'
  | 'short_description'
  | 'long_description'
  | 'employees_count'
  | 'funding'
  | 'founded_year'
  | 'logo_url'
> & {
  created_at?: string | null;
  listed_in: RelatedList;
  selected_in: RelatedList;
  shortlisted_in: RelatedList;
  funnel_label?: StartupFunnelLabel | null;
  signal_label?: StartupSignal | null;
  connected: StartupConnectionStatus | 'not connected';
};

const PAGE_SIZE = 20;
const DEBOUNCED_TIME = 1000; // In milliseconds

const SORTABLE_COLUMNS = ['created_at'];
export default function StartupsPage() {
  const theme = useTheme();

  const [searchStr, setSearchStr] = useState('');

  const [debouncedSearchStr, setDebouncedSearchStr] = useState(searchStr);

  // Effect for debouncing search input
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchStr(searchStr);
    }, DEBOUNCED_TIME); // Delay for 2 seconds

    return () => {
      clearTimeout(handler);
    };
  }, [searchStr]); // Only re-run the effect if searchStr changes

  const [pageSize, setPageSize] = useLocalStorage(
    'library_startups_page_size',
    PAGE_SIZE,
  );
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const [page, setPage] = useState(0);
  const [filterLabel, setFilterLabel] =
    useState<StartupFunnelLabel>('InLibrary');

  const queryOptions = useMemo(
    () => ({
      limit: pageSize,
      offset: page * pageSize,
    }),
    [page, pageSize],
  );

  const where = useMemo((): {
    startup: InputMaybe<SuppliersBoolExp>;
  } => {
    const iLikeSearchStr = `%${debouncedSearchStr}%`;
    const searchStrWhereQuery: InputMaybe<SuppliersBoolExp> = {
      _or: [
        {
          name: {
            _ilike: iLikeSearchStr,
          },
        },
        {
          short_description: {
            _ilike: iLikeSearchStr,
          },
        },
        {
          hq: {
            _ilike: iLikeSearchStr,
          },
        },
        {
          listed_in_startup_lists: {
            title: {
              _ilike: iLikeSearchStr,
            },
          },
        },
        {
          shortlisted_in_startup_lists: {
            title: {
              _ilike: iLikeSearchStr,
            },
          },
        },
        {
          selected_in_startup_lists: {
            title: {
              _ilike: iLikeSearchStr,
            },
          },
        },
      ],
    };

    return {
      startup: {
        ...(debouncedSearchStr ? searchStrWhereQuery : {}),
        funnel_labels: {
          value: {
            _eq: filterLabel,
          },
        },
      },
    };
  }, [filterLabel, debouncedSearchStr]);

  const [orderBy, setOrderBy] = useState<
    InputMaybe<Array<OrganizationStartupsOrderBy> | OrganizationStartupsOrderBy>
  >([{ created_at: 'desc' }]);

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    const sorting = sortModel
      .map(sm => {
        if (SORTABLE_COLUMNS.includes(sm.field)) {
          return {
            created_at: sm.sort,
          };
        } else {
          return null;
        }
      })
      .filter(sm => sm !== null) as Array<OrganizationStartupsOrderBy>;
    // Here you save the data you need from the sort model

    setOrderBy(sorting);
  }, []);

  const {
    data: startupsData,
    previousData: previousStartupsData,
    error: getAllStartupsError,
    loading,
  } = useGetOrganizationStartupsQuery({
    fetchPolicy: 'cache-and-network',
    variables: { ...queryOptions, where, orderBy },
  });

  const { data: filterStats } = useGetLabelsCountForStartupsPageQuery({
    fetchPolicy: 'cache-and-network',
  });

  const allStartups = useMemo(
    () =>
      startupsData?.organization_startups ||
      previousStartupsData?.organization_startups ||
      [],
    [
      startupsData?.organization_startups,
      previousStartupsData?.organization_startups,
    ],
  );

  const startupsCountByLabel = useMemo(() => {
    return {
      InLibrary: filterStats?.labeled_as_in_library?.aggregate?.count,
      Connected: filterStats?.labeled_as_connected?.aggregate?.count,
      Testing: filterStats?.labeled_as_testing?.aggregate?.count,
      Tested: filterStats?.labeled_as_tested?.aggregate?.count,
    };
  }, [
    filterStats?.labeled_as_in_library?.aggregate?.count,
    filterStats?.labeled_as_connected?.aggregate?.count,
    filterStats?.labeled_as_testing?.aggregate?.count,
    filterStats?.labeled_as_tested?.aggregate?.count,
  ]);

  const error = getAllStartupsError;

  const selectedStartups: SelectedStartupsForAddToList = useMemo(
    () =>
      selectionModel
        .map(
          id =>
            allStartups.find(startup => startup.startup?.id === id)?.startup,
        )
        .map(
          s =>
            ({
              id: s?.id || 0,
              name: s?.name || '',
              logo_url: s?.logo_url || '',
              crunchbase_url: '',
              website: s?.website || '',
              domain: s?.domain || '',
              short_description: s?.short_description || '',
              employees_count: s?.employees_count || 0,
              founded_year: s?.founded_year || 0,
              founded: s?.founded_year || 0,
              funding: s?.funding || 0,
              hq: s?.hq || '',
              linkedin_url: '',
              long_description: s?.long_description || '',
            }) as SelectedStartupsForAddToList[number],
        ),
    [selectionModel, allStartups],
  );

  const decoratedStartups = useMemo(
    () => getDecoratedStartups(allStartups),
    [allStartups],
  );

  if (error) {
    captureException(error);
    return <Page500 embedded />;
  }

  return (
    <>
      <Card
        sx={{
          marginBottom: 10,
        }}
      >
        <StartupsDataGrid
          rows={decoratedStartups}
          columns={getDataGridColumns()}
          pagination
          pageSize={pageSize}
          paginationMode='server'
          rowCount={startupsCountByLabel[filterLabel] || 0}
          rowsPerPageOptions={[20, 50, 200, 1000]}
          onPageChange={newPage => setPage(newPage)}
          onPageSizeChange={newPageSize => setPageSize(newPageSize)}
          componentsProps={{
            toolbar: {
              searchStr,
              onSearchValue: setSearchStr,
              filterLabel,
              setFilterLabel,
              startupsCountByLabel: startupsCountByLabel,
              selectedStartups,
            },
          }}
          components={{
            Toolbar: CustomToolbar,
            NoRowsOverlay: () => (
              <Box sx={{ flexGrow: 1, height: 320 }}>
                <EmptyContent
                  title={
                    searchStr
                      ? `No results found matching "${searchStr}"`
                      : 'There are no startups yet'
                  }
                  img='/static/illustrations/illustration_empty_results.svg'
                  sx={{ flexGrow: 1, height: 'auto' }}
                />
              </Box>
            ),
          }}
          theme={theme}
          autoHeight={decoratedStartups.length > 0}
          rowHeight={80}
          sx={decoratedStartups.length === 0 ? { height: 600 } : undefined}
          checkboxSelection
          selectionModel={selectionModel}
          onSelectionModelChange={newSelectionModel =>
            setSelectionModel(newSelectionModel)
          }
          sortingMode='server'
          onSortModelChange={handleSortModelChange}
          disableSelectionOnClick
          loading={loading}
          keepNonExistentRowsSelected
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
                connected: false,
                website: false,
              },
            },
            sorting: {
              sortModel: [{ field: 'created_at', sort: 'desc' }],
            },
          }}
        />
      </Card>
    </>
  );
}

const CustomToolbar = ({
  onSearchValue,
  searchValue,
  onAddToListClicked,
  filterLabel,
  setFilterLabel,
  startupsCountByLabel,
  selectedStartups,
}: {
  onSearchValue: (value: unknown) => void;
  searchValue: string;
  onAddToListClicked?: () => void;
  filterLabel: StartupFunnelLabel;
  setFilterLabel: Dispatch<SetStateAction<StartupFunnelLabel | null>>;
  startupsCountByLabel: {
    [key in StartupFunnelLabel]: number;
  };
  selectedStartups: SelectedStartupsForAddToList;
}) => {
  const { isStaff } = useAuth();

  const theme = useTheme();

  return (
    <Stack
      direction='row'
      sx={{
        padding: 1.5,
        width: '100%',
        backgroundColor: theme.palette.background.neutral,
      }}
      justifyContent='space-between'
    >
      <Stack direction='row' alignItems='center'>
        <Box>
          <StandardSearchBar
            placeholder='Search by name, description, hq, or list title'
            onQueryChange={onSearchValue}
            query={searchValue}
            sx={{
              maxWidth: 500,
              minWidth: 380,
            }}
          />
        </Box>
        {isStaff && (
          <GridToolbarExport
            sx={{ marginLeft: 2 }}
            printOptions={{
              disableToolbarButton: true,
            }}
            csvOptions={{
              delimiter: ';',
              utf8WithBom: true,
              fileName: 'startups-export',
              fields: [
                'name',
                'website',
                'short_description',
                'listed_in',
                'shortlisted_in',
                'selected_in',
                'created_at',
              ],
              getRowsToExport: (params: GridCsvGetRowsToExportParams) => {
                const rows = gridPaginatedVisibleSortedGridRowEntriesSelector(
                  params.apiRef,
                );

                return rows.map(row => row.id);
              },
            }}
          />
        )}
        {selectedStartups.length > 0 && (
          <BaseAddStartupToListMenu
            selectedStartups={selectedStartups}
            onCreate={onAddToListClicked}
            shouldAddMultiple
            analyticsSource='STARTUPS_CRM'
          />
        )}
      </Stack>
      <Stack direction='row' alignItems='center' spacing={1}>
        <ButtonGroup size='small'>
          <FilterButton
            count={startupsCountByLabel.InLibrary}
            text={'All'}
            label={'InLibrary'}
            filterLabel={filterLabel}
            setFilterLabel={setFilterLabel}
          />
          <FilterButton
            count={startupsCountByLabel.Connected}
            text={'Connected'}
            label={'Connected'}
            filterLabel={filterLabel}
            setFilterLabel={setFilterLabel}
          />
          <FilterButton
            count={startupsCountByLabel.Testing}
            text={'Testing'}
            label={'Testing'}
            filterLabel={filterLabel}
            setFilterLabel={setFilterLabel}
          />
          <FilterButton
            count={startupsCountByLabel.Tested}
            text={'Tested'}
            label={'Tested'}
            filterLabel={filterLabel}
            setFilterLabel={setFilterLabel}
          />
        </ButtonGroup>
      </Stack>
    </Stack>
  );
};

const FilterButton = (props: {
  count: number | null;
  text: string;
  label: StartupFunnelLabel;
  filterLabel: StartupFunnelLabel;
  setFilterLabel: (label: StartupFunnelLabel) => void;
}) => {
  const theme = useTheme();
  const filterLabelButtonSelected = {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.grey[100],
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  };
  const filterLabelButtonInactive = {
    borderColor: theme.palette.grey[500],
    color: theme.palette.grey[700],
  };

  return (
    <Button
      onClick={() => props.setFilterLabel(props.label)}
      sx={
        props.filterLabel === props.label
          ? filterLabelButtonSelected
          : filterLabelButtonInactive
      }
    >
      {props.text}
      <Typography variant='inherit' marginLeft={0.5}>
        {props.count !== null && props.count !== undefined ? (
          `(${props.count})`
        ) : (
          <Skeleton
            variant='rounded'
            width={16}
            sx={{
              backgroundColor: ({ palette }) => palette.grey[300],
            }}
          />
        )}
      </Typography>
    </Button>
  );
};

const getDataGridColumns = () => {
  const COLUMNS: GridColumns<DecoratedOrganizationStartup> = [
    { field: 'id', filterable: false, sortable: false },
    {
      field: 'connected',
      headerName: 'Connection',
      type: 'singleSelect',
      valueOptions: ['initiated', 'accepted', 'not connected'],
      sortable: false,
    } as GridColDef<
      DecoratedOrganizationStartup,
      DecoratedOrganizationStartup['connected']
    >,
    {
      field: 'website',
      headerName: 'Website',
      filterable: false,
      sortable: false,
    },
    {
      field: 'name',
      headerName: 'Startup',
      filterable: false,
      sortable: false,
      width: 350,
      renderCell: function RenderName(params) {
        const { openStartupSidePanel } = useStartupSidePanel();
        const supplier = params.row as DecoratedOrganizationStartup;

        return (
          <Stack
            direction='row'
            spacing={1}
            alignItems='center'
            width={'100%'}
            sx={{ cursor: 'pointer' }}
            onClick={() =>
              openStartupSidePanel(supplier.id, {
                startupListId: undefined,
              })
            }
          >
            <BaseStartupAvatar
              startup={{
                logo_url: supplier.logo_url,
                name: supplier.name,
                domain: supplier.domain,
              }}
              size='medium'
            />
            <Stack>
              <Stack direction='row' alignItems='center' spacing={1}>
                <TypographyWithEllipsis
                  tooltipTitle={
                    <Typography variant='body2'>{supplier.name}</Typography>
                  }
                  TooltipComponent={props => <LightTooltip {...props} />}
                  variant='body2'
                  sx={({ palette, spacing }) => ({
                    color: palette.secondary.main,
                    cursor: 'pointer',
                    maxWidth: spacing(20), // x 8 = 160px,
                  })}
                >
                  {supplier.name}
                </TypographyWithEllipsis>
                <StartupFunnelLabelChip
                  label={supplier.funnel_label}
                  startupName={supplier.name as string}
                />
              </Stack>
              <CompanyDetails
                company={{
                  employees_count: supplier.employees_count,
                  founded_year: supplier.founded_year,
                  funding: supplier.funding,
                  hq: supplier.hq,
                  signal: supplier.signal_label,
                  name: supplier.name,
                }}
              />
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: 'short_description',
      headerName: 'Description',
      filterable: false,
      sortable: false,
      width: 300,
      renderCell: params => {
        return (
          <ExpandedGridCell
            value={params.value || '-'}
            multiLineElipsis={true}
            numberOfLines={3}
          />
        );
      },
    },
    {
      field: 'listed_in',
      headerName: 'Listed in',
      width: 200,
      valueFormatter: params => {
        return (params.value as RelatedList)
          ?.map(({ title }) => title)
          .join(', ');
      },
      renderCell: params => {
        const list = params.value as RelatedList;

        return (
          <Stack direction='row' spacing={1}>
            <ListsLabel lists={list?.map(l => l.title) || []} />
          </Stack>
        );
      },
      sortable: false,
      filterOperators: [listsFilterOperator],
    },
    {
      field: 'shortlisted_in',
      headerName: 'Shortlisted in',
      width: 220,
      valueFormatter: params => {
        return (params.value as RelatedList)
          ?.map(({ title }) => title)
          .join(', ');
      },
      renderCell: params => {
        const list = params.value as RelatedList;

        return (
          <Stack direction='row' spacing={1}>
            <ListsLabel lists={list?.map(l => l.title) || []} />
          </Stack>
        );
      },
      sortable: false,
      filterOperators: [listsFilterOperator],
    },
    {
      field: 'selected_in',
      headerName: 'Selected In',
      width: 220,
      valueFormatter: params => {
        return (params.value as RelatedList)
          ?.map(({ title }) => title)
          .join(', ');
      },
      renderCell: params => {
        const list = params.value as RelatedList;

        return (
          <Stack direction='row' spacing={1}>
            <ListsLabel lists={list?.map(l => l.title) || []} />
          </Stack>
        );
      },
      sortable: false,
      filterOperators: [listsFilterOperator],
    },
    {
      field: 'created_at',
      headerName: 'Listed since',
      width: 200,
      valueFormatter: params => {
        return params.value ? new Date(params.value).toLocaleDateString() : '-';
      },
    },
  ];
  return COLUMNS;
};

const listsFilterOperator: GridFilterOperator = {
  label: 'contains',
  value: 'contains',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (
      !filterItem.columnField ||
      !filterItem.value ||
      !filterItem.operatorValue
    ) {
      return null;
    }

    return (params: GridCellParams): boolean => {
      if (!params.value) return false;

      const lists = params.value as RelatedList;

      return lists!.some(list =>
        list.title.toLowerCase().includes(filterItem.value.toLowerCase()),
      );
    };
  },
  InputComponent: TextFieldFilterComponent,
  InputComponentProps: { type: 'string' },
};

function getDecoratedStartups(
  allStartups: Required<GetOrganizationStartupsQuery['organization_startups']>,
  // startupLabelsByStartupId: Record<number, StartupLabel[]> | undefined,
): DecoratedOrganizationStartup[] {
  return (
    allStartups.map(({ startup, created_at }) => {
      if (!startup) throw new Error('Unexpected missing startup');
      if (!created_at) throw new Error('Unexpected missing startup');

      const connectionStatus = startup.connection?.status;
      const connected =
        connectionStatus === 'initiated'
          ? ('initiated' as const)
          : connectionStatus === 'accepted'
            ? ('accepted' as const)
            : ('not connected' as const);

      return {
        created_at,
        ...startup,
        listed_in: startup.listed_in_startup_lists,
        selected_in: startup.selected_in_startup_lists,
        shortlisted_in: startup.shortlisted_in_startup_lists,
        connected,
        signal_label: getStartupSignalLabelByOrder(startup.signal_labels),
        funnel_label: getStartupFunnelLabelOrderWithoutInLibrary(
          startup.funnel_labels,
        ),
      };
    }) || []
  );
}
