import { useTheme } from '@mui/material';
import Image from 'components/Image';

export default function TeamAvatar({
  alt = '',
  src,
  ...rest
}: {
  src: string;
  alt: string;
} & Parameters<typeof Image>[0]) {
  const { spacing } = useTheme();

  return (
    <Image
      src={src}
      alt={alt}
      borderRadius='50%'
      loading='lazy'
      {...rest}
      sx={{
        width: `${spacing(3)} !important`,
        height: `${spacing(3)} !important`,
        objectFit: 'cover',
        '& img': { objectFit: 'cover' },
        ...rest.sx,
      }}
    />
  );
}
