import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import {
  EnumTableProjectStakeholderRolesEnum,
  useGetAllPeopleQuery,
} from 'apollo/generated/sdkInnovationManager';
import {
  useAddNewChallengeStakeholderMutation,
  useUpsertPersonActionMutation,
} from 'apollo/generated/sdkShared';
import { AddPersonModal } from 'components/engagement/people/AddPersonModal';
import { Form, FormikProvider, useFormik } from 'formik';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { useMemo, useState } from 'react';
import { Person } from '../../../../@types/stakeholder';
import { ChallengeStakeholderType } from '../types';
import { captureException } from '@sentry/react';
import { useSnackbar } from 'notistack';
import {
  PROJECT_ROLES,
  PROJECT_ROLES_MAP,
} from 'components/AddNewProjectStakeholder';
import { useApolloClient } from '@apollo/client';
import { useGetChallengeAndConnections } from 'hooks/useGetChallengeAndConnections';

export const AddChallengeStakeholderModal = ({
  challengeId,
  challengeStakeholders,
  open,
  onHide,
}: {
  challengeId: number;
  challengeStakeholders: ChallengeStakeholderType[];
  open: boolean;
  onHide: () => void;
}) => {
  const { cache: apolloCache } = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();

  const { refetch } = useGetChallengeAndConnections({
    challengeID: challengeId,
  });

  const [stakeholderValue, setPersonValue] = useState<Person | null>(null);
  const [openAddPerson, setOpenAddPerson] = useState<boolean>(false);

  const { data: allPeopleData } = useGetAllPeopleQuery({});
  const [upsertPersonAction] = useUpsertPersonActionMutation();

  const [addNewChallengeStakeholder] = useAddNewChallengeStakeholderMutation();

  const allStakeholders =
    allPeopleData?.people.map(list => ({
      id: list.id,
      full_name: list.full_name,
      department: list.department,
      email: list.email,
    })) || [];

  const challengeStakeholderIds = useMemo(
    () => challengeStakeholders.map(ps => ps.stakeholder.id) || [],
    [challengeStakeholders],
  );

  const stakeholdersOptions = allStakeholders
    .filter(stakeholder => !challengeStakeholderIds.includes(stakeholder.id))
    .sort((a, b) => a.full_name.localeCompare(b.full_name));

  const handleProjectStakeholderChange = (id: number) => {
    setFieldValue('stakeholderId', id);
    setPersonValue(stakeholdersOptions.find(s => s.id === id) || null);
  };

  const addChallengeStakeholder = async ({
    stakeholderId,
    role,
  }: {
    stakeholderId?: number;
    role?: keyof PROJECT_ROLES;
  }) => {
    try {
      if (stakeholderId) {
        await addNewChallengeStakeholder({
          variables: {
            challengeId: challengeId,
            personId: stakeholderId,
            role: role as EnumTableProjectStakeholderRolesEnum,
          },
        });
        refetch();

        apolloCache.evict({
          id: apolloCache.identify({
            __typename: 'challenges',
            id: challengeId,
          }),
        });

        apolloCache.gc();

        enqueueSnackbar('Stakeholder added.', {
          variant: 'success',
        });
      }
    } catch (e) {
      enqueueSnackbar('Error adding stakeholder', {
        variant: 'error',
      });
      captureException(e);
    }
  };

  const formik = useFormik<{
    stakeholderId?: number;
    role?: keyof PROJECT_ROLES;
  }>({
    initialValues: {
      stakeholderId: undefined,
      role: undefined,
    },
    onSubmit: async (values, { resetForm }) => {
      addChallengeStakeholder(values);
      if (values.stakeholderId && values.role) {
        captureAnalyticsEvent('Challenge stakeholder added', {
          challengeId: challengeId,
          stakeholderRole: values.role,
        });
      }
      setPersonValue(null);
      resetForm();
      onHide();
    },
  });
  const { errors, touched, handleSubmit, getFieldProps, setFieldValue } =
    formik;

  return (
    <Dialog open={open} fullWidth onClose={onHide}>
      <FormikProvider value={formik}>
        <Form autoComplete='off' onSubmit={handleSubmit}>
          <DialogTitle>Add stakeholder</DialogTitle>
          <DialogContent sx={{ marginTop: '25px' }}>
            <Stack direction='column' spacing={2} sx={{ marginTop: 1 }}>
              <Autocomplete
                options={stakeholdersOptions}
                {...getFieldProps('stakeholderId')}
                onChange={(_e, value: unknown) =>
                  // @ts-expect-error: TODO: FIXME
                  handleProjectStakeholderChange(value?.id)
                }
                value={stakeholderValue}
                multiple={false}
                getOptionLabel={(option: unknown) => {
                  if (!option) return '';

                  // @ts-expect-error: TODO: FIXME
                  return option.department
                    ? // @ts-expect-error: TODO: FIXME
                      option.full_name + ` (${option.department})`
                    : // @ts-expect-error: TODO: FIXME
                      option.full_name;
                }}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Box
                      component='span'
                      sx={{
                        overflow: 'hidden',
                        width: '100%',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'initial',
                      }}
                    >
                      {/* @ts-expect-error: TODO: FIXME */}
                      {`${option.full_name}${
                        // @ts-expect-error: TODO: FIXME
                        option.department ? ` (${option?.department})` : ''
                      }`}
                    </Box>
                  </li>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant='outlined'
                    placeholder='Assign stakeholder'
                  />
                )}
                noOptionsText={
                  <Button
                    color='primary'
                    sx={{ justifyContent: 'flex-start', paddingLeft: 2 }}
                    onMouseDown={() => setOpenAddPerson(true)}
                  >
                    + Add New
                  </Button>
                }
              />
              <TextField
                select
                fullWidth
                label='Role'
                {...getFieldProps('role')}
                helperText={touched.role && errors.role}
              >
                {Object.keys(PROJECT_ROLES_MAP).map((value, index) => {
                  if (
                    [
                      'project_leader',
                      'project_sponsor',
                      'project_team',
                    ].includes(value)
                  ) {
                    return (
                      <MenuItem key={`${value} + ${index}`} value={value}>
                        {PROJECT_ROLES_MAP[value as keyof PROJECT_ROLES]}
                      </MenuItem>
                    );
                  }
                  return null;
                })}
              </TextField>
              <AddPersonModal
                setPersonValue={(value: Person) => setPersonValue(value)}
                setFieldValue={setFieldValue}
                onHide={() => setOpenAddPerson(false)}
                onSetValues={async object => {
                  const stakeholderData = await upsertPersonAction({
                    variables: { object },
                  });
                  setOpenAddPerson(false);

                  return {
                    stakeholderId:
                      // eslint-disable-next-line
                      stakeholderData?.data?.upsert_person?.person?.id!,
                  };
                }}
                open={openAddPerson}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button variant='text' color='inherit' onClick={onHide}>
              Cancel
            </Button>
            <Button
              type='submit'
              variant='contained'
              disabled={!formik.values.role || !formik.values.stakeholderId}
            >
              Save
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};
