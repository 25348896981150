import { emojiFlag, feature } from '@rapideditor/country-coder';

const countryAlpha2Map = new Map([
  //US
  ['United States', 'USA'],
  //CN
  ['China', 'CN'],
  //GB
  ['England', 'GBR'],
  ['The United Kingdom', 'GBR'],
  //IE
  ['Ireland', 'IE'],
  //NL
  ['Netherlands', 'NL'],
  ['The Netherlands', 'NL'],
  //DK
  ['Denmark', 'DK'],
  //RO
  ['ROM', 'RO'],
  //TZ
  ['TAN', 'TZ'],
  //CZ
  ['Czech Republic', 'CZ'],
  //DE
  ['Berlin', 'DE'],
  ['Hamburg', 'DE'],
  ['Köln', 'DE'],
  ['Bayern', 'DE'],
  ['Bavaria', 'DE'],
  ['München', 'DE'],
  ['Munich', 'DE'],
  ['Münster', 'DE'],
  ['Brandenburg', 'DE'],
  ['München', 'DE'],
  ['Hesse', 'DE'],
  ['Lower Saxony', 'DE'],
  ['Hessen', 'DE'],
  ['Sachsen', 'DE'],
  ['Siegen', 'DE'],
  ['Saarland', 'DE'],
  ['Cologne', 'DE'],
  ['North Rhine-Westphalia', 'DE'],
  ['Nrth Rhine Westfalia', 'DE'],
  ['Nordrhein-Westfalen', 'DE'],
  ['Baden-Württemberg', 'DE'],
  ['Baden Württemberg', 'DE'],
]);

export const getCountryFromHQ = (HQ: string = '') => {
  const splitHq = HQ.split(',');
  const country = splitHq[splitHq.length - 1].trim();
  return country || '';
};

export const getAlpha2CountryFromHQ = (HQ: string = '') => {
  const country = getCountryFromHQ(HQ);
  return countryAlpha2Map.get(country) || country;
};

export const getCountryFlag = (HQ: string = '') => {
  const country = getCountryFromHQ(HQ);
  let countryFlag = emojiFlag(country);
  if (!countryFlag) {
    countryFlag = emojiFlag(countryAlpha2Map.get(country)!);
  }

  return countryFlag || '🏳️';
};

export const UNKNOWN_COUNTRY_OPTION = 'Unknown';

export const getCountryName = (HQ: string = '') => {
  const countryCode = getAlpha2CountryFromHQ(HQ);
  const country = feature(countryCode)?.properties?.nameEn;
  return country || UNKNOWN_COUNTRY_OPTION;
};
