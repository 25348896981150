import { gql } from '@apollo/client';
import TagIcon from '@mui/icons-material/Tag';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import {
  useFindOrganizationStartupProfileQuery,
  useUpdateSupplierIdMutation,
} from 'apollo/generated/sdkShared';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { StartupForSidePanel } from './StartupInfoSidePanel';

const EditSupplierIdMenuItem = ({
  currentStartup,
}: {
  currentStartup: StartupForSidePanel;
}) => {
  const [setOpen, editSupplierDialog] = useEditSupplierDialog({
    currentStartup,
  });

  return (
    <>
      <MenuItem sx={{ color: 'text.secondary' }} onClick={() => setOpen(true)}>
        <TagIcon />
        <Typography
          variant='body2'
          sx={{
            marginLeft: 0.5,
          }}
        >
          Edit supplier ID
        </Typography>
      </MenuItem>
      {editSupplierDialog}
    </>
  );
};

const useEditSupplierDialog = ({
  currentStartup,
}: {
  currentStartup: StartupForSidePanel;
}) => {
  const [open, setOpen] = useState(false);

  const { data } = useFindOrganizationStartupProfileQuery({
    variables: {
      startupId: currentStartup!.id,
    },
    skip: !currentStartup?.id,
  });

  const procurementSupplierIdentifier = useMemo(
    () =>
      data?.organization_startup_profile[0]?.procurement_supplier_identifier ||
      '',
    [data?.organization_startup_profile],
  );

  return [
    setOpen,
    <EditSupplierDialog
      key={'EditSupplierDialog'}
      open={open}
      setOpen={setOpen}
      startupId={currentStartup.id}
      procurementSupplierIdentifier={procurementSupplierIdentifier}
    />,
  ] as const;
};

const EditSupplierDialog = ({
  open,
  setOpen,
  procurementSupplierIdentifier,
  startupId,
}: {
  open: boolean;
  setOpen: (value: boolean) => void;
  procurementSupplierIdentifier: string;
  startupId: number;
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = useState(procurementSupplierIdentifier);

  const [updateSupplierNumber] = useUpdateSupplierIdMutation();

  const handleUpdate = async () => {
    await updateSupplierNumber({
      variables: {
        startupId: startupId,
        procurementSupplierIdentifier: value,
      },
      update(cache, { data }) {
        if (!data?.insert_organization_startup_profile_one) return;

        const supplierId = cache.identify({
          __typename: 'suppliers',
          id: startupId,
        });

        cache.modify({
          id: supplierId,
          fields: {
            organization_profile(existingProfiles) {
              const newProfileRef = cache.writeFragment({
                data: data.insert_organization_startup_profile_one,
                fragment: gql`
                  fragment NewOrganizationStartupProfile on organization_startup_profile {
                    id
                    procurement_supplier_identifier
                  }
                `,
              });

              if (newProfileRef) return newProfileRef;

              return existingProfiles;
            },
          },
        });
      },
    });

    setOpen(false);

    enqueueSnackbar('Saved', {
      variant: 'success',
      autoHideDuration: 500,
    });
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
      <form
        onSubmit={async e => {
          e.preventDefault();
          await handleUpdate();
        }}
      >
        <DialogTitle
          sx={{ fontWeight: 700, marginBottom: 1, paddingBottom: 0 }}
        >
          Edit supplier ID
        </DialogTitle>
        <DialogContent>
          <TextField
            sx={{ marginTop: 2 }}
            fullWidth
            label='Supplier identifier'
            value={value}
            onChange={e => setValue(e.target.value)}
          />
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <Button variant='text' color='inherit' onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button type='submit' variant='outlined'>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditSupplierIdMenuItem;
