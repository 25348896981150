import {
  Box,
  Button,
  TextField,
  Tooltip,
  Typography,
  TypographyProps,
} from '@mui/material';

import { Add, InfoOutlined } from '@mui/icons-material';
import { useSharedPagesContext } from 'layouts/SharedPagesLayout';
import { SourcingOrderState } from 'pages/request-form/sourcingOrderModel';
import { useEffect, useRef, useState } from 'react';
import { FieldProps } from '../../pages/request-form/types';

type ProblemSchemaValues = Pick<SourcingOrderState, 'problem'>;

type EditableFieldProps = {
  name: string;
  value: string;
  checked?: boolean;
  multiple?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
};

interface FormLabelWithTooltipProps extends TypographyProps {
  label: string;
  tooltipText: string;
  isMandatory?: boolean;
}

export const FormLabelWithTooltip = ({
  label,
  tooltipText,
  isMandatory = false,
  ...props
}: FormLabelWithTooltipProps) => {
  return (
    <Typography
      gutterBottom
      variant='body2'
      component='div'
      display='flex'
      alignItems='center'
      marginBottom='8px'
      fontWeight={900}
      paddingX={'4px'}
      {...props}
    >
      {label}
      <Tooltip title={tooltipText} placement='top-start'>
        <InfoOutlined
          fontSize='small'
          sx={{
            marginLeft: 1,
            cursor: 'help',
            color: 'text.secondary',
          }}
        />
      </Tooltip>
      {isMandatory && '*'}
    </Typography>
  );
};

export function ProblemField({
  getFieldProps,
  touched,
  isEditMode,
  errors,
}: FieldProps<Pick<ProblemSchemaValues, 'problem'>>) {
  const { isSharedPage } = useSharedPagesContext();

  return (
    <>
      <FormLabelWithTooltip
        label='Need / Pain Point'
        tooltipText={
          isSharedPage
            ? 'Please describe the specific pain point or challenge you are facing and provide some examples if possible.'
            : 'Please describe the specific pain point or challenge your stakeholders are facing and provide some examples if possible.'
        }
        isMandatory
      />
      {!isEditMode ? (
        <TextField
          rows={4}
          multiline={true}
          fullWidth
          data-test='need--field'
          {...getFieldProps('problem')}
          error={Boolean(touched.problem && errors.problem)}
          helperText={touched.problem && errors.problem}
        />
      ) : (
        <EditableField data-test='need--field' {...getFieldProps('problem')} />
      )}
    </>
  );
}

export function EditableField(props: EditableFieldProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(props.value);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleTextClick = () => {
    setIsEditing(true);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsEditing(false);
    props.onBlur(e);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setText(newValue);
    props.onChange(e);
  };

  useEffect(() => {
    if (isEditing && inputRef.current) {
      const length = text.length;
      inputRef.current.focus();
      inputRef.current.setSelectionRange(length, length);
    }
  }, [isEditing, text]);

  return (
    <>
      {!text && !isEditing ? (
        <Button
          sx={{
            padding: '2px',
          }}
          startIcon={<Add />}
          onClick={() => setIsEditing(true)}
        >
          Add
        </Button>
      ) : (
        <Box
          onClick={handleTextClick}
          sx={{
            cursor: isEditing ? 'text' : 'pointer',
            padding: '4px',
            border: `0.5px solid ${isEditing ? '#ddd' : 'transparent'}`,
            boxShadow: isEditing ? ({ shadows }) => shadows[4] : 'none',
            borderRadius: '8px',
            '&:hover': {
              backgroundColor: !isEditing ? '#f0f0f0' : 'transparent',
              cursor: !isEditing ? 'text' : 'pointer',
            },
          }}
        >
          {isEditing ? (
            <TextField
              {...props}
              value={text}
              onChange={handleChange}
              inputRef={inputRef}
              variant='standard'
              onBlur={handleBlur}
              autoFocus
              fullWidth
              multiline
              InputProps={{
                disableUnderline: true,
                sx: { padding: 0, lineHeight: 1.5, fontSize: '14px' },
              }}
              inputProps={{
                style: { lineHeight: '1.5' },
              }}
            />
          ) : (
            <Box lineHeight={1.5} fontSize='14px'>
              {text}
            </Box>
          )}
        </Box>
      )}
    </>
  );
}
