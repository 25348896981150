import { Divider, Grid, Stack, Typography } from '@mui/material';
import { ChallengeConnectedProjectType } from '../../types';
import { LinkConnectionCard as LinkProjectCard } from '../LinkConnectionCard';
import LinkProjectToChallengeModal from './LinkProjectToChallengeModal';
import { UnlinkChallengeConnectionModal } from '../UnlinkChallengeConnectionModal';
import { useState } from 'react';
import { ChallengeSubProjectCard } from './ChallengeSubProjectCard';

export const ChallengeSubProjectsSection = ({
  challengeId,
  connectedProjects,
  isReadonly,
}: {
  challengeId: number;
  connectedProjects: ChallengeConnectedProjectType[];
  isReadonly?: boolean;
}) => {
  const [showLinkProjectModal, setShowLinkProjectModal] = useState(false);

  const [selectedProjectToUnlink, setSelectedProjectToUnlink] =
    useState<ChallengeConnectedProjectType | null>(null);

  return (
    <>
      <Stack marginTop={1}>
        <Typography variant='body1' fontSize={20}>
          Projects
        </Typography>
        <Divider />
        <Grid container spacing={2} marginY={2}>
          {connectedProjects?.map(connectedProject => (
            <Grid item key={connectedProject.id}>
              <ChallengeSubProjectCard
                connectedProject={connectedProject}
                type='project'
                setSelectedProject={setSelectedProjectToUnlink}
                isReadonly={isReadonly || false}
              />
            </Grid>
          ))}
          {!isReadonly && (
            <Grid item>
              <LinkProjectCard
                setShowLinkModal={() => setShowLinkProjectModal(true)}
                sx={
                  connectedProjects.length !== 0 &&
                  connectedProjects.length % 3 === 0
                    ? {
                        height: '44px',
                        marginBottom: 1.5,
                      }
                    : { minHeight: 222 }
                }
              />
            </Grid>
          )}
        </Grid>
      </Stack>

      <LinkProjectToChallengeModal
        open={showLinkProjectModal}
        onHide={() => setShowLinkProjectModal(false)}
        challengeId={challengeId}
        type='project'
        connectedLeads={[]}
        connectedProjects={connectedProjects}
      />
      <UnlinkChallengeConnectionModal
        open={Boolean(selectedProjectToUnlink)}
        onHide={() => setSelectedProjectToUnlink(null)}
        challengeId={challengeId}
        projectId={selectedProjectToUnlink?.id}
        type='project'
        title={selectedProjectToUnlink?.title}
      />
    </>
  );
};
