import CloseIcon from '@mui/icons-material/Close';
import { Tooltip, alpha } from '@mui/material';
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  GetInAppNotificationsQuery,
  useGetInAppNotificationsQuery,
} from 'apollo/generated/sdkInnovationManager';
import { useResponsive } from 'hooks/useResponsive';
import { useEffect, useState } from 'react';

import { NotificationsOutlined } from '@mui/icons-material';
import { useMarkAllNotificationsAsSeenMutation } from 'apollo/generated/sdkShared';
import BaseScrollbar from 'components/base/BaseScrollbar';
import useAuth from 'hooks/useAuth';
import NotificationItem from './NotificationItem';

export default function NotificationsPopover() {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const isHigherThanSmallScreen = useResponsive('up', 'sm');

  const { data, loading } = useGetInAppNotificationsQuery();

  if (loading)
    return <NotificationIconButton drawerOpen={false} totalUnRead={0} />;
  const notifications = data?.inapp_notifications || [];
  const unreadNotifications = notifications.filter(item => item.seen === false);

  const totalUnRead = unreadNotifications.length;

  return (
    <>
      <NotificationIconButton
        drawerOpen={drawerOpen}
        onClick={() => {
          setDrawerOpen(true);
        }}
        totalUnRead={totalUnRead}
      />

      <Drawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        anchor='right'
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: theme => ({
            width: 1,
            maxWidth: 420,
            backdropFilter: 'blur(20px)',
            backgroundColor: alpha(theme.palette.grey[0], 0.9),
          }),
        }}
      >
        <NotificationsHeader
          isHigherThanSmallScreen={isHigherThanSmallScreen}
          setDrawerOpen={() => setDrawerOpen(false)}
        />
        <Divider />
        <NotificationItemList
          notifications={notifications}
          onItemClick={() => setDrawerOpen(false)}
        />
      </Drawer>
    </>
  );
}

const NotificationIconButton = (props: {
  drawerOpen: boolean;
  onClick?: () => void;
  totalUnRead: number;
}) => {
  return (
    <Tooltip title='Notifications'>
      <IconButton
        color={props.drawerOpen ? 'primary' : 'default'}
        size='medium'
        onClick={props.onClick}
        sx={({ spacing }) => ({
          width: spacing(6),
          height: spacing(6),
        })}
      >
        <Badge badgeContent={props.totalUnRead} color='error'>
          <NotificationsOutlined width={24} />
        </Badge>
      </IconButton>
    </Tooltip>
  );
};

const NotificationsHeader = ({
  isHigherThanSmallScreen,
  setDrawerOpen,
}: {
  isHigherThanSmallScreen: boolean;
  setDrawerOpen: () => void;
}) => (
  <Stack
    direction='row'
    alignItems='center'
    sx={{ paddingY: 2, paddingLeft: 2.5, paddingRight: 1, minHeight: 68 }}
  >
    <Typography variant='h6' sx={{ flexGrow: 1 }}>
      Notifications
    </Typography>

    {!isHigherThanSmallScreen && (
      <IconButton onClick={setDrawerOpen}>
        <CloseIcon />
      </IconButton>
    )}
  </Stack>
);

function NotificationItemList({
  notifications,
  onItemClick,
}: {
  notifications: GetInAppNotificationsQuery['inapp_notifications'];
  onItemClick: () => void;
}) {
  const { user } = useAuth();
  const [markAllAsRead] = useMarkAllNotificationsAsSeenMutation();

  useEffect(() => {
    const unreadNotifications = notifications.filter(
      item => item.seen === false,
    );

    if (unreadNotifications.length > 0) {
      markAllAsRead({ variables: { userId: user.id } });
    }
  }, [notifications, markAllAsRead, user.id]);

  return (
    <BaseScrollbar>
      <List disablePadding>
        {notifications.map(notification => (
          <NotificationItem
            key={notification.id}
            notification={notification}
            onClick={onItemClick}
          />
        ))}
      </List>
    </BaseScrollbar>
  );
}
