import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
} from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { UserOutreachTab } from './UserOutreachTab';
import { GDOutreachTab } from './GDOutreachTab';
import { OutreachStartupProps } from './types';

type OutreachModalProps = {
  open: boolean;
  onHide: () => void;
  startup: OutreachStartupProps;
  projectId?: number;
  isAskGDEnabled: boolean;
  setIsAddContactModalOpened: Dispatch<SetStateAction<boolean>>;
};

export const OutreachModal = (modalProps: OutreachModalProps) => {
  const {
    open,
    onHide,
    startup,
    projectId,
    isAskGDEnabled,
    setIsAddContactModalOpened,
  } = modalProps;

  const [connectTabValue, setConnectTabValue] = useState<
    'userOutreach' | 'askGD'
  >('userOutreach');

  const [actionButtonProps, setActionButtonProps] = useState({
    label: 'Send (opens email client)',
    disabled: true,
    onClick: () => {},
  });

  return (
    <Dialog open={open} fullWidth maxWidth='md' onClose={onHide}>
      <DialogTitle sx={{ fontWeight: 700, marginBottom: 1, paddingBottom: 0 }}>
        Get in touch with {startup.name}
      </DialogTitle>
      <DialogContent>
        {isAskGDEnabled && (
          <Tabs
            value={connectTabValue}
            onChange={(event, newValue) => setConnectTabValue(newValue)}
          >
            <Tab value='userOutreach' label='Reach out yourself' />
            <Tab value='askGD' label='Ask GlassDollar' />
          </Tabs>
        )}
        {connectTabValue === 'userOutreach' && (
          <UserOutreachTab
            startup={startup}
            projectId={projectId}
            setActionButtonProps={setActionButtonProps}
            setIsAddContactModalOpened={setIsAddContactModalOpened}
          />
        )}
        {connectTabValue === 'askGD' && (
          <GDOutreachTab
            startup={startup}
            setActionButtonProps={setActionButtonProps}
          />
        )}
      </DialogContent>
      <DialogActions sx={{ padding: 3 }}>
        <Button variant='text' color='inherit' onClick={onHide}>
          Cancel
        </Button>
        <Button
          type='submit'
          variant='outlined'
          disabled={actionButtonProps.disabled}
          onClick={actionButtonProps.onClick}
        >
          {actionButtonProps.label}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
