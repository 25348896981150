import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  Stack,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import BaseInitialsAvatar from 'components/base/BaseInitialsAvatar';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import { formatDistance } from 'date-fns';

const avatarSize = 25;
const overlapMargin = -7.5; // Negative margin for overlapping effect in pixels
const MAX_VISIBLE_AVATARS = 5;

const StyledList = styled(List)({
  display: 'flex',
  flexDirection: 'row',
  padding: 0,
  margin: 0,
  alignItems: 'center',
  marginRight: 3,
  '& .MuiListItem-root': {
    display: 'inline-flex',
    marginRight: 0,
    marginLeft: `${overlapMargin}px`,
    padding: 0,
  },
  '& .MuiListItem-root:first-of-type': {
    marginLeft: 0, // No negative margin for the first item
  },
});

type Visitor = {
  full_name: string;
  email: string;
  last_visited_at: string | null;
  invited_at: string | null;
};

const AvatarViews = ({ visitors = [] }: { visitors?: Visitor[] }) => {
  const { lists_last_viewed_at_enabled } = useCurrentOrganizationFromContext();
  const sortedVisitors = visitors.sort(
    (a, b) =>
      new Date(b.last_visited_at || 0).getTime() -
      new Date(a.last_visited_at || 0).getTime(),
  );

  const visibleVisitors = sortedVisitors.slice(0, MAX_VISIBLE_AVATARS);
  const additionalVisitors = sortedVisitors.slice(MAX_VISIBLE_AVATARS);

  const invitesThatVisited = sortedVisitors.filter(
    visitor =>
      (visitor.invited_at && visitor.last_visited_at) ||
      (!visitor.invited_at && !visitor.last_visited_at),
  );

  const invitedButNotVisited = sortedVisitors.filter(
    visitor => visitor.invited_at && !visitor.last_visited_at,
  );

  return (
    <StyledList>
      {visibleVisitors.map((visitor, index) => (
        <Tooltip
          key={`avatar-views-${visitor.email}`}
          title={
            lists_last_viewed_at_enabled ? (
              <VisitorsLastSeenStatus visitor={visitor} showSeenBy />
            ) : (
              ''
            )
          }
        >
          <ListItem sx={{ zIndex: visibleVisitors.length - index }}>
            <ListItemAvatar sx={{ marginRight: 0 }}>
              <BaseInitialsAvatar
                full_name={visitor.full_name || ''}
                sx={{
                  width: `${avatarSize}px`,
                  height: `${avatarSize}px`,
                  fontSize: '12px',
                  margin: 0,
                  border: '2px solid white',
                }}
                doubleInitials={false}
              />
            </ListItemAvatar>
          </ListItem>
        </Tooltip>
      ))}
      {additionalVisitors.length > 0 && (
        <ListItem>
          <Tooltip
            title={
              lists_last_viewed_at_enabled ? (
                <AllInvitesTooltipContent
                  invitesThatVisited={invitesThatVisited}
                  invitedButNotVisited={invitedButNotVisited}
                />
              ) : (
                ''
              )
            }
            PopperProps={{
              sx: {
                [`& .MuiTooltip-tooltip`]: {
                  maxWidth: 'none',
                  minWidth: '350px',
                  padding: '8px 0px',
                  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                },
              },
            }}
          >
            <Typography variant='caption' marginLeft={1.25}>
              +{additionalVisitors.length}
            </Typography>
          </Tooltip>
        </ListItem>
      )}
    </StyledList>
  );
};

const VisitorsLastSeenStatus = ({
  visitor,
  showSeenBy,
}: {
  visitor: Visitor;
  showSeenBy?: boolean;
}) => (
  <Stack>
    {visitor.invited_at && visitor.last_visited_at && (
      <>
        {showSeenBy && (
          <Typography variant='caption'>Seen by {visitor.full_name}</Typography>
        )}
        <Typography variant='caption' color='grey.400'>
          {getDateText(visitor.last_visited_at)}
        </Typography>
      </>
    )}
    {!visitor.invited_at && !visitor.last_visited_at && (
      <Typography variant='caption' color='grey.400'>
        N/A
      </Typography>
    )}
    {visitor.invited_at && !visitor.last_visited_at && (
      <Typography variant='caption' color='grey.400'>
        Not seen yet
      </Typography>
    )}
  </Stack>
);

const InvitedVisitorsSection = ({
  title,
  visitors,
  isSeen,
}: {
  title: string;
  visitors: Visitor[];
  isSeen?: boolean;
}) => (
  <Box paddingX={2}>
    <Typography variant='caption' fontWeight='600'>
      {title}
    </Typography>
    {visitors.map((visitor, index) => (
      <Box
        key={`${isSeen ? 'seen' : 'not-seen'}-${index}`}
        sx={{
          display: 'flex',
          direction: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginY: 0.5,
          gap: 2,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 0.5 }}>
          <BaseInitialsAvatar
            full_name={visitor.full_name || ''}
            sx={{
              width: 20,
              height: 20,
              fontSize: '12px',
              marginRight: 0.5,
            }}
            doubleInitials={false}
          />
          <Typography variant='caption' fontWeight='600' sx={{ lineHeight: 1 }}>
            {visitor.full_name}
          </Typography>
        </Box>
        {isSeen ? (
          <VisitorsLastSeenStatus visitor={visitor} />
        ) : (
          <Typography variant='caption'>-</Typography>
        )}
      </Box>
    ))}
  </Box>
);

const AllInvitesTooltipContent = ({
  invitedButNotVisited,
  invitesThatVisited,
}: {
  invitesThatVisited: Visitor[];
  invitedButNotVisited: Visitor[];
}) => (
  <Stack>
    {invitesThatVisited.length > 0 && (
      <InvitedVisitorsSection
        title='Seen by'
        visitors={invitesThatVisited}
        isSeen
      />
    )}

    {invitesThatVisited.length > 0 && invitedButNotVisited.length > 0 && (
      <Divider sx={{ marginY: 1, width: '100%' }} />
    )}

    {invitedButNotVisited.length > 0 && (
      <InvitedVisitorsSection
        title="Hasn't viewed yet"
        visitors={invitedButNotVisited}
      />
    )}
  </Stack>
);

export const getDateText = (date: string) =>
  formatDistance(new Date(date), new Date(), {
    addSuffix: true,
  }).replace('about', '');

export default AvatarViews;
