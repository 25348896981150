import { Box, Skeleton, Typography } from '@mui/material';
import {
  EnumTableProjectResultTypesEnum,
  useGetProjectTitleByUuidQuery,
} from 'apollo/generated/sdkShared';
import { X_HASURA_ORGANIZATION_UUID } from 'config';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import { useMemo } from 'react';
import { matchPath } from 'react-router';
import { PATH_NAME } from 'routes/paths';

export type LoginPageType =
  | 'regular'
  | EnumTableProjectResultTypesEnum
  | 'scoping';

const Title = ({
  name,
  loading = false,
  listTitle = '',
  shouldShowListName = false,
}: {
  name: string;
  loading?: boolean;
  listTitle?: string;
  shouldShowListName: boolean;
}) => (
  <Typography variant='h6' fontWeight={300}>
    Authenticate with your {name} email address
    {shouldShowListName && (
      <>
        {' '}
        to access <ListName loading={loading} title={listTitle} />
      </>
    )}
  </Typography>
);

const ListName = ({ loading, title }: { loading: boolean; title: string }) => {
  if (loading) {
    return (
      <Skeleton sx={{ display: 'inline-flex', minWidth: 150 }} variant='text' />
    );
  }

  return (
    <Box component='span' sx={{ fontWeight: 900 }}>
      {title}
    </Box>
  );
};

const SubtitleWithListName = ({ name }: { name: string }) => (
  <Typography variant='body1'>
    Our startup intelligence is reserved to members of <b>{name}</b>.
  </Typography>
);

export const useGetLoginCopy = (
  returnTo = '',
): {
  title: JSX.Element;
  subtitle: JSX.Element | null;
  loginPageType: LoginPageType;
  startupListTitle?: string;
} => {
  const { name, uuid: organizationUUID } = useCurrentOrganizationFromContext();

  const sharedListMatch = matchPath(
    `/${PATH_NAME.shared}/${PATH_NAME.lists}/:uuid/:slug`,
    returnTo,
  );

  const scopingMatch = matchPath(
    `/${PATH_NAME.portalRoot}/${PATH_NAME.scope}/:uuid`,
    returnTo,
  );

  const isSharedList = useMemo(
    () =>
      !!sharedListMatch ||
      returnTo.startsWith(`/${PATH_NAME.portalRoot}/${PATH_NAME.lists}`),
    [returnTo, sharedListMatch],
  );

  const { data, loading } = useGetProjectTitleByUuidQuery({
    variables: {
      where: {
        public_uuid: {
          _eq: sharedListMatch?.params.uuid || scopingMatch?.params.uuid,
        },
      },
    },
    context: { headers: { [X_HASURA_ORGANIZATION_UUID]: organizationUUID } },
    skip: (!sharedListMatch || !scopingMatch) && !!organizationUUID,
  });

  const startupList = data?.startup_lists[0];

  if (scopingMatch) {
    return {
      title: (
        <Title
          name={name}
          listTitle={startupList?.title}
          loading={loading}
          shouldShowListName
        />
      ),
      subtitle: <></>,
      loginPageType: 'scoping',
    };
  }

  if (isSharedList && sharedListMatch) {
    return {
      title: (
        <Title
          name={name}
          listTitle={startupList?.title}
          loading={loading}
          shouldShowListName
        />
      ),
      subtitle: <SubtitleWithListName name={name} />,
      loginPageType:
        (startupList?.result_type as EnumTableProjectResultTypesEnum) ||
        'benchmark',
      startupListTitle: startupList?.title,
    };
  }

  return {
    title: (
      <Typography variant='h6' fontWeight={300}>
        Log into your Venture Clienting OS
      </Typography>
    ),
    subtitle: null,
    loginPageType: 'regular',
  };
};
