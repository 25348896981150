import { Box, Stack } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { AddNoteButtonShared } from 'components/startupComparisonTable/shared/AddNoteButtonShared';
import {
  DecoratedStartup,
  StartupsTableContext,
} from 'components/startupComparisonTable/shared/StartupsTableContext';
import { VotingButtonInternal } from 'components/startupComparisonTable/voting/VotingButtonInternal';
import { VotingButtonShared } from 'components/startupComparisonTable/voting/shared/VotingButtonShared';
import { useContext } from 'react';

const ReactionsCell = (
  params: GridRenderCellParams<string, DecoratedStartup>['row'] & {
    isDiscovery: boolean;
    isPublicPage: boolean;
    id: number;
  },
) => {
  const { currentStartupList } = useContext(StartupsTableContext)!;
  const { isDiscovery, isPublicPage, ...votingParams } = params;

  return (
    <Stack
      width='100%'
      direction='row'
      justifyContent='center'
      spacing={1}
      data-testid='reactions-column-cell'
    >
      {!isDiscovery && (
        <Box data-testid='voting-container'>
          {isPublicPage ? (
            <VotingButtonShared decoratedStartup={votingParams} />
          ) : (
            <VotingButtonInternal decoratedStartup={votingParams} />
          )}
        </Box>
      )}

      <AddNoteButtonShared
        startupId={params.id}
        currentStartupList={currentStartupList}
      />
    </Stack>
  );
};

export { ReactionsCell };
