import { gql, useSubscription } from '@apollo/client';
import { FeatureFlags } from 'apollo/generated/sdkShared';
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

type State = Array<Pick<FeatureFlags, 'enabled' | 'id' | 'name'>>;

const defaultState: State = [];

export const FeatureFlagsContext = createContext<{
  loading: boolean;
  state: State;
}>({
  loading: true,
  state: defaultState,
});

export const FeatureFlagsContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [initialLoading, setInitialLoading] = useState(true);
  const { loading, data, error } = useSubscription(gql`
    subscription getFeatureFlags {
      feature_flags {
        id
        name
        enabled
      }
    }
  `);

  useEffect(() => {
    if (!loading && !!data) {
      setInitialLoading(false);
    }

    if (error) {
      setInitialLoading(false);
    }
  }, [data, error, loading]);

  return (
    <FeatureFlagsContext.Provider
      value={{
        loading: initialLoading,
        state: data?.feature_flags || defaultState,
      }}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export const useGetFeatureFlag = (
  flag: 'ff_client_refresh' | 'ff_new_features_released' | 'ff_maintenance',
) => {
  const context = useContext(FeatureFlagsContext);

  if (!context) {
    throw new Error(
      'using FeatureFlagsContext without the appropriate provider',
    );
  }

  return {
    isEnabled: context.state.find(f => f.name === flag)?.enabled,
    loading: context.loading,
  };
};
