import { Button } from '@mui/material';
import { EnumTableProjectStagesEnum } from 'apollo/generated/sdkInnovationManager';
import { useNavigate } from 'react-router';
import { buildProjectDetailsPath, PATH_ROOT } from 'routes/paths';

export const BaseSnackbarActionNavigateToList = ({
  listId,
  categoryId,
}: {
  listId: number;
  categoryId?: number;
}) => {
  const navigate = useNavigate();
  const path = categoryId
    ? PATH_ROOT.categoryDetails(listId, categoryId, false)
    : PATH_ROOT.lists.details(listId);

  return (
    <Button
      onClick={() => {
        navigate(path);
      }}
    >
      Navigate to {categoryId ? 'Category' : 'List'}
    </Button>
  );
};

export const BaseSnackbarActionNavigateToProject = ({
  projectLeadId,
  stage,
}: {
  projectLeadId: number;
  stage: EnumTableProjectStagesEnum;
}) => {
  const navigate = useNavigate();
  const path = buildProjectDetailsPath({
    id: projectLeadId,
    stage,
  });

  return (
    <Button
      onClick={() => {
        navigate(path);
      }}
    >
      Navigate to Lead/Project
    </Button>
  );
};
