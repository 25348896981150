import { useNavigate } from 'react-router';
import { hasNavigationHistory } from 'utils/url';

export const useNavigateBack = (backToParentPath: string) => {
  const navigate = useNavigate();

  const navigateBack = () => {
    if (hasNavigationHistory()) {
      navigate(-1);
    } else {
      navigate(backToParentPath, { replace: true });
    }
  };

  return { navigateBack };
};
