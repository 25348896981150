import { useEffect, useRef } from 'react';

// eslint-disable-next-line
function useInterval(callback: () => void, delay = 1000, deps: any = []) {
  const savedCallback = useRef<(() => void) | null>(null);

  const dependenciesParams = [delay, ...deps];

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    function runCallback() {
      if (savedCallback.current !== null) {
        savedCallback.current();
      }
    }
    if (typeof delay === 'number') {
      const interval = setInterval(runCallback, delay);
      return () => clearInterval(interval);
    }
  }, dependenciesParams);
}

export default useInterval;
