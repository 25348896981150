import { Badge } from '@mui/material';

const BaseTabCountBadge = ({ count = 0 }: { count: number | null }) => {
  return (
    <Badge
      color='primary'
      sx={{
        '& .MuiBadge-badge': {
          position: 'relative',
          transform: 'none',
          marginBottom: 2,
        },
        transform: 'translateY(22%)',
        '.MuiBadge-standard': {
          backgroundColor: 'primary.main',
        },
      }}
      badgeContent={`${count}`}
    />
  );
};

export default BaseTabCountBadge;
