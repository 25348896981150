import { Stack, Typography, useTheme } from '@mui/material';
import FilePreview from 'components/base/upload/FilePreview';
import { useFormikContext } from 'formik';
import { StartupFormState } from './useStartupDetailsFormik';

const FILE_TYPE_MAPPING = {
  sales_material: 'Sales Material',
  customer_case_studies: 'Customer Case Studies',
  relevant_documents: 'Relevant Documents',
};

export const getFileExtension = (fileName: string): string =>
  fileName?.slice(fileName?.lastIndexOf('.') + 1);

export const FileField = ({
  documentType,
  scopingView = false,
}: {
  documentType:
    | 'sales_material'
    | 'customer_case_studies'
    | 'relevant_documents';
  scopingView?: boolean;
}) => {
  const theme = useTheme();

  const {
    values: { documents_json },
  } = useFormikContext<StartupFormState>();

  const filteredDocuments = documents_json?.filter(
    d => d.document_type === documentType,
  );

  return (
    <Stack flex={1}>
      <Typography
        variant='body1'
        sx={{
          margin: '0 0 0.5rem',
          color: scopingView ? 'primary' : 'text.secondary',
          marginBottom: 1,
          fontWeight: scopingView ? 'bold' : 'normal',
        }}
      >
        {FILE_TYPE_MAPPING[documentType]}
      </Typography>
      <Stack
        flexGrow='1'
        overflow='hidden'
        textAlign='center'
        position='relative'
        alignItems='center'
        flexDirection='column'
        padding='3% !important'
        minHeight='4rem'
        sx={{
          outline: 'none',
          ...(filteredDocuments?.length === 0
            ? { justifyContent: 'center' }
            : {}),
          transition: theme.transitions.create('padding'),
          backgroundColor: scopingView
            ? 'transparent'
            : theme.palette.grey[200],
          border: scopingView
            ? `1px dashed ${theme.palette.grey.A400}`
            : 'none',
          [theme.breakpoints.up('md')]: {
            textAlign: 'left',
            flexDirection: 'row',
          },
        }}
      >
        {filteredDocuments?.map(document => (
          <FilePreview
            key={document.attachment_url}
            document={{
              name: document.name,
              url: document.attachment_url,
            }}
            readonly
          />
        ))}

        {!filteredDocuments?.length && <>No file uploaded or approved yet</>}
      </Stack>
    </Stack>
  );
};
