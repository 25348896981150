import { Page, Text, View } from '@react-pdf/renderer';
import CustomHTML from './CustomHTML';
import { Pager } from './Pager';
import { styles } from './styles';

export const PublicDescriptionPage = ({
  description,
}: {
  description?: string | null;
}) => {
  return (
    <Page size='A4' orientation='landscape' style={styles.contentPage}>
      <Pager />
      <View style={{ justifyContent: 'space-between' }}>
        <View fixed>
          <Text style={styles.pageTitle}>Description & Methodology</Text>
        </View>
        <View style={{ lineHeight: 1.6 }}>
          <Text>
            <CustomHTML
              html={(description || '').replace(/<p><br><\/p>/g, '<p></p>')}
              fontSize={12}
            />
          </Text>
        </View>
      </View>
    </Page>
  );
};
