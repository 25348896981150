import { PictureAsPdf as PictureAsPDFIcon } from '@mui/icons-material';
import { CircularProgress, MenuItem, Typography } from '@mui/material';
import { Document, pdf } from '@react-pdf/renderer';
import { captureException } from '@sentry/react';
import { useSnackbar } from 'notistack';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { useCallback, useState } from 'react';
import { downloadFile } from 'utils/downloadFile';
import { StartupPDFPage } from 'components/dashboard/startupList/pdfExport/StartupPDFPage';
import { StartupForPDFExport } from 'components/dashboard/startupList/pdfExport/types';

const ExportStartupProfilePDFMenuItem = ({
  currentStartup,
}: {
  currentStartup: StartupForPDFExport;
}) => {
  const [isGenerating, setGenerating] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const generateStartupProfileBlob = useCallback(async () => {
    return pdf(
      <Document title={currentStartup.name} subject={currentStartup.name}>
        <StartupPDFPage
          currentStartup={currentStartup}
          landscapeCategoriesTitles={null}
          categorizedStartupOrder={null}
        />
      </Document>,
    ).toBlob();
  }, [currentStartup]);

  const handleExportPDF = useCallback(async () => {
    try {
      setGenerating(true);

      const blob = await generateStartupProfileBlob();

      downloadFile({
        content: blob,
        title: `${currentStartup.name} Company Profile.pdf`,
        format: 'pdf',
      });

      captureAnalyticsEvent('Startup profile PDF downloaded', {
        startupId: currentStartup.id,
      });
    } catch (e) {
      captureException(e);
      enqueueSnackbar(
        'Export failed. Please contact support if the problem persists.',
        {
          variant: 'error',
        },
      );
    } finally {
      setGenerating(false);
    }
  }, [
    setGenerating,
    generateStartupProfileBlob,
    currentStartup.name,
    currentStartup.id,
    enqueueSnackbar,
  ]);

  return (
    <MenuItem
      sx={{ display: 'flex', alignItems: 'center', color: 'text.secondary' }}
      onClick={handleExportPDF}
      disabled={isGenerating}
    >
      <PictureAsPDFIcon fontSize='small' sx={{ marginRight: 0.5 }} />
      <Typography
        variant='body2'
        sx={{
          marginLeft: 0.5,
          flexGrow: 1,
        }}
      >
        Export as PDF
      </Typography>
      {isGenerating && (
        <CircularProgress
          size={16}
          sx={{
            marginLeft: 1,
          }}
        />
      )}
    </MenuItem>
  );
};

export { ExportStartupProfilePDFMenuItem };
