import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import { captureException } from '@sentry/react';
import { useArchiveStartupOutreachMutation } from 'apollo/generated/sdkShared';
import { useSnackbar } from 'notistack';
import { captureAnalyticsEvent } from 'plugins/Analytics';

export const ArchiveRequestModal = ({
  onClose,
  connectionId,
  startupName,
}: {
  onClose: () => void;
  connectionId: number | null;
  startupName: string | null;
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [archiveStartupOutreach] = useArchiveStartupOutreachMutation();

  if (!connectionId || !startupName) return <></>;

  const onArchive = async () => {
    try {
      await archiveStartupOutreach({
        variables: {
          startup_connection_id: connectionId,
        },
        update: (cache, { data }) => {
          if (!data) return;

          cache.evict({
            id: cache.identify({
              __typename: 'startup_connections',
              id: connectionId,
            }),
          });
          cache.gc();
        },
      });
      captureAnalyticsEvent('Connection archived.', {
        connectionId: connectionId,
      });

      enqueueSnackbar(`Successfully removed connection to ${startupName}.`, {
        variant: 'success',
      });
    } catch (error) {
      captureException(error);
      enqueueSnackbar(
        `Couldn't remove connection to ${startupName}. Please try again later.`,
        {
          variant: 'error',
        },
      );
    } finally {
      onClose();
    }
  };

  return (
    <Dialog open fullWidth>
      <DialogContent sx={{ marginTop: 2, paddingBottom: 0 }}>
        <Typography sx={{ marginBottom: 0 }}>
          Are you sure you want to archive connection to
          <Box component='span' fontWeight='bold'>
            {` ${startupName} `}
          </Box>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant='text' color='inherit' onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onArchive} variant='contained' color='error'>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
