import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Button,
  Menu,
  MenuItem,
  Skeleton,
  SxProps,
  Typography,
} from '@mui/material';
import {
  Teams,
  useAssignPersonToTeamMutation,
  useAssignProjectToTeamMutation,
  useAssignStartupListToTeamMutation,
} from 'apollo/generated/sdkShared';
import { useSnackbar } from 'notistack';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { MouseEvent, useState } from 'react';
import { pxToRem } from 'theme/typography';
import TeamAvatar from './TeamAvatar';
import { useGetAllTeamsData } from './useGetAllTeamsData';

const AssignTeamButton = ({
  id,
  name,
  type,
  team,
  sxProps = {},
  readonly,
}: {
  id: number;
  name: string;
  team?: Pick<Teams, 'id' | 'name'> | null;
  type?: 'person' | 'startupList' | 'project' | 'stakeholder';
  sxProps?: SxProps;
  readonly?: boolean;
}) => {
  const { avatarUrlMap, loadingTeamsData, teamsData } = useGetAllTeamsData();
  const { enqueueSnackbar } = useSnackbar();
  const [assignPersonFromTeam] = useAssignPersonToTeamMutation();
  const [assignListFromTeam] = useAssignStartupListToTeamMutation();
  const [assignProjectFromTeam] = useAssignProjectToTeamMutation();

  // TODO: Refactor when we scale to more complex use cases
  // Comment from @kam This abstraction could be improved IMO, conditions like type === 'xyz' could be replaced
  // by dep injections aka "onAssigneeChange" that handles the specific logic in each case + the success and error messages.
  let mutation = assignPersonFromTeam;
  let successMessage = 'User assigned to team';
  let errorMessage = 'Failed to assign user';

  if (type === 'startupList') {
    mutation = assignListFromTeam;
    successMessage = 'List assigned to team';
    errorMessage = 'Failed to assign list';
  } else if (type === 'project') {
    mutation = assignProjectFromTeam;
    successMessage = 'Project assigned to team';
    errorMessage = 'Failed to assign project';
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    if (readonly) return;

    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAssignTeam = async (teamId: number | null) => {
    const teamName = teamsData.find(t => t.id === teamId)?.name;

    if (teamName) {
      captureAnalyticsEvent('Assign team to user profile', {
        personId: id,
        teamName,
      });
    }

    try {
      await mutation({ variables: { teamId, id } });

      enqueueSnackbar(successMessage, { variant: 'success' });
      handleClose();
    } catch (e) {
      enqueueSnackbar(errorMessage, { variant: 'error' });
    }
  };

  if (loadingTeamsData) return <Skeleton width='100%' />;

  const noTeamOption = type === 'person' || !type ? 'Cross-functional' : 'None';
  const noTeamLabel = type === 'person' || !type ? 'Cross-functional' : 'None';

  if (readonly) {
    return (
      <Typography data-testid='assign-team-readonly' sx={sxProps}>
        {team ? team.name : noTeamLabel}
      </Typography>
    );
  }

  return (
    <>
      <Button
        variant='text'
        color='inherit'
        id={`assign-team-button-${name}`}
        data-testid='assign-team-button'
        aria-controls={open ? `assign-team-menu-${name}` : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        sx={{
          paddingX: 0,
          fontWeight: 400,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          textAlign: 'left',
          padding: ({ spacing }) => `0 ${spacing(0.5)} !important`,
          '&: hover': {
            backgroundColor: 'grey.50012',
            padding: ({ spacing }) => `0 ${spacing(0.5)} !important`,
            borderRadius: '6px',
          },
          ...sxProps,
        }}
        onClick={handleClick}
        endIcon={
          open ? (
            <ExpandLess
              sx={{ marginLeft: -1, height: ({ spacing }) => spacing(3.5) }}
            />
          ) : (
            <ExpandMore
              sx={{ marginLeft: -1, height: ({ spacing }) => spacing(3.5) }}
            />
          )
        }
      >
        <Typography
          textOverflow='ellipsis'
          whiteSpace='nowrap'
          overflow='hidden'
          width='100%'
          sx={theme => ({
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            lineHeight: '2.099',
            color: team?.name ? 'initial' : 'grey.600',
            '& hover': { backgroundColor: 'grey.50012' },
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: pxToRem(14),
          })}
        >
          {team && avatarUrlMap?.[team.id] && (
            <TeamAvatar
              src={avatarUrlMap[team.id]}
              alt={team.name}
              sx={{ marginRight: 1 }}
            />
          )}
          {team ? team.name : noTeamLabel}
        </Typography>
      </Button>
      <Menu
        id={`assign-team-menu-${name}`}
        data-testid={`assign-team-menu-${name}`}
        aria-labelledby={`assign-team-button-${name}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 40,
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          selected={!team}
          onClick={e => {
            e.stopPropagation();
            handleAssignTeam(null);
          }}
          sx={{
            fontSize: '14px',
            lineHeight: '2.099',
          }}
        >
          <Typography variant='body2'>{noTeamOption}</Typography>
        </MenuItem>
        {teamsData.map(mappedTeam => (
          <MenuItem
            sx={{ fontSize: '14px', lineHeight: '1.99' }}
            selected={mappedTeam.id === (team && team.id)}
            key={mappedTeam.id}
            onClick={e => {
              e.stopPropagation();
              handleAssignTeam(mappedTeam.id);
            }}
          >
            {mappedTeam && avatarUrlMap?.[mappedTeam.id] && (
              <TeamAvatar
                src={avatarUrlMap[mappedTeam.id]}
                alt={mappedTeam.name}
                sx={{ marginRight: 1 }}
              />
            )}
            {mappedTeam.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default AssignTeamButton;
