import { useApolloClient } from '@apollo/client';
import { captureMessage } from '@sentry/react';
import { useGetEnrichStartupAsyncStatusQuery } from 'apollo/generated/sdkInnovationManager';
import { GetDataForSidePanelDocument } from 'apollo/generated/sdkShared';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

type StartupEnrichmentProgressItem = {
  progressUUID: string;
  domain: string;
};

type StartupEnrichmentProgressParams = {
  enrichmentProgressPool: StartupEnrichmentProgressItem[];
  addProgressUUIDToPool: (_params: StartupEnrichmentProgressItem) => void;
  dropProgressUUIDFromPool: (uuid: string) => void;
};

export const StartupEnrichmentProgressContext =
  createContext<StartupEnrichmentProgressParams | null>(null);

const StartupEnrichmentProgressProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [enrichmentProgressPool, setEnrichmentProgressPool] = useState<
    StartupEnrichmentProgressItem[]
  >([]);

  const addProgressUUIDToPool = useCallback(
    ({ progressUUID, domain }: StartupEnrichmentProgressItem) => {
      setEnrichmentProgressPool(prevPool => [
        ...prevPool,
        {
          progressUUID,
          domain,
        },
      ]);
    },
    [],
  );

  const dropProgressUUIDFromPool = useCallback((uuid: string) => {
    setEnrichmentProgressPool(prevPool =>
      prevPool.filter(({ progressUUID }) => progressUUID !== uuid),
    );
  }, []);
  console.log('[DEBUG] enrichmentProgressPool', enrichmentProgressPool);

  return (
    <StartupEnrichmentProgressContext.Provider
      value={{
        enrichmentProgressPool,
        addProgressUUIDToPool,
        dropProgressUUIDFromPool,
      }}
    >
      {enrichmentProgressPool.map(item => (
        <SingleStartupEnrichmentProgressTracker
          key={item.progressUUID}
          progressUUID={item.progressUUID}
        />
      ))}
      {children}
    </StartupEnrichmentProgressContext.Provider>
  );
};

const POLL_INTERVAL = 1000;
const POLL_TIMEOUT = 15000;

const SingleStartupEnrichmentProgressTracker = ({
  progressUUID,
}: {
  progressUUID: string;
}) => {
  const { data } = useGetEnrichStartupAsyncStatusQuery({
    variables: {
      uuid: progressUUID,
    },
    pollInterval: POLL_INTERVAL,
  });

  const apolloClient = useApolloClient();
  const { dropProgressUUIDFromPool } = useEnrichmentProgressPool();

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (progressUUID) {
        captureMessage('Enrichment progress timeout', {
          extra: {
            data: data?.enrich_startup,
          },
        });
        dropProgressUUIDFromPool(progressUUID);
      }
    }, POLL_TIMEOUT);

    return () => {
      clearTimeout(timeout);
    };
  }, [progressUUID, dropProgressUUIDFromPool, data?.enrich_startup]);

  useEffect(() => {
    if (!data?.enrich_startup?.output) {
      return;
    }

    if (data?.enrich_startup?.output?.success) {
      console.log('enrichment success', data.enrich_startup);
      apolloClient.refetchQueries({
        include: [GetDataForSidePanelDocument],
      });
      dropProgressUUIDFromPool(progressUUID);
    }
    if (data?.enrich_startup?.errors) {
      captureMessage(
        'GraphQL is having issues with the action log. Ignore this error if enrichment worked.',
        {
          extra: {
            data: data?.enrich_startup,
          },
        },
      );
      dropProgressUUIDFromPool(progressUUID);
    } else if (
      data?.enrich_startup?.output?.errors ||
      !data?.enrich_startup?.output?.success
    ) {
      captureMessage('Enrichment failed', {
        extra: {
          data: data?.enrich_startup,
        },
      });
      dropProgressUUIDFromPool(progressUUID);
    }

    return () => {
      dropProgressUUIDFromPool(progressUUID);
    };
  }, [data, dropProgressUUIDFromPool, progressUUID, apolloClient]);

  return null;
};

const useEnrichmentProgressPool = () => {
  const context = useContext(StartupEnrichmentProgressContext);
  if (!context) {
    throw new Error(
      'useEnrichmentProgressPool must be used within a StartupEnrichmentProgressProvider',
    );
  }

  return {
    addProgressUUIDToPool: context.addProgressUUIDToPool,
    dropProgressUUIDFromPool: context.dropProgressUUIDFromPool,
    progressPool: context.enrichmentProgressPool,
  };
};

export { StartupEnrichmentProgressProvider, useEnrichmentProgressPool };
