import {
  Card,
  Divider,
  Grid,
  Stack,
  Typography,
  useTheme,
  Box,
  IconButton,
} from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import LinkStartupListToChallengeModal from './LinkStartupListToChallengeModal';
import { useGetCategoriesForListCardQuery } from 'apollo/generated/sdkShared';
import { useSnackbar } from 'notistack';
import { parseRelatedStartups } from 'components/PoCs/hooks/useGetRelatedStartups';
import { StartupsInARow } from 'components/PoCs/hooks/useGetCardFields';
import { ChallengeConnectedStartupListType } from '../../types';
import { useNavigate } from 'react-router';
import { PATH_ROOT } from 'routes/paths';
import { LinkConnectionCard as LinkListCard } from '../LinkConnectionCard';
import { LinkOff as UnlinkIcon } from '@mui/icons-material';
import { UnlinkChallengeConnectionModal } from '../UnlinkChallengeConnectionModal';
import { DEFAULT_LINK_TARGET_GROUP_SLUG } from 'components/OrganizationPortal/shared/usePortalAnalytics';
import { NAV_TEXT_GREY } from 'components/NavSection';

const ListCard = ({
  connectedStartupList,
  setSelectedList,
  isReadonly,
}: {
  connectedStartupList: ChallengeConnectedStartupListType;
  setSelectedList: Dispatch<
    SetStateAction<ChallengeConnectedStartupListType | null>
  >;
  isReadonly: boolean;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const [hover, setHover] = useState(false);

  const { data, loading } = useGetCategoriesForListCardQuery({
    variables: { startupListId: connectedStartupList.id },
  });

  const { relatedOrganizationStartups: startupsInList } = {
    ...parseRelatedStartups({
      data,
      enqueueSnackbar,
    }),
  };

  const handleCardClick = (event: React.MouseEvent<HTMLElement>) => {
    const listURL = !isReadonly
      ? PATH_ROOT.lists.details(connectedStartupList.id)
      : PATH_ROOT.lists.shared(
          connectedStartupList.public_uuid,
          DEFAULT_LINK_TARGET_GROUP_SLUG,
        );

    if (event.ctrlKey || event.metaKey) {
      window.open(listURL, '_blank');
    } else {
      navigate(listURL, {
        state: {
          backToPage: `${window.location.pathname}`,
        },
      });
    }
  };

  const handleUnlinkClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setSelectedList(connectedStartupList);
  };

  return (
    <Box
      onClick={event => handleCardClick(event)}
      paddingBottom={2}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Card
        sx={{
          display: 'flex',
          height: 170,
          width: 262,
          border: '1px solid rgba(0, 0, 0, 0.1)',
          borderRadius: '6px',
          boxShadow: '0px 1px 6px 0px rgba(0, 0, 0, 0.08)',
          ':hover': {
            cursor: 'pointer',
          },
        }}
      >
        {!isReadonly && hover && (
          <IconButton
            sx={{
              position: 'absolute',
              top: theme.spacing(1),
              right: theme.spacing(1),
              color: NAV_TEXT_GREY,
            }}
            onClick={handleUnlinkClick}
          >
            <UnlinkIcon fontSize='small' />
          </IconButton>
        )}
        <Stack alignItems='stretch' sx={{ marginY: 2, marginX: 2 }}>
          <Typography variant='subtitle2'>
            {connectedStartupList.title}
          </Typography>
          <Box>
            <StartupsInARow
              loading={loading}
              startups={startupsInList}
              cardTitle='Startups'
            />
          </Box>
        </Stack>
      </Card>
    </Box>
  );
};

export const ChallengeSubListsSection = ({
  challengeId,
  connectedStartupLists,
  isReadonly,
}: {
  challengeId: number;
  connectedStartupLists: ChallengeConnectedStartupListType[];
  isReadonly?: boolean;
}) => {
  const [showLinkListModal, setShowLinkListModal] = useState(false);
  const [selectedListToUnlink, setSelectedListToUnlink] =
    useState<ChallengeConnectedStartupListType | null>(null);

  return (
    <>
      <Stack marginTop={2}>
        <Typography variant='body1' fontSize={20}>
          Lists
        </Typography>
        <Divider />

        <Grid container spacing={2} marginY={2}>
          {connectedStartupLists?.map(connectedList => (
            <Grid item key={connectedList.id}>
              <ListCard
                connectedStartupList={connectedList}
                setSelectedList={setSelectedListToUnlink}
                isReadonly={isReadonly || false}
              />
            </Grid>
          ))}
          {!isReadonly && (
            <Grid item>
              <LinkListCard
                setShowLinkModal={setShowLinkListModal}
                sx={
                  connectedStartupLists.length !== 0 &&
                  connectedStartupLists.length % 3 === 0
                    ? {
                        height: '44px',
                        marginBottom: 1.5,
                      }
                    : {}
                }
              />
            </Grid>
          )}
        </Grid>
      </Stack>
      <LinkStartupListToChallengeModal
        open={showLinkListModal}
        onHide={() => setShowLinkListModal(false)}
        challengeId={challengeId}
        connectedStartupLists={connectedStartupLists}
      />
      <UnlinkChallengeConnectionModal
        open={Boolean(selectedListToUnlink)}
        onHide={() => setSelectedListToUnlink(null)}
        challengeId={challengeId}
        startupListId={selectedListToUnlink?.id}
        type='startup_list'
        title={selectedListToUnlink?.title}
      />
    </>
  );
};
