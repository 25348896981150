import React, { useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import {
  useFindSimilarStartupsQuery,
  useAsyncFindSimilarStartupsMutation,
} from 'apollo/generated/sdkShared';
import { StartupForSidePanel } from './StartupInfoSidePanel';
import useLocalStorage from 'hooks/useLocalStorage';
import { Suppliers } from 'apollo/generated/sdkInnovationManager';
import { BaseStartupAvatar } from 'components/base/BaseStartupAvatar';
import CompanyDetails from 'components/shared/table/CompanyDetails';
import { varFadeIn } from 'components/animate';
import { motion } from 'framer-motion';
import { isOrganizationPortalURL } from 'utils/url';
import useAuth from 'hooks/useAuth';
import useSearchSimilarActions from 'hooks/useSearchSimilarActions';
import { captureException } from '@sentry/react';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { useNavigate } from 'react-router';
interface SimilarStartupsProps {
  startup: StartupForSidePanel;
}

interface SimilarStartupsListProps {
  currentStartup: StartupForSidePanel;
  asyncId: string;
  setAsyncIdSessionStorage: (id: string | undefined) => void;
}

type SimilarStartup = Pick<
  Suppliers,
  | 'id'
  | 'domain'
  | 'name'
  | 'website'
  | 'logo_url'
  | 'short_description'
  | 'hq'
  | 'founded_year'
  | 'employees_count'
  | 'funding'
>;

const SimilarStartups: React.FC<SimilarStartupsProps> = ({ startup }) => {
  return (
    <Stack>
      <Typography color='text.secondary'>Similar startups</Typography>

      <Grid container spacing={2} sx={{ marginTop: 0.2 }}>
        <>
          {startup.similar_startups.length > 0 ? (
            <SimilarStartupsFromDB startup={startup} />
          ) : (
            <RequestAsyncSimilarStartups startup={startup} />
          )}
          <ShowMoreButton currentStartup={startup} />
        </>
      </Grid>
    </Stack>
  );
};

const ShowMoreButton: React.FC<{
  currentStartup: StartupForSidePanel;
}> = ({ currentStartup }) => {
  const isValidOrganizationPortalURL = isOrganizationPortalURL();
  const { user } = useAuth();
  const { onSearchSimilarClickFromStartupProfile } = useSearchSimilarActions();

  const isOrganizationPortal =
    isValidOrganizationPortalURL || user.type === 'bu_member';

  return (
    <Grid item xs={12}>
      {isOrganizationPortal ? (
        <Button
          variant='text'
          sx={{
            width: '100%',
          }}
          onClick={() =>
            onSearchSimilarClickFromStartupProfile(
              currentStartup.domain,
              currentStartup.id,
            )
          }
        >
          Search similar
        </Button>
      ) : (
        <Tooltip title='Search similar'>
          <Button
            color='primary'
            sx={{
              width: '100%',
            }}
            onClick={() =>
              onSearchSimilarClickFromStartupProfile(
                currentStartup.domain,
                currentStartup.id,
              )
            }
          >
            <Typography sx={{ fontWeight: 600 }}>Search similar</Typography>
          </Button>
        </Tooltip>
      )}
    </Grid>
  );
};

const SimilarStartup: React.FC<{
  startup: SimilarStartup;
  currentStartup: StartupForSidePanel;
}> = ({ startup, currentStartup }) => {
  const { palette, spacing, shadows, typography } = useTheme();
  const navigate = useNavigate();

  return (
    <Grid item xs={12} sm={12} md={6} lg={6} key={startup?.id}>
      <Card
        onClick={() => {
          navigate(`${location.pathname}?startup_id=${startup!.id}`);
          captureAnalyticsEvent('Similar startup clicked', {
            startupId: currentStartup!.id,
            similarStartupId: startup!.id,
          });
        }}
        component={motion.div}
        {...varFadeIn}
        sx={({ palette, customShadows }) => ({
          borderRadius: 1,
          boxShadow: customShadows.z4,
          backgroundColor: palette.background.paper,
          cursor: 'pointer',
          '&:hover': {
            boxShadow: customShadows.z16,
            backgroundColor: palette.grey[50],
          },
        })}
      >
        <CardContent
          sx={{
            padding: 2,
          }}
        >
          <Stack gap={0.5}>
            <Stack direction='row' gap={2} alignItems='center'>
              <BaseStartupAvatar
                startup={{
                  logo_url: startup?.logo_url,
                  name: startup!.name,
                  domain: startup!.domain,
                }}
                size='small'
              />
              <Stack width='100%'>
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                  gap={0.5}
                >
                  <Stack direction='row' gap={0.5} alignItems='center'>
                    <Tooltip title={startup?.name} placement='top-end'>
                      <Typography
                        variant='body2'
                        sx={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          lineHeight: 'unset',
                          color: palette.secondary.main,
                          cursor: 'pointer',
                          '&:hover': {
                            color: palette.secondary.dark,
                          },
                          maxWidth: spacing(25),
                        }}
                      >
                        {startup?.name}
                      </Typography>
                    </Tooltip>
                  </Stack>
                </Stack>
                <CompanyDetails
                  company={{
                    website: startup?.website,
                    employees_count: startup?.employees_count,
                    founded_year: startup?.founded_year,
                    funding: startup?.funding,
                    hq: startup?.hq,
                    name: startup?.name,
                  }}
                  showWebsiteLink
                />
              </Stack>
            </Stack>
            <Box>
              <Tooltip
                title={startup?.short_description}
                placement='bottom-start'
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: 'common.white',
                      color: 'common.black',
                      boxShadow: shadows[5],
                      fontSize: typography.pxToRem(14),
                      fontWeight: '400',
                    },
                  },
                }}
              >
                <Typography
                  variant='body2'
                  color='text.secondary'
                  sx={{
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2,
                    textOverflow: 'ellipsis',
                    maxHeight: '2.55rem', // Approximately 2 lines
                    color: palette.grey.A700,
                  }}
                >
                  {startup?.short_description}
                </Typography>
              </Tooltip>
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  );
};

const SimilarStartupsFromDB: React.FC<{
  startup: StartupForSidePanel;
}> = ({ startup }) => {
  return (
    <>
      {startup.similar_startups.map(similarStartup => (
        <SimilarStartup
          key={similarStartup.similar_startup.id}
          startup={similarStartup.similar_startup}
          currentStartup={startup}
        />
      ))}
    </>
  );
};

const RequestAsyncSimilarStartups: React.FC<SimilarStartupsProps> = ({
  startup,
}) => {
  const [asyncFindSimilarStartups] = useAsyncFindSimilarStartupsMutation();
  const [asyncIdSessionStorage, setAsyncIdSessionStorage] = useLocalStorage<
    string | undefined
  >(
    `async-similar-startups-${startup.domain}`,
    undefined,
    undefined,
    undefined,
    true,
  );

  const handleAsyncFindSimilarStartups = useCallback(async () => {
    //? Callback probably useful in this case, discuss with Fipo or Alex
    const { data: asyncData } = await asyncFindSimilarStartups({
      variables: { startupDomain: startup.domain },
    });
    setAsyncIdSessionStorage(asyncData?.find_similar_startups);
  }, [asyncFindSimilarStartups, startup.domain, setAsyncIdSessionStorage]);

  useEffect(() => {
    if (asyncIdSessionStorage) return;

    handleAsyncFindSimilarStartups();
  }, [handleAsyncFindSimilarStartups, asyncIdSessionStorage]);

  return (
    <>
      {asyncIdSessionStorage && (
        <PollSimilarStartupsList
          asyncId={asyncIdSessionStorage}
          setAsyncIdSessionStorage={setAsyncIdSessionStorage}
          currentStartup={startup}
        />
      )}
    </>
  );
};

const PollSimilarStartupsList: React.FC<SimilarStartupsListProps> = ({
  asyncId,
  currentStartup,
}) => {
  const { data: similarStartupsData, stopPolling } =
    useFindSimilarStartupsQuery({
      variables: { id: asyncId },
      pollInterval: 2000,
    });

  useEffect(() => {
    const timeout = setTimeout(() => {
      return <ErrorState />;
    }, 60000);

    return () => {
      stopPolling();

      clearTimeout(timeout);
    };
  }, [stopPolling]);

  if (similarStartupsData?.find_similar_startups?.errors) {
    stopPolling();
    captureException(similarStartupsData.find_similar_startups.errors);
    return <ErrorState />;
  }

  if (!similarStartupsData?.find_similar_startups?.output?.length) {
    return <LoadingState />;
  }

  stopPolling();

  return (
    <>
      {similarStartupsData?.find_similar_startups?.output?.map(
        (startup, index) => (
          <SimilarStartup
            key={index}
            startup={startup}
            currentStartup={currentStartup}
          />
        ),
      )}
    </>
  );
};

const LoadingState: React.FC = () => {
  return (
    <>
      <Grid item xs={6}>
        <Skeleton variant='rectangular' height={100} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant='rectangular' height={100} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant='rectangular' height={100} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton variant='rectangular' height={100} />
      </Grid>
    </>
  );
};

const ErrorState: React.FC = () => {
  return (
    <Grid item xs={12}>
      <Typography>
        We couldn&apos;t find any similar startups at this time. Please, try
        again later.
      </Typography>
    </Grid>
  );
};

export default SimilarStartups;
