import { Stack, SxProps, Typography, useTheme } from '@mui/material';
import { formatDistance } from 'date-fns';

export function DistanceFromDate({
  date,
  label = 'last edit',
  sxProps = {},
}: {
  date: string;
  label?: string;
  sxProps?: SxProps;
}) {
  const { palette } = useTheme();
  const tenSecondsAgo = Date.now() - 1000 * 10;
  const lessThanAMinuteAgo = Date.now() - 1000 * 30;
  const justNow = new Date(date).getTime() > tenSecondsAgo;
  const momentsAgo = new Date(date).getTime() > lessThanAMinuteAgo;

  const showDateDistance = justNow
    ? 'just now'
    : momentsAgo
      ? 'moments ago'
      : formatDistance(new Date(date), new Date(), {
          addSuffix: true,
        });

  return (
    <Stack
      direction='row'
      gap={1}
      marginLeft='auto'
      alignItems='center'
      sx={{ ...sxProps, whiteSpace: 'nowrap' }}
    >
      <Typography
        variant='caption'
        color={palette.grey[600]}
        sx={{ wordWrap: 'nowrap' }}
      >
        {label}:
      </Typography>
      <Typography
        sx={{ whiteSpace: 'nowrap' }}
        color={palette.primary.main}
        variant='caption'
        marginLeft={-0.5}
        fontWeight={({ typography }) => typography.fontWeightBold}
      >
        {showDateDistance}
      </Typography>
    </Stack>
  );
}
