import {
  ButtonBase,
  Fade,
  FormControlLabel,
  Switch,
  Typography,
} from '@mui/material';
import {
  useGetStartupListSharingSettingsQuery,
  useUpdateStartupListSharingSettingsMutation,
} from 'apollo/generated/sdkShared';

export const LonglistSettings = ({
  currentStartupListId,
}: {
  currentStartupListId: number | null;
}) => {
  const { data, loading, called } = useGetStartupListSharingSettingsQuery({
    variables: { id: currentStartupListId! },
    skip: !currentStartupListId,
  });
  const [update] = useUpdateStartupListSharingSettingsMutation();

  const checked = !!data?.startup_lists_by_pk?.is_longlist_displayed;

  return (
    <Fade in mountOnEnter unmountOnExit>
      <ButtonBase sx={{ paddingLeft: 2, marginLeft: 'auto' }}>
        <FormControlLabel
          control={<Switch />}
          disabled={loading || !called}
          id='is_longlist_displayed'
          name='is_longlist_displayed'
          onClick={e => e.stopPropagation()}
          onChange={(e, checked) => {
            e.stopPropagation();
            if (!currentStartupListId) return;

            update({
              variables: {
                id: currentStartupListId,
                isLonglistDisplayed: checked,
              },
              optimisticResponse: {
                update_startup_lists_by_pk: {
                  id: currentStartupListId,
                  is_longlist_displayed: checked,
                },
              },
            });
          }}
          checked={checked}
          label={<Typography variant='body1'>Show on shared links</Typography>}
        />
      </ButtonBase>
    </Fade>
  );
};
