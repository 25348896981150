import { ReactNode, useEffect } from 'react';
import posthog from 'posthog-js';
import useAuth from 'hooks/useAuth';
import { useLocation } from 'react-router';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import { makeVar } from '@apollo/client';
import { captureAnalyticsEvent } from './Analytics';
import { PostHogProvider } from 'posthog-js/react';

type AnalyticsUser = {
  email: string;
  type: 'user' | 'visitor';
};

const PosthogAnalytics = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const { isAuthenticated, user: authUser } = useAuth();
  const currentOrganization = useCurrentOrganizationFromContext();

  const user = {
    ...authUser,
    type:
      authUser.type === 'bu_member' ? ('visitor' as const) : ('user' as const),
  };

  // TODO: Init posthog on before rendering everything else
  useEffect(() => {
    initPosthog(isAuthenticated, user, {
      id: currentOrganization.id,
      subdomain: currentOrganization.subdomain,
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isAuthenticated)
      identify(user, {
        subdomain: currentOrganization.subdomain,
      });
    // eslint-disable-next-line
  }, [isAuthenticated]);

  useEffect(() => {
    if (currentOrganization.subdomain) {
      identifyGroup(currentOrganization);
    }
    // eslint-disable-next-line
  }, [currentOrganization.subdomain]);

  useEffect(() => {
    posthog.capture('$pageview');
  }, [location]);

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
};

export const identify = (
  user: AnalyticsUser,
  organization: { subdomain: string },
) => {
  if (!import.meta.env.VITE_POSTHOG_API_KEY) return;

  posthog.identify(user.email, {
    ...user,
    organizationSubdomain: organization.subdomain,
  });
};

const blacklistedRecordingOrigins = [
  'regression-testing',
  'discovery',
  'glassies',
  'demo',
];

const isRecordingBlacklisted = blacklistedRecordingOrigins.some(origin =>
  window.location.origin.includes(origin),
);

const isProduction = import.meta.env.PROD;

export const initPosthog = (
  isAuthenticated: boolean,
  user: AnalyticsUser,
  organization: { subdomain: string; id: number },
) => {
  if (!import.meta.env.VITE_POSTHOG_API_KEY) return;

  posthog.init(import.meta.env.VITE_POSTHOG_API_KEY, {
    api_host: 'https://eu.posthog.com',
    debug: !isProduction,
    disable_session_recording:
      !isProduction ||
      isRecordingBlacklisted ||
      user?.email?.includes('glassdollar.com'),
    autocapture: isProduction,
    capture_pageview: false,
    session_recording: {
      maskAllInputs: false,
    },
    bootstrap: {
      featureFlags: {
        activitiesTab: false,
      },
    },
    // eslint-disable-next-line
    loaded(_posthog_instance) {
      if (isAuthenticated)
        identify(user, {
          subdomain: organization.subdomain,
        });
    },
  });
};

export const identifyGroup = (currentOrganization: {
  subdomain: string;
  id: number;
  account_type: string;
}) => {
  if (!import.meta.env.VITE_POSTHOG_API_KEY) return;

  posthog.group('organization', currentOrganization.subdomain, {
    id: currentOrganization.id,
    subdomain: currentOrganization.subdomain,
    accountType: currentOrganization.account_type,
  });
};

export const reset = () => {
  if (!import.meta.env.VITE_POSTHOG_API_KEY) return;

  posthog.reset();
};

export const isFeatureEnabled = (feature: FeatureFlag) => {
  if (!import.meta.env.VITE_POSTHOG_API_KEY) return true;

  return posthog.isFeatureEnabled(feature);
};

/**
 * REMINDER (Fix it later):
 * If a user open a page using the URL that needs a feature flag. When the page render for the
 * first time it will have the default value of the flag, and it will be updated once posthog loads.
 * This can cause some race conditions where the UI thinks the flag has a different state
 */

// Example: Here you can add the feature flag. The name should be the same as in posthog
// e.g type FeatureFlag = 'feature1' | 'feature2' | 'feature3'
type FeatureFlag = never;

export type FeatureFlagsType = Record<FeatureFlag, boolean>;

/**
 * All the feature flags should be enable by default for development
 */
export const FeatureFlagsVar = makeVar<FeatureFlagsType>({
  // Example: How to define the initial start of the feature flag
  // As a rule of thump it is active by default in development and disable everywhere else
  // feature1: import.meta.env.DEV,
});

posthog.onFeatureFlags(function () {
  // feature flags are guaranteed to be available at this point
  // Example: Set the feature flags after posthog load
  // FeatureFlagsVar({
  //   feature1: isFeatureEnabled('feature1'),
  // });
});

export const useTrackPage = ({ title }: { title: string }) => {
  useEffect(() => {
    captureAnalyticsEvent('Page Visited', {
      page: title,
    });
  }, [title]);
};

export default PosthogAnalytics;
