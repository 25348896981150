import { useState, useEffect } from 'react';

const useLocalStorage = <T>(
  key: string,
  defaultValue: T,
  legacyKey?: string,
  overrideValue?: T,
  shouldUseSessionStorage?: boolean,
) => {
  const accessKey: 'localStorage' | 'sessionStorage' = shouldUseSessionStorage
    ? 'sessionStorage'
    : 'localStorage';
  const [localState, setLocalState] = useState<T>(() => {
    const item =
      window[accessKey].getItem(key) ||
      (legacyKey && window[accessKey].getItem(legacyKey));

    if (overrideValue) return overrideValue;

    return item ? JSON.parse(item) : defaultValue;
  });

  useEffect(() => {
    window[accessKey].setItem(key, JSON.stringify(localState));
    // Missing dependencies is a desired effect here:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localState]);

  return [localState, setLocalState] as const;
};

export default useLocalStorage;
