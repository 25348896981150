import { Suspense } from 'react';
import LoadingScreen from '../components/LoadingScreen';

// TODO: Try to infer Component type's props instead of passing it as a generic
const Loadable =
  <T,>(Component: unknown) =>
  // eslint-disable-next-line
  (props: T) => {
    const isDashboard = window.location.pathname.includes('/dashboard');

    return (
      <Suspense
        fallback={
          <LoadingScreen
            sx={{
              ...(!isDashboard && {
                top: 0,
                left: 0,
                width: 1,
                zIndex: 9999,
                position: 'fixed',
              }),
            }}
          />
        }
      >
        {/* @ts-expect-error: TODO: FIXME */}
        <Component {...props} />
      </Suspense>
    );
  };

export { Loadable };
