import { captureMessage } from '@sentry/react';
import { useSendMailMutation } from 'apollo/generated/sdkInnovationManager';

import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import { buildListDetailsUrl } from 'utils/url';
import { StartupListForMatrix } from '../../shared/StartupsTableContext';

const useNotifyInnovationManagerForVote = () => {
  const currentOrganization = useCurrentOrganizationFromContext();
  const [sendMail] = useSendMailMutation();

  const sendEmailNotification = async ({
    startupList,
    stakeholderFullName,
  }: {
    startupList: StartupListForMatrix;
    stakeholderFullName: string;
  }) => {
    const listOwner = startupList.sourcing_order?.created_by?.person;

    if (!listOwner) {
      captureMessage(
        `No email was sent because nobody was assigned to list ${startupList.id}`,
      );
    } else {
      await sendMail({
        variables: {
          emailPayload: {
            // Edit here:
            // https://mc.sendgrid.com/dynamic-templates/d-9fb07424f84c4a0fb6c3b682e2556093/edit
            templateId: 'd-9fb07424f84c4a0fb6c3b682e2556093',
            from: {
              name: currentOrganization.from_name,
              email: currentOrganization.from_email,
            },
            to: [listOwner.email],
            reply_to: currentOrganization.reply_to_email,
            templateData: {
              innovation_manager_name: listOwner.full_name,
              stakeholder_full_name: stakeholderFullName,
              startup_list_title: startupList.title,
              resource_url: buildListDetailsUrl(startupList.id),
            },
          },
        },
      });
    }
  };

  return [sendEmailNotification];
};

export default useNotifyInnovationManagerForVote;
