import { Button } from '@mui/material';
import { useUpdateStartupListStartupChartsVisibilityMutation } from 'apollo/generated/sdkShared';
import { useMemo } from 'react';
import { useUpdateStartupListActivities } from '../useUpdateStartupListActivities';

type CategorizedStartupForChartToggle = {
  id: number;
  startup: {
    id: number;
  };
};

export const ShowHideChartButton = ({
  categorizedStartups,
  currentStartupList,
}: {
  categorizedStartups: CategorizedStartupForChartToggle[];
  currentStartupList: {
    id: number;
    is_startup_charts_data_shown?: boolean | null;
  };
}) => {
  const { logStartupListActivity } = useUpdateStartupListActivities();
  const [toggleShowStartupCharts] =
    useUpdateStartupListStartupChartsVisibilityMutation({
      variables: {
        payload: {
          is_startup_charts_data_shown:
            !currentStartupList.is_startup_charts_data_shown,
        },
        id: currentStartupList.id,
      },
    });

  const handleShowClick = async () => {
    toggleShowStartupCharts();
    await logStartupListActivity({
      logs: [
        {
          entityIds: currentStartupList.id,
          entityType: 'startup_lists',
          action: 'updated',
        },
      ],
      startupListId: currentStartupList.id,
    });
  };

  const shouldShowChartToggleButton = useMemo(
    () => [...new Set(categorizedStartups.map(s => s.startup.id))].length >= 5,
    [categorizedStartups],
  );

  return (
    <>
      {shouldShowChartToggleButton && (
        <Button
          sx={{
            marginLeft: 'auto !important',
          }}
          variant='text'
          onClick={handleShowClick}
        >
          {currentStartupList.is_startup_charts_data_shown ? 'Hide' : 'Show'}{' '}
          data
        </Button>
      )}
    </>
  );
};
