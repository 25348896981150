import { Box, Theme, Tooltip, useTheme } from '@mui/material';
import { EnumTableProjectStagesEnum } from 'apollo/generated/sdkInnovationManager';

import { BaseStatusSelect } from 'components/base/BaseStatusSelect';
import { useGetRelatedStartups } from 'components/PoCs/hooks/useGetRelatedStartups';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import { ReactNode, useMemo } from 'react';
import {
  canMoveToStage,
  getExtendedStageMapping,
  VISIBLE_PROJECT_STAGES,
  VisibleProjectStages,
} from 'utils/projectStageEnum';
import { ProjectForDetail } from '../../../@types/startupList';
import { useChangeProjectStage } from './hooks/useChangeProjectStage';

const useGetOptionLabelsMap = ({
  projectId,
  currentStage,
}: {
  projectId: number;
  currentStage: EnumTableProjectStagesEnum;
}) => {
  const { subdomain: organizationSubdomain } =
    useCurrentOrganizationFromContext();
  const stageMapping = useMemo(
    () => getExtendedStageMapping({ organizationSubdomain }),
    [organizationSubdomain],
  );
  const getRelatedStartups = useGetRelatedStartups(
    projectId,
    'cache-and-network',
  );

  if (!getRelatedStartups) {
    return stageMapping;
  }

  const { loading, selectedStartupForPoC } = getRelatedStartups;

  if (loading) {
    return stageMapping;
  }

  return Object.entries(stageMapping).reduce(
    (acc, [stage, label]) => {
      const [canMove, message] = canMoveToStage(
        currentStage,
        stage as EnumTableProjectStagesEnum,
        selectedStartupForPoC,
      );

      if (canMove) {
        acc[stage as EnumTableProjectStagesEnum] = label;
      } else {
        acc[stage as EnumTableProjectStagesEnum] = (
          <Tooltip title={message}>
            <Box
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
              }}
              sx={{ width: '100%', cursor: 'not-allowed' }}
              component='span'
            >
              {label}
            </Box>
          </Tooltip>
        );
      }

      return acc;
    },
    {} as Record<EnumTableProjectStagesEnum, ReactNode>,
  );
};

export const ProjectStageSelect = ({
  project,
  readonly,
}: {
  readonly?: boolean;
  project: {
    id: number;
    stage: EnumTableProjectStagesEnum;
    project_stages: ProjectForDetail['project_stages'];
  };
}) => {
  const theme = useTheme();
  const labelStyleMap = useMemo(() => getLabelStyleMap(theme), [theme]);
  const label = project.stage as VisibleProjectStages;
  const labelStyle = labelStyleMap[label] || {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  };

  const { handleChangeStage } = useChangeProjectStage();

  const optionLabelsMap = useGetOptionLabelsMap({
    currentStage: project.stage,
    projectId: project.id,
  });

  return (
    <BaseStatusSelect
      value={project.stage}
      options={VISIBLE_PROJECT_STAGES}
      optionLabelsMap={optionLabelsMap}
      labelStyle={labelStyle}
      readonly={readonly}
      handleChange={toStage => {
        if (readonly) {
          return new Promise(() => {});
        }

        return handleChangeStage({
          projectId: project.id,
          fromStage: project.stage,
          toStage,
        });
      }}
    />
  );
};

export const getLabelStyleMap = (
  theme: Theme,
): Record<
  VisibleProjectStages,
  { backgroundColor: string; color: string }
> => ({
  ['assessment' as const]: {
    backgroundColor: theme.palette.primary.luminous,
    color: 'black',
  },
  ['purchasing' as const]: {
    backgroundColor: theme.palette.primary.lighter,
    color: 'black',
  },
  ['testing' as const]: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  ['test_fail' as const]: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
  },
  ['test_success' as const]: {
    backgroundColor: theme.palette.primary.dark,
    color: 'white',
  },
  ['adoption' as const]: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  ['adopted' as const]: {
    backgroundColor: theme.palette.primary.darker,
    color: 'white',
  },
  ['not_adopted' as const]: {
    backgroundColor: theme.palette.error.lighter,
    color: 'black',
  },
  ['scaling' as const]: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  ['scaling_completed' as const]: {
    backgroundColor: theme.palette.primary.darker,
    color: 'white',
  },
  ['discarded' as const]: {
    backgroundColor: theme.palette.error.light,
    color: 'white',
  },
  ['on_hold' as const]: {
    backgroundColor: theme.palette.grey['50016'],
    color: 'black',
  },
});
