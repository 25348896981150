import { ReactElement, useState } from 'react';

// components
import {
  Box,
  Button,
  Divider,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import Page from 'components/Page';
import PageHeader from 'components/PageHeader';
// helpers
import { useGetNewStartupPitchesMinimalQuery } from 'apollo/generated/sdkShared';
import BaseTabCountBadge from 'components/base/BaseTabCountBadge';
import AddStartupDialog from 'components/library/AddStartupDialog';
import { useNavigate } from 'react-router-dom';
import { PATH_ROOT } from 'routes/paths';
import { ActionButtons } from './ActionButtons';

type StartupsTabType = 'all' | 'inbox' | 'requests';

const TAB_TO_PATH = {
  all: PATH_ROOT.startups.all,
  inbox: PATH_ROOT.startups.inbox,
  requests: PATH_ROOT.startups.requests,
};

export default function StartupsLayout({
  currentTab,
  children,
}: {
  currentTab: StartupsTabType;
  children: ReactElement;
}) {
  const [addStartupInProgress, setAddStartupInProgress] = useState(false);
  const navigate = useNavigate();
  const { data: newStartupPitchesData } = useGetNewStartupPitchesMinimalQuery();

  const newStartupPitchesCount = newStartupPitchesData
    ? newStartupPitchesData.organization_startup_pitches.length
    : null;

  return (
    <Page
      title='Startups | GlassDollar'
      trackingTitle='Startups'
      sx={{
        position: 'relative',
        height: '100%',
        paddingY: 0,
        marginBottom: 10,
      }}
    >
      <PageHeader
        heading='Startup CRM'
        sx={{ marginBottom: 2 }}
        rightAction={
          <Stack direction='row' spacing={2}>
            <ActionButtons />
            <Button
              variant='contained'
              onClick={() => setAddStartupInProgress(true)}
            >
              + Add new startup
            </Button>
          </Stack>
        }
      />
      {addStartupInProgress && (
        <AddStartupDialog
          open={addStartupInProgress}
          handleClose={() => setAddStartupInProgress(false)}
        />
      )}
      <Stack justifyContent='space-between' sx={{ marginBottom: 3 }}>
        <Stack justifyContent='space-between' alignItems='center'>
          <Tabs
            sx={{ width: '100%', flex: 1 }}
            value={currentTab}
            variant='scrollable'
            onChange={(_e, value) => {
              navigate(TAB_TO_PATH[value as StartupsTabType]);
            }}
          >
            <Tab label='All startups' value='all' />
            <Tab
              label={
                <Stack direction='row' alignItems='center' gap={1}>
                  <Typography variant='inherit'>Pitch inbox</Typography>
                  {newStartupPitchesCount !== null ? (
                    Boolean(newStartupPitchesCount) && (
                      <BaseTabCountBadge count={newStartupPitchesCount} />
                    )
                  ) : (
                    <Skeleton
                      variant='text'
                      width={20}
                      sx={{ marginLeft: 1 }}
                    />
                  )}
                </Stack>
              }
              value='inbox'
            />
            <Tab label='Outbound requests' value='requests' />
          </Tabs>
        </Stack>

        <Box sx={{ marginBottom: 2 }}>
          <Divider />
        </Box>
        {children}
      </Stack>
    </Page>
  );
}
