import ChallengeDetailsHeader from './ChallengeDetailsHeader';
import { ChallengeConnectionSettingsType, ChallengeType } from '../types';
import { Box, Divider, Grid, Stack } from '@mui/material';
import { ChallengeSubListsSection } from './lists/ChallengeSubListsSection';
import { useCallback, useMemo, useState } from 'react';
import { ChallengeSubProjectsSection } from './projects/ChallengeSubProjectsSection';
import { LEAD_STAGES, POC_STAGES_FOR_KANBAN } from 'utils/projectStageEnum';
import { ChallengeDescriptionSection } from './ChallengeDescriptionSection';
import { ChallengeStakeholdersSection } from './ChallengeStakeholdersSection';
import { ChallengeSubLeadsSection } from './projects/ChallengeSubLeadsSection';
import ChallengeVisibilityAndOrderButton from './ChallengeVisibilityAndOrderButton';
import { useUpdateChallengeConnectionSettingsMutation } from 'apollo/generated/sdkShared';
import { captureException } from '@sentry/react';

export type UpdatedSectionType = {
  title: string;
  settings: {
    display_order: number;
    is_hidden: boolean;
  };
};

const ChallengeDetailsPageContent = ({
  challenge,
  isAnonymous,
}: {
  challenge: ChallengeType;
  isAnonymous: boolean;
}) => {
  const [updateChallengeConnectionSettings] =
    useUpdateChallengeConnectionSettingsMutation();

  const [challengeConnectionSettings, setChallengeConnectionSettings] =
    useState(challenge.challenge_connection_settings);

  const { connectedLeads, connectedProjects, connectedStartupLists } =
    useMemo(() => {
      const connections = challenge.challenge_connections || [];

      const projects = connections.filter(
        connection => connection.project_id !== null && connection.project,
      );

      const connectedLeads = projects
        .filter(connection =>
          LEAD_STAGES.includes(
            connection.project.stage as (typeof LEAD_STAGES)[number],
          ),
        )
        .map(connection => connection.project);

      const connectedProjects = projects
        .filter(connection =>
          POC_STAGES_FOR_KANBAN.includes(
            connection.project.stage as (typeof POC_STAGES_FOR_KANBAN)[number],
          ),
        )
        .map(connection => connection.project);

      const connectedStartupLists = connections
        .filter(
          connection =>
            connection.startup_list_id !== null && connection.startup_list,
        )
        .map(connection => connection.startup_list);

      return {
        connectedLeads,
        connectedProjects,
        connectedStartupLists,
      };
    }, [challenge.challenge_connections]);

  const challengeConnectionSubSections = useMemo(() => {
    return [
      {
        component: (
          <ChallengeSubListsSection
            challengeId={challenge.id}
            connectedStartupLists={connectedStartupLists}
            isReadonly={isAnonymous}
          />
        ),
        settings: challengeConnectionSettings.list_settings,
      },
      {
        component: (
          <ChallengeSubLeadsSection
            challengeId={challenge.id}
            connectedLeads={connectedLeads}
            isReadonly={isAnonymous}
          />
        ),
        settings: challengeConnectionSettings.lead_settings,
      },
      {
        component: (
          <ChallengeSubProjectsSection
            challengeId={challenge.id}
            connectedProjects={connectedProjects}
            isReadonly={isAnonymous}
          />
        ),
        settings: challengeConnectionSettings.project_settings,
      },
    ]
      .filter(section => !section.settings.is_hidden)
      .sort((a, b) => a.settings.display_order - b.settings.display_order);
  }, [
    challenge.id,
    connectedStartupLists,
    isAnonymous,
    challengeConnectionSettings.list_settings,
    challengeConnectionSettings.lead_settings,
    challengeConnectionSettings.project_settings,
    connectedLeads,
    connectedProjects,
  ]);

  const handleUpdateChallengeConnectionSettings = useCallback(
    async (updatedSections: UpdatedSectionType[]) => {
      const updatedListSettings = updatedSections.find(s => s.title === 'Lists')
        ?.settings || { display_order: 0, is_hidden: false };
      const updatedLeadSettings = updatedSections.find(s => s.title === 'Leads')
        ?.settings || { display_order: 0, is_hidden: false };
      const updatedProjectSettings = updatedSections.find(
        s => s.title === 'Projects',
      )?.settings || { display_order: 0, is_hidden: false };

      setChallengeConnectionSettings(
        (prevSettings: ChallengeConnectionSettingsType) => ({
          ...prevSettings,
          list_settings: updatedListSettings,
          lead_settings: updatedLeadSettings,
          project_settings: updatedProjectSettings,
        }),
      );

      try {
        await updateChallengeConnectionSettings({
          variables: {
            id: challenge.id,
            listSettings: updatedListSettings,
            leadSettings: updatedLeadSettings,
            projectSettings: updatedProjectSettings,
          },
        });
      } catch (error) {
        captureException(error);
      }
    },
    [challenge.id, updateChallengeConnectionSettings],
  );

  return (
    <>
      <ChallengeDetailsHeader challenge={challenge} isReadonly={isAnonymous} />
      <Divider sx={{ marginY: 3 }} />
      <Grid container>
        <Grid item sx={{ width: '100%', marginBottom: 2 }}>
          <ChallengeDescriptionSection
            challengeId={challenge.id}
            challengeDescription={challenge.description || ''}
            challengeImpact={challenge.impact || ''}
            isReadonly={isAnonymous}
          />
        </Grid>
        <Grid item xs={9.5}>
          <Stack direction='row' justifyContent='flex-end' marginRight={4}>
            <ChallengeVisibilityAndOrderButton
              challengeConnectionSettings={challengeConnectionSettings}
              onUpdateSettings={handleUpdateChallengeConnectionSettings}
            />
          </Stack>
          <Stack direction='row' width='100%' justifyContent='space-between'>
            <Stack>
              {challengeConnectionSubSections.map((subSection, index) => (
                <Box key={index}>{subSection.component}</Box>
              ))}
            </Stack>
            <Divider
              orientation='vertical'
              sx={{ height: 'unset', margin: 4 }}
            />
          </Stack>
        </Grid>
        <Grid item sx={{ paddingTop: 3 }} xs={2.5}>
          <ChallengeStakeholdersSection
            challenge={challenge}
            connectedLeads={
              challengeConnectionSettings.lead_settings.is_hidden
                ? []
                : connectedLeads
            }
            connectedProjects={
              challengeConnectionSettings.project_settings.is_hidden
                ? []
                : connectedProjects
            }
            isReadonly={isAnonymous}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ChallengeDetailsPageContent;
