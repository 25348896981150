import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import {
  EnumTableProjectStakeholderRolesEnum,
  useGetAllPeopleQuery,
} from 'apollo/generated/sdkInnovationManager';
import { useUpsertPersonActionMutation } from 'apollo/generated/sdkShared';
import { AddPersonModal } from 'components/engagement/people/AddPersonModal';
import { StartupListDetailPageContext } from 'contexts/ProjectScopeContext';
import { Form, FormikProvider, useFormik } from 'formik';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { useContext, useState } from 'react';
import { Person } from '../@types/stakeholder';

type AddProjectStakeholderModalProps = {
  open: boolean;
  onHide: () => void;
  onSetValues: (f: {
    stakeholder_id?: number;
    role?: keyof PROJECT_ROLES;
  }) => void;
};

export type PROJECT_ROLES = {
  project_sponsor: 'Project Sponsor';
  project_team: 'Other Stakeholders (Business Unit)';
  primary: 'Primary Contact (Startup)';
  other: 'Other Stakeholders (Startup)';
};

type ExtendedProjectRoles = EnumTableProjectStakeholderRolesEnum | 'primary';

export const PROJECT_ROLES_MAP: Record<ExtendedProjectRoles, string> = {
  // Business Unit
  project_leader: 'Pain Point Owner',
  project_sponsor: 'Project Sponsor',
  project_team: 'Other Stakeholders (Business Unit)',
  // Startup
  primary: 'Primary Contact (Startup)',
  other: 'Other Stakeholders (Startup)',
  adoption_committee: 'Adoption Committee',
  adoption_owner: 'Adoption Owner',
  innovation_team: 'Innovation Team',
  key_stakeholders_gatekeeper: 'Key Stakeholders/Gatekeepers',
  management_attention: 'Management Attention',
  project_manager: 'Project Manager',
  project_owner: 'Project Owner',
};

export const AddProjectStakeholderModal = (
  props: AddProjectStakeholderModalProps,
) => {
  const [stakeholderValue, setPersonValue] = useState<Person | null>(null);
  const [openAddPerson, setOpenAddPerson] = useState<boolean>(false);
  const { open, onHide, onSetValues } = props;
  const { project } = useContext(StartupListDetailPageContext);

  const { data: allPeopleData } = useGetAllPeopleQuery({});

  const [upsertPersonAction] = useUpsertPersonActionMutation();

  const allStakeholders =
    allPeopleData?.people.map(list => ({
      id: list.id,
      full_name: list.full_name,
      department: list.department,
      email: list.email,
    })) || [];

  const projectStakeholderIds =
    project?.project_stakeholders?.map(ps => ps.stakeholder.id) || [];

  const stakeholdersOptions = allStakeholders
    .filter(stakeholder => !projectStakeholderIds.includes(stakeholder.id))
    .sort((a, b) => a.full_name.localeCompare(b.full_name));

  const handleProjectStakeholderChange = (id: number) => {
    setFieldValue('stakeholder_id', id);
    setPersonValue(stakeholdersOptions.find(s => s.id === id) || null);
  };

  const formik = useFormik<{
    stakeholder_id?: number;
    role?: keyof PROJECT_ROLES;
  }>({
    initialValues: {
      stakeholder_id: undefined,
      role: undefined,
    },
    onSubmit: async (values, { resetForm }) => {
      onSetValues(values);
      if (values.stakeholder_id && values.role) {
        captureAnalyticsEvent('Stakeholder added to PoC scope', {
          stakeholderId: values.stakeholder_id,
          stakeholderRole: values.role,
        });
      }
      setPersonValue(null);
      resetForm();
      onHide();
    },
  });
  const { errors, touched, handleSubmit, getFieldProps, setFieldValue } =
    formik;

  return (
    <Dialog open={open} fullWidth onClose={onHide}>
      <FormikProvider value={formik}>
        <Form autoComplete='off' onSubmit={handleSubmit}>
          <DialogTitle>Add stakeholder</DialogTitle>
          <DialogContent sx={{ marginTop: '25px' }}>
            <Stack direction='column' spacing={2} sx={{ marginTop: '5px' }}>
              <Autocomplete
                options={stakeholdersOptions}
                {...getFieldProps('stakeholder_id')}
                onChange={(_e, value: unknown) =>
                  // @ts-expect-error: TODO: FIXME
                  handleProjectStakeholderChange(value?.id)
                }
                value={stakeholderValue}
                multiple={false}
                getOptionLabel={(option: unknown) => {
                  if (!option) return '';

                  // @ts-expect-error: TODO: FIXME
                  return option.department
                    ? // @ts-expect-error: TODO: FIXME
                      option.full_name + ` (${option.department})`
                    : // @ts-expect-error: TODO: FIXME
                      option.full_name;
                }}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Box
                      component='span'
                      sx={{
                        overflow: 'hidden',
                        width: '100%',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'initial',
                      }}
                    >
                      {/* @ts-expect-error: TODO: FIXME */}
                      {`${option.full_name}${
                        // @ts-expect-error: TODO: FIXME
                        option.department ? ` (${option?.department})` : ''
                      }`}
                    </Box>
                  </li>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant='outlined'
                    placeholder='Assign stakeholder'
                  />
                )}
                noOptionsText={
                  <Button
                    color='primary'
                    sx={{ justifyContent: 'flex-start', paddingLeft: 2 }}
                    onMouseDown={() => setOpenAddPerson(true)}
                  >
                    + Add New
                  </Button>
                }
              />
              <TextField
                select
                fullWidth
                label='Role'
                {...getFieldProps('role')}
                helperText={touched.role && errors.role}
              >
                {Object.keys(PROJECT_ROLES_MAP).map((value, index) => {
                  if (
                    [
                      'project_leader',
                      'project_sponsor',
                      'project_team',
                    ].includes(value)
                  ) {
                    return (
                      <MenuItem key={`${value} + ${index}`} value={value}>
                        {PROJECT_ROLES_MAP[value as keyof PROJECT_ROLES]}
                      </MenuItem>
                    );
                  }
                  return null;
                })}
              </TextField>
              <AddPersonModal
                setPersonValue={(value: Person) => setPersonValue(value)}
                setFieldValue={setFieldValue}
                onHide={() => setOpenAddPerson(false)}
                onSetValues={async object => {
                  const stakeholderData = await upsertPersonAction({
                    variables: { object },
                  });
                  setOpenAddPerson(false);

                  return {
                    stakeholderId:
                      // eslint-disable-next-line
                      stakeholderData?.data?.upsert_person?.person?.id!,
                  };
                }}
                open={openAddPerson}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button variant='text' color='inherit' onClick={onHide}>
              Cancel
            </Button>
            <Button
              type='submit'
              variant='contained'
              disabled={!formik.values.role || !formik.values.stakeholder_id}
            >
              Save
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};
