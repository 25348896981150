import { EnumTableProjectStagesEnum } from 'apollo/generated/sdkInnovationManager';
import { useGetProjectStageDataQuery } from 'apollo/generated/sdkShared';
import { differenceInDays } from 'date-fns';

export const useGetTimeSpentIn = ({
  projectId,
  projectStage,
}: {
  projectId: number;
  projectStage: EnumTableProjectStagesEnum;
}) => {
  const { data, loading } = useGetProjectStageDataQuery({
    variables: { projectId, stage: projectStage },
  });

  const created_at = data?.project_stages?.find(
    s => projectStage === s.stage,
  )?.created_at;

  return {
    daysIn: !(loading || !created_at)
      ? differenceInDays(new Date(), new Date(created_at))
      : null,
  };
};
