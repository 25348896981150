import { Link } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import { captureException } from '@sentry/react';
import { useSnackbar } from 'notistack';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { useEffect, useState } from 'react';

import { SharedStartupList } from '../../../../../@types/shared';
import { Person } from '../../../../../@types/startupList';
import { SharedListsTable } from './ShareListTable';
import { StartupListForShare } from './ShareStartupList';

const ShareLinkTabContent = ({
  shares,
  onShare,
  startupList,
  captureCopyLinkEvent,
}: {
  shares: SharedStartupList[];
  onShare: (_params: {
    slug: string;
    sendToStakeholders?: Person[];
  }) => Promise<unknown>;
  startupList: StartupListForShare;
  captureCopyLinkEvent: () => void;
}) => {
  const [slug, setSlug] = useState('');
  const [slugError, setSlugError] = useState('');
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const unsafeSlugPattern = /[\\/.,?=&:#\s]+/g;
    if (unsafeSlugPattern.test(slug)) {
      setSlugError('Do not use special characters');
    } else if (
      shares.some(({ target_group_slug }) => target_group_slug === slug)
    ) {
      setSlugError('Such identifier already exists');
    } else {
      setSlugError('');
    }
  }, [slug, shares]);

  const handleCreateLink = () => {
    setLoading(true);
    onShare({ slug: slug.trim() })
      .then(
        () => {
          captureAnalyticsEvent('Create share link', {
            startupListId: startupList.id,
            startupListSource: startupList.source,
            startupListDeliveredByVE:
              !!startupList.sourcing_order?.delivered_date,
            startupListDeliveryDate: startupList.sourcing_order?.delivered_date,
          });
        },
        e => {
          captureException(e);
          enqueueSnackbar('Failed to create link', {
            variant: 'error',
          });
        },
      )
      .finally(() => {
        setSlug('');
        setLoading(false);
      });
  };

  return (
    <>
      <TextField
        sx={{ marginBottom: 2 }}
        fullWidth
        inputProps={{
          style: {
            backgroundColor: 'white',
          },
        }}
        onKeyUp={e => {
          if (Boolean(slugError) || !slug.trim()) {
            return;
          }

          if (e.key === 'Enter') {
            handleCreateLink();
          }
        }}
        placeholder='Type an identifier for your link, e.g. who it goes to'
        error={Boolean(slugError)}
        helperText={slugError}
        onChange={e => setSlug(e.target.value)}
        value={slug}
        InputProps={{
          endAdornment: (
            <LoadingButton
              sx={{ whiteSpace: 'nowrap', width: 150 }}
              type='submit'
              startIcon={<Link />}
              variant='contained'
              onClick={handleCreateLink}
              disabled={Boolean(!slug.trim() || slugError)}
              loading={loading}
              loadingIndicator='Creating link...'
            >
              Create link
            </LoadingButton>
          ),
        }}
      />

      {shares.length > 0 && (
        <SharedListsTable
          shares={shares}
          startupListPublicUUID={startupList.public_uuid}
          captureCopyLinkEvent={captureCopyLinkEvent}
        />
      )}
    </>
  );
};

export { ShareLinkTabContent };
