import {
  useFilesWithSignedUrlsQuery,
  useGetAllTeamsQuery,
} from 'apollo/generated/sdkShared';
import { useMemo } from 'react';

export const useGetAllTeamsData = () => {
  const { data, loading } = useGetAllTeamsQuery();

  const teamAvatarFileIds = useMemo(
    () =>
      data?.teams.filter(t => !!t.logo_file_id).map(t => t.logo_file_id!) || [],
    [data?.teams],
  );

  const { data: signedFileUrl } = useFilesWithSignedUrlsQuery({
    skip: teamAvatarFileIds.length === 0,
    variables: { files_ids: teamAvatarFileIds },
    fetchPolicy: 'cache-and-network',
  });

  const { avatarUrlMap, teamNameMap } = useMemo(() => {
    const avatarMap: Record<number, string> = {};
    const nameMap: Record<number, string> = {};

    data?.teams.forEach(team => {
      const signedUrl = signedFileUrl?.files_with_signed_urls?.data.find(
        file => file.id === team.logo_file_id,
      )?.signed_url;
      if (signedUrl) {
        avatarMap[team.id] = signedUrl;
      }
      nameMap[team.id] = team.name;
    });
    return { avatarUrlMap: avatarMap, teamNameMap: nameMap };
  }, [data?.teams, signedFileUrl?.files_with_signed_urls?.data]);

  return {
    teamsData: data?.teams || [],
    loadingTeamsData: loading,
    avatarUrlMap,
    teamNameMap,
  };
};
