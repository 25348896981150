import {
  Card,
  Stack,
  Typography,
  useTheme,
  Box,
  IconButton,
} from '@mui/material';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useGetCategoriesForListCardQuery } from 'apollo/generated/sdkShared';
import { useSnackbar } from 'notistack';
import { parseRelatedStartups } from 'components/PoCs/hooks/useGetRelatedStartups';
import {
  CardPainPointOwner,
  StartAvatarButton,
  StartupsInARow,
} from 'components/PoCs/hooks/useGetCardFields';
import { ChallengeConnectedProjectType, LinkConnectionType } from '../../types';
import { useNavigate } from 'react-router';
import { PATH_ROOT } from 'routes/paths';
import { ProjectLeadMaturityLevelSelect } from 'components/projectLeadDetails/MaturityLevelSelect';
import { ProjectStageSelect } from 'pages/dashboard/scoping/ProjectStageSelect';
import { indexOfStage } from 'utils/projectStageEnum';
import { Circle, LinkOff as UnlinkIcon } from '@mui/icons-material';
import {
  STATUS_COLOR_MAPPING,
  StatusMap,
} from 'components/dashboard/startupList/constants';
import { NAV_TEXT_GREY } from 'components/NavSection';

export const ChallengeSubProjectCard = ({
  connectedProject,
  type,
  setSelectedProject,
  isReadonly,
}: {
  connectedProject: ChallengeConnectedProjectType;
  type: LinkConnectionType;
  setSelectedProject: Dispatch<
    SetStateAction<ChallengeConnectedProjectType | null>
  >;
  isReadonly: boolean;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const [hover, setHover] = useState(false);

  const { data, loading } = useGetCategoriesForListCardQuery({
    variables: { startupListId: connectedProject.id },
  });

  const { relatedOrganizationStartups: startupsInList, selectedStartupForPoC } =
    {
      ...parseRelatedStartups({
        data,
        enqueueSnackbar,
      }),
    };

  const isLead = type === 'lead';
  const isProject = type === 'project';

  const handleCardClick = (event: React.MouseEvent<HTMLElement>) => {
    const connectionURL = isReadonly
      ? PATH_ROOT.projects.sharedProjectScopeDetails(
          connectedProject.public_uuid,
        )
      : isLead
        ? PATH_ROOT.projectLeads._details(connectedProject.id)
        : PATH_ROOT.projects._pocDetails(connectedProject.id);

    if (event.ctrlKey || event.metaKey) {
      window.open(connectionURL, '_blank');
    } else {
      navigate(connectionURL, {
        state: {
          backToPage: `${window.location.pathname}`,
        },
      });
    }
  };

  const projectPainPointOwners = useMemo(
    () =>
      connectedProject.project_stakeholders
        .filter(
          projectStakeholder => projectStakeholder.role === 'project_leader',
        )
        .flatMap(projectStakeholders => projectStakeholders.stakeholder),
    [connectedProject.project_stakeholders],
  );

  const hasPainPointOwner = projectPainPointOwners.length > 0;

  const currentIndexStage = indexOfStage(connectedProject.stage);
  const shouldRenderShortlisted =
    currentIndexStage === indexOfStage('assessment');
  const shouldRenderSelectedForPoC =
    currentIndexStage > indexOfStage('assessment');

  const handleUnlinkClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setSelectedProject(connectedProject);
  };

  return (
    <Box
      onClick={event => handleCardClick(event)}
      paddingBottom={2}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Card
        sx={{
          display: 'flex',
          minHeight: isLead ? 170 : 222,
          width: 262,
          border: '1px solid rgba(0, 0, 0, 0.1)',
          borderRadius: '6px',
          boxShadow: '0px 1px 6px 0px rgba(0, 0, 0, 0.08)',
          ':hover': {
            cursor: 'pointer',
          },
        }}
      >
        {!isReadonly && hover && (
          <IconButton
            sx={{
              position: 'absolute',
              top: theme.spacing(1),
              right: theme.spacing(1),
              color: NAV_TEXT_GREY,
            }}
            onClick={handleUnlinkClick}
          >
            <UnlinkIcon fontSize='small' />
          </IconButton>
        )}
        <Stack
          justifyContent='space-between'
          alignItems='stretch'
          sx={{ marginY: 2, marginX: 2 }}
        >
          <Box>
            <Stack direction='row'>
              {connectedProject.health_status && (
                <Circle
                  fontSize='small'
                  sx={{
                    marginRight: 0.5,
                    color:
                      STATUS_COLOR_MAPPING[
                        connectedProject.health_status as keyof StatusMap
                      ],
                  }}
                />
              )}
              <Typography variant='subtitle2' sx={{ marginBottom: 1 }}>
                {connectedProject.title}
              </Typography>
            </Stack>
            {isLead && (
              <>
                <ProjectLeadMaturityLevelSelect
                  project={{
                    id: connectedProject.id,
                    lead_maturity: connectedProject.lead_maturity,
                  }}
                  readonly
                />
                <StartupsInARow
                  loading={loading}
                  startups={startupsInList}
                  cardTitle='Startups'
                />
              </>
            )}
            {isProject && (
              <>
                <ProjectStageSelect
                  project={{ ...connectedProject, project_stages: [] }}
                  readonly
                />
                {shouldRenderShortlisted && (
                  <StartupsInARow
                    loading={loading}
                    startups={startupsInList}
                    cardTitle='Shortlisted startup(s)'
                  />
                )}
                {shouldRenderSelectedForPoC && (
                  <>
                    <Typography
                      variant='subtitle2'
                      sx={{ color: 'text.disabled', marginTop: 1 }}
                    >
                      Selected for PoC
                    </Typography>

                    {selectedStartupForPoC ? (
                      <StartAvatarButton startup={selectedStartupForPoC} />
                    ) : (
                      <Typography variant='body2'>-</Typography>
                    )}
                  </>
                )}
              </>
            )}

            {hasPainPointOwner && (
              <CardPainPointOwner
                painPointOwners={projectPainPointOwners}
                typographyVariant='body2'
              />
            )}
          </Box>
        </Stack>
      </Card>
    </Box>
  );
};
