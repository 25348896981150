import { Box, Typography } from '@mui/material';
import { SxProps } from '@mui/material';
import { ReactNode } from 'react';

const SectionFieldLayout = ({
  title,
  children,
  sx,
  titleSx,
}: {
  title?: string;
  children: ReactNode;
  sx?: SxProps | undefined;
  titleSx?: SxProps;
}) => {
  return (
    <Box sx={{ paddingBottom: '5px', width: '100%', ...sx }}>
      {title && (
        <Typography
          variant='subtitle2'
          sx={{ color: 'text.disabled', marginBottom: 1, ...titleSx }}
        >
          {title}
        </Typography>
      )}
      {children}
    </Box>
  );
};

export default SectionFieldLayout;
