// Helps reduce repetition of `try { ... } catch { enqueueSnackbar(...) }` type of code.

import { captureException } from '@sentry/react';
import { useSnackbar } from 'notistack';
import { DependencyList, useCallback } from 'react';

export const useSafeCallback = <A, T>(
  callback: (...args: A[]) => Promise<T>,
  dependencies: DependencyList = [],
  {
    errorMessage = 'Something went wrong',
  }: {
    errorMessage?: string;
  } = {},
) => {
  const { enqueueSnackbar } = useSnackbar();

  const safeCallback = useCallback(
    async (...args: A[]) => {
      try {
        return await callback(...args);
      } catch (error) {
        captureException(error);
        enqueueSnackbar(errorMessage, { variant: 'error' });
        if (process.env.VITE_ENV !== 'production') console.error(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [callback, enqueueSnackbar, errorMessage, ...dependencies],
  );

  return safeCallback;
};
