import { RocketLaunch } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Fade,
  ListItem,
  ListItemAvatar,
  useTheme,
} from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { StartupSidePanelContext } from 'contexts/StartupSidePanelContext';
import { AnimatePresence, motion } from 'framer-motion';
import { useContext } from 'react';
import { StartupResultForGoToSearch } from './useSearch';
import { BaseStartupAvatar } from 'components/base/BaseStartupAvatar';
import { captureAnalyticsEvent } from 'plugins/Analytics';

type Props = {
  onClose: () => void;
  startups: StartupResultForGoToSearch[];
};

function StartupResults({ onClose, startups }: Props) {
  const state = useContext(StartupSidePanelContext);
  const { spacing, palette, shadows } = useTheme();

  return (
    <Fade in={startups.length > 0} unmountOnExit mountOnEnter>
      <Box>
        <MenuList>
          <ListItem sx={{ background: palette.grey[200] }}>
            <ListItemAvatar>
              <Avatar
                sx={{
                  width: spacing(4),
                  height: spacing(4),
                  background: palette.grey[0],
                  boxShadow: shadows[1],
                }}
              >
                <RocketLaunch fontSize='small' />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary='Startups' />
          </ListItem>
          <AnimatePresence>
            {startups.map(({ domain, id, name }) => {
              return (
                <motion.div
                  key={id}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <MenuItem
                    onClick={() => {
                      captureAnalyticsEvent('App Search > Result clicked', {
                        type: 'startup',
                        recordId: id,
                      });
                      onClose();
                      state?.setOpen(id!);
                    }}
                  >
                    <ListItemIcon sx={{ marginRight: 0 }}>
                      <BaseStartupAvatar
                        startup={{
                          name: name,
                          domain: domain,
                          logo_url: null,
                        }}
                        size='tiny'
                      />
                    </ListItemIcon>
                    <ListItemText>{name}</ListItemText>
                  </MenuItem>
                </motion.div>
              );
            })}
          </AnimatePresence>
        </MenuList>
      </Box>
    </Fade>
  );
}

export { StartupResults };
