import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------
// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    tab: true;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    fontWeightSemiBold: number;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    fontWeightSemiBold: number;
  }
}

export default function Typography(theme: Theme) {
  return {
    MuiTypography: {
      styleOverrides: {
        paragraph: {
          marginBottom: theme.spacing(2),
        },
        gutterBottom: {
          marginBottom: theme.spacing(1),
        },
      },
    },
  };
}
