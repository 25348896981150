import { captureException } from '@sentry/core';
import { useCreateStartupListCategoryMutation } from 'apollo/generated/sdkInnovationManager';
import { GLASSDOLLAR_SUPPORT_EMAIL } from 'config';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useUpdateStartupListActivities } from './useUpdateStartupListActivities';

export const useCreateNewCategory = ({
  startupListId,
  errorMessage = `Error creating category. Please contact our support ${GLASSDOLLAR_SUPPORT_EMAIL}`,
}: {
  startupListId: number;
  errorMessage?: string;
}) => {
  const [createCategory] = useCreateStartupListCategoryMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { logStartupListActivity } = useUpdateStartupListActivities();

  const handleCreateNewCategory = useCallback(
    async ({ title, rank }: { title: string; rank: number }) => {
      try {
        const result = await createCategory({
          variables: {
            payload: {
              startup_list_id: startupListId,
              title,
              rank,
            },
          },
        });
        const categoryId = result.data?.insert_project_categories_one?.id;

        await logStartupListActivity({
          logs: [
            {
              action: 'created',
              entityIds: [categoryId ? categoryId : 0],
              entityType: 'project_categories',
            },
          ],
          startupListId,
        });

        if (!categoryId) {
          throw new Error('Failed to create category, missing category id');
        }
        return categoryId;
      } catch (error) {
        console.error(error);
        captureException(error);
        enqueueSnackbar(errorMessage, {
          variant: 'error',
        });
      }
    },
    [
      createCategory,
      enqueueSnackbar,
      startupListId,
      errorMessage,
      logStartupListActivity,
    ],
  );

  return {
    handleCreateNewCategory,
  };
};
