import { useSnackbar } from 'notistack';
import { ReactElement, useCallback } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

export default function BaseCopyToClipboard({
  text,
  successMessage,
  children,
}: {
  text: string;
  successMessage: string;
  children: ReactElement;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const handleCopySuccess = useCallback(() => {
    enqueueSnackbar(successMessage, { variant: 'success' });
  }, [enqueueSnackbar, successMessage]);

  return (
    <CopyToClipboard text={text} onCopy={handleCopySuccess}>
      {children}
    </CopyToClipboard>
  );
}
