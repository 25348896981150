import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { captureException } from '@sentry/react';
import { SentryLink } from 'apollo-link-sentry';
import { API_GRAPHQL_ENDPOINT } from 'config';
import { createClient } from 'graphql-ws';
import useAuth from 'hooks/useAuth';
import { produce } from 'immer';
import { PATH_ROOT } from 'routes/paths';

const httpLink = new HttpLink({ uri: API_GRAPHQL_ENDPOINT });

const createWsLink = (token?: string) =>
  new GraphQLWsLink(
    createClient({
      url: API_GRAPHQL_ENDPOINT.includes('localhost')
        ? API_GRAPHQL_ENDPOINT.replace('http', 'ws')
        : API_GRAPHQL_ENDPOINT.replace('https', 'wss'),
      connectionParams: () =>
        token
          ? {
              headers: {
                authorization: token ? `Bearer ${token}` : '',
              },
            }
          : {},
    }),
  );

const splitLinkWithToken = (token?: string) =>
  split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    createWsLink(token), // Pass token to the wsLink
    httpLink, // Keep the same httpLink for regular queries/mutations
  );

const authMiddleware = (token?: string) =>
  new ApolloLink((operation, forward) => {
    if (token) {
      operation.setContext({
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
    }

    return forward(operation);
  });

const buildErrorLink = () =>
  onError(({ operation, graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      if (graphQLErrors[0].extensions?.code === 'invalid-jwt') {
        window.location.href = PATH_ROOT.auth.login;
      }
      captureException(new Error(`${operation.operationName} - errors`), {
        extra: { errors: graphQLErrors },
      });
    }
    if (networkError) captureException(networkError);
  });

const filterStartupListLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    if (response?.data) {
      const newData = produce(response.data, draft => {
        Object.keys(draft).forEach(key => {
          const value = draft[key];
          if (Array.isArray(value)) {
            // Filter out objects that have a `startup_list` key with a value of `null`
            draft[key] = value.filter(item => item?.startup_list !== null);
          }
        });
      });

      return {
        ...response,
        data: newData,
      };
    }

    return response;
  });
});

const cache = new InMemoryCache({});

export const useApolloClientDoNotUse = () => {
  const { token } = useAuth();

  return new ApolloClient({
    link: ApolloLink.from([
      buildErrorLink(),
      new SentryLink({
        attachBreadcrumbs: { includeError: true },
      }),
      authMiddleware(token),
      filterStartupListLink,
      splitLinkWithToken(token),
    ]),
    cache,
    connectToDevTools: true,
  });
};
