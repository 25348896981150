import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';

type noteStartupType = { id: number; name: string };

export const NotesContext = createContext<{
  noteProjectId: number | null;
  noteStartup: noteStartupType | null;
  setNoteProjectId: Dispatch<SetStateAction<number | null>>;
  setNoteStartup: Dispatch<SetStateAction<noteStartupType | null>>;
  clearNotesContext: () => void;
}>({
  noteProjectId: null,
  noteStartup: null,
  setNoteProjectId: () => {},
  setNoteStartup: () => {},
  clearNotesContext: () => {},
});

export const NotesContextProvider = ({ children }: { children: ReactNode }) => {
  const [noteProjectId, setNoteProjectId] = useState<number | null>(null);
  const [noteStartup, setNoteStartup] = useState<noteStartupType | null>(null);

  const clearNotesContext = () => {
    setNoteProjectId(null);
    setNoteStartup(null);
  };

  return (
    <NotesContext.Provider
      value={{
        noteProjectId,
        noteStartup,
        clearNotesContext,
        setNoteProjectId,
        setNoteStartup,
      }}
    >
      {children}
    </NotesContext.Provider>
  );
};

export const useNotesContext = () => {
  const state = useContext(NotesContext);

  if (!state) {
    throw new Error('Using NotesContext without the appropriate provider');
  }

  return state;
};
