import { Stack } from '@mui/material';
import { GridCellParams, GridColumns } from '@mui/x-data-grid-pro';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { BaseAddStartupToListMenu } from 'components/base/BaseAddStartupToListMenu';
import { useRightTableActions } from 'components/base/useRightTableActions';
import { CorporateCustomer } from 'components/dashboard/StartupInfoSidePanel/useStartupDetailsFormik';
import { MutableRefObject, useMemo } from 'react';
import { useMatch } from 'react-router';
import { MoveStartupActions } from '../MoveStartupActions';
import { ColumnSettings } from '../fields';
import {
  CategorizedStartupForMatrix,
  DecoratedStartup,
} from './StartupsTableContext';
import { SharedCategoryForStartupComparison } from './types';
import { useGetCustomColumns } from './useGetCustomColumn';
import { useGetMainColumns } from './useGetMainColumns';

export type StartupCorporateCustomer = {
  type?: string | null;
  corporate_customer: CorporateCustomer;
};

export const CUSTOM_DIMENSION_HEADER_FIELD_PREFIX = 'c';

export const useGetColumns = ({
  isPublicPage,
  isDiscovery,
  selectedCategory,
  categorizedStartups,
  shortlist,
  onRemoveStartup,
  apiRef,
  isEmbeddedInProject,
}: {
  apiRef: MutableRefObject<GridApiPro>;
  isPublicPage: boolean;
  isDiscovery: boolean;
  selectedCategory: SharedCategoryForStartupComparison;
  categorizedStartups: CategorizedStartupForMatrix[];
  shortlist: CategorizedStartupForMatrix[];
  onRemoveStartup?: (startup: DecoratedStartup) => void;
  isEmbeddedInProject?: boolean;
}) => {
  // TODO: refactor this to use dynamic project lead path
  const isExternalLeadScopingPage = !!useMatch(
    '/o/default/list-scope/:startupListPublicUUID',
  );

  const { row, getActionsColumn, pinnedRightColumn } = useRightTableActions();
  const hasCustomers = useMemo(
    () => shortlist.some(cs => cs.startup.startup_corporate_customers?.length),
    [shortlist],
  );

  const { mainColumns } = useGetMainColumns({
    isDiscovery,
    isPublicPage,
    selectedCategory,
    categorizedStartups,
    hasCustomers,
  });
  const customFeatureColumns = useGetCustomColumns({
    selectedCategory,
    isReadonly: isDiscovery || isPublicPage,
  });

  const localCols = useMemo(
    () =>
      [
        ...mainColumns,
        ...customFeatureColumns,
      ] as GridColumns<DecoratedStartup>,
    [mainColumns, customFeatureColumns],
  );

  const isEditable = !isPublicPage && !isDiscovery && !isEmbeddedInProject;

  const columns = useMemo(() => {
    if (isEditable) {
      localCols.push(
        getActionsColumn({
          minWidth: 170,
          renderCellNode: (
            params: GridCellParams<
              unknown,
              DecoratedStartup & { is_empty_row?: boolean }
            >,
          ) => (
            <Stack
              direction='row'
              justifyContent='center'
              sx={({ spacing }) => ({ gap: spacing(1) })}
              alignItems='center'
              width='100%'
            >
              <>
                {!isExternalLeadScopingPage && (
                  <BaseAddStartupToListMenu
                    selectedStartups={[params.row]}
                    analyticsSource='STARTUP_LIST'
                  />
                )}
                <MoveStartupActions
                  id={Number(params.id)}
                  onRemoveStartup={onRemoveStartup}
                  startup={params.row}
                />
              </>
            </Stack>
          ),
        }),
      );
    }

    if (!isPublicPage && !isDiscovery) {
      localCols.push({
        field: 'add-new-column',
        headerName: '',
        minWidth: 120,
        maxWidth: 120,
        width: 120,
        resizable: false,
        disableColumnMenu: true,
        sortable: false,
        filterable: false,
        editable: false,
        hideable: false,
        renderHeader: () => <ColumnSettings />,
      });
    }

    if (!isPublicPage && isDiscovery) {
      localCols.push(
        getActionsColumn({
          minWidth: 80,
          renderCellNode: (
            params: GridCellParams<unknown, DecoratedStartup>,
          ) => (
            <Stack
              direction='row'
              justifyContent='center'
              sx={({ spacing }) => ({ gap: spacing(1) })}
              alignItems='center'
              width='100%'
            >
              <BaseAddStartupToListMenu
                selectedStartups={[params.row]}
                analyticsSource='STARTUP_LIST'
              />
            </Stack>
          ),
        }),
      );
    }

    return localCols.filter(Boolean);
  }, [
    isEditable,
    isPublicPage,
    isDiscovery,
    localCols,
    getActionsColumn,
    onRemoveStartup,
    isExternalLeadScopingPage,
  ]);

  const visibleTableWidth =
    apiRef?.current?.rootElementRef?.current?.offsetWidth || 0;

  if (
    visibleTableWidth > 0 &&
    columns.reduce((acc, col) => acc + (col?.minWidth || 0), 0) <
      visibleTableWidth
  ) {
    return {
      columns: columns.map(col => ({
        ...col,
        flex: col.field === 'short_description' ? 1 : undefined,
      })),
      row,
      pinnedRightColumn,
      hasCustomers,
    };
  }

  return {
    columns,
    row,
    pinnedRightColumn,
    hasCustomers,
  };
};
