import { Palette } from '@mui/material';
import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  introPage: {
    paddingLeft: 60,
    paddingRight: 60,
    paddingTop: 40,
    paddingBottom: '12px',

    flexDirection: 'row',
    justifyContent: 'space-between',
    fontFamily: 'Helvetica',
  },

  contentPage: {
    paddingLeft: 60,
    paddingRight: 60,
    paddingTop: 40,
    paddingBottom: '12px',
    fontFamily: 'Helvetica',
  },

  pageTitle: {
    fontSize: 30,
    marginBottom: 20,
  },
});

export const tableCellStyle = {
  padding: 5,
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  fontSize: 11,
  borderColor: '#DFE3E8',
  borderBottom: `1px solid #DFE3E8`,
};

export const headerCellStyle = {
  ...tableCellStyle,
  backgroundColor: '#F4F6F8',
};

export const projectPDFExportStyles = (palette: Palette) => {
  const tableHeaderCell = {
    padding: 4,
    fontSize: 7,
    height: 26,
    minWidth: 50,
    maxWidth: 150,
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    borderColor: palette.grey[300],
    borderBottom: `1px solid ${palette.grey[300]}`,
    borderTop: `1px solid ${palette.grey[300]}`,
  } as const;

  return StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
      fontSize: 10,
      lineHeight: 1.5,
      fontFamily: 'Public Sans',
      fontWeight: 400,
    },
    title: {
      fontSize: 18,
      fontWeight: 600,
      marginBottom: 26,
    },
    subtitle: {
      color: `${palette.grey[600]}`,
      fontSize: 12,
      marginBottom: 11,
    },
    sectionTitle: {
      color: `${palette.grey[600]}`,
      fontSize: 10,
      marginTop: 24,
      marginBottom: 8,
    },
    tableHeaderCell,
    tableHeaderFirstColl: {
      ...tableHeaderCell,
      borderLeft: `1px solid ${palette.grey[300]}`,
      minWidth: 115,
      maxWidth: 115,
    },
    tableHeaderSecondColl: {
      ...tableHeaderCell,
      minWidth: 150,
    },
    tableBodyCellStyle: {
      paddingHorizontal: 6,
      alignItems: 'center',
      display: 'flex',
      height: 37,
      lineHeight: 1.2,
      fontSize: 6.5,
      borderColor: palette.grey[300],
      borderBottom: `1px solid ${palette.grey[300]}`,
      flexDirection: 'row',
      justifyContent: 'flex-start',
      overflow: 'hidden',
      minWidth: 50,
      maxWidth: 150,
    },
    documentPage: {
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    documentContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    documentLabel: {
      fontSize: 10,
      color: palette.grey[600],
      marginBottom: 8,
    },
    documentTitle: {
      fontSize: 12,
      fontWeight: 600,
      marginBottom: 26,
    },
  });
};
