import { Divider, Grid, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { ChallengeConnectedProjectType, LinkConnectionType } from '../../types';
import { LinkConnectionCard as LinkProjectCard } from '../LinkConnectionCard';
import LinkProjectToChallengeModal from './LinkProjectToChallengeModal';
import { UnlinkChallengeConnectionModal } from '../UnlinkChallengeConnectionModal';
import { ChallengeSubProjectCard } from './ChallengeSubProjectCard';

export const ChallengeSubLeadsSection = ({
  challengeId,
  connectedLeads,
  isReadonly,
}: {
  challengeId: number;
  connectedLeads: ChallengeConnectedProjectType[];
  isReadonly?: boolean;
}) => {
  const [showLinkProjectModal, setShowLinkProjectModal] = useState(false);

  const [selectedProjectToUnlink, setSelectedProjectToUnlink] =
    useState<ChallengeConnectedProjectType | null>(null);

  const [linkConnectionType, setLinkConnectionType] =
    useState<LinkConnectionType>('lead');

  const handleOpenModal = (type: LinkConnectionType) => {
    setLinkConnectionType(type);
    setShowLinkProjectModal(true);
  };

  return (
    <>
      <Stack marginTop={1}>
        <Typography variant='body1' fontSize={20}>
          Leads
        </Typography>
        <Divider />
        <Grid container spacing={2} marginY={2}>
          {connectedLeads?.map(connectedLead => (
            <Grid item key={connectedLead.id}>
              <ChallengeSubProjectCard
                connectedProject={connectedLead}
                type='lead'
                setSelectedProject={setSelectedProjectToUnlink}
                isReadonly={isReadonly || false}
              />
            </Grid>
          ))}
          {!isReadonly && (
            <Grid item>
              <LinkProjectCard
                setShowLinkModal={() => handleOpenModal('lead')}
                sx={
                  connectedLeads.length !== 0 && connectedLeads.length % 3 === 0
                    ? {
                        height: '44px',
                        marginBottom: 1.5,
                      }
                    : {}
                }
              />
            </Grid>
          )}
        </Grid>
      </Stack>

      <LinkProjectToChallengeModal
        open={showLinkProjectModal}
        onHide={() => setShowLinkProjectModal(false)}
        challengeId={challengeId}
        type={linkConnectionType}
        connectedLeads={connectedLeads}
        connectedProjects={[]}
      />
      <UnlinkChallengeConnectionModal
        open={Boolean(selectedProjectToUnlink)}
        onHide={() => setSelectedProjectToUnlink(null)}
        challengeId={challengeId}
        projectId={selectedProjectToUnlink?.id}
        type='project'
        title={selectedProjectToUnlink?.title}
      />
    </>
  );
};
