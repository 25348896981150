import { View } from '@react-pdf/renderer';
import { CustomDimensionCellJSON } from '../../../../../@types/shared';
import { CrossCircle, QuestionMarkCircle, TickCircle } from './Icons';

export const TriboolFeature = ({ value }: Partial<CustomDimensionCellJSON>) => {
  if (value === 'yes') {
    return (
      <View style={{ width: 9, height: 9 }}>
        <TickCircle fill='#4CA85E' />
      </View>
    );
  } else if (value === 'no') {
    return (
      <View style={{ width: 9, height: 9 }}>
        <CrossCircle fill='#EB574C' />
      </View>
    );
  } else {
    return (
      <View style={{ width: 9, height: 9 }}>
        <QuestionMarkCircle fill='#667280' />
      </View>
    );
  }
};
