import { captureException } from '@sentry/react';
import { useApolloClient } from '@apollo/client';
import {
  useGetOrganizationStartupContactsQuery,
  useUpdateProjectOverviewMutation,
} from 'apollo/generated/sdkShared';
import { StartupListDetailPageContext } from 'contexts/ProjectScopeContext';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useContext, useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { toAPIArray } from 'utils/general';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';

// Types

const EnumTableProjectCategoriesEnum = z.enum([
  'business_enablers',
  'product_innovation',
  'production_technology',
]);

const EnumTableProjectHealthStatusesEnum = z.enum(['amber', 'green', 'red']);

const EnumTableProjectSolutionTypesEnum = z.enum([
  'hardware',
  'mixed',
  'software',
  'unknown',
]);

const EnumTableProjectSourcesEnum = z.enum([
  'favourites',
  'inbox',
  'legacy_landscape',
  'portal_item',
  'scoping',
  'self_created',
]);

const USER_SCHEMA = z.object({
  id: z.number(),
  full_name: z.string().optional().nullable(),
  email: z.string().optional(),
});

const STAKEHOLDER_SCHEMA = z.object({
  id: z.number().int(),
  full_name: z.string(),
  email: z.string().email(),
  department: z.string().optional().nullable(),
});

// const DOCUMENT_SCHEMA = z.object({
//   id: z.number(),
//   name: z.string(),
//   attachment_url: z.string(),
//   document_type: z.string(),
// });

const CONTACT_SCHEMA = z.object({
  email: z.string().email().optional().nullable(),
  id: z.number(),
  is_primary_contact: z.boolean(),
  title: z.string().optional().nullable(),
  linkedin_url: z.string().optional().nullable(),
  full_name: z.string(),
});

const OVERVIEW_SCHEMA = z.object({
  tags: z.array(z.string()).optional(),
  assignee: USER_SCHEMA.nullable().optional(),
  project_category: EnumTableProjectCategoriesEnum.optional().nullable(),
  health_status: EnumTableProjectHealthStatusesEnum.optional().nullable(),
  description: z.string().nullable().optional(),
  department: z.string().nullable().optional(),
  source: EnumTableProjectSourcesEnum.optional().nullable(),
  business_impact: z.number().nullable().optional(),
  poc_cost: z.number().nullable().optional(),
  business_unit_project_owner: z.array(STAKEHOLDER_SCHEMA).optional(),
  business_unit_project_manager: z.array(STAKEHOLDER_SCHEMA).optional(),
  project_leader: z.array(STAKEHOLDER_SCHEMA).optional(),
  project_sponsor: z.array(STAKEHOLDER_SCHEMA),
  key_stakeholders_gatekeeper: STAKEHOLDER_SCHEMA.nullable().optional(),
  adoption_owner: STAKEHOLDER_SCHEMA.nullable().optional(),
  adoption_committee: z.array(STAKEHOLDER_SCHEMA).optional(),
  project_team: z.array(STAKEHOLDER_SCHEMA).optional(),
  management_attention: z.array(STAKEHOLDER_SCHEMA).optional(),
  others: z.array(STAKEHOLDER_SCHEMA).optional(),
  similar_projects: z.string().nullable().optional(),
  // project_files: z.array(DOCUMENT_SCHEMA),
  internal_cost_estimate: z.number().nullable().optional(),
  business_case: z.string().nullable().optional(),
  business_impact_type: z.string().nullable().optional(),
  business_impact_estimate: z.number().nullable().optional(),
  business_case_comment: z.string().nullable().optional(),
  business_impact_related_link: z.string().nullable().optional(),
  budget_availability: z.string().nullable().optional(),
  budget_availability_comment: z.string().nullable().optional(),
  pilot_budget: z.number().nullable().optional(),
  implementation_budget: z.number().nullable().optional(),
  adoption_use_cases: z.string().nullable().optional(),
  adoption_roadmap: z.string().nullable().optional(),
  adoption_resources_available: z.string().nullable().optional(),
  last_scope_edited_at: z.string().optional().nullable(),
  scoping_completed_at: z.string().optional().nullable(),
  sourcing_completed_at: z.string().optional().nullable(),
  assessment_completed_at: z.string().optional().nullable(),
  purchasing_completed_at: z.string().optional().nullable(),
  testing_completed_at: z.string().optional().nullable(),
  identified_at: z.string().optional().nullable(),
  type_of_solution: EnumTableProjectSolutionTypesEnum.optional(),
  solution_timeframe: z.string().optional().nullable(),
  primary_contacts: z.array(CONTACT_SCHEMA),
  other_contacts: z.array(CONTACT_SCHEMA),
  business_impact_estimate_degree_of_accuracy: z.number().optional().nullable(),
});

export type StartupListOverviewFormState = z.infer<typeof OVERVIEW_SCHEMA>;

// Hooks

const useInitialValues = () => {
  const { project, selectedStartup } = useContext(StartupListDetailPageContext);

  const { data: contactsData } = useGetOrganizationStartupContactsQuery({
    variables: {
      // eslint-disable-next-line
      startupId: selectedStartup?.startup?.id!,
    },
    skip: !selectedStartup?.startup?.id,
  });

  const contacts = useMemo(() => {
    const contacts = contactsData?.organization_startup_contacts || [];
    return [...contacts].sort((a, b) => b?.id - a?.id);
  }, [contactsData?.organization_startup_contacts]);

  // Initialize the form with initial values and validation schema
  const initialValues: StartupListOverviewFormState = useMemo(() => {
    return {
      identified_at: project.created_at,
      // stage: project.stage,
      // FIXME: Check if needed
      project_category: project.project_category,
      department: project.department,
      description: project.description,
      health_status: project.health_status,

      // e2e specific
      adoption_committee: project
        ?.project_stakeholders!.filter(
          projectStakeholder =>
            projectStakeholder.role === 'adoption_committee',
        )
        .map(projectStakeholder => projectStakeholder.stakeholder),
      others: project
        .project_stakeholders!.filter(
          projectStakeholder => projectStakeholder.role === 'other',
        )
        .map(projectStakeholder => projectStakeholder.stakeholder),
      project_team: project
        ?.project_stakeholders!.filter(
          projectStakeholder => projectStakeholder.role === 'project_team',
        )
        .map(projectStakeholder => projectStakeholder.stakeholder),
      management_attention: project
        ?.project_stakeholders!.filter(
          projectStakeholder =>
            projectStakeholder.role === 'management_attention',
        )
        .map(projectStakeholder => projectStakeholder.stakeholder),
      project_leader: project
        ?.project_stakeholders!.filter(
          projectStakeholder => projectStakeholder.role === 'project_leader',
        )
        ?.map(projectStakeholder => projectStakeholder.stakeholder),
      project_sponsor: project
        .project_stakeholders!.filter(
          projectStakeholder => projectStakeholder.role === 'project_sponsor',
        )
        ?.map(projectStakeholder => projectStakeholder.stakeholder),
      key_stakeholders_gatekeeper: project.project_stakeholders?.find(
        projectStakeholder =>
          projectStakeholder.role === 'key_stakeholders_gatekeeper',
      )?.stakeholder,
      adoption_owner: project.project_stakeholders?.find(
        projectStakeholder => projectStakeholder.role === 'adoption_owner',
      )?.stakeholder,
      similar_projects: project.similar_projects,
      internal_cost_estimate: project.internal_cost_estimate,
      business_impact_estimate: project.business_impact_estimate,
      business_case: project.business_case,
      business_impact_type: project.business_impact_type,
      business_case_comment: project.business_case_comment,
      business_impact_related_link: project.business_impact_related_link,
      budget_availability: project.budget_availability,
      budget_availability_comment: project.budget_availability_comment,
      pilot_budget: project.pilot_budget,
      implementation_budget: project.implementation_budget,
      adoption_use_cases: project.adoption_use_cases,
      adoption_roadmap: project.adoption_roadmap,
      adoption_resources_available: project.adoption_resources_available,
      type_of_solution: project.type_of_solution,
      solution_timeframe: project.solution_timeframe,
      scoping_completed_at: project.scoping_completed_at,
      sourcing_completed_at: project.sourcing_completed_at,
      assessment_completed_at: project.assessment_completed_at,
      purchasing_completed_at: project.purchasing_completed_at,
      testing_completed_at: project.testing_completed_at,
      // project_files: project.project_files || [],
      primary_contacts: contacts.filter(c => c.is_primary_contact),
      other_contacts: contacts.filter(c => !c.is_primary_contact),
      poc_adoption_probability: project.poc_adoption_probability,
      poc_cost: project.poc_cost,
      business_impact_estimate_degree_of_accuracy:
        project.business_impact_estimate_degree_of_accuracy,
    };
  }, [project, contacts]);
  return { initialValues };
};

/**
 * @deprecated Use specific formik hook for each form/input
 */
const useOverviewFormik = (
  setFieldValue?: (
    field: string,
    value: unknown,
    shouldValidate?: boolean | undefined,
  ) => void,
) => {
  const { initialValues } = useInitialValues();
  const formik = useFormik<StartupListOverviewFormState>({
    initialValues,
    onSubmit: () => {},
    initialStatus: 'hidden',
    validationSchema: toFormikValidationSchema(OVERVIEW_SCHEMA),
    validateOnChange: true,
    enableReinitialize: true,
  });

  const { handleOverviewUpdate } = useOverviewUpdate({
    initialValues,
    setFieldValue,
  });

  return { formik, handleOverviewUpdate };
};

const useOverviewUpdate = ({
  initialValues,
  setFieldValue,
}: {
  initialValues: StartupListOverviewFormState;
  setFieldValue?: (
    field: string,
    value: unknown,
    shouldValidate?: boolean | undefined,
  ) => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { project } = useContext(StartupListDetailPageContext);
  const [updateStartupList] = useUpdateProjectOverviewMutation();
  const { cache: apolloCache } = useApolloClient();

  const handleUpdate = useDebouncedCallback(
    async (changedFields: Record<string, unknown> = {}) => {
      changedFields['last_scope_edited_at'] = new Date(
        Date.now(),
      ).toISOString();
      // TODO - prepare a better next stage update trigger/action for the purchasing stage.
      // Other stages in the PoC have triggers or actions that automatically update the project to the next stage
      // We currently don't have this for stage purchasing
      if (changedFields['purchasing_completed_at']) {
        changedFields['stage'] = 'testing';
      }
      try {
        await updateStartupList({
          variables: {
            projectId: project.id,
            payload: changedFields,
          },
        });

        apolloCache.evict({
          id: apolloCache.identify({ __typename: 'projects', id: project.id }),
        });

        apolloCache.gc();

        enqueueSnackbar('Saved', {
          variant: 'success',
          autoHideDuration: 500,
        });
      } catch (error) {
        captureException(error);
        enqueueSnackbar('Failed to save project', {
          variant: 'error',
        });
      }
    },
    1000,
  );

  const handleOverviewUpdate = async ({
    fieldName,
    fieldValue,
  }: {
    fieldName: keyof StartupListOverviewFormState;
    fieldValue: StartupListOverviewFormState[keyof StartupListOverviewFormState];
  }) => {
    setFieldValue && setFieldValue(fieldName, fieldValue);
    const changedFields: Record<string, unknown> = {};

    if (fieldValue === initialValues[fieldName]) {
      return;
    }

    if (
      ['tags', 'must_have_features', 'nice_to_have_features'].includes(
        fieldName,
      )
    ) {
      changedFields[fieldName] = toAPIArray(fieldValue as string[]);
    } else {
      changedFields[fieldName] = fieldValue;
    }
    handleUpdate(changedFields);
  };

  return {
    handleOverviewUpdate,
  };
};

export default useOverviewFormik;
