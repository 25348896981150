import { Typography } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { useCallback } from 'react';
import { useCreateNewCategory } from '../useCreateNewCategory';

export const useCreateNewCategoryModal = ({
  startupListId,
  hasZeroCategories,
}: {
  startupListId: number;
  hasZeroCategories: boolean;
}) => {
  const confirm = useConfirm();
  const { handleCreateNewCategory } = useCreateNewCategory({
    startupListId,
  });

  const showCreateNewCategoryModal = useCallback(async () => {
    await confirm({
      title: 'Convert this List into Landscape',
      content: (
        <Typography
          variant='body2'
          sx={{
            marginTop: 2,
          }}
          paragraph
        >
          You are about to convert this List to a Landscape. This will move all
          the startups in this list to the a default Category and will enable
          you to create new Categories and place startups in them.
          <br /> <br />
          Are you sure you want to continue?
        </Typography>
      ),
      confirmationText: 'Confirm',
      confirmationButtonProps: {
        variant: 'contained',
      },
      cancellationText: 'Cancel',
      cancellationButtonProps: {
        variant: 'text',
        color: 'inherit',
      },
      dialogProps: {
        fullWidth: true,
      },
    });
    await handleCreateNewCategory({ title: 'Category 2', rank: 2 });
    if (hasZeroCategories)
      await handleCreateNewCategory({ title: 'Category 1', rank: 1 });
  }, [confirm, handleCreateNewCategory, hasZeroCategories]);

  return {
    showCreateNewCategoryModal,
  };
};
