import { usePrioritizedReferencedCustomersQuery } from 'apollo/generated/sdkShared';
import { CorporateCustomer } from 'components/dashboard/StartupInfoSidePanel/useStartupDetailsFormik';
import { useMemo, useState } from 'react';

type ReferencedCustomersKeyedById = Record<string, number>;

/**
 * given referencedCustomers array, returns sorted array
 * @param referencedCustomers
 */
export const usePrioritizedReferencedCustomers = (
  referencedCustomers: CorporateCustomer[],
) => {
  const [
    allReferencedCustomersPrioritized,
    setAllReferencedCustomersPrioritized,
  ] = useState<ReferencedCustomersKeyedById>({});

  const { loading: loadingReferencedCustomers } =
    usePrioritizedReferencedCustomersQuery({
      onCompleted: data => {
        if (Array.isArray(data.referenced_customers)) {
          const referencedCustomers = data.referenced_customers.reduce(
            (
              referencedCustomersResult: ReferencedCustomersKeyedById,
              { domain, priority }: { domain: string; priority: number },
            ) => {
              referencedCustomersResult[domain] = priority;
              return referencedCustomersResult;
            },
            {},
          );
          setAllReferencedCustomersPrioritized(referencedCustomers);
        }
      },
      skip:
        Object.keys(allReferencedCustomersPrioritized).length > 0 ||
        !referencedCustomers,
    });

  return useMemo(() => {
    if (!referencedCustomers) return [];

    if (!loadingReferencedCustomers && Array.isArray(referencedCustomers)) {
      const prioritizedCustomers = [...referencedCustomers];
      prioritizedCustomers.sort(
        (customerA: CorporateCustomer, customerB: CorporateCustomer) => {
          return (
            (allReferencedCustomersPrioritized[customerB?.domain as string] ||
              0) -
            (allReferencedCustomersPrioritized[customerA?.domain as string] ||
              0)
          );
        },
      );
      return prioritizedCustomers;
    }

    return referencedCustomers;
  }, [
    allReferencedCustomersPrioritized,
    loadingReferencedCustomers,
    referencedCustomers,
  ]);
};
