import { Typography } from '@mui/material';
import LogoLong from 'components/LogoLong';

export default function PoweredByGlassdollar() {
  return (
    <>
      <Typography
        color='grey.600'
        variant='subtitle2'
        align='center'
        sx={{ marginRight: 1 }}
      >
        Powered by
      </Typography>
      <a href='https://glassdollar.com' target='_blank' rel='noreferrer'>
        <LogoLong sx={{ width: 120 }} />
      </a>
    </>
  );
}
