import { Link, SxProps, Typography } from '@mui/material';
import { RouterLink } from 'components/RouterLink';
import { useLocation } from 'react-router';
import { To } from 'react-router';

const LinkToPocOrLead = ({
  item,
  sxProps = {},
  to,
}: {
  item: {
    id: number;
    title: string;
  };
  sxProps?: SxProps;
  to: To;
}) => {
  const { pathname } = useLocation();

  return (
    <Link
      color='secondary'
      component={RouterLink}
      variant='body2'
      state={{ backToPage: `${pathname}${window.location.hash}` }}
      onClick={e => e.stopPropagation()}
      to={to}
      sx={{
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        ...sxProps,
      }}
    >
      <Typography
        variant='body2'
        component={'span'}
        sx={{ whiteSpace: 'nowrap' }}
      >
        {item.title}
      </Typography>
    </Link>
  );
};

export default LinkToPocOrLead;
