import { captureAnalyticsEvent } from 'plugins/Analytics';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

export const FullscreenContext = createContext<{
  isFullscreenView: boolean;
  setIsFullscreenView: Dispatch<SetStateAction<boolean>>;
  toggleFullscreen: () => void;
}>({
  isFullscreenView: false,
  setIsFullscreenView: () => {},
  toggleFullscreen: () => {},
});

export const FullscreenContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [isFullscreenView, setIsFullscreenView] = useState(false);

  const toggleFullscreen = () => {
    const element = document.documentElement;

    if (!isFullscreenView && element.requestFullscreen) {
      element.requestFullscreen();
      captureAnalyticsEvent('View List in Fullscreen');
    } else {
      document.exitFullscreen();
    }
  };

  useEffect(() => {
    const onFullscreenChange = () => {
      setIsFullscreenView(Boolean(document.fullscreenElement));
    };
    document.addEventListener('fullscreenchange', onFullscreenChange);

    return () =>
      document.removeEventListener('fullscreenchange', onFullscreenChange);
  }, [setIsFullscreenView]);

  return (
    <FullscreenContext.Provider
      value={{
        isFullscreenView,
        setIsFullscreenView,
        toggleFullscreen,
      }}
    >
      {children}
    </FullscreenContext.Provider>
  );
};

export const useFullscreenContext = () => {
  const state = useContext(FullscreenContext);

  if (!state) {
    throw new Error('Using FullscreenContext without the appropriate provider');
  }

  return state;
};
