import { Insights, InfoOutlined } from '@mui/icons-material';
import {
  IconButton,
  Skeleton,
  Stack,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';

export const TotalViews: React.FC<{
  viewsCount: number | undefined;
  onClick?: () => void;
  disabled?: boolean;
}> = ({ viewsCount, onClick, disabled }) => {
  const {
    spacing,
    sizes: { buttonHeight },
  } = useTheme();

  if (viewsCount === undefined)
    return <Skeleton width={100} height={buttonHeight} />;

  return (
    <TotalViewsBanner
      viewsCount={viewsCount}
      sx={{
        height: buttonHeight,
        transition: 'all 0.2s ease-in-out',
        transform: `translateX(${spacing(3)})`,
        '&:hover': {
          transform: 'translateX(0)',
          '.total-views-info-button': {
            transform: 'scale(1)',
          },
        },
      }}
    >
      <IconButton
        disabled={disabled}
        className='total-views-info-button'
        sx={{
          marginLeft: `-${spacing(1)}`,
          transform: 'scale(0)',
          transition: 'transform 0.2s ease-in-out',
        }}
        size='small'
        onClick={onClick}
      >
        <InfoOutlined fontSize='small' />
      </IconButton>
    </TotalViewsBanner>
  );
};

export const TotalViewsBanner = ({
  viewsCount,
  sx,
  children,
}: {
  viewsCount: number | undefined;
  sx?: SxProps;
  children?: React.ReactNode;
}) => {
  const {
    sizes: { buttonHeight },
  } = useTheme();

  return (
    <Stack
      direction='row'
      gap={1}
      alignItems='center'
      marginRight={1}
      sx={sx}
      height={buttonHeight}
    >
      <Insights
        sx={theme => ({
          alignSelf: 'center',
          color: theme.palette.primary.main,
        })}
      />
      <Typography variant='h4' lineHeight='normal' sx={{ cursor: 'default' }}>
        {viewsCount}
      </Typography>
      <Typography
        lineHeight='normal'
        marginBottom='-6px'
        sx={{ cursor: 'default' }}
      >
        views
      </Typography>
      {children}
    </Stack>
  );
};
