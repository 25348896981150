import {
  SharedCategorizedSupplier,
  SharedProjectCategory,
} from '../@types/shared';
import { useStartupIdFromSearchParams } from 'hooks/useStartupIdFromSearchParams';
import { Dictionary } from 'lodash';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { StartupLists } from 'apollo/generated/sdkShared';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { SEARCH_PARAMS } from 'config';
import { PATH_NAME } from 'routes/paths';

export type StartupListForStartupProfile = Pick<
  StartupLists,
  'id' | 'title' | 'source' | 'is_readonly'
> & {
  project_categories: {
    id: number;
    title: string;
  }[];
};

type StartupSidePanelState = {
  category?: SharedProjectCategory;
  categorizedStartupsById?: Dictionary<SharedCategorizedSupplier>;
  currentStartupList?: StartupListForStartupProfile | undefined;

  pitchId?: number | undefined;
  startupId?: number | undefined;
  startupListId?: number;
  categoryId?: number;
};

type StartupSidePanelParams = {
  startupId: number | null;
  setOpen: (id: number | null, state?: StartupSidePanelState) => void;
  setIsNotesIconClicked: (value: boolean) => void;
  isNotesIconClicked?: boolean;
  state: StartupSidePanelState;
  setState: Dispatch<SetStateAction<StartupSidePanelState>>;
};

export const StartupSidePanelContext =
  createContext<StartupSidePanelParams | null>(null);

export const StartupSidePanelProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  const location = useLocation();

  const { openedStartupId: startupId, setOpenedStartupId } =
    useStartupIdFromSearchParams();

  const isInListPath =
    location.pathname.startsWith(`/${PATH_NAME.discovery}/`) ||
    location.pathname.startsWith(`/${PATH_NAME.list}/`);

  const [state, setState] = useState<StartupSidePanelState>({
    startupId: undefined,
    startupListId:
      isInListPath && params.startupListId
        ? parseInt(params.startupListId)
        : undefined,
    categoryId:
      isInListPath && params.categoryId
        ? parseInt(params.categoryId)
        : undefined,
  });

  const [isNotesIconClicked, setIsNotesIconClicked] = useState<boolean>(false);

  useEffect(() => {
    const startupTab = searchParams.get(SEARCH_PARAMS.startupTab);

    if (startupTab === 'notes') {
      setIsNotesIconClicked(true);
      setSearchParams(
        prevParams => {
          prevParams.delete(SEARCH_PARAMS.startupTab);
          return prevParams;
        },
        {
          replace: true,
        },
      );
    }
  }, [searchParams, setSearchParams]);

  //Cannot pass context unless it is re-opened
  return (
    <StartupSidePanelContext.Provider
      value={{
        startupId,
        setOpen: (id: number | null, state?: StartupSidePanelState) => {
          state &&
            setState({
              ...state,
              startupListId: state.startupListId,
              categoryId: state.categoryId,
            });
          setOpenedStartupId(id);
        },
        setIsNotesIconClicked: (value: boolean) => setIsNotesIconClicked(value),
        setState,
        state,
        isNotesIconClicked,
      }}
    >
      {children}
    </StartupSidePanelContext.Provider>
  );
};

export const useStartupSidePanel = () => {
  const contextValue = useContext(StartupSidePanelContext);

  if (!contextValue) {
    throw new Error(
      'using StartupSidePanelContext without the appropriate provider',
    );
  }

  return {
    startupId: contextValue.startupId,
    startupListId: contextValue.state.startupListId,
    categoryId: contextValue.state.categoryId,
    pitchId: contextValue.state.pitchId,
    openStartupSidePanel: contextValue.setOpen,
    setIsNotesIconClicked: contextValue.setIsNotesIconClicked,
    isNotesIconClicked: contextValue.isNotesIconClicked,
  };
};
