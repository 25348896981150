import { useUpdateProjectStakeholdersMutation } from 'apollo/generated/sdkInnovationManager';
import {
  useDispatchEventMutation,
  useGetPersonDetailsQuery,
} from 'apollo/generated/sdkShared';
import { useSafeCallback } from 'components/base/useSafeCallback';
import { SEARCH_PARAMS } from 'config';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { GrantAccessDialog } from '../GrantAccessDialog';

const useGrantProjectAccess = (title: string, projectId: number | null) => {
  const { search, pathname } = useLocation();
  const params = new URLSearchParams(search);
  const [isOpen, setIsOpen] = useState(false);
  const grantAccessPersonId = params.get(SEARCH_PARAMS.grantAccessPersonId);
  const [upsertProjectStakeholders] = useUpdateProjectStakeholdersMutation();
  const { data, loading } = useGetPersonDetailsQuery({
    variables: { id: Number(grantAccessPersonId) },
    skip: !grantAccessPersonId,
  });
  const [dispatchEvent] = useDispatchEventMutation();

  const { enqueueSnackbar } = useSnackbar();
  const handleSubmit = useSafeCallback(async () => {
    if (!grantAccessPersonId || !projectId) {
      return;
    }

    await upsertProjectStakeholders({
      variables: {
        projectStakeholdersData: [
          {
            role: 'other',
            stakeholder_id: Number(grantAccessPersonId),
            project_id: projectId,
          },
        ],
      },
    });

    await dispatchEvent({
      variables: {
        event: {
          name: 'AccessGrantedForProject',
          payload: {
            projectId,
            requesterPersonId: Number(grantAccessPersonId),
            actionURL: pathname,
          },
        },
      },
    });

    enqueueSnackbar('Access granted', {
      variant: 'success',
    });

    setIsOpen(false);
  }, [
    dispatchEvent,
    enqueueSnackbar,
    grantAccessPersonId,
    pathname,
    projectId,
    upsertProjectStakeholders,
  ]);

  useEffect(() => {
    if (!projectId) return;

    if (grantAccessPersonId && !loading) {
      setIsOpen(true);
    }
  }, [grantAccessPersonId, loading, projectId]);

  return {
    isOpen,
    GrantAccessDialog: (
      <GrantAccessDialog
        title={title}
        loading={loading}
        onConfirm={handleSubmit}
        onHide={() => setIsOpen(false)}
        requesterEmail={data?.people_by_pk?.email || ''}
        requesterName={data?.people_by_pk?.full_name || ''}
      />
    ),
  };
};

export default useGrantProjectAccess;
