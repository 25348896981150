import { TextSnippet } from '@mui/icons-material';
import { MenuItem, Typography } from '@mui/material';
import { captureException } from '@sentry/react';
import { useGetDataForCsvExportLazyQuery } from 'apollo/generated/sdkShared';
import { useSnackbar } from 'notistack';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { useCallback } from 'react';
import { exportResultsToCSV } from 'services/exportProjectResultsToCsv';

export const ExportCSVMenuItem = ({
  currentLandscapeCategoryId,
  currentStartupListId,
}: {
  currentLandscapeCategoryId: number | false;
  currentStartupListId: number;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [getData] = useGetDataForCsvExportLazyQuery({
    variables: {
      startupListId: currentStartupListId,
    },
  });

  const showFailureMessage = useCallback(() => {
    enqueueSnackbar(
      'Export failed. Please contact support if problem persists.',
      {
        variant: 'error',
      },
    );
  }, [enqueueSnackbar]);

  const handleExportCSV = async () => {
    const { data } = await getData();
    if (!data) {
      captureException(`Couldn't fetch data for CSV export`);
      showFailureMessage();
      return;
    }
    const categories = data.project_categories;
    const startupList = data.startup_lists_by_pk;

    if (!startupList) {
      captureException(`Couldn't fetch startup list data for CSV export`);
      showFailureMessage();
      return;
    }
    captureAnalyticsEvent('Download Results Clicked', {
      isLandscape: categories.length > 0,
      isLandscapeCategory: !!currentLandscapeCategoryId,
      isDiscovery: Boolean(startupList.is_readonly),
    });

    try {
      if (currentLandscapeCategoryId) {
        const currentLandscapeCategory = categories.find(
          c => c.id === currentLandscapeCategoryId,
        );
        if (!currentLandscapeCategory) {
          captureException(
            `Couldn't fetch landscape category data for CSV export, category ID: ${currentLandscapeCategoryId}`,
          );
          showFailureMessage();
          return;
        }

        exportResultsToCSV({
          categories: [currentLandscapeCategory],
          projectTitle: currentLandscapeCategory.title,
        });
      } else {
        exportResultsToCSV({
          categories: categories,
          projectTitle: startupList.title,
        });
      }
    } catch (e) {
      captureException(e);
      showFailureMessage();
    }
  };

  return (
    <MenuItem sx={{ color: 'text.secondary' }} onClick={handleExportCSV}>
      <TextSnippet fontSize='small' sx={{ marginRight: 0.5 }} />
      <Typography variant='body1'>Export CSV</Typography>
    </MenuItem>
  );
};
