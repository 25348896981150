import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import {
  Box,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  StartupListForStartupProfile,
  useStartupSidePanel,
} from 'contexts/StartupSidePanelContext';
import { Dictionary, orderBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SharedOrganizationStartup } from '../../../@types/shared';
import { StartupClickBox } from '../startupList/shared/StartupClickBox';
import { CategorizedSuppliers } from 'apollo/generated/sdkShared';

export type CategorizedStartupForInfoNavigator = Pick<
  CategorizedSuppliers,
  'is_in_matrix' | 'matrix_table_score' | 'is_recommended'
> & {
  startup: {
    id: number;
    name: string;
    domain: string;
    logo_url?: string | null;
  };
};

export function StartupInfoNavigator({
  currentStartupId,
  categorizedStartupsById,
  currentStartupList,
  currentCategoryId,
  setOpenSuccessBanner,
  isMobile,
}: {
  currentStartupId: number;
  categorizedStartupsById: Dictionary<CategorizedStartupForInfoNavigator>;
  currentStartupList: StartupListForStartupProfile;
  currentCategoryId?: number;
  setOpenSuccessBanner?: (value: boolean) => void;
  isMobile: boolean;
}) {
  type Startup = SharedOrganizationStartup & {
    matrix_table_score: number;
    is_in_matrix: boolean;
    order: number;
  };

  const { openStartupSidePanel } = useStartupSidePanel();

  const orderedStartupsInList = useMemo(() => {
    const startups = [];
    for (const id in categorizedStartupsById) {
      startups.push({
        matrix_table_score: categorizedStartupsById[id]?.matrix_table_score,
        is_in_matrix: categorizedStartupsById[id]?.is_in_matrix,
        ...categorizedStartupsById[id].startup,
      });
    }

    const orderedStartups = orderBy(
      startups,
      ['matrix_table_score', 'is_in_matrix', 'id'],
      ['desc', 'desc', 'asc'],
    ).map((orderedStartup, index) => {
      return { ...orderedStartup, order: index + 1 };
    });

    return orderedStartups;
  }, [categorizedStartupsById]);

  const startupMap = useMemo(() => {
    return orderedStartupsInList.reduce(
      (map, startup) => {
        if (!startup || !startup.id) return map;

        // @ts-expect-error: TODO: FIXME!
        map[startup.id] = startup;
        return map;
      },
      {} as Record<string, Startup>,
    );
  }, [orderedStartupsInList]);

  const [startupNavigationGalleryInfo, setStartupNavigationGalleryInfo] =
    useState(() => {
      const currentStartup = startupMap[currentStartupId];
      if (currentStartup) {
        const currentStartupIndex = orderedStartupsInList.findIndex(
          ({ id }) => id === currentStartupId,
        );
        const navStartIndex = Math.max(currentStartupIndex - 5, 0);
        const navEndIndex = Math.min(
          currentStartupIndex + 5,
          orderedStartupsInList.length - 1,
        );
        const visibleNavRange = orderedStartupsInList.slice(
          navStartIndex,
          navEndIndex + 1,
        );

        return visibleNavRange || [];
      }
    });

  const [startupAdjacenceInfo] = useState(() => {
    const currentStartupIndex = orderedStartupsInList.findIndex(
      startup => startup?.id === currentStartupId,
    );

    return {
      previousStartup: orderedStartupsInList[currentStartupIndex - 1] || null,
      nextStartup: orderedStartupsInList[currentStartupIndex + 1] || null,
    };
  });

  const navigateToNextProfile = useCallback(
    (targetStartupId: number) => {
      if (setOpenSuccessBanner) {
        setOpenSuccessBanner(false);
      }
      openStartupSidePanel(targetStartupId, {
        startupListId: currentStartupList.id,
        categoryId: currentCategoryId,
      });
    },
    [
      currentCategoryId,
      currentStartupList.id,
      openStartupSidePanel,
      setOpenSuccessBanner,
    ],
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        event.key === 'ArrowLeft' &&
        startupAdjacenceInfo.previousStartup?.id
      ) {
        event.preventDefault();
        navigateToNextProfile(startupAdjacenceInfo.previousStartup?.id);
      }
      if (event.key === 'ArrowRight' && startupAdjacenceInfo.nextStartup?.id) {
        event.preventDefault();
        navigateToNextProfile(startupAdjacenceInfo.nextStartup?.id);
      }
    };
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [navigateToNextProfile, startupAdjacenceInfo]);

  useEffect(() => {
    const currentStartup = startupMap[currentStartupId];
    if (currentStartup) {
      const currentStartupIndex = orderedStartupsInList.findIndex(
        ({ id }) => id === currentStartupId,
      );
      const navStartIndex = Math.max(currentStartupIndex - 5, 0);
      const navEndIndex = Math.min(
        currentStartupIndex + 5,
        orderedStartupsInList.length - 1,
      );
      const visibleNavRange = orderedStartupsInList.slice(
        navStartIndex,
        navEndIndex + 1,
      );
      setStartupNavigationGalleryInfo(visibleNavRange);
    }
  }, [currentStartupId, orderedStartupsInList, startupMap]);

  const isLandscape = Boolean(
    // eslint-disable-next-line
    currentStartupList?.project_categories?.length! > 1,
  );

  const categoryTitle = currentStartupList.project_categories.find(
    category => category.id === currentCategoryId,
  )?.title;

  return (
    <Stack sx={{ marginTop: 1, marginBottom: 4 }}>
      <Typography
        variant='subtitle2'
        justifyContent='center'
        display='flex'
        sx={{ marginBottom: 2 }}
      >
        {isLandscape ? categoryTitle : currentStartupList?.title}
      </Typography>
      <Stack
        direction='row'
        sx={({ spacing }) => ({
          justifyContent: 'space-between',
          alignItems: 'center',
          ...(isMobile && {
            overflowX: 'auto',
            width: '100%',
            maxWidth: `calc(100vw - ${spacing(5)})`,
            height: spacing(9),
            paddingX: 1,
          }),
        })}
      >
        {isMobile ? (
          orderedStartupsInList?.map(navigationItem => {
            const isSelectedStartup = Boolean(
              navigationItem?.id === currentStartupId,
            );

            return (
              <StartupClickBox
                startup={{
                  logo_url: navigationItem.logo_url,
                  name: navigationItem.name,
                  domain: navigationItem.domain,
                }}
                avatarSize='medium'
                disabled={isSelectedStartup}
                key={navigationItem.id}
                // eslint-disable-next-line
                onClick={() => navigateToNextProfile(navigationItem?.id!)}
              />
            );
          })
        ) : (
          <>
            <Tooltip title='Navigate left or use the arrow key'>
              <Box component='span'>
                <IconButton
                  onClick={() =>
                    navigateToNextProfile(
                      // eslint-disable-next-line
                      startupAdjacenceInfo.previousStartup?.id!,
                    )
                  }
                  disabled={!startupAdjacenceInfo?.previousStartup}
                >
                  <ArrowBackIosNew />
                </IconButton>
              </Box>
            </Tooltip>
            {startupNavigationGalleryInfo?.map(navigationItem => {
              const isSelectedStartup = Boolean(
                navigationItem?.id === currentStartupId,
              );

              return (
                <StartupClickBox
                  startup={{
                    logo_url: navigationItem.logo_url,
                    name: navigationItem.name,
                    domain: navigationItem.domain,
                  }}
                  avatarSize='medium'
                  disabled={isSelectedStartup}
                  key={navigationItem.id}
                  // eslint-disable-next-line
                  onClick={() => navigateToNextProfile(navigationItem?.id!)}
                />
              );
            })}
            <Tooltip title='Navigate right or use the arrow key'>
              <Box>
                <IconButton
                  onClick={() =>
                    // eslint-disable-next-line
                    navigateToNextProfile(startupAdjacenceInfo.nextStartup?.id!)
                  }
                  disabled={!startupAdjacenceInfo?.nextStartup}
                >
                  <ArrowForwardIos />
                </IconButton>
              </Box>
            </Tooltip>
          </>
        )}
      </Stack>
      <Divider sx={{ marginTop: 2 }} />
    </Stack>
  );
}
