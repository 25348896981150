import { useGetUpdatesReleaseBanner } from 'components/SystemMessages/FeaturesUpdateBanner';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

type FeaturesUpdatesBannerContextType = {
  isFeaturesUpdatesBannerVisible: boolean;
  currentFeaturesTourStep: string;
  setIsFeaturesUpdatesBannerVisible: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setFeaturesTourNextStep: React.Dispatch<React.SetStateAction<string>>;
  endFeaturesTour: () => void;
};

const FeaturesUpdatesBannerContext = createContext<
  FeaturesUpdatesBannerContextType | undefined
>(undefined);

type FeaturesUpdatesBannerProviderProps = {
  children: ReactNode;
};

// TODO: Refactor component to hold more abstracted logic and a different name
export const FeaturesUpdatesBannerProvider = ({
  children,
}: FeaturesUpdatesBannerProviderProps) => {
  const { isNewUpdateReleased, updateReleaseTimestamp } =
    useGetUpdatesReleaseBanner();
  const [isFeaturesUpdatesBannerVisible, setIsFeaturesUpdatesBannerVisible] =
    useState(isNewUpdateReleased);
  const [currentFeaturesTourStep, setFeaturesTourStep] = useState('');

  const endFeaturesTour = useCallback(() => {
    setFeaturesTourStep('');
    setIsFeaturesUpdatesBannerVisible(false);
    window.localStorage.setItem(updateReleaseTimestamp, 'true');
  }, [updateReleaseTimestamp]);

  useEffect(() => {
    if (isNewUpdateReleased) {
      setIsFeaturesUpdatesBannerVisible(true);
    }
  }, [isNewUpdateReleased]);

  const value = useMemo(
    () => ({
      isFeaturesUpdatesBannerVisible: !!isFeaturesUpdatesBannerVisible,
      setIsFeaturesUpdatesBannerVisible,
      currentFeaturesTourStep,
      setFeaturesTourNextStep: setFeaturesTourStep,
      endFeaturesTour,
    }),
    [isFeaturesUpdatesBannerVisible, currentFeaturesTourStep, endFeaturesTour],
  );

  return (
    <FeaturesUpdatesBannerContext.Provider value={value}>
      {children}
    </FeaturesUpdatesBannerContext.Provider>
  );
};

export const useFeaturesUpdatesBanner =
  (): FeaturesUpdatesBannerContextType => {
    const context = useContext(FeaturesUpdatesBannerContext);
    if (!context) {
      throw new Error(
        'useFeaturesUpdatesBanner must be used within a FeaturesUpdatesBannerProvider',
      );
    }
    return context;
  };
