import LoadingScreen from 'components/LoadingScreen';
import Page404 from 'pages/Page404';
import Page500 from 'pages/Page500';
import { useParams } from 'react-router';
import Page from '../../components/Page';
import ChallengeDetailsPageContent from 'components/engagement/challenges/details/ChallengeDetailsPageContent';
import { useGetChallengeAndConnections } from 'hooks/useGetChallengeAndConnections';

export default function ChallengeDetailsPage() {
  const { challengeId: challangeIdString } = useParams<{
    challengeId: string;
  }>();
  const challengeId = Number(challangeIdString);

  const { challenge, error, loading } = useGetChallengeAndConnections({
    challengeID: challengeId!,
  });

  if (loading) return <LoadingScreen />;

  if (!loading && error) {
    return <Page500 embedded />;
  }

  if (!challenge) return <Page404 embedded />;

  return (
    <Page
      title='Challenge Details | Glassdollar'
      trackingTitle='Challenge Details'
    >
      <ChallengeDetailsPageContent challenge={challenge} isAnonymous={false} />
    </Page>
  );
}
