import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  TextField,
  Button,
} from '@mui/material';
import { captureException } from '@sentry/react';
import {
  GetChallengesDocument,
  useCreateNewChallengeMutation,
} from 'apollo/generated/sdkShared';
import { useSnackbar } from 'notistack';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { PATH_ROOT } from 'routes/paths';

const NewChallengeModal = ({ onClose }: { onClose: () => void }) => {
  const [title, setTitle] = useState('');
  const [createChallenge, { loading }] = useCreateNewChallengeMutation({
    refetchQueries: [
      {
        query: GetChallengesDocument,
      },
    ],
  });

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async () => {
    try {
      const { data } = await createChallenge({
        variables: {
          object: {
            title,
          },
        },
      });

      const challengeId = data?.create_challenge?.challenge_id;

      if (challengeId) {
        captureAnalyticsEvent('Challenge created', {
          challengeId: challengeId,
        });
        navigate(PATH_ROOT.challenges._challengeDetails(challengeId));
      }
    } catch (e) {
      captureException(e);
      enqueueSnackbar(`Error creating challenge...`, {
        variant: 'error',
      });
    }
  };

  return (
    <Dialog open={true} onClose={onClose} fullWidth>
      <DialogTitle>Add new Challenge</DialogTitle>
      <DialogContent sx={{ paddingBottom: 0 }}>
        <Box sx={{ paddingTop: 4 }}>
          <TextField
            autoFocus
            fullWidth
            value={title}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                onSubmit();
              }
            }}
            onChange={e => setTitle(e.target.value)}
            label='Title'
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant='text' color='inherit' onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant='contained'
          disabled={!title || loading}
          onClick={onSubmit}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewChallengeModal;
