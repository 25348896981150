import {
  Check,
  InfoOutlined,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from '@mui/icons-material';
import { Alert, Button, Menu, MenuItem, Stack, SxProps } from '@mui/material';
import { captureException } from '@sentry/react';
import { EnumTableEntityVisibilityEnum } from 'apollo/generated/sdkInnovationManager';
import {
  useAssignStartupListToTeamMutation,
  useUpdateStartupListVisibilityMutation,
} from 'apollo/generated/sdkShared';
import { WARNING_TEXT_COLOR } from 'components/base/typography/constants';
import { StartupListForShare } from 'components/dashboard/startupList/details/share/ShareStartupList';
import LightTooltip from 'components/LightTooltip';
import TeamAvatar from 'components/teams/TeamAvatar';
import { useGetAllTeamsData } from 'components/teams/useGetAllTeamsData';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { MouseEvent, useState } from 'react';
import { OptionContent } from '../PermissionHelpers';

const ConnectedProjectMessage = ({
  connectedProject,
}: {
  connectedProject: StartupListForShare['project'];
}) => {
  if (!connectedProject) return '';

  return (
    <Alert
      icon={<InfoOutlined sx={{ fill: WARNING_TEXT_COLOR, marginY: 'auto' }} />}
      sx={{ fontWeight: 'bold', color: WARNING_TEXT_COLOR }}
      severity='warning'
    >
      Changing this list&apos;s permissions may remove its visibility within its
      linked project.
    </Alert>
  );
};

export const ListPermissions = ({
  currentStartupList,
  sxProps = {},
}: {
  currentStartupList: StartupListForShare;
  sxProps?: SxProps;
}) => {
  const { avatarUrlMap, teamsData } = useGetAllTeamsData();
  const [assignListToTeam] = useAssignStartupListToTeamMutation();
  const { user } = useAuth();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [submenuAnchorEl, setSubmenuAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  const { enqueueSnackbar } = useSnackbar();

  const open = Boolean(anchorEl);
  const submenuOpen = Boolean(submenuAnchorEl);
  const connectedProject = currentStartupList.project;

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSubmenuAnchorEl(null);
  };

  const handleSubmenuClick = (event: MouseEvent<HTMLElement>) => {
    setSubmenuAnchorEl(event.currentTarget);
  };

  const handleSubmenuClose = () => {
    setSubmenuAnchorEl(null);
  };

  const [updateListVisibility] = useUpdateStartupListVisibilityMutation();

  const handleSelect = async (
    visibility: EnumTableEntityVisibilityEnum,
    teamId?: number | null,
  ) => {
    try {
      await updateListVisibility({
        variables: {
          id: currentStartupList.id,
          payload: {
            visibility,
            created_by_user_id:
              currentStartupList?.created_by_user_id || user.id,
          },
        },
      });

      if (visibility === 'team') {
        await assignListToTeam({
          variables: { teamId, id: currentStartupList.id },
        });
      }

      captureAnalyticsEvent('Set entity permissions', {
        id: currentStartupList.id,
        type: 'list',
        permission: visibility,
      });

      enqueueSnackbar('Permissions updated successfully', {
        variant: 'success',
      });

      handleClose();
    } catch (e) {
      console.error(e);
      captureException(e);
      enqueueSnackbar('Failed to update permissions.', {
        variant: 'error',
      });
      return;
    }
  };

  return (
    <Stack direction='row'>
      <Button
        id='list-permissions-button'
        data-testid='list-permissions-button'
        aria-controls={open ? 'list-permissions-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        endIcon={<KeyboardArrowDown />}
        variant='outlined'
        sx={{
          ...sxProps,
          whiteSpace: 'nowrap',
          backgroundColor: open ? 'primary.luminous' : 'transparent',
        }}
      >
        <OptionContent entity={currentStartupList} isMainGreenSelector />
      </Button>
      <Menu
        id='list-permissions-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'list-permissions-button',
        }}
      >
        <MenuItem
          onClick={() => handleSelect('organization')}
          data-testid='menu-item-organization-list-permission'
        >
          <Stack direction='row' alignItems='center' width='100%'>
            <OptionContent
              entity={{
                ...currentStartupList,
                visibility: 'organization',
              }}
            />
            {currentStartupList.visibility === 'organization' && (
              <Check sx={{ marginLeft: 'auto' }} fontSize='small' />
            )}
          </Stack>
        </MenuItem>
        <MenuItem
          data-testid='menu-item-team-list-permission'
          onClick={event => {
            handleSubmenuClick(event);
          }}
          aria-haspopup='true'
          aria-controls={submenuOpen ? 'team-submenu' : undefined}
          aria-expanded={submenuOpen ? 'true' : undefined}
        >
          <LightTooltip
            placement='top'
            PopperProps={{
              sx: { '& .MuiTooltip-tooltip': { padding: '0 !important' } },
            }}
            title={
              <ConnectedProjectMessage connectedProject={connectedProject} />
            }
          >
            <Stack direction='row' alignItems='center' gap={1}>
              <OptionContent
                entity={{
                  ...currentStartupList,
                  visibility: 'team',
                }}
              />
              <KeyboardArrowRight
                fontSize='small'
                sx={{ color: 'text.primary' }}
              />
            </Stack>
          </LightTooltip>
        </MenuItem>
        <MenuItem
          data-testid='menu-item-private-list-permission'
          onClick={() => handleSelect('private')}
        >
          <LightTooltip
            placement='top'
            PopperProps={{
              sx: {
                '& .MuiTooltip-tooltip': { padding: '0 !important' },
              },
            }}
            title={
              <ConnectedProjectMessage connectedProject={connectedProject} />
            }
          >
            <Stack direction='row' alignItems='center' width='100%'>
              <OptionContent
                entity={{
                  ...currentStartupList,
                  visibility: 'private',
                }}
              />
              {currentStartupList.visibility === 'private' && (
                <Check sx={{ marginLeft: 'auto' }} />
              )}
            </Stack>
          </LightTooltip>
        </MenuItem>
      </Menu>
      {/* Submenu for Team */}
      <Menu
        id='team-submenu'
        anchorEl={submenuAnchorEl}
        open={submenuOpen}
        onClose={handleSubmenuClose}
        MenuListProps={{
          'aria-labelledby': 'team-submenu-button',
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {teamsData.map(mappedTeam => (
          <MenuItem
            data-testid={`sub-menu-item-${mappedTeam.name}`}
            key={mappedTeam.id}
            onClick={() => handleSelect('team', mappedTeam.id)}
            sx={{ fontSize: 14 }}
          >
            {mappedTeam && avatarUrlMap?.[mappedTeam.id] && (
              <TeamAvatar
                src={avatarUrlMap[mappedTeam.id]}
                alt={mappedTeam.name}
                sx={{ marginRight: 1 }}
              />
            )}
            {mappedTeam.name}
            {currentStartupList.team?.id === mappedTeam.id && (
              <Check
                fontSize='small'
                sx={{ marginLeft: 'auto', paddingLeft: 1 }}
              />
            )}
          </MenuItem>
        )) || []}
      </Menu>
    </Stack>
  );
};
