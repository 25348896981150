import { Link, LinkProps } from '@mui/material';
import { Ref, forwardRef } from 'react';
import { NavLink } from 'react-router-dom';

export const RouterLink = forwardRef(
  (
    props: LinkProps<typeof NavLink> & {
      queryExtend?: Record<string, unknown>;
    },
    ref: Ref<HTMLAnchorElement>,
  ) => {
    return (
      <Link ref={ref} color='inherit' component={NavLink} {...props}>
        {props.children}
      </Link>
    );
  },
);

RouterLink.displayName = 'RouterLink';
