import { Dialog, useTheme, Theme } from '@mui/material';
import {
  Suppliers,
  useBulkImportStartupsToStartupListMutation,
} from 'apollo/generated/sdkInnovationManager';
import { GLASSDOLLAR_SUPPORT_EMAIL, NUVO_LICENSE_KEY, PROD_ENV } from 'config';
import {
  ColumnAPI,
  NuvoImporter,
  RejectSubmitResult,
  PassSubmitResult,
} from 'nuvo-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useBulkEnrichment } from 'components/admin/useBulkEnrichment';
import { captureException } from '@sentry/core';
import { useCreateNewCategory } from '../../useCreateNewCategory';
import { toggleChatwoot } from 'plugins/ChatwootPlugin';
import { isArray, omit, pick } from 'lodash';

type StartupForImport = Pick<
  Suppliers,
  'name' | 'linkedin_url' | 'crunchbase_url'
> & {
  website: string;
};

type ImportColumn = ColumnAPI & {
  key: keyof StartupForImport;
};

const COLUMNS: Array<ImportColumn> = [
  {
    label: 'Website',
    key: 'website',
    validations: [
      { validate: 'required' },
      {
        validate: 'unique',
      },
    ],
    example: 'https://miro.com',
  },
  {
    label: 'Name',
    key: 'name',
    example: 'Miro',
  },
  {
    label: 'LinkedIn URL',
    key: 'linkedin_url',
    validations: [
      {
        validate: 'unique',
      },
      {
        validate: 'regex',
        // eslint-disable-next-line no-useless-escape
        regex: '.*linkedin.com/compan(y|ies)/.*',
      },
    ],
    example: 'https://linkedin.com/company/mirohq',
  },
  {
    label: 'Crunchbase URL',
    key: 'crunchbase_url',
    validations: [
      {
        validate: 'unique',
      },
      {
        validate: 'regex',
        // eslint-disable-next-line no-useless-escape
        regex: '.*crunchbase.com/organization/.*',
      },
    ],
    example: 'https://www.crunchbase.com/organization/mirohq',
  },
];

const SUCCESS_TIMEOUT = 8000;
const ERROR_TIMEOUT = 60000;

const BulkImportStartupsModal = ({
  open,
  onHide,
  startupListId,
  categoryId,
  invalidateCache,
}: {
  open: boolean;
  onHide: () => void;
  startupListId: number;
  categoryId: number | null;
  invalidateCache: () => void;
}) => {
  const [bulkImportStartupsToStartupList] =
    useBulkImportStartupsToStartupListMutation();
  const { bulkEnrichStartups } = useBulkEnrichment({
    pendingMessage: <>Enriching imported startups</>,
    successMessage: <>Success: All startups has been enriched</>,
  });
  const { handleCreateNewCategory } = useCreateNewCategory({
    startupListId,
    errorMessage: `Import failed, please contact support at ${GLASSDOLLAR_SUPPORT_EMAIL}`,
  });

  useEffect(() => {
    if (open) {
      toggleChatwoot('hidden');
    } else {
      toggleChatwoot('visible');
    }

    return () => toggleChatwoot('visible');
  }, [open]);

  const handleUpload = async ({
    results,
    complete,
  }: {
    results: StartupForImport[];
    complete: (
      rejection?: RejectSubmitResult | PassSubmitResult | undefined,
    ) => void;
  }) => {
    console.log('[Bulk import]', results);
    let ensuredCategoryId: number | null = categoryId;
    if (!ensuredCategoryId) {
      const newCategoryId = await handleCreateNewCategory({
        title: 'Default',
        rank: 0,
      });
      invalidateCache();

      if (!newCategoryId) {
        throw new Error('Failed to create new category');
      }
      ensuredCategoryId = newCategoryId;
    }

    const startups = results.map(result => ({
      ...(pick(
        result,
        COLUMNS.map(c => c.key as string),
      ) as StartupForImport),
      extra: omit(
        result,
        COLUMNS.map(c => c.key as string),
      ),
    }));

    const { data } = await bulkImportStartupsToStartupList({
      variables: {
        input: {
          startups,
        },
        startup_list_id: startupListId,
        category_id: ensuredCategoryId,
      },
    });
    const importedStartups = data?.bulk_import_to_list?.data?.startups;

    const errors: string[] | undefined = isArray(
      data?.bulk_import_to_list?.error,
    )
      ? data?.bulk_import_to_list?.error
      : [data?.bulk_import_to_list?.error];
    console.log('[Bulk import] Errors: ', errors);

    if (!importedStartups || !importedStartups.length) {
      complete(
        new PassSubmitResult({
          successfulRecords: 0,
          failedRecords: startups?.length || 0,
          title: 'Failed to import',
          text: errors?.length
            ? `${errors?.join(' ')}`
            : `Error importing startups. Please contact support for more information: ${GLASSDOLLAR_SUPPORT_EMAIL}.`,
          imageUrl: '/public/static/startup.svg',
          duration: errors?.length ? ERROR_TIMEOUT : SUCCESS_TIMEOUT,
        }),
      );
      return;
    }
    complete(
      new PassSubmitResult({
        successfulRecords: importedStartups.length,
        failedRecords: errors?.length || 0,
        title: errors?.length ? 'Partial success' : 'Success',
        text: errors?.length
          ? `${errors?.join(' ')}`
          : 'All startups has been imported successfully. Enrichment in progress...',
        imageUrl: '/public/static/startup.svg',
        duration: errors?.length ? ERROR_TIMEOUT : SUCCESS_TIMEOUT,
      }),
    );

    bulkEnrichStartups(importedStartups)
      .catch(error => {
        console.log(error);
        captureException(error);
      })
      .then(() => {
        invalidateCache();
      });

    invalidateCache();
    setTimeout(
      () => onHide(),
      errors?.length ? ERROR_TIMEOUT : SUCCESS_TIMEOUT,
    );
  };

  const theme = useTheme();
  const style = useMemo(() => getStyle(theme), [theme]);
  return (
    <Dialog open={open} onClose={onHide} maxWidth='xl' fullWidth>
      <NuvoImporter
        licenseKey={NUVO_LICENSE_KEY}
        settings={{
          developerMode: !PROD_ENV,
          identifier: 'import_startups',
          automaticHeaderDetection: true,
          enableExamples: true,
          modal: false,
          title: 'Import startups',
          columns: COLUMNS,
          allowCustomColumns: true,
          style,
        }}
        onResults={(results, errors, complete) =>
          handleUpload({ results: results as StartupForImport[], complete })
        }
        onCancel={onHide}
      />
    </Dialog>
  );
};

const getStyle = ({ typography, palette }: Theme) => ({
  globals: {
    textColor: palette.text.primary,
    fontFamily: typography.fontFamily,
    backgroundColor: palette.background.default,
    primaryTextColor: palette.text.primary,
    secondaryTextColor: palette.text.secondary,
  },
  buttons: {
    primary: {
      backgroundColor: palette.primary.main,
      ':hover': {
        backgroundColor: palette.primary.dark,
      },
      ':disabled': {
        color: palette.action.disabled,
        backgroundColor: palette.action.disabledBackground,
      },
    },
  },
  loader: {
    root: {
      backgroundColor: palette.background.default,
    },
    content: {
      color: palette.text.primary,
    },
    loadAnimationColor: palette.primary.main,
  },
});

const useBulkImportModal = ({
  startupListId,
  categoryId,
  invalidateCache,
}: {
  startupListId: number;
  categoryId: number | null;
  invalidateCache: () => void;
}) => {
  const [showImportModal, setShowImportModal] = useState(false);
  const showModal = useCallback(
    () => setShowImportModal(true),
    [setShowImportModal],
  );

  return [
    <BulkImportStartupsModal
      key={`${startupListId}-${categoryId}`}
      open={showImportModal}
      onHide={() => setShowImportModal(false)}
      startupListId={startupListId}
      categoryId={categoryId}
      invalidateCache={invalidateCache}
    />,
    showModal,
  ] as const;
};

export { BulkImportStartupsModal, useBulkImportModal };
