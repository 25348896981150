import { Box, styled } from '@mui/material';
import { BANNER_HEIGHT } from './utils';

export const BannerStyle = styled(Box)(({ theme: { spacing } }) => ({
  width: '100%',
  display: 'flex',
  paddingLeft: spacing(4),
  paddingRight: spacing(2),
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'fixed',
  top: 0,
  zIndex: 1200,
  height: BANNER_HEIGHT,
}));
