import { SEARCH_PARAMS } from 'config';
import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

/**
 * TODO: Update to take any params name
 */

export function useStartupIdFromSearchParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  const openedStartupId = searchParams.get(SEARCH_PARAMS.startupId)
    ? Number(searchParams.get(SEARCH_PARAMS.startupId))
    : null;

  const setOpenedStartupId = useCallback(
    (id: number | null | undefined) => {
      setSearchParams(
        currParams => {
          if (id) {
            currParams.set(SEARCH_PARAMS.startupId, id.toString());
          } else {
            currParams.delete(SEARCH_PARAMS.startupId);
          }
          return currParams;
        },
        { replace: true },
      );
    },
    [setSearchParams],
  );

  return { openedStartupId, setOpenedStartupId };
}
