import { Document, Font } from '@react-pdf/renderer';
import { useMemo } from 'react';
import { orderBy, uniqBy } from 'lodash';
import { IntroPage } from './IntroPage';
import { PublicDescriptionPage } from './PublicDescriptionPage';
import { StartupPDFPage } from './StartupPDFPage';
import { CategoriesPageGroup } from './CategoriesPageGroup';
import { CategoryForPDFExport } from './types';
import { StartupListForDetail } from '../../../../@types/startupList';

type StartupListForLandscapePDF = Pick<
  StartupListForDetail,
  'id' | 'title' | 'public_description' | 'public_uuid'
>;

export const LandscapePDF = ({
  startupList,
  organization,
  categories,
}: {
  startupList: StartupListForLandscapePDF;
  organization: { logo_url: string };
  categories: CategoryForPDFExport[];
}) => {
  // Make sure that words are not broken in the middle in the PDF
  Font.registerHyphenationCallback(word => [word]);

  const categoryTitlesByCategorizedStartupId: Record<number, string[]> = {};
  for (const category of categories) {
    for (const categorizedStartup of category.categorized_suppliers) {
      categoryTitlesByCategorizedStartupId[categorizedStartup.startup.id!] = [
        ...(categoryTitlesByCategorizedStartupId[
          categorizedStartup.startup.id!
        ] || []),
        category.title,
      ];
    }
  }

  const orderedCategorizedStartups = useMemo(() => {
    return orderBy(
      uniqBy(
        categories.flatMap(c => c.categorized_suppliers),
        'startup.id',
      ),
      ['id'],
      ['asc'],
    ).map((startup, index) => {
      return { ...startup, order: index + 1 };
    });
  }, [categories]);

  return (
    <Document title={startupList.title} subject={startupList.title}>
      <IntroPage
        organizationLogoURL={organization.logo_url}
        title={startupList.title}
      />
      <PublicDescriptionPage description={startupList.public_description} />
      <CategoriesPageGroup startupList={startupList} categories={categories} />
      {orderedCategorizedStartups.map(categorizedStartup => (
        <StartupPDFPage
          key={categorizedStartup.id}
          currentStartup={categorizedStartup.startup}
          categorizedStartupOrder={categorizedStartup.order}
          landscapeCategoriesTitles={
            categoryTitlesByCategorizedStartupId[categorizedStartup.startup.id!]
          }
        />
      ))}
    </Document>
  );
};
