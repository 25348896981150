import { Delete } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useState } from 'react';
import DeletePersonConfirmDialog from '../../settings/users/DeleteUserConfirmDialog';

const DeletePersonFromTable = ({
  peopleIds,
  onDelete,
  disabled,
}: {
  peopleIds: number[];
  onDelete: () => void;
  disabled?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton
        disabled={disabled}
        data-testid='delete-person-button'
        color='error'
        onClick={e => {
          e.stopPropagation();
          setIsOpen(true);
        }}
      >
        <Delete />
      </IconButton>
      {isOpen && (
        <DeletePersonConfirmDialog
          type='member'
          peopleIds={peopleIds}
          onClose={() => {
            setIsOpen(false);
          }}
          onDelete={() => onDelete()}
        />
      )}
    </>
  );
};

export default DeletePersonFromTable;
