import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import { ReactNode } from 'react';

export const DetailsSectionCard = ({
  title,
  action,
  children,
}: {
  title?: string;
  action?: ReactNode;
  children: ReactNode;
}) => {
  return (
    <Card
      square
      sx={{
        boxShadow: 'none',
        padding: 0,
        overflow: 'inherit',
      }}
    >
      {Boolean(title) && (
        <CardHeader
          sx={{
            padding: 0,
            marginBottom: 1,
            '& .MuiCardHeader-action': {
              margin: 0,
            },
          }}
          title={
            title && (
              <Typography
                variant='caption'
                fontWeight={({ typography }) => typography.fontWeightLight}
                color={({ palette }) => palette.grey['600']}
              >
                {title}
              </Typography>
            )
          }
          action={action}
        />
      )}

      <CardContent
        sx={{
          padding: 0,
          borderRadius: 0,
          '& .MuiPaper-root': { borderRadius: 0, overflow: 'initial' },
        }}
      >
        {children}
      </CardContent>
    </Card>
  );
};
