import {
  useDeleteStartupListTagMutation,
  useDeleteStartupListsStartupListTagMutation,
  useFindStartupListTagsQuery,
  useFindStartupListTagByNameLazyQuery,
  useInsertStartupListTagMutation,
  useInsertStartupListsStartupListTagMutation,
  useUpdateStartupListTagMutation,
} from 'apollo/generated/sdkShared';
import { Tag, ValueTag } from 'components/base/BaseMultiselect';
import { BaseTagsSelect } from 'components/base/BaseTagsSelect';
import { useGetStartupList } from 'hooks/useGetStartupList';
import { useState } from 'react';
import { useUpdateStartupListActivities } from '../useUpdateStartupListActivities';

export const useInsertListTag = ({
  onInsertComplete,
}: {
  onInsertComplete?: (tagId: number) => void;
}) => {
  const { logStartupListActivity } = useUpdateStartupListActivities();
  const [insertTag] = useInsertStartupListTagMutation();
  const [insertStartupListTags] = useInsertStartupListsStartupListTagMutation();
  const [getTagByName] = useFindStartupListTagByNameLazyQuery();

  const onInsert = async (name: string, startupListId: number) => {
    const tag = await insertTag({
      variables: { name },
    });

    let newTag = tag.data?.insert_startup_list_tags_one;

    if (!newTag) {
      const res = await getTagByName({ variables: { name } });

      newTag = res.data?.startup_list_tags[0];
    }

    if (newTag?.id) {
      const res = await insertStartupListTags({
        variables: {
          startup_list_id: startupListId,
          tag_id: newTag.id,
        },
      });

      const startupListTag =
        res.data?.insert_startup_lists_startup_list_tags_one;

      if (startupListTag) {
        await logStartupListActivity({
          logs: [
            {
              entityIds: startupListTag?.id,
              entityType: 'startup_lists_startup_list_tags',
              action: 'updated',
            },
          ],
          startupListId,
        });
      }

      startupListTag?.id &&
        onInsertComplete &&
        onInsertComplete(startupListTag.id);
    }
  };

  return onInsert;
};

export const useRemoveListTag = () => {
  const { logStartupListActivity } = useUpdateStartupListActivities();
  const [deleteStartupListTags] = useDeleteStartupListsStartupListTagMutation();

  return async ({
    name,
    startupListId,
  }: {
    name: string;
    startupListId: number;
  }) => {
    await deleteStartupListTags({
      variables: { name, startupListId },
      update: (cache, { data }) => {
        const tagId =
          data?.delete_startup_lists_startup_list_tags?.returning?.[0]?.id;

        if (!tagId) {
          return;
        }

        cache.evict({
          id: cache.identify({
            __typename: 'startup_lists_startup_list_tags',
            id: tagId,
          }),
        });
        cache.gc();

        logStartupListActivity({
          logs: [
            {
              entityIds: tagId,
              entityType: 'startup_lists_startup_list_tags',
              action: 'deleted',
            },
          ],
          startupListId,
        });
      },
    });
  };
};

export const StartupListTagsSelect = ({
  startupListId,
}: {
  startupListId: number;
}) => {
  const { data, loading } = useFindStartupListTagsQuery({
    fetchPolicy: 'cache-and-network',
  });
  const { startupList } = useGetStartupList({ startupListId });
  const { logStartupListActivity } = useUpdateStartupListActivities();
  const [insertStartupListTags] = useInsertStartupListsStartupListTagMutation();
  const [deleteTag] = useDeleteStartupListTagMutation();
  const [updateTag] = useUpdateStartupListTagMutation();
  const onInsert = useInsertListTag({
    onInsertComplete: (tagId: number) => {
      setAddedTagIds(prev => [...prev, tagId]);
    },
  });
  const onRemove = useRemoveListTag();

  const startupListTags =
    startupList?.startup_list_tags
      .map(t => ({
        id: t.id,
        name: t?.tag?.name || '',
      }))
      .sort((a, b) => a.id - b.id) || [];

  const [addedTagIds, setAddedTagIds] = useState<number[]>(
    startupListTags?.map(slt => slt.id),
  );

  const options: Tag[] = (data?.startup_list_tags || []).map(t => ({
    id: t.id,
    value: t.name,
    description: t.name,
  }));

  const value: ValueTag[] = (startupListTags || []).map(t => ({
    id: t.id,
    value: t.name,
    description: t.name,
    option_id: options.find(o => o.value === t.name)?.id,
  }));

  const onAdd = async (tag_name: string) => {
    const option = options.find(o => o.value === tag_name);
    if (!option) return;

    const res = await insertStartupListTags({
      variables: {
        startup_list_id: startupListId,
        tag_id: option.id as number,
      },
    });

    const startupListTag = res.data?.insert_startup_lists_startup_list_tags_one;
    startupListTag?.id && setAddedTagIds(prev => [...prev, startupListTag.id]);

    if (startupListTag?.id) {
      await logStartupListActivity({
        logs: [
          {
            entityIds: startupListTag?.id,
            entityType: 'startup_lists_startup_list_tags',
            action: 'updated',
          },
        ],
        startupListId,
      });
    }
  };

  const onDelete = async (tag_id: number) => {
    await deleteTag({
      variables: {
        id: tag_id,
      },
      update: (cache, { data }) => {
        if (!data?.delete_startup_list_tags_by_pk?.id) {
          return;
        }

        cache.evict({
          id: cache.identify({
            __typename: 'startup_list_tags',
            id: data.delete_startup_list_tags_by_pk.id,
          }),
        });
        cache.gc();
      },
    });
  };

  const onUpdate = async (tag_id: number, name: string) => {
    await updateTag({
      variables: {
        id: tag_id,
        name: name,
      },
    });
  };

  return (
    <BaseTagsSelect
      loading={loading}
      options={options}
      value={value}
      onAdd={onAdd}
      onRemove={tag => onRemove({ name: tag.value, startupListId })}
      // Tag management actions
      onInsert={name => onInsert(name, startupListId)}
      onDelete={onDelete}
      onUpdate={onUpdate}
      addedTagIds={addedTagIds}
      sx={{
        width: 'unset',
        maxWidth: '400px',
      }}
    />
  );
};
