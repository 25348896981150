import { DragIndicator } from '@mui/icons-material';
import { Card, Skeleton, Stack, Typography, useTheme } from '@mui/material';
import { SxProps, styled } from '@mui/material/styles';
import {
  DataGridPro,
  DataGridProProps,
  GridColumns,
} from '@mui/x-data-grid-pro';
import { NewStartupRowToggleButton as Footer } from 'components/startupComparisonTable/shared/Footer';

export type MatrixProps = {
  category?: unknown;
};

const DataGridComparisonMatrix = (params: DataGridProProps) => (
  <DataGridPro {...params} />
);

const StyledDataGrid = styled(DataGridComparisonMatrix)(({ theme }) => ({
  '& .MuiDataGrid-renderingZone': {
    maxHeight: 'fit-content !important',
  },

  '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
    outline: 'none',
    border: 'none',
  },

  '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
    outline: 'none',
    border: 'none',
  },

  '& .MuiDataGrid-columnHeader': {
    paddingLeft: '5px',
  },

  '& .MuiDataGrid-cellCheckbox .MuiCheckbox-root.Mui-disabled': {
    color: theme.palette.primary.main,
  },

  '& .MuiDataGrid-cell': {
    overflow: 'visible !important',
    maxHeight: 'fit-content !important',
    padding: '10px 5px',
    border: '1px solid rgba(145, 158, 171, 0.08) ',
    '&:is([data-field="__reorder__"])': {
      background: theme.palette.grey['50012'],
    },
    '&:is([data-field="referenced_customers"])': {
      background: theme.palette.grey['50012'],
    },
    '&:is([data-field="short_description"])': {
      background: theme.palette.grey['50012'],
    },
    '&:is([data-field="hq"])': { background: theme.palette.grey['50012'] },
  },

  '& .MuiDataGrid-row': {
    maxHeight: 'none !important',
    height: '80px',
  },

  '& .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },

  '& .MuiDataGrid-pinnedColumnHeaders--right': {
    display: 'none !important',
  },

  '& .MuiDataGrid-pinnedColumns--right': {
    boxShadow: 'unset',
    background: 'transparent',

    '& .MuiDataGrid-row': {
      background: 'transparent !important',
      padding: '0px !important',
    },

    '& .MuiDataGrid-cell': {
      borderLeft: 'none',
      background: 'transparent !important',
    },
  },

  '& .MuiDataGrid-pinnedColumns': {
    boxShadow: 'unset',
  },

  '& .actions-header': {
    '& > .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
  },

  '& .key-highlight-cell': {
    padding: '5px',
    cursor: 'pointer',
  },

  '& .MuiDataGrid-row:hover': {
    backgroundColor: 'inherit',
  },

  '& .MuiDataGrid-header:hover': {
    backgroundColor: 'inherit',
  },

  '& .MuiDataGrid-rowReorderCell': {
    cursor: 'default',
    pointerEvents: 'none',
  },
}));

const EmptyListDataGrid = ({
  withSkeleton,
  nbRows = 3,
  sxProps,
  showNewRow = true,
}: {
  nbRows?: number;
  withSkeleton?: boolean;
  sxProps?: SxProps;
  showNewRow?: boolean;
}) => {
  const { palette, spacing } = useTheme();

  const placeholderRows = Array.from(Array(nbRows).keys()).map(i => ({
    id: i,
    name: '',
    long_description: '',
    is_in_matrix: true,
    matrix_table_score: -1,
    order: i + 1,
  }));

  const placeholderColumns: GridColumns = [
    {
      field: 'name',
      headerName: 'Position, company',
      minWidth: 320,
      flex: 0.7,
      disableColumnMenu: true,
      sortable: false,
      hideable: false,
      renderCell: params => {
        if (withSkeleton) {
          return (
            <Stack direction='row' spacing={2} width='100%'>
              <Typography sx={{ margin: '5px' }} variant='h5'>
                {params.row.order}
              </Typography>
              <Skeleton
                variant='circular'
                sx={{
                  flexShrink: 0,
                  height: spacing(6),
                  width: spacing(6),
                }}
              />
              <Stack width='100%'>
                <Skeleton variant='text' />
                <Skeleton variant='text' />
              </Stack>
            </Stack>
          );
        }

        return null;
      },
    },
    {
      field: 'short_description',
      headerName: 'Description',
      minWidth: 250,
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderCell: () => {
        if (withSkeleton) {
          return (
            <Skeleton
              width='100%'
              height='100%'
              sx={{ backgroundColor: palette.grey['50012'] }}
              variant='text'
            />
          );
        }

        return null;
      },
    },
    {
      field: 'add-new-column',
      headerName: '',
      minWidth: 90,
      flex: 0.5,
      width: 90,
      resizable: false,
      disableColumnMenu: true,
      sortable: false,
      filterable: false,
      editable: false,
      hideable: false,
      renderCell: () => {
        if (withSkeleton) {
          return (
            <Stack
              direction='row'
              width='100%'
              height='100%'
              gap={1}
              alignItems='center'
            >
              <Skeleton variant='rounded' sx={{ flex: 1 }} />
              <Skeleton variant='rounded' sx={{ flex: 1 }} />
              <Skeleton variant='rounded' sx={{ flex: 1 }} />
            </Stack>
          );
        }

        return null;
      },
    },
  ];

  return (
    <Card sx={{ marginTop: 3 }}>
      <StyledDataGrid
        sx={sxProps}
        rows={placeholderRows}
        columns={placeholderColumns}
        getRowHeight={() => 'auto'}
        autoHeight
        rowReordering
        disableSelectionOnClick
        disableColumnResize
        {...(showNewRow ? {} : { hideFooter: true })}
        components={{
          RowReorderIcon: DragIndicator,
          ...(showNewRow ? { Footer } : {}),
        }}
      />
    </Card>
  );
};

export { EmptyListDataGrid };
