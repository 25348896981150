import { Link } from '@mui/material';
import AvatarGroup, {
  FALLBACK_REFERENCED_CUSTOMER_LOGO_URL,
} from 'components/AvatarGroup';
import { CorporateCustomer } from 'components/dashboard/StartupInfoSidePanel/useStartupDetailsFormik';
import { usePrioritizedReferencedCustomers } from '../../services/usePrioritizedReferencedCustomers';

export const CustomersField = ({
  referencedCustomers,
  keyHelper = '', // Utility to avoid repeated keys
}: {
  referencedCustomers: CorporateCustomer[]; // TODO:
  keyHelper?: string;
}) => {
  const prioritizedCustomers =
    usePrioritizedReferencedCustomers(referencedCustomers);

  if (!prioritizedCustomers || !Array.isArray(prioritizedCustomers))
    return null;

  return (
    <AvatarGroup
      keyHelper={keyHelper}
      avatarsData={prioritizedCustomers.map(pc => ({
        src: pc?.logo_url,
        tooltipTitle: (
          <Link
            href={`https://${pc?.domain}`}
            sx={({ palette }) => ({
              color: palette.getContrastText(palette.primary.main),
            })}
            target='_blank'
          >
            {pc?.domain}
          </Link>
        ),
      }))}
      fallbackSrc={FALLBACK_REFERENCED_CUSTOMER_LOGO_URL}
      size={30}
      max={6}
      tighten
    />
  );
};
