import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  InputAdornment,
  Paper,
  Popper,
  Stack,
  TextField,
  Tooltip,
  Typography,
  buttonClasses,
  filledInputClasses,
  inputLabelClasses,
  useTheme,
} from '@mui/material';
import {
  AutocompleteRenderGetTagProps,
  AutocompleteRenderInputParams,
  autocompleteClasses,
} from '@mui/material/Autocomplete';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { People } from 'apollo/generated/sdkShared';
import TypographyWithEllipsis from 'components/TypographyWithEllipsis';
import BaseInitialsAvatar from 'components/base/BaseInitialsAvatar';
import { EmailField } from 'components/shared/EmailField';
import { GDLogo } from 'components/shared/GDLogo';
import { differenceBy } from 'lodash';

import React, { useCallback, useMemo, useRef, useState } from 'react';

type PersonOption = Pick<People, 'id' | 'email' | 'full_name'>;

type Owner = PersonOption | null;

type EditOwnerProps = {
  owner?: Owner | Array<Owner>;
  options: Array<PersonOption>;
  updateOwner: (userId: number) => Promise<void>;
  removeOwner?: (id: number) => Promise<void>;
  isMultiSelect?: boolean;
  isOwnedByGD?: boolean;
};

export const useOwnerButtonStyles = () => {
  const { spacing } = useTheme();
  const commonButtonStyles = {
    padding: '2px',
    width: '100%',
    [`& .${buttonClasses.endIcon}`]: {
      pointerEvents: 'none',
    },
    '&:hover': {
      backgroundColor: 'grey.50012',
    },
  };

  const noOwnerButtonStyles = {
    paddingX: 0,
    fontWeight: 400,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    textAlign: 'left',
    padding: `0 ${spacing(0.5)} !important`,
    '&: hover': {
      backgroundColor: 'grey.50012',
      padding: `0 ${spacing(0.5)} !important`,
      borderRadius: '6px',
    },
  };

  const ownerButtonStyles = {
    marginLeft: '2px !important',
  };

  const iconStyles = {
    marginLeft: -1,
    color: 'grey.600',
  };

  const typographyStyles = {
    fontSize: '14px',
    color: 'grey.600',
    cursor: 'pointer',
    lineHeight: 1.6,
    display: 'flex',
    alignItems: 'center',
  };

  return {
    commonButtonStyles,
    noOwnerButtonStyles,
    ownerButtonStyles,
    iconStyles,
    typographyStyles,
  };
};

const OwnerButton = React.forwardRef<
  HTMLButtonElement,
  {
    owner?:
      | Array<{ full_name?: string | null } | null>
      | { full_name?: string | null }
      | null;
    handleToggle: () => void;
    open: boolean;
    isOwnedByGD?: boolean;
  }
>(({ owner, handleToggle, open, isOwnedByGD }, ref) => {
  const {
    commonButtonStyles,
    ownerButtonStyles,
    noOwnerButtonStyles,
    iconStyles,
  } = useOwnerButtonStyles();

  const showOwner =
    owner && Array.isArray(owner) ? owner.length > 0 : owner?.full_name;

  return (
    <Box ref={ref}>
      {!showOwner ? (
        <Button
          variant='text'
          onClick={handleToggle}
          sx={{ ...commonButtonStyles, ...noOwnerButtonStyles }}
          endIcon={
            !open ? (
              <ExpandMore
                sx={{
                  ...iconStyles,
                  height: ({ spacing }) => spacing(3.5),
                }}
              />
            ) : (
              <ExpandLess
                sx={{
                  ...iconStyles,
                  height: ({ spacing }) => spacing(3.5),
                }}
              />
            )
          }
        >
          <Typography
            fontSize={14}
            width='100%'
            sx={{
              lineHeight: '2.099',
              color: 'grey.600',
              '& hover': { backgroundColor: 'grey.50012' },
              fontWeight: 500,
            }}
          >
            None
          </Typography>
        </Button>
      ) : (
        <Stack direction='row'>
          <Button
            variant='text'
            onClick={handleToggle}
            sx={{ ...commonButtonStyles, ...ownerButtonStyles }}
            endIcon={
              !open ? (
                <ExpandMore sx={iconStyles} />
              ) : (
                <ExpandLess sx={iconStyles} />
              )
            }
          >
            <Stack
              direction={'row'}
              spacing={0.5}
              alignItems={'center'}
              justifyContent={'center'}
            >
              {isOwnedByGD && (
                <GDLogo
                  size={20}
                  sxProps={{
                    display: 'inline-flex',
                    marginTop: 0.1,
                  }}
                />
              )}
              <BaseInitialsAvatar
                full_name={
                  Array.isArray(owner)
                    ? owner[0]?.full_name || ''
                    : owner?.full_name || ''
                }
                sx={{
                  height: '22px',
                  width: '22px',
                  fontSize: '10px',
                }}
              />
              <Typography variant='inherit' color='#212b36' fontWeight={500}>
                {Array.isArray(owner)
                  ? owner[0]?.full_name || ''
                  : owner?.full_name || ''}
              </Typography>
              {Array.isArray(owner) && owner.length > 1 && (
                <Typography variant='inherit' color='grey.600' fontWeight={500}>
                  {` +${owner.length - 1}`}
                </Typography>
              )}
            </Stack>
          </Button>
        </Stack>
      )}
    </Box>
  );
});

// TODO: See if we need this for all components
// Resolves eslint's cannot display component name error
OwnerButton.displayName = 'OwnerButton';

export function EditOwner({
  owner,
  options,
  updateOwner,
  removeOwner,
  isMultiSelect = false,
  isOwnedByGD,
}: EditOwnerProps) {
  const [open, setOpen] = useState(false);
  const [inputTextValue, setInputTextValue] = useState('');

  const anchorEl = useRef<HTMLButtonElement>(null);

  const emailsToCheck = useMemo(() => {
    return (Array.isArray(owner) ? owner : [owner]).flatMap(o => o?.email);
  }, [owner]);

  const filterOptions = useCallback(
    (options: PersonOption[], { inputValue }: { inputValue: string }) => {
      const inputLower = inputValue.toLowerCase();
      const selectedIds = new Set(emailsToCheck);

      return options.filter(
        option =>
          selectedIds.has(option.email) ||
          option.full_name.toLowerCase().includes(inputLower) ||
          option.email.toLowerCase().includes(inputLower),
      );
    },
    [emailsToCheck],
  );

  const currentValue = useMemo(
    () =>
      isMultiSelect
        ? options.filter(option => emailsToCheck.includes(option?.email))
        : options.find(option => option.email === emailsToCheck[0]),
    [options, emailsToCheck, isMultiSelect],
  );

  const handleToggle = () => setOpen(prevOpen => !prevOpen);

  const handleClear = useCallback(() => setInputTextValue(''), []);

  const handleClose = (event: MouseEvent | TouchEvent) => {
    const target = event.target as Node;
    if (anchorEl.current?.contains(target)) {
      return;
    }
    setOpen(false);
  };

  const handleUpdateOwner = useCallback(
    async (id: number) => {
      setOpen(false);
      await updateOwner(id);
    },
    [updateOwner],
  );

  const handleChange = useCallback(
    async (_event: unknown, newValue: PersonOption[] | PersonOption | null) => {
      if (newValue === null) return;
      const valueToAdd = isMultiSelect
        ? differenceBy(
            newValue as PersonOption[],
            currentValue as PersonOption[],
            'id',
          )[0]
        : (newValue as PersonOption);
      if (valueToAdd) {
        await handleUpdateOwner(valueToAdd.id);
      }
    },
    [isMultiSelect, currentValue, handleUpdateOwner],
  );

  const getOptionLabel = (option: PersonOption) =>
    option.full_name || option.email || '';

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => {
      // Find the clear indicator icon if it exists
      const endAdornment =
        params.InputProps.endAdornment &&
        React.isValidElement(params.InputProps.endAdornment) &&
        React.Children.toArray(
          params.InputProps.endAdornment.props.children,
        ).find(child => {
          return (
            React.isValidElement(child) &&
            child.props.className.includes(autocompleteClasses.clearIndicator)
          );
        });
      return (
        <TextField
          {...params}
          variant='filled'
          autoFocus
          placeholder='Name or email'
          InputProps={{
            ...params.InputProps,
            endAdornment: endAdornment && (
              <InputAdornment position='end' onClick={handleClear}>
                {endAdornment}
              </InputAdornment>
            ),
          }}
          size='medium'
          onChange={e => setInputTextValue(e.target.value)}
          sx={{
            [`& .${inputLabelClasses.root}.${inputLabelClasses.focused}`]: {
              color: 'text.secondary',
            },
            [`.${filledInputClasses.root}`]: {
              paddingTop: '6px !important',
            },
            [`.${filledInputClasses.root}:hover`]: {
              backgroundColor: 'whitesmoke',
            },
            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
              backgroundColor: 'whitesmoke',
            },
            '& .MuiAutocomplete-inputFocused': {
              backgroundColor: 'whitesmoke',
            },
            '& .MuiInputBase-input::placeholder': {
              fontSize: '14px',
            },
            '& input': { fontSize: '14px' },
          }}
          InputLabelProps={{
            shrink: false,
            style: {
              visibility: inputTextValue ? 'hidden' : 'visible',
              fontSize: '14px',
              lineHeight: '1.45em',
            },
          }}
        />
      );
    },
    [handleClear, inputTextValue],
  );

  const renderOption = useCallback(
    (props: React.HTMLAttributes<HTMLLIElement>, option: PersonOption) => (
      <Stack
        component={'li'}
        {...props}
        direction='row'
        alignItems='center'
        padding={1}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'grey.50012',
          },
        }}
        onClick={() => handleUpdateOwner(option.id)}
        key={`edit-owner-${option.id}`}
      >
        <BaseInitialsAvatar
          full_name={option.full_name || ''}
          sx={{ height: '30px', width: '30px', fontSize: '12px' }}
        />
        <Stack sx={{ minWidth: 0, flex: 1, paddingY: -1.5 }}>
          <TypographyWithEllipsis sx={{ fontSize: '14px' }}>
            {option.full_name}
          </TypographyWithEllipsis>
          {option.email && <EmailField value={option.email} tooltip={false} />}
        </Stack>
      </Stack>
    ),
    [handleUpdateOwner],
  );

  const renderTags = useCallback(
    (value: PersonOption[], getTagProps: AutocompleteRenderGetTagProps) => {
      const numTags = value.length;
      const limitTags = 3;
      return (
        <>
          {value.slice(0, limitTags).map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              key={`tag-owner-${option.id}`}
              size='small'
              sx={{
                margin: 0,
                '& .MuiChip-label': {
                  paddingLeft: '0 !important',
                },
              }}
              onDelete={() => removeOwner && removeOwner(option.id)}
              label={
                <Stack
                  direction={'row'}
                  spacing={0.5}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <BaseInitialsAvatar
                    full_name={option.full_name || option.email || ''}
                    sx={{
                      height: '24px',
                      width: '24px',
                      fontSize: '10px',
                    }}
                  />
                  <Typography variant='inherit'>
                    {option.full_name || option.email}
                  </Typography>
                </Stack>
              }
            />
          ))}
          {numTags > limitTags && (
            <Tooltip
              title={
                <Stack flexDirection='column'>
                  {value.slice(limitTags).map(t => (
                    <Typography variant='caption' key={t.id}>
                      {t.full_name}
                    </Typography>
                  ))}
                </Stack>
              }
            >
              <Typography variant='body2'>+{numTags - limitTags}</Typography>
            </Tooltip>
          )}
          {/* Moves the input caret to a new line */}
          <div style={{ width: '100%', height: '0px' }} />
        </>
      );
    },
    [removeOwner],
  );

  return (
    <Box
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        color: 'text.secondary',
      }}
    >
      <OwnerButton
        open={open}
        ref={anchorEl}
        handleToggle={handleToggle}
        owner={owner}
        isOwnedByGD={isOwnedByGD}
      />
      <ClickAwayListener onClickAway={handleClose}>
        <Popper
          open={open}
          anchorEl={anchorEl.current}
          placement='bottom-start'
          sx={{
            zIndex: theme => theme.zIndex.mobileStepper,
          }}
        >
          <FormControl
            variant='standard'
            sx={{
              minWidth: 240,
              marginTop: 1,
            }}
          >
            <Autocomplete
              open={!!anchorEl.current}
              sx={{
                [`&.${autocompleteClasses.hasPopupIcon}.${autocompleteClasses.hasClearIcon}
                 .${autocompleteClasses.inputRoot}`]: {
                  paddingRight: '0px',
                  width: ' 240px',
                },
                [`&.${autocompleteClasses.hasPopupIcon} .${autocompleteClasses.inputRoot},
                 &.${autocompleteClasses.hasClearIcon} .${autocompleteClasses.inputRoot}`]:
                  {
                    paddingRight: '0px',
                    backgroundColor: 'whitesmoke',
                  },
                '& .MuiInputBase-root': {
                  backgroundColor: 'whitesmoke',
                },
                '& .MuiInputLabel-root': {},
              }}
              inputValue={inputTextValue}
              onInputChange={(_event, newInputValue) =>
                setInputTextValue(newInputValue)
              }
              onChange={handleChange}
              value={currentValue}
              multiple={isMultiSelect}
              getOptionLabel={getOptionLabel}
              options={options}
              filterOptions={filterOptions}
              ListboxProps={{
                style: {
                  maxHeight: '500px',
                  overflow: 'auto',
                  padding: '0px',
                },
              }}
              PaperComponent={props => (
                <Paper
                  {...props}
                  sx={{
                    borderTopLeftRadius: '0px',
                    borderTopRightRadius: '0px',
                  }}
                />
              )}
              PopperComponent={props => (
                <Popper
                  {...props}
                  sx={{
                    zIndex: '2 !important',
                  }}
                  popperOptions={{
                    placement: 'bottom',
                  }}
                />
              )}
              componentsProps={{
                clearIndicator: {
                  onClick: (e: unknown) => {
                    if (inputTextValue === '') {
                      handleClose(e as MouseEvent | TouchEvent);
                    } else {
                      handleClear();
                    }
                  },
                  sx: {
                    marginRight: '5px !important',
                  },
                },
              }}
              renderInput={renderInput}
              renderOption={renderOption}
              renderTags={renderTags}
            />
          </FormControl>
        </Popper>
      </ClickAwayListener>
    </Box>
  );
}
