import { useMediaQuery, useTheme } from '@mui/material';
import { useChatwootGetIdentifierLazyQuery } from 'apollo/generated/sdkInnovationManager';
import useAuth from 'hooks/useAuth';
import { useEffect } from 'react';

const CHATWOOT_BUBBLE_CONTAINER = '.woot--bubble-holder';

export function ChatwootPlugin() {
  const { user, isAuthenticated } = useAuth();
  const [fetchChatwootIdentifier] = useChatwootGetIdentifierLazyQuery();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  useEffect(() => {
    window.chatwootSettings = {
      locale: 'en', // Language to be set
      type: isMobile ? 'standard' : 'expanded_bubble', // [standard, expanded_bubble]
      hideMessageBubble: true,
    };

    (function (d, t) {
      const BASE_URL = 'https://app.chatwoot.com';
      const g = d.createElement(t) as HTMLScriptElement;
      const s = d.getElementsByTagName(t)[0];
      g.src = BASE_URL + '/packs/js/sdk.js';
      g.defer = true;
      g.async = true;
      s.parentNode!.insertBefore(g, s);
      g.onload = function () {
        const websiteToken = import.meta.env.VITE_CHATWOOT_WEBSITE_TOKEN;
        // @ts-expect-error: TODO: FIXME!
        window.chatwootSDK.run({
          websiteToken: websiteToken,
          baseUrl: BASE_URL,
        });
      };
    })(document, 'script');
  }, [isMobile]);

  useEffect(() => {
    window.addEventListener('chatwoot:ready', async function () {
      if (!isAuthenticated) return;

      const res = await fetchChatwootIdentifier();
      const hash = res.data?.chatwoot_get_identifier?.data;

      // Use window.$chatwoot here
      try {
        // @ts-expect-error: TODO: FIXME!
        window.$chatwoot.setUser(user.id, {
          name: user.displayName, // Name of the user
          avatar_url: user.photoURL, // Avatar URL
          email: user.email, // Email of the user
          identifier_hash: hash, // Identifier Hash generated based on the webwidget hmac_token
        });
      } catch (error) {
        console.log('There was an error setting the user in Chatwoot', error);
      }
    });

    return () => {
      window.removeEventListener('chatwoot:ready', () => {});
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
}

export const toggleChatwoot = (state: 'visible' | 'hidden') => {
  const display = state === 'visible' ? 'block' : 'none';
  document.querySelectorAll(CHATWOOT_BUBBLE_CONTAINER).forEach(el => {
    el.setAttribute('style', `display: ${display}`);
  });
};
