import { Stack, Typography } from '@mui/material';
import { BusinessUnitPersonRow } from 'contexts/PersonTableContext';
import { LEAD_STAGES, POC_STAGES_FOR_KANBAN } from 'utils/projectStageEnum';
import { LeadsInvolvement } from './LeadsInvolvement';
import { PoCInvolvement } from './PoCInvolvement';
import { useMemo } from 'react';

type LeadStages = (typeof LEAD_STAGES)[number];
type PocStages = (typeof POC_STAGES_FOR_KANBAN)[number];

const ProjectsAndLeadsForPerson = ({
  projects,
  notes,
}: {
  projects: BusinessUnitPersonRow['projects']['projects_involved_in'];
  notes: BusinessUnitPersonRow['projects']['notes'];
}) => {
  const { leadsInvolvement, poCInvolvement } = useMemo(() => {
    const leadsInvolvement = projects.filter(lead =>
      LEAD_STAGES.includes(lead?.project?.stage as LeadStages),
    );
    const poCInvolvement = projects.filter(poc =>
      POC_STAGES_FOR_KANBAN.includes(poc?.project?.stage as PocStages),
    );

    return { leadsInvolvement, poCInvolvement };
  }, [projects]);

  return (
    <Stack color='grey.600' gap={1}>
      <Stack>
        <Typography variant='body2' fontSize='1rem'>
          PoCs involved in
        </Typography>
        {poCInvolvement.length === 0 && (
          <Typography variant='body2' fontSize='0.8rem'>
            No involvement
          </Typography>
        )}
        <PoCInvolvement items={poCInvolvement} />
      </Stack>
      <Stack>
        <Typography variant='body2' fontSize='1rem'>
          Leads involved in
        </Typography>
        {leadsInvolvement.length === 0 && (
          <Typography variant='body2' fontSize='0.8rem'>
            No involvement
          </Typography>
        )}
        <LeadsInvolvement items={leadsInvolvement} />
      </Stack>
      <Stack>
        <Typography variant='body2' fontSize='1rem'>
          Notes written - {notes}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ProjectsAndLeadsForPerson;
