import {
  GridCellParams,
  GridEditCellProps,
  GridEnrichedColDef,
} from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import { CustomFeatureHeader } from '../../../@types/shared';
import { CustomColumnCell } from '../CustomColumnCell';
import { EditCustomColumnCell } from '../EditCustomColumnCell';
import {
  CustomDimensionHeaderReadonly,
  EditCustomDimensionHeader,
  TREND_CHART_WIDTH_WITH_PADDING,
} from '../fields';
import { DecoratedStartup } from './StartupsTableContext';
import { CUSTOM_DIMENSION_HEADER_FIELD_PREFIX } from './useGetColumns';

export const useGetCustomColumns = ({
  selectedCategory,
  isReadonly,
}: {
  selectedCategory: {
    id: number;
    custom_dimensions_schema: CustomFeatureHeader[];
  };
  isReadonly: boolean;
}) => {
  const dimensionsSchema = selectedCategory.custom_dimensions_schema;
  const categoryId = selectedCategory.id;
  const customColumns = useMemo(
    () =>
      (dimensionsSchema || []).map(
        ({ name: customDimensionName, type, isMustHave }) => {
          // field MUST not change if we rename columns
          const field = `${CUSTOM_DIMENSION_HEADER_FIELD_PREFIX}-${customDimensionName}`;

          return {
            field,
            renderHeader: isReadonly
              ? () => (
                  <CustomDimensionHeaderReadonly
                    name={customDimensionName}
                    type={type}
                    field={field}
                    isMustHave={isMustHave}
                  />
                )
              : () => (
                  <EditCustomDimensionHeader
                    name={customDimensionName}
                    selectedCategory={{
                      id: categoryId,
                      custom_dimensions_schema: dimensionsSchema,
                    }}
                    type={type}
                    field={field}
                    isMustHave={isMustHave}
                  />
                ),

            disableReorder: true,
            minWidth: type === 'line' ? TREND_CHART_WIDTH_WITH_PADDING : 150,
            sortable: false,
            disableColumnMenu: true,
            editable: !isReadonly,
            cellClassName: 'custom-column-cell',
            renderEditCell: (params: GridEditCellProps<string>) => (
              <EditCustomColumnCell params={params} type={type} />
            ), // eslint-disable-next-line
            renderCell: (params: GridCellParams<string, any, any>) => (
              <CustomColumnCell
                value={params.value}
                type={type}
                columnName={customDimensionName}
                categorizedStartupId={params.row.categorized_startup_id}
                isReadonly={isReadonly}
              />
            ), // eslint-disable-next-line
          } as GridEnrichedColDef<DecoratedStartup, any, any>;
        },
      ),
    [dimensionsSchema, categoryId, isReadonly],
  );

  return customColumns;
};
