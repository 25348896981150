import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import { PATH_ROOT } from 'routes/paths';

// ----------------------------------------------------------------------

type AdminGuardProps = {
  children: ReactNode;
  disabled?: boolean;
};

export default function AdminGuard({ children, disabled }: AdminGuardProps) {
  const { isAuthenticated, user } = useAuth();

  if (disabled) {
    return <>{children}</>;
  }

  if (isAuthenticated && user.type === 'bu_member') {
    return <Navigate to={PATH_ROOT.organizationPortal.home('default')} />;
  }

  if (!isAuthenticated) {
    const returnTo = encodeURIComponent(
      window.location.pathname + window.location.search,
    );
    return <Navigate to={`${PATH_ROOT.auth.login}?returnTo=${returnTo}`} />;
  }

  return <>{children}</>;
}
