import { ReactNode } from 'react';
import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
} from 'react-router-dom';
// material
import {
  Box,
  BoxProps,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Stack,
  SxProps,
  Theme,
  Tooltip,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
// theme
import { Badge } from '@mui/material';
import {
  useGetNewStartupPitchesMinimalQuery,
  useGetProjectLeadCountInLeadMaturityLevelsQuery,
} from 'apollo/generated/sdkShared';
import { LEAD_STAGES } from 'utils/projectStageEnum';
import typography from '../theme/typography';

// ----------------------------------------------------------------------

const NavItemBadge = ({ badgeContent }: { badgeContent: number }) => (
  <Badge
    badgeContent={badgeContent}
    color='primary'
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    sx={{
      transform: 'translateY(50%)',
      '.MuiBadge-standard': {
        backgroundColor: 'primary.main',
      },
    }}
  >
    <Box width={16} />
  </Badge>
);

const ListSubheaderStyle = styled((props: { children: ReactNode }) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  color: theme.palette.text.secondary,
}));

export const NAV_TEXT_GREY = '#667380';
export const NAV_TEXT_HOVERED = '#232B35';
const NAV_ITEM_BACKGROUND_HOVERED = '#EFF0F2';

const ListItemStyle = styled(ListItemButton, {
  // Prevents Warning: React does not recognize the `isActive` prop on a DOM element.
  shouldForwardProp(propName) {
    return !['isActive', 'isActiveChild', 'isCollapsed'].includes(
      propName as string,
    );
  },
})(({ theme, isActive }: { theme: Theme; isActive: boolean; sx: SxProps }) => ({
  ...typography.body2,
  height: theme.spacing(5),
  position: 'relative',
  paddingRight: theme.spacing(2.5),
  paddingTop: 0,
  paddingBottom: 0,
  color: NAV_TEXT_GREY,
  '& svg, & path': { fill: NAV_TEXT_GREY },
  '&:before': {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main,
  },
  borderRadius: theme.spacing(0.75),
  ...(isActive && {
    fontWeight: 'fontWeightSemiBold',
    backgroundColor: NAV_ITEM_BACKGROUND_HOVERED,
    color: `${NAV_TEXT_HOVERED}`,
    '&:before': { display: 'block' },
    '& svg, & path': { fill: `${NAV_TEXT_HOVERED}` },
  }),
}));

export const NAV_ICON_SIZE = 22;
const ListItemIconStyle = styled(ListItemIcon)({
  width: NAV_ICON_SIZE,
  height: NAV_ICON_SIZE,
  marginRight: 0,
});

// ----------------------------------------------------------------------

type NavItemProps = {
  id: string;
  title: string;
  path: string;
  alternativePath?: string;
  matchEnd: boolean;
  disabled?: boolean;
  icon?: JSX.Element;
  endIcon?: JSX.Element;
  info?: JSX.Element;
  children?: {
    title: string;
    path: string;
    icon?: JSX.Element;
  }[];
  show: boolean;
  target?: string;
  onClick?: VoidFunction;
};

function NavItem({
  item,
  isCollapsed = false,
}: {
  item: NavItemProps;
  isCollapsed?: boolean;
}) {
  const theme = useTheme();
  const { pathname } = useLocation();
  const {
    id,
    path,
    alternativePath,
    icon,
    endIcon,
    info,
    disabled,
    matchEnd,
    title,
    target,
    onClick,
  } = item;
  // Hacky solution to highlight nav item for discovery items
  let isActiveRoot = false;

  const isRootPath = path === '/';
  const isRootAndPathname = isRootPath && pathname === '/';
  const isPortalBuilderPath = path.includes('portal-builder');
  const isMatchPath = (path: string) =>
    !!matchPath({ path, end: matchEnd }, pathname);

  if (isRootAndPathname) {
    isActiveRoot = true;
  } else if (isRootPath) {
    isActiveRoot = !!alternativePath && isMatchPath(alternativePath);
  } else if (isPortalBuilderPath) {
    isActiveRoot = pathname.includes('portal-builder');
  } else {
    isActiveRoot =
      isMatchPath(path) || (!!alternativePath && isMatchPath(alternativePath));
  }

  if (id === 'tab-chat-support') {
    isActiveRoot = false;
  }

  const { data: totalCount } = useGetProjectLeadCountInLeadMaturityLevelsQuery({
    variables: {
      leadMaturityLevels: ['pending_request'],
      stages: LEAD_STAGES,
    },
    fetchPolicy: 'cache-and-network',
  });

  const totalLeadsCount = totalCount?.projects_aggregate.aggregate?.count ?? 0;

  const { data: newStartupPitchesData } = useGetNewStartupPitchesMinimalQuery({
    fetchPolicy: 'cache-and-network',
    skip: title !== 'CRM',
  });

  const newStartupPitchesCount = newStartupPitchesData
    ? newStartupPitchesData.organization_startup_pitches.length
    : 0;

  const onClickProps = onClick
    ? {
        onClick: (event: Event) => {
          event.preventDefault();
          onClick();
        },
      }
    : {};
  const targetProps = target ? { target } : {};

  const listItem = (
    <ListItemStyle
      disableGutters
      isActive={isActiveRoot}
      theme={theme}
      disabled={!!disabled}
      // @ts-expect-error: TODO: fix this
      component={RouterLink}
      id={id}
      to={path}
      target={target}
      {...onClickProps}
      {...targetProps}
      sx={{
        display: 'flex',
        cursor: 'pointer',
        justifyContent: 'flex-start',
        alignContent: 'center',
        alignItems: 'center',
        gap: 1,
        paddingLeft: isCollapsed ? 2 : 4,
      }}
    >
      <ListItemIconStyle>{icon}</ListItemIconStyle>
      {!isCollapsed && (
        <>
          <Stack direction='row'>
            <ListItemText disableTypography primary={title} />
          </Stack>
          {!isCollapsed && endIcon && endIcon}
          <Box sx={{ marginLeft: 'auto' }}>{info}</Box>
          {title === 'CRM' && newStartupPitchesCount > 0 && (
            <NavItemBadge badgeContent={newStartupPitchesCount} />
          )}
          {title === 'Leads' && totalLeadsCount > 0 && (
            <NavItemBadge badgeContent={totalLeadsCount} />
          )}
        </>
      )}
    </ListItemStyle>
  );

  if (isCollapsed) {
    return (
      <Tooltip key={title} title={title} placement='right'>
        <span>{listItem}</span>
      </Tooltip>
    );
  }

  return listItem;
}

interface NavSectionProps extends BoxProps {
  isCollapsed?: boolean | undefined;
  navConfig: {
    subheader: string;
    items: NavItemProps[];
  }[];
}

export default function NavSection({
  navConfig,
  isCollapsed = false,
  ...other
}: NavSectionProps) {
  return (
    <Box {...other}>
      {navConfig.map(list => {
        const { subheader, items } = list;
        return (
          <List key={subheader} sx={{ marginBottom: 0 }}>
            {!isCollapsed && (
              <ListSubheaderStyle>{subheader}</ListSubheaderStyle>
            )}
            {items
              .filter(i => i.show)
              .map((item: NavItemProps) => (
                <NavItem
                  key={item.title}
                  item={item}
                  isCollapsed={isCollapsed}
                />
              ))}
          </List>
        );
      })}
    </Box>
  );
}
