import { PictureAsPdf } from '@mui/icons-material';
import { CircularProgress, MenuItem, Typography } from '@mui/material';
import { pdf } from '@react-pdf/renderer';
import { captureException } from '@sentry/react';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import { useSnackbar } from 'notistack';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { useCallback, useState } from 'react';
import { downloadFile } from 'utils/downloadFile';
import { BenchmarkPDF } from '../../pdfExport/BenchmarkPDF';
import { LandscapePDF } from '../../pdfExport/LandscapePDF';
import { useGetDataForPdfExportLazyQuery } from 'apollo/generated/sdkShared';
import { StartupListForDetail } from '../../../../../@types/startupList';
import { SourcingOrders } from 'apollo/generated/sdkInnovationManager';
import { CategoryForPDFExport } from '../../pdfExport/types';
import { sortBy } from 'lodash';

export type ProjectForExport = Pick<
  StartupListForDetail,
  | 'id'
  | 'title'
  | 'public_uuid'
  | 'public_description'
  | 'is_longlist_displayed'
  | 'source'
  | 'source'
> & {
  sourcing_order?: Pick<SourcingOrders, 'due_date' | 'delivered_date'> | null;
};

const ExportPDFMenuItem = ({
  currentLandscapeCategoryId,
  currentStartupListId,
}: {
  currentLandscapeCategoryId: number | false;
  currentStartupListId: number;
}) => {
  const [isGenerating, setGenerating] = useState(false);
  const currentOrganization = useCurrentOrganizationFromContext();
  const [fetchData] = useGetDataForPdfExportLazyQuery({
    variables: {
      startupListId: currentStartupListId,
      ...(currentLandscapeCategoryId && {
        categoryId: currentLandscapeCategoryId,
      }),
    },
  });
  const { enqueueSnackbar } = useSnackbar();

  const showFailureMessage = useCallback(() => {
    enqueueSnackbar(
      'Export failed. Please contact support if problem persists.',
      {
        variant: 'error',
      },
    );
  }, [enqueueSnackbar]);

  const generateBenchmarkBlob = useCallback(
    (
      currentStartupList: ProjectForExport,
      currentCategory: CategoryForPDFExport,
    ) => {
      return pdf(
        <BenchmarkPDF
          title={
            currentLandscapeCategoryId
              ? currentCategory.title
              : currentStartupList.title
          }
          description={
            currentLandscapeCategoryId
              ? currentCategory.short_description
              : currentStartupList.public_description
          }
          startupList={currentStartupList}
          organization={currentOrganization}
          category={currentCategory}
        />,
      ).toBlob();
    },
    [currentLandscapeCategoryId, currentOrganization],
  );

  const handleExportPDF = useCallback(async () => {
    try {
      const { data } = await fetchData();

      if (!data) {
        captureException(`Couldn't fetch data for PDF export`);
        showFailureMessage();
        return;
      }

      const currentStartupList = data.startup_lists_by_pk;
      const categories = data.project_categories;

      if (!currentStartupList) {
        captureException(`Couldn't fetch startup list data for PDF export`);
        showFailureMessage();
        return;
      }

      captureAnalyticsEvent('Download presentation PDF', {
        startupListId: currentStartupList.id,
        startupListSource: currentStartupList.source,
        startupListDeliveredByVE: !!currentStartupList.sourcing_order,
        startupListDeliveryDate:
          currentStartupList.sourcing_order?.delivered_date,
        isLandscapeCategory: !!currentLandscapeCategoryId,
      });

      setGenerating(true);

      if (currentLandscapeCategoryId) {
        const currentCategory = categories.find(
          category => category.id === currentLandscapeCategoryId,
        );

        if (!currentCategory) {
          captureException(
            `Couldn't find category with id ${currentLandscapeCategoryId}`,
          );
          showFailureMessage();
          return;
        }

        const blob = await generateBenchmarkBlob(
          currentStartupList,
          currentCategory,
        );
        downloadFile({
          content: blob,
          title: `${currentCategory.title}.pdf`,
          format: 'pdf',
        });
      }

      if (!categories.length) {
        return;
      } else if (categories.length === 1) {
        const blob = await generateBenchmarkBlob(
          currentStartupList,
          categories[0],
        );
        downloadFile({
          content: blob,
          title: `${currentStartupList.title}.pdf`,
          format: 'pdf',
        });
      } else {
        const sortedCategories = sortBy(categories, category => category.rank);
        const blob = await pdf(
          <LandscapePDF
            startupList={currentStartupList}
            organization={currentOrganization}
            categories={sortedCategories}
          />,
        ).toBlob();
        downloadFile({
          content: blob,
          title: `${currentStartupList.title}.pdf`,
          format: 'pdf',
        });
      }
    } catch (e) {
      captureException(e);
      enqueueSnackbar('Export failed', {
        variant: 'error',
      });
    } finally {
      setGenerating(false);
    }
  }, [
    fetchData,
    currentLandscapeCategoryId,
    currentOrganization,
    enqueueSnackbar,
    generateBenchmarkBlob,
    showFailureMessage,
  ]);

  return (
    <>
      <MenuItem
        sx={{ color: 'text.secondary' }}
        onClick={handleExportPDF}
        disabled={isGenerating}
      >
        <PictureAsPdf fontSize='small' sx={{ marginRight: 0.5 }} />
        <Typography variant='body1'>Export PDF</Typography>
        {isGenerating && (
          <CircularProgress
            size={16}
            sx={{
              marginLeft: 1,
            }}
          />
        )}
      </MenuItem>
    </>
  );
};

export { ExportPDFMenuItem };
