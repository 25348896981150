import { Image, Link, Text, View } from '@react-pdf/renderer';
import { FALLBACK_IMAGE } from 'components/AvatarGroup';
import { chunk } from 'lodash';
import { GREY, PRIMARY, SECONDARY } from 'theme/palette';
import {
  buildOrderScoutingFromLandscapeUrl,
  buildProjectPreviewUrl,
} from 'utils/url';
import CustomHTML from './CustomHTML';
import { CategoryForPDFExport } from './types';
import { useTheme } from '@mui/material';

const MAX_VISIBLE_STARTUPS = 5;
const MAX_STARTUPS_PER_CATEGORY_ROW = 3;

export const CategoryCard = ({
  category,
  startupListUUID,
  startupListId,
}: {
  category: CategoryForPDFExport;
  startupListUUID: string;
  startupListId: number;
}) => {
  const visibleCategorizedStartups = category.categorized_suppliers.slice(
    0,
    MAX_VISIBLE_STARTUPS,
  );
  const theme = useTheme();
  const fontWeightMedium = theme.typography.fontWeightMedium as number;

  return (
    <View
      style={{
        height: 400,
        width: 215,
        border: `1px solid ${GREY[300]}`,
        backgroundColor: '#ffffff',
        borderRadius: 12,
        padding: 10,
        marginRight: 25,
      }}
    >
      <Text
        style={{
          fontSize: 14,
          marginBottom: 12,
          height: 35,
        }}
      >
        {category.title}
      </Text>
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: 140,
        }}
      >
        {chunk(visibleCategorizedStartups, MAX_STARTUPS_PER_CATEGORY_ROW).map(
          (categorizedStartupsSlice, startupsRow) => (
            <View
              key={startupsRow}
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: 12,
              }}
            >
              {categorizedStartupsSlice.map(categorizedStartup => (
                <View
                  key={categorizedStartup.id}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    minWidth: 55,
                    maxWidth: 55,
                    marginRight: 10,
                  }}
                >
                  <View
                    style={{
                      height: 24,
                      width: 24,
                      marginBottom: 5,
                      borderRadius: '50%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#ffffff',
                      borderColor: GREY[300],
                      borderWidth: 1.5,
                    }}
                  >
                    <Image
                      source={
                        categorizedStartup.startup.logo_url || FALLBACK_IMAGE
                      }
                      style={{
                        height: 17,
                        width: 17,
                        borderRadius: '50%',
                      }}
                    />
                  </View>

                  <Link
                    style={{
                      textDecoration: 'none',
                      color: SECONDARY.main,
                    }}
                    src={categorizedStartup.startup.website || ''}
                  >
                    <Text style={{ fontSize: 10, height: 30 }}>
                      {categorizedStartup.startup.name}
                    </Text>
                  </Link>
                </View>
              ))}
              {startupsRow === 1 &&
                category.categorized_suppliers.length >
                  MAX_VISIBLE_STARTUPS && (
                  <View
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: 55,
                    }}
                  >
                    <Link
                      style={{
                        color: SECONDARY.main,
                      }}
                      src={buildProjectPreviewUrl(startupListUUID)}
                    >
                      <Text style={{ fontSize: 11 }}>See more</Text>
                    </Link>
                  </View>
                )}
            </View>
          ),
        )}
      </View>
      <View style={{ lineHeight: 1.4, height: 180 }}>
        <Text style={{ fontSize: 10 }}>
          <CustomHTML
            fontSize={10}
            html={`<div>${(category.short_description || '').replace(
              /<p><br><\/p>/g,
              '',
            )}</div>`}
          />
        </Text>
      </View>
      <View
        style={{
          marginTop: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Link
          style={{ textDecoration: 'none', width: 105 }}
          src={buildOrderScoutingFromLandscapeUrl({
            projectId: startupListId,
            categoryId: category.id,
          })}
        >
          <Text
            style={{
              border: `1px solid ${PRIMARY.main}`,
              paddingTop: 5,
              paddingBottom: 5,
              paddingLeft: 8,
              paddingRight: 8,
              borderRadius: 5,
              fontWeight: fontWeightMedium,
              fontSize: 11,
              backgroundColor: PRIMARY.main,
              color: 'white',
            }}
          >
            Request Scouting
          </Text>
        </Link>
        <View style={{ width: '100%' }} />
      </View>
    </View>
  );
};
