import {
  Tooltip,
  TooltipProps,
  Typography,
  TypographyTypeMap,
} from '@mui/material';
import { DefaultComponentProps } from '@mui/material/OverridableComponent';
import { ReactNode, useEffect, useRef, useState } from 'react';

const TypographyWithEllipsis = ({
  TooltipComponent = Tooltip,
  tooltipTitle,
  children,
  numberOfLines = 1,
  placement = 'bottom',
  tooltipComponentProps,
  ...typographyProps
}: {
  TooltipComponent?: (props: TooltipProps) => JSX.Element | null;
  tooltipComponentProps?: TooltipProps['componentsProps'];
  numberOfLines?: number;
  tooltipTitle?: ReactNode;
  children: ReactNode;
  placement?: TooltipProps['placement'];
} & DefaultComponentProps<
  TypographyTypeMap<Record<string, unknown>, 'span'>
>) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (textElementRef.current) {
      setIsOverflow(
        textElementRef.current.scrollWidth >
          textElementRef.current.clientWidth ||
          textElementRef.current.innerText.length > 100 ||
          (numberOfLines === 2 && textElementRef.current.innerText.length > 65),
      );
    }
  }, [textElementRef, numberOfLines]);

  return (
    <TooltipComponent
      title={tooltipTitle || ''}
      disableHoverListener={!isOverflowed}
      placement={placement}
      componentsProps={tooltipComponentProps}
    >
      <Typography
        {...typographyProps}
        noWrap
        ref={textElementRef}
        component='div'
        sx={{
          '&:hover': {
            color: typographyProps.contentEditable
              ? 'secondary.main'
              : 'inherit',
          },
          ...typographyProps.sx,
        }}
      >
        {children}
      </Typography>
    </TooltipComponent>
  );
};

export default TypographyWithEllipsis;
