import { Rocket as RocketIcon } from '@mui/icons-material';
import { MenuItem, Typography } from '@mui/material';
import BaseCopyToClipboard from 'components/base/BaseCopyToClipboard';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import { PATH_ROOT } from 'routes/paths';

const CopyClaimProfileLinkMenuItem = ({
  startupDomain,
}: {
  startupDomain: string;
}) => {
  const currentOrganization = useCurrentOrganizationFromContext();
  const pitchLink = PATH_ROOT.startupPitchFormLink(
    currentOrganization.uuid,
    startupDomain,
  );

  return (
    <BaseCopyToClipboard
      text={pitchLink}
      successMessage='Link copied to clipboard'
    >
      <MenuItem sx={{ color: 'text.secondary' }}>
        <RocketIcon />
        <Typography
          variant='body2'
          sx={{
            marginLeft: 0.5,
          }}
        >
          Pitch form link
        </Typography>
      </MenuItem>
    </BaseCopyToClipboard>
  );
};

export default CopyClaimProfileLinkMenuItem;
