import { Stack, Theme, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { StartupFormState } from './useStartupDetailsFormik';
import Markdown from 'components/Markdown';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  description: {
    '& ul, & ol': { ...theme.typography.body2 },
  },
}));

export const DescriptionField = ({
  fieldName,
}: {
  fieldName: 'long_description' | 'short_description';
  label: string;
  helperText?: string;
}) => {
  const classes = useStyles();
  const { values } = useFormikContext<StartupFormState>();
  const value = values[fieldName];

  return (
    <Stack gap={0.5} pt={2}>
      <Typography color='text.secondary' marginTop={1}>
        Description
      </Typography>
      {value ? (
        <Typography variant='body2' component='div'>
          <Markdown className={classes.description}>{value}</Markdown>
        </Typography>
      ) : (
        'No description'
      )}
    </Stack>
  );
};
