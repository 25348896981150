// ----------------------------------------------------------------------
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
    customL: true;
  }
}

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 800,
    lg: 900,
    customL: 1160,
    xl: 1536,
    xxl: 1836,
  },
};

export default breakpoints;
