import { Box, BoxProps, SxProps } from '@mui/material';
import {
  LazyLoadImage,
  LazyLoadImageProps,
} from 'react-lazy-load-image-component';
import { FALLBACK_IMAGE } from './AvatarGroup';

type ImageRato =
  | '4/3'
  | '3/4'
  | '6/4'
  | '4/6'
  | '16/9'
  | '9/16'
  | '21/9'
  | '9/21'
  | '1/1';

type IProps = BoxProps &
  LazyLoadImageProps & {
    disabledEffect?: boolean;
    imgHeight?: number | string;
  };

interface Props extends IProps {
  sx?: SxProps;
  ratio?: ImageRato;
  fallbackSrc?: string;
}

export default function Image({
  ratio,
  disabledEffect = false,
  effect = 'blur',
  sx,
  // eslint-disable-next-line
  fallbackSrc = FALLBACK_IMAGE,
  imgHeight,
  ...other
}: Props) {
  if (ratio) {
    return (
      <Box
        component='span'
        sx={{
          width: 1,
          lineHeight: 0,
          display: 'block',
          overflow: 'hidden',
          position: 'relative',
          pt: getRatio(ratio),
          '& .wrapper': {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            lineHeight: 0,
            position: 'absolute',
            backgroundSize: 'cover !important',
          },
          ...sx,
        }}
      >
        <ImageWrapper
          disabledEffect={disabledEffect}
          effect={effect}
          imgHeight={imgHeight}
          {...other}
        />
      </Box>
    );
  }

  return (
    <Box
      component='span'
      sx={{
        lineHeight: 0,
        display: 'block',
        overflow: 'hidden',
        '& .wrapper': {
          width: 1,
          height: 1,
          backgroundSize: 'cover !important',
        },
        ...sx,
      }}
    >
      <ImageWrapper
        disabledEffect={disabledEffect}
        effect={effect}
        imgHeight={imgHeight}
        {...other}
      />
    </Box>
  );
}

const ImageWrapper = ({ disabledEffect, effect, ...other }: IProps) => {
  return (
    <Box
      component={LazyImageWithErrorFallback}
      wrapperClassName='wrapper'
      effect={disabledEffect ? undefined : effect}
      placeholderSrc='https://zone-assets-api.vercel.app/assets/img_placeholder.svg'
      sx={{ width: 1, height: 1, objectFit: 'contain' }}
      {...other}
    />
  );
};

const LazyImageWithErrorFallback: React.FC<Props> = ({
  // eslint-disable-next-line
  fallbackSrc = FALLBACK_IMAGE,
  // eslint-disable-next-line
  imgHeight,
  ...props
}) => {
  return (
    <LazyLoadImage
      {...props}
      height={imgHeight}
      onError={({ currentTarget }) => {
        currentTarget.src = fallbackSrc;
        currentTarget.onerror = null;
      }}
    />
  );
};

function getRatio(ratio = '1/1') {
  return {
    '4/3': 'calc(100% / 4 * 3)',
    '3/4': 'calc(100% / 3 * 4)',
    '6/4': 'calc(100% / 6 * 4)',
    '4/6': 'calc(100% / 4 * 6)',
    '16/9': 'calc(100% / 16 * 9)',
    '9/16': 'calc(100% / 9 * 16)',
    '21/9': 'calc(100% / 21 * 9)',
    '9/21': 'calc(100% / 9 * 21)',
    '1/1': '100%',
  }[ratio];
}
