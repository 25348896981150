import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  SxProps,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import DetailsTextField from 'components/dashboard/DetailsTextField';
import useOverviewFormik, {
  StartupListOverviewFormState,
} from 'components/dashboard/startupList/details/useOverviewFormik';
import { useFormikContext } from 'formik';
import { NumericFormat } from 'react-number-format';
import FieldsAccordion from './FieldsAccordion';
import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material';

interface ImpactTypeMap {
  [key: string]: string;
}

export const IMPACT_TYPE: ImpactTypeMap = {
  cost_saving: 'Cost Savings',
  revenue_generating: 'Revenue Generation',
};

export const BUSINESS_CASE = {
  yes: 'Yes',
  in_progress: 'In progress',
  no: 'No',
};

export default function BudgetAndImpactFields() {
  return (
    <FieldsAccordion title='Business Case'>
      <Card sx={{ boxShadow: 'none' }}>
        <CardContent sx={{ padding: 0, borderRadius: 0 }}>
          <Box sx={{ marginBottom: 2 }}>
            <Stack direction='row' alignItems='center' sx={{ marginBottom: 1 }}>
              <Typography
                variant='body1'
                sx={{ fontWeight: 'bold', marginRight: 1 }}
              >
                Budget
              </Typography>
              <Tooltip
                placement='right'
                title='How much budget do you have available for a PoC? How much for an implementation afterwards?'
              >
                <InfoOutlinedIcon fontSize='small' color='disabled' />
              </Tooltip>
            </Stack>
            <Stack marginTop={2} marginBottom={3} direction='row' gap={2}>
              <NumberFormatField
                label='Available budget for PoC*'
                fieldName='pilot_budget'
                dataTestId='impact-and-budget__business_pilot_budget'
              />
              <NumberFormatField
                label='Available budget for implementation'
                fieldName='implementation_budget'
                dataTestId='impact-and-budget__business_implementation_budget'
              />
            </Stack>
            <DetailsTextField
              fullWidth
              fieldName='budget_availability_comment'
              label='Additional comment'
            />
          </Box>
          <Box>
            <Stack direction='row' alignItems='center' sx={{ marginBottom: 1 }}>
              <Typography
                variant='body1'
                sx={{ fontWeight: 'bold', marginRight: 1 }}
              >
                Impact*
              </Typography>
              <Tooltip
                placement='right'
                title='If you implement a solution, will you save cost (time, resources) or generate revenue (e.g. new product/service)?'
              >
                <InfoOutlinedIcon fontSize='small' color='disabled' />
              </Tooltip>
            </Stack>
            <CustomRadioField
              sx={{ flex: 1, marginX: 0.5, marginBottom: 2 }}
              label=''
              fieldName='business_impact_type'
              options={IMPACT_TYPE}
              dataTestId='impact-and-budget__business_impact_type'
              isRow
            />
            <Typography
              variant='body2'
              sx={{ marginLeft: 0.5, marginBottom: 3 }}
            >
              If you implement a solution, please describe all potential use
              cases for it.
            </Typography>
            <DetailsTextField
              sx={{ marginBottom: 2 }}
              fullWidth
              multiline
              fieldName='adoption_use_cases'
              label='Additional use cases'
              dataTestId='impact-and-budget__impact_use_cases'
            />
            <DetailsTextField
              fullWidth
              fieldName='business_case_comment'
              label='Additional comment'
            />
          </Box>
        </CardContent>
      </Card>
    </FieldsAccordion>
  );
}

export const CustomRadioField = ({
  label,
  fieldName,
  options,
  isRow,
  sx,
  dataTestId,
}: {
  label: string;
  fieldName:
    | 'budget_availability'
    | 'business_case'
    | 'business_impact_type'
    | 'adoption_roadmap'
    | 'adoption_resources_available';
  options: Record<string, string>;
  isRow?: boolean;
  sx?: SxProps;
  dataTestId?: string;
}) => {
  const { getFieldProps, setFieldValue } =
    useFormikContext<StartupListOverviewFormState>();

  const { handleOverviewUpdate } = useOverviewFormik(setFieldValue);

  return (
    <FormControl sx={{ ...sx, flex: 1 }}>
      <FormLabel
        component={() => (
          <Typography variant='subtitle1' color='text.disabled'>
            {label}
          </Typography>
        )}
      />
      <RadioGroup
        {...(isRow ? { row: true } : { row: false })}
        {...getFieldProps(fieldName)}
        data-testid={dataTestId}
      >
        {Object.keys(options).map(option => (
          <FormControlLabel
            key={option}
            onClick={() =>
              handleOverviewUpdate({ fieldName, fieldValue: option })
            }
            value={option}
            control={<Radio />}
            label={options[option]}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export const NumberFormatField = ({
  label,
  fieldName,
  dataTestId,
}: {
  label: string;
  fieldName:
    | 'poc_cost'
    | 'business_impact_estimate'
    | 'pilot_budget'
    | 'implementation_budget';
  dataTestId?: string;
}) => {
  const { setFieldValue, values } =
    useFormikContext<StartupListOverviewFormState>();
  const { handleOverviewUpdate } = useOverviewFormik(setFieldValue);

  const handleChange = (value?: number) =>
    handleOverviewUpdate({ fieldName, fieldValue: value });

  return (
    <NumericFormat
      thousandSeparator={','}
      decimalSeparator={'.'}
      prefix={'€ '}
      value={values[fieldName]}
      decimalScale={2}
      label={label}
      name={fieldName}
      fixedDecimalScale={true}
      customInput={TextField}
      onValueChange={({ floatValue }) => handleChange(floatValue)}
      type='text'
      sx={{ flex: 1 }}
      data-testid={dataTestId}
    />
  );
};
