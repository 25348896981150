import { useFindStartupListTagsQuery } from 'apollo/generated/sdkShared';
import BaseServerFilterAutocomplete from 'components/base/serverFilters/BaseServerFilterAutocomplete';
import { useServerFiltersContext } from 'components/base/serverFilters/BaseServerFiltersContext';
import { uniqBy } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';

export const TagsFilter = () => {
  const { filters, setFilters } = useServerFiltersContext('listTableFilters');
  const { data, loading } = useFindStartupListTagsQuery({
    fetchPolicy: 'cache-and-network',
  });
  const options = useMemo(
    () =>
      data?.startup_list_tags.map(s => ({
        value: s.name,
        label: s.name,
      })) || [],
    [data],
  );
  const filterValue = filters?.tags?.startup_list_tags?.tag?.name?._in || [];
  const defaultValue = options?.filter(t => filterValue.includes(t.value));
  const clearFilter = useCallback(() => setFilters('tags', {}), [setFilters]);
  const value =
    uniqBy(
      options.filter(t => filterValue.includes(t.value)),
      ({ value }) => value,
    ) || [];

  useEffect(() => {
    // Clear filter in case we have deleted a team
    if (filterValue.length && !defaultValue?.length) {
      clearFilter();
    }
  }, [clearFilter, defaultValue?.length, filterValue.length]);

  const handleChange = (values: typeof options) => {
    if (!values || values.length === 0) {
      setFilters('tags', {});
      return;
    }

    setFilters('tags', {
      startup_list_tags: {
        tag: { name: { _in: values.map(value => value.value) } },
      },
    });
  };

  return (
    <BaseServerFilterAutocomplete
      value={value}
      defaultValue={defaultValue}
      options={options}
      handleChange={values => handleChange(values)}
      loading={loading}
      placeholder='Tags'
      sxProps={{ minWidth: 80 }}
    />
  );
};
