import { Close, Search } from '@mui/icons-material';
import {
  Box,
  BoxProps,
  CircularProgress,
  Fade,
  IconButton,
  InputAdornment,
  Stack,
  Zoom,
} from '@mui/material';
import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import { alpha, styled } from '@mui/material/styles';
import { useRef, useState } from 'react';
import { Results } from './Results';
import { useSearch } from './useSearch';
import { useSharedPagesContext } from 'layouts/SharedPagesLayout';
import useBreakpoints from 'hooks/useBreakpoints';
import { useNavigate, useParams } from 'react-router';
import { PATH_ROOT } from 'routes/paths';

const SearchStyled = styled(
  // eslint-disable-next-line
  ({ isSharedPage, ...rest }: BoxProps & { isSharedPage: boolean }) => (
    <Box {...rest} />
  ),
)(({ theme: { palette, breakpoints, shape }, isSharedPage }) => ({
  position: 'relative',
  borderRadius: shape.borderRadius,
  backgroundColor: isSharedPage
    ? palette.grey[0]
    : alpha(palette.grey[500], 0.15),
  '&:hover': {
    backgroundColor: isSharedPage
      ? palette.grey[0]
      : alpha(palette.grey[500], 0.25),
  },
  border: isSharedPage ? `1px solid ${palette.grey[400]}` : undefined,
  marginLeft: 0,
  width: '100%',
  [breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(
  ({
    // eslint-disable-next-line
    isSharedPage,
    // eslint-disable-next-line
    isMobile,
    ...rest
  }: InputBaseProps & { isSharedPage: boolean; isMobile: boolean }) => (
    <InputBase {...rest} />
  ),
  {
    shouldForwardProp: props => props !== 'isMobile',
  },
)(({ theme, isSharedPage, isMobile }) => ({
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    height: isSharedPage ? theme.spacing(5) : undefined,
    [theme.breakpoints.up('sm')]: {
      width: isMobile ? '100%' : isSharedPage ? '29ch' : '22ch',
      '&:focus': {
        width: isMobile ? '100%' : isSharedPage ? '35ch' : '28ch',
      },
    },
  },
}));

export const HEADER_SEARCH_ID = 'header-search-input';

function GoTo({
  isEmbedded,
  isDisabled,
}: {
  isEmbedded?: boolean;
  isDisabled?: boolean;
}) {
  const ref = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [query, setQuery] = useState('');
  const navigate = useNavigate();
  const {
    handleSearch,
    loading,
    startupsData,
    projectsData,
    startupListsData,
    discoveryItemsData,
  } = useSearch();
  const { isSharedPage } = useSharedPagesContext();
  const { isBelowSm } = useBreakpoints();
  const showSharedPageStyle = !!(isSharedPage || isEmbedded);
  const { teamSlug } = useParams<{
    teamSlug: string;
  }>();

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (!ref.current) return;

    if (event.key === 'Tab') {
      event.preventDefault();
      ref.current.blur();
    } else if (event.key === 'Escape') {
      ref.current.blur();
    } else if (event.key === 'Enter') {
      if (
        !startupsData?.organization_startups?.length &&
        !projectsData?.projects?.length
      ) {
        if (isSharedPage) {
          navigate(PATH_ROOT.organizationPortal.searchFor(query, teamSlug));
        } else {
          navigate(PATH_ROOT.scout.searchFor(query));
        }
      }
    }
  };

  return (
    <Stack
      direction='row'
      alignItems='center'
      sx={{ ...(isBelowSm && { width: '100%' }) }}
    >
      <SearchStyled isSharedPage={showSharedPageStyle}>
        <SearchIconWrapper>
          <Search sx={({ palette }) => ({ color: palette.grey[500] })} />
        </SearchIconWrapper>
        <StyledInputBase
          isMobile={isBelowSm}
          isSharedPage={showSharedPageStyle}
          key={HEADER_SEARCH_ID}
          id={HEADER_SEARCH_ID}
          value={query}
          role='search'
          disabled={isDisabled}
          onFocus={() => {
            setTimeout(() => {
              setIsFocused(true);
            }, 300);
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
          onChange={e => {
            handleSearch(e);
            setQuery(e.target.value);
          }}
          placeholder={
            showSharedPageStyle
              ? 'Search startups or topics of interest'
              : 'Search'
          }
          inputRef={ref}
          inputProps={{ 'aria-label': 'search', onKeyDown: handleKeyDown }}
          endAdornment={
            !loading ? (
              <Fade in={!!query} mountOnEnter unmountOnExit>
                <InputAdornment position='end'>
                  <IconButton
                    size='small'
                    onClick={() => setQuery('')}
                    sx={{ marginRight: 1 }}
                  >
                    <Close fontSize='small' />
                  </IconButton>
                </InputAdornment>
              </Fade>
            ) : (
              <Zoom in={loading}>
                <CircularProgress sx={{ marginX: 1 }} size={24} />
              </Zoom>
            )
          }
        />
      </SearchStyled>
      <Results
        query={query}
        startups={startupsData?.organization_startups || []}
        projects={projectsData?.projects || []}
        startupLists={startupListsData?.startup_lists || []}
        discoveryItems={discoveryItemsData?.startup_lists || []}
        anchorEl={ref.current}
        open={isFocused && !loading && !!query}
        onClose={() => {
          setIsFocused(false);
        }}
        onKeyDown={handleKeyDown}
      />
    </Stack>
  );
}

export { GoTo };
