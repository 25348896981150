import { Tab, Tabs } from '@mui/material';

export type TabsType = 'link' | 'email';

const ShareModalTabs = ({
  currentTabValue,
  onClick,
}: {
  currentTabValue: TabsType;
  onClick: (tab: TabsType) => void;
}) => (
  <Tabs value={currentTabValue} sx={{ marginBottom: 2 }}>
    <Tab
      onClick={() => {
        onClick('email');
      }}
      value='email'
      label='Send invite'
    />
    <Tab
      onClick={() => {
        onClick('link');
      }}
      label='Create link'
      value='link'
    />
  </Tabs>
);

export { ShareModalTabs };
