import { Box, Card, Stack, SxProps, Typography, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { BORDER_COLOR } from 'theme/palette';

type OptionCardProps = {
  title: ReactNode | string;
  content: ReactNode | string;
  disabled?: boolean;
  onClick: (e: React.MouseEvent) => void;
  children?: ReactNode;
  border?: string;
  boxShadow?: string;
  error?: boolean;
  sxProps?: SxProps;
};

export default function OptionCard({
  title,
  content,
  disabled,
  border,
  boxShadow,
  onClick,
  children,
  error,
  sxProps,
}: OptionCardProps) {
  const theme = useTheme();
  return (
    <Card
      variant='outlined'
      onClick={onClick}
      sx={{
        border: border ? border : 'none',
        padding: '7px 5px 7px 12px',
        borderRadius: '8px',
        boxShadow: boxShadow ? boxShadow : 'none',
        width: '100%',
        cursor: 'pointer',
        ...(disabled ? { opacity: 0.3, pointerEvents: 'none' } : {}),
        borderColor: error ? theme.palette.error.main : BORDER_COLOR,
        ...sxProps,
      }}
    >
      <Stack direction='row' spacing={0} sx={{ width: '100%' }}>
        <Box>{children}</Box>
        <Box sx={{ width: '100%' }}>
          <Typography variant='subtitle1'>{title}</Typography>
          {typeof content === 'string' ? (
            <Typography variant='body2' color='text.secondary'>
              {content}
            </Typography>
          ) : (
            content
          )}
        </Box>
      </Stack>
    </Card>
  );
}
