import { useFilesWithSignedUrlsQuery } from 'apollo/generated/sdkShared';
import { usePersonContext } from 'contexts/PersonContext';
import { useMemo } from 'react';

export const useGetCurrentUserTeamAvatarUrl = () => {
  const { person } = usePersonContext();
  const fileId = person?.team?.logo_file_id;
  const { data } = useFilesWithSignedUrlsQuery({
    variables: { files_ids: fileId ? [fileId] : [] },
    fetchPolicy: 'cache-and-network',
  });

  return useMemo(() => {
    return data?.files_with_signed_urls?.data[0]?.signed_url || null;
  }, [data]);
};
