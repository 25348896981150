import { Info } from '@mui/icons-material';
import DoneAll from '@mui/icons-material/DoneAll';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { stripScheme } from 'utils/url';
import { DescriptionField } from './DescriptionField';
import { FirstPartyDataFields } from './FirstPartyDataFields';
import { InvestorsField } from './InvestorsField';
import { ReferencedCustomersField } from './ReferencedCustomersField';
import { StartupForSidePanel } from './StartupInfoSidePanel';
import { StartupTagsField } from './StartupTagsField';
import { StartupDetailsFormik } from './useStartupDetailsFormik';
import SimilarStartups from './SimilarStartups';

export function StartupInfoSidePanelGeneral({
  formik,
  hasFirstPartyData,
  isApprovedAndNotOverridden,
  dateApproved,
  currentStartup,
}: {
  formik: StartupDetailsFormik;
  isApprovedAndNotOverridden: boolean;
  hasFirstPartyData: boolean;
  dateApproved?: string;
  currentStartup: StartupForSidePanel;
}) {
  const theme = useTheme();
  const showFirstPartyFields = hasFirstPartyData || isApprovedAndNotOverridden;

  const cachedScreenshotURL = formik.values.cached_website_images.length
    ? formik.values.cached_website_images[0].image_url
    : undefined;

  return (
    <Stack spacing={2}>
      {showFirstPartyFields && (
        <Stack
          justifyContent='space-between'
          flexDirection='row'
          alignItems='center'
        >
          <Typography variant='h6'>General Information</Typography>
          {isApprovedAndNotOverridden && (
            <Stack flexDirection='row' margin='10px'>
              <DoneAll
                sx={{
                  width: theme.spacing(3),
                  height: theme.spacing(3),
                  color: theme.palette.primary.main,
                }}
              />
              <Stack
                flexDirection='column'
                marginLeft={0.5}
                sx={{ color: theme.palette.primary.main }}
              >
                <Typography variant='subtitle2'>
                  Data validated by startup
                </Typography>
                <Typography variant='caption'>
                  on {new Date(dateApproved!).toLocaleDateString()}
                </Typography>
              </Stack>
              <Tooltip title='As part of your GlassDollar membership, you get access to first-party data, that has been validated by the start-up concerned, on the date indicated. GlassDollar cannot be deemed liable for the accuracy of the data validated by the start-up.'>
                <span>
                  <IconButton sx={{ top: '-5px' }}>
                    <Info fontSize='small' />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
          )}
        </Stack>
      )}
      <DescriptionField fieldName='long_description' label='Description' />
      {showFirstPartyFields && <StartupTagsField />}
      {!showFirstPartyFields && <ReferencedCustomersField label='Customers' />}
      <InvestorsField label='Investors' />
      {showFirstPartyFields && <FirstPartyDataFields />}
      <SimilarStartups startup={currentStartup} />
      {cachedScreenshotURL && (
        <Box paddingTop={2}>
          <WebsitePreview
            cachedScreenshotURL={cachedScreenshotURL}
            websiteURL={`https://${stripScheme(currentStartup.website || '')}`}
          />
        </Box>
      )}
    </Stack>
  );
}

const WebsitePreview = ({
  cachedScreenshotURL,
  websiteURL,
}: {
  cachedScreenshotURL: string;
  websiteURL?: string;
}) => {
  return (
    <Box paddingTop={4} display='inline-block'>
      <Card sx={{ position: 'relative' }}>
        <CardActionArea onClick={() => window.open(websiteURL, '_blank')}>
          <CardMedia
            component='img'
            image={cachedScreenshotURL}
            alt='Company Website'
          />
          <CardContent
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              opacity: 0,
              '&:hover': {
                opacity: 1,
              },
            }}
          >
            <Box
              sx={theme => ({
                minWidth: '64px',
                padding: '6px 16px',
                borderRadius: '8px',
                backgroundColor: theme.palette.primary.main,
                color: 'white',
                fontWeight: 600,
              })}
            >
              Visit website
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );
};
