import { AddOutlined } from '@mui/icons-material';
import { Box, Button, Stack, Typography } from '@mui/material';
import { FALLBACK_SUPPLIER_LOGO_URL, LogoAvatar } from 'components/AvatarGroup';
import Markdown from 'components/Markdown';
import { UploadScopeFile } from 'pages/request-form/useScopeFiles';
import { useMemo } from 'react';
import { KnownStartup } from '../../../@types/startupList';
import { StartupListFeatureBox } from '../startupList/StartupListFeatureBox';
import { ProblemScope } from './types';

export const AddFeatureButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button
      sx={({ spacing }) => ({ height: spacing(3.5) })}
      variant='text'
      startIcon={<AddOutlined />}
      onClick={onClick}
    >
      Add
    </Button>
  );
};

const KnownSolutions = ({
  knownSolutions,
}: {
  knownSolutions: KnownStartup[];
}) => {
  return (
    <Stack direction='row' gap={1.5} flexWrap='wrap'>
      {knownSolutions.map((value: KnownStartup) => {
        return (
          <Stack
            alignItems='center'
            direction='row'
            gap={0.5}
            key={value.domain}
          >
            <LogoAvatar
              src={value.logo || FALLBACK_SUPPLIER_LOGO_URL}
              size={20}
            />
            <Typography variant='caption'>{value.name}</Typography>
          </Stack>
        );
      })}
    </Stack>
  );
};

export const SourcingOrderScopeContent = ({
  problemScope,
}: {
  problemScope?: ProblemScope;
}) => {
  const features = useMemo(
    () => [
      ...(problemScope?.must_have_features || []).map(x => {
        return {
          mustHave: true,
          name: x,
        };
      }),
      ...(problemScope?.nice_to_have_features || []).map(y => {
        return {
          mustHave: false,
          name: y,
        };
      }),
    ],
    [problemScope?.must_have_features, problemScope?.nice_to_have_features],
  );

  const showLastCardIfAtLeastOneFieldExists =
    problemScope?.desired_solution ||
    (problemScope?.known_startups && problemScope?.known_startups.length > 0) ||
    features.length > 0;

  return (
    <>
      {problemScope?.problem && (
        <Box marginBottom={1.5}>
          <Box marginBottom={1}>
            <Typography
              gutterBottom
              variant='body2'
              fontWeight={600}
              lineHeight={1.3}
            >
              Need / Pain Point
            </Typography>
          </Box>
          <Typography variant='body2' whiteSpace='pre-line' component={'div'}>
            <Markdown>{problemScope?.problem}</Markdown>
          </Typography>
        </Box>
      )}
      {showLastCardIfAtLeastOneFieldExists && (
        <Box>
          {problemScope?.desired_solution && (
            <Box marginBottom={1.5}>
              <Typography gutterBottom variant='body2' fontWeight={600}>
                Desired Solution
              </Typography>
              <Typography
                variant='body2'
                whiteSpace='pre-line'
                component={'div'}
              >
                <Markdown>{problemScope?.desired_solution || ''}</Markdown>
              </Typography>
            </Box>
          )}
          {problemScope?.known_startups &&
            problemScope?.known_startups.length > 0 && (
              <Box marginBottom={1.5}>
                <Typography gutterBottom variant='body2' fontWeight={600}>
                  Known solutions
                </Typography>
                <KnownSolutions knownSolutions={problemScope.known_startups} />
              </Box>
            )}
          {features.length > 0 && (
            <Box marginBottom={1.5}>
              <Typography gutterBottom variant='body2' fontWeight={600}>
                Features & requirements
              </Typography>
              <Stack direction='row' flexWrap='wrap' gap={1}>
                {features.map((feature, index) => (
                  <Box key={`${feature.name}-${index}`}>
                    <StartupListFeatureBox
                      editModeEnabled={false}
                      featureText={feature.name}
                    />
                  </Box>
                ))}
              </Stack>
            </Box>
          )}
        </Box>
      )}
      {problemScope?.additional_info && (
        <Box marginBottom={1.5}>
          <Typography gutterBottom variant='body2' fontWeight={600}>
            Additional Information
          </Typography>
          <Typography
            sx={{ lineHeight: '24px' }}
            variant='body2'
            whiteSpace='pre-line'
            component={'div'}
          >
            <Markdown>{problemScope?.additional_info}</Markdown>
          </Typography>
        </Box>
      )}
      {problemScope?.files && problemScope.files.length > 0 && (
        <Box marginBottom={1.5}>
          <Typography gutterBottom variant='body2' fontWeight={600}>
            File(s)
          </Typography>
          <UploadScopeFile
            setFieldValue={() => {}}
            problemScopeId={problemScope.id}
            isReadOnly
          />
        </Box>
      )}
    </>
  );
};

SourcingOrderScopeContent.displayName = 'SourcingOrderDrawerContent';
