import { CustomDimensionCellJSON } from '../@types/shared';
import { useGetStartupListAndUsersQuery } from 'apollo/generated/sdkInnovationManager';
import { CategorizedSuppliers, Suppliers } from 'apollo/generated/sdkShared';
import { flatMap } from 'lodash';
import { useMemo } from 'react';

export type CategorizedStartupForProjectAndStartups = Pick<
  CategorizedSuppliers,
  | 'id'
  | 'matrix_table_score'
  | 'all_startups_table_score'
  | 'is_shortlisted'
  | 'is_selected'
  | 'is_contacted'
  | 'is_recommended'
  | 'is_in_matrix'
  | 'is_archived'
> & {
  startup: Pick<Suppliers, 'id' | 'domain' | 'name' | 'logo_url'>;
  custom_dimensions_json_v1: Record<string, CustomDimensionCellJSON>;
  voters: string[];
};

export const useGetStartupList = ({
  startupListId,
  skipGetStartupListAndUsers,
  publicUuid,
}: {
  startupListId: number;
  skipGetStartupListAndUsers?: boolean;
  publicUuid?: string;
}) => {
  const {
    error: startupListError,
    data: startupListData,
    called: projectCalled,
    loading,
    previousData: startupListPreviousData,
    refetch,
  } = useGetStartupListAndUsersQuery({
    variables: {
      ...(publicUuid
        ? { uuid: publicUuid, startupListId: -1 }
        : { startupListId, uuid: '' }),
    },
    skip: skipGetStartupListAndUsers,
  });

  const startupList = useMemo(
    () =>
      startupListData?.startup_list[0] ||
      startupListPreviousData?.startup_list[0],
    [startupListData, startupListPreviousData],
  );

  const categories = useMemo(
    () => startupList?.project_categories || [],
    [startupList],
  );

  const categorizedStartups: CategorizedStartupForProjectAndStartups[] =
    useMemo(
      () =>
        flatMap(
          categories.filter(c => !c.is_hidden),
          c => c.categorized_suppliers,
        ).filter(c => !c.is_archived),
      [categories],
    );

  const shortlistedStartups: CategorizedStartupForProjectAndStartups[] =
    useMemo(
      () =>
        categorizedStartups
          .filter(cs => cs.is_shortlisted)
          .sort((a, b) => a.id - b.id) || [],
      [categorizedStartups],
    );

  const startupsInMatrixAndNotShortlisted: CategorizedStartupForProjectAndStartups[] =
    useMemo(
      () =>
        categorizedStartups.filter(cs => cs.is_in_matrix && !cs.is_shortlisted),
      [categorizedStartups],
    );

  const called = projectCalled;

  return {
    startupList,
    categories,
    categorizedStartups,
    shortlistedStartups,
    startupsInMatrixAndNotShortlisted,
    loading,
    called,
    error: !!startupListError,
    errorData: startupListError,
    refetch,
  };
};
