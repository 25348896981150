import { Box, SxProps } from '@mui/material';

type Props = {
  size?: number;
  sxProps?: SxProps;
};

const GDLogo = ({ size, sxProps }: Props) => (
  <Box
    component='img'
    sx={sxProps}
    width={size}
    height={size}
    alt='GlassDollar Logo'
    src={`/favicon/glass-dollar-logo.png`}
  />
);

export { GDLogo };
