import { Button, Link } from '@mui/material';
import { captureException } from '@sentry/react';
import { useUpdateStartupListMutation } from 'apollo/generated/sdkInnovationManager';
import { useAddStartupListActivityLogsMutation } from 'apollo/generated/sdkShared';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import { buildAdminPlatformDomain } from 'utils/url';

type StartupListActivityLog = {
  action: string;
  entityIds: number | number[];
  entityType: string;
};

export const useUpdateStartupListActivities = () => {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [updateStartupListMutation] = useUpdateStartupListMutation();
  const [addStartupListActivityLog] = useAddStartupListActivityLogsMutation();

  const logStartupListActivity = async ({
    logs,
    startupListId,
  }: {
    logs: StartupListActivityLog[];
    startupListId: number;
  }) => {
    try {
      const updatedLogs = logs.map(log => {
        return {
          action: log.action,
          startup_list_id: startupListId,
          entity_ids:
            typeof log.entityIds === 'number' ? [log.entityIds] : log.entityIds,
          user_id: user.id,
          entity_type: log.entityType,
        };
      });

      await addStartupListActivityLog({
        variables: {
          objects: updatedLogs,
        },
        update(cache, result) {
          const currentCreatedAtTimeStamp =
            result.data?.insert_startup_lists_activity_logs?.returning[0]
              .created_at;

          const listId = cache.identify({
            __typename: 'startup_lists',
            id: startupListId,
          });

          if (!currentCreatedAtTimeStamp || !listId) return;

          cache.modify({
            id: listId,
            fields: {
              latest_activity_created_at() {
                return currentCreatedAtTimeStamp;
              },
            },
          });
        },
      });
    } catch (error) {
      captureException(error);
    }
  };

  const convertListToDiscoveryItem = async (startupListId: number) => {
    const updateListSourceResponse = await updateStartupListMutation({
      variables: {
        id: startupListId,
        payload: {
          source: 'discovery_section',
        },
      },
    });
    if (updateListSourceResponse.data?.update_startup_lists_by_pk) {
      await logStartupListActivity({
        logs: [
          {
            action: 'updated',
            entityIds: [startupListId],
            entityType: 'startup_lists',
          },
        ],
        startupListId,
      });

      enqueueSnackbar('List converted to Discovery Item!', {
        variant: 'success',
        action: (
          <Button
            component={Link}
            href={`${buildAdminPlatformDomain()}/discovery-items`}
            target='_blank'
          >
            Navigate to Startups Admin
          </Button>
        ),
      });
    }
  };

  return {
    logStartupListActivity,
    convertListToDiscoveryItem,
  };
};
