import {
  format,
  differenceInDays,
  isToday,
  formatDistance,
  parse,
} from 'date-fns';

// ----------------------------------------------------------------------

export function formatApiDate(date: string | number | Date) {
  return format(new Date(date), 'yyyy-MM-dd');
}

export function fDate(date: string | number | Date) {
  return format(new Date(date), 'dd/MM/yyyy');
}

export function fDateTime(date: string | number | Date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fDayMonthDate(date: string) {
  const parsedDate = parse(date, 'dd/MM', new Date());
  return format(parsedDate, 'MMMM d');
}

export function isWeekendOrMonday(date: Date) {
  return date.getDay() === 0 || date.getDay() === 6 || date.getDay() === 1;
}

export function isChristmasBreak(date: Date) {
  return (
    (date.getMonth() === 11 && date.getDate() >= 19) ||
    (date.getMonth() === 0 && date.getDate() <= 11)
  );
}

export function differenceInDaysHumanized(fromDate: Date, toDate: Date) {
  const fromDateBeginningOfTheDay = new Date(fromDate.getTime());
  fromDateBeginningOfTheDay.setHours(0, 0, 0, 0);
  const toDateBeginningOfTheDay = toDate;
  toDateBeginningOfTheDay.setHours(0, 0, 0, 0);

  const dayDifference = differenceInDays(
    toDateBeginningOfTheDay,
    fromDateBeginningOfTheDay,
  );

  let dayDifferenceHumanized;
  if (dayDifference === 0) {
    dayDifferenceHumanized = 'Today';
  } else if (dayDifference === 1) {
    dayDifferenceHumanized = '1 day';
  } else {
    dayDifferenceHumanized = `${dayDifference} days`;
  }

  return {
    dayDifference,
    dayDifferenceHumanized,
  };
}

export const getMonthDiff = (d1: Date, d2: Date) => {
  let months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth() + 1;
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
};

const PUBLIC_HOLIDAYS = [
  new Date('1 Jan'), // New Year's DaySat
  new Date('8 Mar'), // International Women's DayTue
  // TODO: change every year
  new Date('7 Apr'), // Good Friday, Fri
  // TODO: add Easter, change every year
  new Date('1 May'), // Labour Day, Sun
  //TODO: change every year
  new Date('18 May'), // Ascension Day, Thu
  //TODO: change every year
  new Date('29 May'), // Whit Monday, Mon
  new Date('3 Oct'), // German Unity Day, Mon
  new Date('25 Dec'), // Christmas Day, Sun
  new Date('26 Dec'), // 2nd Day of Christmas, Mon
];

export const isPublicHoliday = (date: Date) => {
  return !!PUBLIC_HOLIDAYS.find(
    holiday =>
      holiday.getDate() === date.getDate() &&
      holiday.getMonth() === date.getMonth(),
  );
};

export const getWeek = (date: Date) => {
  const firstJan = new Date(date.getFullYear(), 0, 1);
  const millisecsInDay = 86400000;
  return Math.ceil(
    ((date.valueOf() - firstJan.valueOf()) / millisecsInDay +
      firstJan.getDay() +
      1) /
      7,
  );
};

export const getPastYearsInMilliseconds = (years: number) =>
  new Date().setFullYear(new Date().getFullYear() - years);

export const formatDate = (date: string) =>
  isToday(new Date(date))
    ? 'Today'
    : formatDistance(new Date(date), new Date(), {
        addSuffix: true,
      });
