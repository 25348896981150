import ReactMarkdown, { Options } from 'react-markdown';
// markdown plugins
import rehypeRaw from 'rehype-raw';
// @mui
import { styled } from '@mui/material/styles';
import { Divider, Link, Typography } from '@mui/material';
//
import Image from '../components/Image';
import { forwardRef } from 'react';

// ----------------------------------------------------------------------

const MarkdownStyle = styled('div')(({
  theme: { spacing, shape, palette, typography, breakpoints },
}) => {
  const isLight = palette.mode === 'light';

  return {
    '& .ql-align-center': {
      textAlign: 'center',
    },
    '& .ql-video.ql-align-center': {
      // TODO: Don't hardcode but include it in the some config/theme file
      display: 'block',
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      border: 'none',
      padding: 0,
      margin: 0,
      overflow: 'hidden',
    },
    // List
    '& ul, & ol': {
      ...typography.body2,
      paddingLeft: spacing(5),
      '& li': {
        lineHeight: 2,
      },
    },

    // Blockquote
    '& blockquote': {
      lineHeight: 1.5,
      fontSize: '1.5em',
      margin: '40px auto',
      position: 'relative',
      fontFamily: 'Georgia, serif',
      padding: spacing(3, 3, 3, 8),
      borderRadius: Number(shape.borderRadius) * 2,
      backgroundColor: palette.background.neutral,
      color: `${palette.text.secondary} !important`,
      [breakpoints.up('md')]: {
        width: '80%',
      },
      '& p, & span': {
        marginBottom: '0 !important',
        fontSize: 'inherit !important',
        fontFamily: 'Georgia, serif !important',
        color: `${palette.text.secondary} !important`,
      },
      '&:before': {
        left: spacing(2),
        top: `-${spacing(1)}`,
        display: 'block',
        fontSize: '3em',
        content: '"\\201C"',
        position: 'absolute',
        color: palette.text.disabled,
      },
    },

    // Code Block
    '& pre, & pre > code': {
      fontSize: 16,
      overflowX: 'auto',
      whiteSpace: 'pre',
      padding: spacing(2),
      color: palette.common.white,
      borderRadius: shape.borderRadius,
      backgroundColor: isLight ? palette.grey[900] : palette.grey[500_16],
    },
    '& code': {
      fontSize: 14,
      borderRadius: 4,
      whiteSpace: 'pre',
      padding: spacing(0.2, 0.5),
      color: palette.warning[isLight ? 'darker' : 'lighter'],
      backgroundColor: palette.warning[isLight ? 'lighter' : 'darker'],
    },
    '& a': {
      color: palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  };
});

// ----------------------------------------------------------------------

const Markdown = forwardRef<HTMLDivElement, Options>((props, ref) => {
  return (
    <MarkdownStyle ref={ref}>
      <ReactMarkdown
        rehypePlugins={[rehypeRaw]}
        components={components}
        {...props}
      />
    </MarkdownStyle>
  );
});

Markdown.displayName = 'Markdown';

export default Markdown;

// ----------------------------------------------------------------------

const components = {
  h1: ({ ...props }) => (
    <Typography variant='body2' fontWeight={700} {...props} />
  ),
  h2: ({ ...props }) => (
    <Typography variant='body2' fontWeight={600} {...props} />
  ),
  h3: ({ ...props }) => (
    <Typography variant='body2' fontWeight={500} {...props} />
  ),
  h4: ({ ...props }) => (
    <Typography variant='body2' fontWeight={400} {...props} />
  ),
  h5: ({ ...props }) => (
    <Typography variant='body2' fontWeight={300} {...props} />
  ),
  h6: ({ ...props }) => (
    <Typography variant='body2' fontWeight={200} {...props} />
  ),
  hr: ({ ...props }) => <Divider sx={{ my: 3 }} {...props} />,
  img: ({ ...props }) => (
    <Image
      alt={props.alt}
      ratio='16/9'
      sx={{ borderRadius: 2, my: 5 }}
      {...props}
    />
  ),
  a: ({ ...props }) => {
    const { href } = props;
    return !href.includes('http') ? (
      <Link {...props} />
    ) : (
      <Link target='_blank' rel='noopener' {...props} />
    );
  },
};
