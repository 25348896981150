import { Page, Text, View, Link, Image } from '@react-pdf/renderer';
import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  DataTableCell,
} from '@david.kucsai/react-pdf-table';
import { buildProjectPreviewUrl } from 'utils/url';
import { CustomFeatureHeader } from '../../../../@types/shared';
import { styles, tableCellStyle } from './styles';
import { truncateWithEllipsis } from 'utils/general';
import { removeBorders } from './utils';
import { headerCellStyle } from './styles';
import { Pager } from './Pager';
import { FALLBACK_IMAGE } from 'components/AvatarGroup';
import { OrderedCategorizedStartup } from './types';
import { TriboolFeature } from './components/TriboolFeature';
import { TextFeatureValue } from './components/TextFeatureValue';
import { NumberFeatureValue } from './components/NumberFeatureValue';

export const ComparisonMatrixPageGroup = ({
  startupList,
  startups,
  customColumnsSchema,
}: {
  startupList: { public_uuid: string };
  startups: OrderedCategorizedStartup[];
  customColumnsSchema: CustomFeatureHeader[];
}) => {
  const customFeatures = customColumnsSchema;

  const triboolCustomFeatures = customFeatures.filter(
    f => f.type === 'tribool',
  );
  const textCustomFeatures = customFeatures.filter(f => f.type === 'text');

  if (customFeatures.length === 0) return null;

  return (
    <Page size='A4' orientation='landscape' style={styles.contentPage}>
      <Pager />
      <View>
        <View
          fixed
          style={{
            ...styles.pageTitle,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Text>{startups.length} Most Relevant Startups: Comparison</Text>
          {/* TODO: generate default share link and use it instead of the public preview */}
          <Link src={buildProjectPreviewUrl(startupList.public_uuid)}>
            <Text
              style={{
                // TODO: color from the theme doesn't work
                border: `1px solid #00AB55`,
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 15,
                paddingRight: 15,
                borderRadius: 5,
                fontSize: 14,
                backgroundColor: '#00AB55',
                color: 'white',
                textDecoration: 'none',
              }}
            >
              Select favorite startup(s)
            </Text>
          </Link>
        </View>
        {triboolCustomFeatures.length && (
          <View wrap={false} style={{ height: '100%', marginBottom: 24 }}>
            <ComparisonMatrix
              startups={startups}
              customFeatures={triboolCustomFeatures}
            />
          </View>
        )}
        {textCustomFeatures.map(customFeature => (
          <View
            wrap={false}
            style={{ height: '100%' }}
            key={customFeature.name}
          >
            <ComparisonMatrix
              startups={startups}
              customFeatures={[customFeature]}
            />
          </View>
        ))}
      </View>
    </Page>
  );
};

const ComparisonMatrix = ({
  startups,
  customFeatures,
}: {
  startups: OrderedCategorizedStartup[];
  customFeatures: CustomFeatureHeader[];
}) => {
  return (
    <Table data={startups}>
      <TableHeader {...removeBorders}>
        <TableCell style={{ ...headerCellStyle, maxWidth: 160, minWidth: 160 }}>
          Position & Company
        </TableCell>

        {customFeatures.map(customFeature => {
          const colWeighting = customFeature.type === 'tribool' ? 0.1 : 0.3;
          return (
            <TableCell
              key={customFeature.name}
              weighting={colWeighting}
              style={{ ...headerCellStyle, maxHeight: 100 }}
            >
              {customFeature.name}
            </TableCell>
          );
        })}
      </TableHeader>
      <TableBody {...removeBorders}>
        <DataTableCell
          style={{
            ...tableCellStyle,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            overflow: 'hidden',
            minWidth: 160,
            maxWidth: 160,
          }}
          getContent={(categorizedStartup: OrderedCategorizedStartup) => {
            return (
              <>
                <Text>{categorizedStartup.order}</Text>
                <Image
                  source={categorizedStartup.startup.logo_url || FALLBACK_IMAGE}
                  style={{ width: 20, marginLeft: 5 }}
                />
                <Text style={{ marginLeft: 5, minWidth: 110, maxWidth: 110 }}>
                  {truncateWithEllipsis(categorizedStartup.startup.name, 30)}
                </Text>
              </>
            );
          }}
        />
        {customFeatures.map(customFeature => {
          const colWeighting = customFeature.type === 'tribool' ? 0.1 : 0.3;
          return (
            <DataTableCell
              key={customFeature.name}
              weighting={colWeighting}
              style={tableCellStyle}
              getContent={(startup: OrderedCategorizedStartup) => {
                const data = startup.custom_dimensions_json_v1[
                  customFeature.name
                ] || { value: 'unknown' };

                const props = { value: data.value };

                if (customFeature.type === 'tribool') {
                  return <TriboolFeature {...props} />;
                } else if (customFeature.type === 'number') {
                  return <NumberFeatureValue value={data.value as number} />;
                } else {
                  return <TextFeatureValue value={data.value as string} />;
                }
              }}
            />
          );
        })}
      </TableBody>
    </Table>
  );
};
