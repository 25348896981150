import { Link, SxProps, Typography, useTheme } from '@mui/material';
import useAlternativeNavigate from 'hooks/useAlternativeNavigate';
import { useSharedPagesContext } from 'layouts/SharedPagesLayout';
import { PATH_ROOT } from 'routes/paths';
import { pxToRem } from 'theme/typography';

const LinkToPerson = ({
  id,
  fullName,
  sxProps = {},
}: {
  id: number;
  fullName: string;
  sxProps?: SxProps;
}) => {
  const { navigateTo } = useAlternativeNavigate();
  const { isSharedPage } = useSharedPagesContext();
  const { palette } = useTheme();

  return (
    <Link
      onClick={e => {
        e.stopPropagation();
        navigateTo(PATH_ROOT.personProfile.details(id));
      }}
      sx={{ '&:hover': { textDecoration: 'none' }, ...sxProps }}
    >
      <Typography
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          color: palette.text.primary,
          cursor: isSharedPage ? 'default' : 'pointer',
          '&:hover': {
            textDecoration: 'none',
            color: palette.secondary.main,
          },
        }}
        fontWeight={500}
        fontSize={pxToRem(14)}
      >
        {fullName}
      </Typography>
    </Link>
  );
};

export default LinkToPerson;
