import React from 'react';
import {
  Tooltip,
  Box,
  IconButton,
  Stack,
  Typography,
  Link,
} from '@mui/material';
import Close from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { fileUploadedPreview } from 'utils/getFileFormat';
import { getFileExtension } from 'utils/general';

// Updated interface for a single document
type Document = {
  name: string;
  url: string;
};

type FilePreviewProps = {
  document: Document; // Now expects a single Document object
  readonly?: boolean;
  setDocumentToBeDeleted?: (document: Document) => void;
};

const FilePreview = ({
  document,
  readonly = false,
  setDocumentToBeDeleted,
}: FilePreviewProps) => {
  const theme = useTheme();
  const { thumb } = fileUploadedPreview(document.name);

  return (
    <Tooltip title={document.name} key={document.url}>
      <Box sx={{ display: 'inline-block', marginRight: 2, width: '100px' }}>
        <Box sx={{ position: 'relative' }}>
          {!readonly && (
            <IconButton
              size='small'
              onClick={
                setDocumentToBeDeleted &&
                (() => setDocumentToBeDeleted(document))
              }
              sx={{
                background: theme.palette.error.main,
                position: 'absolute',
                left: '65%',
                width: 18,
                height: 18,
              }}
            >
              <Close
                fontSize='small'
                sx={{
                  zIndex: theme.zIndex.tooltip,
                  color: theme.palette.common.white,
                  width: 10,
                  height: 10,
                }}
              />
            </IconButton>
          )}
          <Stack alignItems='center'>
            <Link target='_blank' href={document.url}>
              <Box
                component='img'
                src={thumb}
                sx={{
                  width: 50,
                  height: 60,
                }}
              />
              <Typography
                textAlign='center'
                variant='caption'
                color={theme.palette.text.secondary}
              >
                {document.name.slice(0, 5) +
                  '...' +
                  getFileExtension(document.name)}
              </Typography>
            </Link>
          </Stack>
        </Box>
      </Box>
    </Tooltip>
  );
};

export default FilePreview;
