import { useApolloClient } from '@apollo/client';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { captureException } from '@sentry/react';
import {
  GetChallengeDetailsDocument,
  GetListsForAddToProjectModalQuery,
  useGetListsForAddToProjectModalQuery,
  useLinkElementToChallengeMutation,
} from 'apollo/generated/sdkShared';
import BaseErrorMessage from 'components/base/BaseErrorMessage';
import { useServerFiltersContext } from 'components/base/serverFilters/BaseServerFiltersContext';
import { useSnackbar } from 'notistack';
import { Fragment, HTMLAttributes, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { PATH_ROOT } from 'routes/paths';
import { ChallengeConnectedStartupListType } from '../../types';

type List = GetListsForAddToProjectModalQuery['startup_lists'][number];

const RenderOption = ({
  htmlProps,
  option,
  connectedStartupLists,
}: {
  htmlProps: HTMLAttributes<HTMLLIElement>;
  option: List;
  connectedStartupLists: ChallengeConnectedStartupListType[];
}) => {
  // @ts-expect-error: data-option-index is not a valid prop
  // eslint-disable-next-line react/prop-types
  const index = htmlProps?.['data-option-index'];
  const navigate = useNavigate();

  const isAlreadyLinked = useMemo(() => {
    return connectedStartupLists.some(
      startupList => startupList.id === option.id,
    );
  }, [connectedStartupLists, option.id]);

  const tooltipTitle = isAlreadyLinked ? (
    <Typography fontSize={12}>
      {`This list is already linked to the current challenge.`}
    </Typography>
  ) : (
    ''
  );

  return (
    <Fragment key={`option-challenge-to-list-${index}`}>
      <Box
        component='li'
        sx={({ spacing, palette }) => ({
          height: spacing(5),
          ...(isAlreadyLinked && {
            backgroundColor: palette.action.disabledBackground,
            cursor: 'not-allowed !important',
            color: palette.action.disabled,
            '&:hover': {
              backgroundColor: `${palette.action.disabledBackground} !important`,
            },
          }),
        })}
        {...htmlProps}
      >
        <Tooltip
          sx={{
            whiteSpace: 'pre-line',
          }}
          title={tooltipTitle}
          placement='bottom'
        >
          <Typography fontSize={14} noWrap>
            {option.title}
          </Typography>
        </Tooltip>

        <Stack direction='row' sx={{ marginLeft: 'auto' }} alignItems='center'>
          <Button
            sx={{ marginLeft: 'auto' }}
            size='small'
            onClick={e => {
              e.stopPropagation();
              navigate(PATH_ROOT.lists.details(option.id), {
                state: {
                  backToPage: `${window.location.pathname}${window.location.hash}`,
                },
              });
            }}
          >
            Open
          </Button>
        </Stack>
      </Box>
    </Fragment>
  );
};

const LinkStartupListToChallengeModal = ({
  open,
  challengeId,
  onHide,
  connectedStartupLists,
}: {
  open: boolean;
  challengeId: number;
  onConfirm?: (startupListId: number) => void;
  onHide: () => void;
  connectedStartupLists: ChallengeConnectedStartupListType[];
}) => {
  const [selectedList, setSelectedList] = useState<List | null>(null);
  const { normalizedDefaultFilter: listsFilter } =
    useServerFiltersContext('listTableFilters');

  const [linkListToChallenge, { loading: linkingInProgress }] =
    useLinkElementToChallengeMutation({
      refetchQueries: [
        {
          query: GetChallengeDetailsDocument,
          variables: { challengeID: challengeId, challengeUUID: '' },
        },
      ],
    });

  const {
    data: listsData,
    loading: loadingListsData,
    previousData,
    refetch: refetchLists,
    error,
  } = useGetListsForAddToProjectModalQuery({
    fetchPolicy: 'cache-and-network',
    variables: { where: listsFilter },
  });

  const options = useMemo(
    () =>
      listsData?.startup_lists
        .slice()
        .sort((a, b) => (a.project ? 1 : b.project ? -1 : 0)) || [],
    [listsData],
  );

  const containerRef = useRef(null);

  const { enqueueSnackbar } = useSnackbar();

  const { cache: apolloCache } = useApolloClient();

  const handleConnectListToChallenge = async () => {
    if (!selectedList) return;

    try {
      await linkListToChallenge({
        variables: {
          challengeId: challengeId,
          startupListId: selectedList.id,
        },
      });

      apolloCache.evict({
        id: apolloCache.identify({ __typename: 'challenges', challengeId }),
      });

      apolloCache.gc();
    } catch (error) {
      captureException(error);
      enqueueSnackbar(`Error linking list to challenge.`, { variant: 'error' });
    } finally {
      await refetchLists();
      onHide();
    }
  };

  if (!previousData && loadingListsData)
    return (
      <Dialog open={open} fullWidth>
        <DialogContent>
          <Skeleton height={400} />
        </DialogContent>
      </Dialog>
    );

  if (error) return <BaseErrorMessage />;

  return (
    <>
      <Dialog open={open} fullWidth ref={containerRef} onClose={onHide}>
        <DialogTitle sx={{ marginBottom: 5 }}>Link startup list</DialogTitle>
        <DialogContent
          sx={({ spacing }) => ({
            paddingTop: `${spacing(1)} !important`,
          })}
        >
          <Box sx={{ width: '100%' }}>
            <Stack direction='row' sx={{ width: '100%' }}>
              <Autocomplete
                blurOnSelect
                fullWidth
                options={options}
                autoHighlight
                getOptionLabel={option => option.title}
                onChange={(_e, selected) => {
                  setSelectedList(selected);
                }}
                renderOption={(htmlProps, option) => (
                  <RenderOption
                    key={`option-list-to-challenge-${option.id}`}
                    htmlProps={htmlProps}
                    option={option}
                    connectedStartupLists={connectedStartupLists}
                  />
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    label='Choose List'
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant='text' color='inherit' onClick={onHide}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (challengeId) {
                handleConnectListToChallenge();
              }
            }}
            disabled={
              !challengeId ||
              !selectedList ||
              connectedStartupLists.some(
                startupList => startupList.id === selectedList.id,
              ) ||
              linkingInProgress ||
              loadingListsData
            }
            variant='contained'
          >
            Link
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LinkStartupListToChallengeModal;
