import { Tooltip } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import {
  CalendarPicker,
  PickersDay,
  PickersDayProps,
} from '@mui/x-date-pickers';
import StandardErrorSection from 'components/StandardErrorSection';
import {
  BUSINESS_DAYS_TILL_FIRST_VALID_SOURCING_DEADLINE,
  B_MAX_ALLOWED_SOURCING_ORDERS_PER_WEEK,
  MAX_ALLOWED_SOURCING_ORDERS_PER_WEEK,
  VW_MAX_ALLOWED_SOURCING_ORDERS_PER_WEEK,
} from 'config';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import { parseISO } from 'date-fns';
import addBusinessDays from 'date-fns/addBusinessDays';
import { useSourcingOrdersInProgress } from 'hooks/useSourcingOrdersInProgress';
import { useMemo } from 'react';
import {
  formatApiDate,
  getWeek,
  isChristmasBreak,
  isPublicHoliday,
  isWeekendOrMonday,
} from 'utils/datetime';

type FormikDatePickerProps = {
  deadlineValue: string;
  setDeadlineValue: (value: string) => void;
};

const useCalendarStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .MuiPickersCalendarHeader-root': {
        marginTop: 0,
      },
    },
  }),
);

const DeliveryCalendarPicker = ({
  deadlineValue,
  setDeadlineValue,
}: FormikDatePickerProps) => {
  const normalizedDeadlineValue = deadlineValue
    ? parseISO(deadlineValue)
    : null;
  const {
    data: { ordersCountPerWeek: sourcingOrdersCountPerWeek },
    hasError: loadError,
  } = useSourcingOrdersInProgress();
  const minDate = useMemo(() => {
    const estimatedDate = addBusinessDays(
      new Date(),
      BUSINESS_DAYS_TILL_FIRST_VALID_SOURCING_DEADLINE,
    );

    return baseNonSourcingDates(estimatedDate)
      ? addBusinessDays(estimatedDate, 1)
      : estimatedDate;
  }, []);

  const calendarClasses = useCalendarStyles();

  const { uuid: organizationUUID } = useCurrentOrganizationFromContext();

  const standardSourcingLimitExceeded = (date: Date) => {
    let maxAllowedSourcingOrdersPerWeek = MAX_ALLOWED_SOURCING_ORDERS_PER_WEEK;
    if (organizationUUID === 'dd464f0c-d376-47eb-ba17-9634b2b7ea4b')
      maxAllowedSourcingOrdersPerWeek = VW_MAX_ALLOWED_SOURCING_ORDERS_PER_WEEK;
    if (organizationUUID === '5cd45c82-f742-4264-b082-d6b9bf82953c')
      maxAllowedSourcingOrdersPerWeek = B_MAX_ALLOWED_SOURCING_ORDERS_PER_WEEK;

    return (
      (sourcingOrdersCountPerWeek[getWeek(date)] || 0) >=
      maxAllowedSourcingOrdersPerWeek
    );
  };

  const renderDay = (
    date: Date,
    _selectedDates: (Date | null)[],
    pickerDayProps: PickersDayProps<Date>,
  ) => {
    const props = { ...pickerDayProps };
    const limitExceededForWeekday =
      standardSourcingLimitExceeded(date) && !isWeekendOrMonday(date);

    let errorTooltip = '';
    if (limitExceededForWeekday) {
      props.sx = { backgroundColor: '#8080804a' };
      errorTooltip = `You've already placed ${MAX_ALLOWED_SOURCING_ORDERS_PER_WEEK} orders for this week.`;
    } else if (baseNonSourcingDates(date)) {
      errorTooltip = baseNonSourcingDates(date);
    }

    return (
      // eslint-disable-next-line
      <Tooltip key={props.key} title={errorTooltip}>
        <span>
          <PickersDay {...props} />
        </span>
      </Tooltip>
    );
  };

  const shouldDisableDate = (date: Date) => {
    return (
      isWeekendOrMonday(date) ||
      isPublicHoliday(date) ||
      standardSourcingLimitExceeded(date) ||
      isChristmasBreak(date)
    );
  };

  if (loadError)
    return <StandardErrorSection title='Delivery schedule is not available' />;

  return (
    <CalendarPicker<Date>
      classes={calendarClasses}
      shouldDisableDate={shouldDisableDate}
      date={normalizedDeadlineValue || null}
      minDate={minDate}
      defaultCalendarMonth={minDate}
      renderDay={renderDay}
      onChange={value => {
        if (value) {
          setDeadlineValue(formatApiDate(value));
        }
      }}
    />
  );
};

export const baseNonSourcingDates = (date: Date) => {
  let error;
  if (isChristmasBreak(date)) {
    error = 'This date is closed due to the Christmas holidays.';
  } else if (isPublicHoliday(date)) {
    error = 'This date is closed due to public holidays.';
  } else if (isWeekendOrMonday(date)) {
    error = 'Weekends and Mondays are not allowed.';
  } else {
    error = '';
  }
  return error;
};

export default DeliveryCalendarPicker;
