import { Box, Dialog, DialogContent, Link } from '@mui/material';
import { GLASSDOLLAR_SUPPORT_EMAIL } from 'config';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import { Dispatch, SetStateAction, useState } from 'react';

export const useCanOrderSourcing = () => {
  const {
    current_sourcing_credits_used,
    total_sourcing_credits,
    charge_credit_units_enabled,
  } = useCurrentOrganizationFromContext();
  const [openContactSupport, setOpenContactSupport] = useState<boolean>(false);

  const hasSourcingCredits =
    !((current_sourcing_credits_used || 0) >= (total_sourcing_credits || 0)) ||
    !charge_credit_units_enabled;

  return {
    hasSourcingCredits,
    openContactSupport,
    setOpenContactSupport,
  };
};

export const ContactSourcingSupportModal = ({
  openContactSupport,
  setOpenContactSupport,
}: {
  openContactSupport: boolean;
  setOpenContactSupport: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <Dialog
      open={openContactSupport}
      onClose={() => setOpenContactSupport(false)}
      fullWidth
    >
      <DialogContent>
        <Box width={'100%'}>
          You are out of sourcing credits. Please contact{' '}
          <Link
            color='secondary'
            target='_blank'
            variant='body1'
            href={`mailto:${GLASSDOLLAR_SUPPORT_EMAIL}`}
          >
            @support
          </Link>{' '}
          to buy more units.
        </Box>
      </DialogContent>
    </Dialog>
  );
};
