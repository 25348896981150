import {
  DragHandle,
  Visibility,
  VisibilityOff,
  KeyboardArrowDown,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { useState, useMemo } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { ChallengeConnectionSettingsType } from '../types';
import { UpdatedSectionType } from './ChallengeDetailsPageContent';

export default function ChallengeVisibilityAndOrderButton({
  challengeConnectionSettings,
  onUpdateSettings,
}: {
  challengeConnectionSettings: ChallengeConnectionSettingsType;
  onUpdateSettings: (updatedSections: UpdatedSectionType[]) => Promise<void>;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const sections = useMemo(
    () => [
      { title: 'Lists', settings: challengeConnectionSettings.list_settings },
      {
        title: 'Leads',
        settings: challengeConnectionSettings.lead_settings,
      },
      {
        title: 'Projects',
        settings: challengeConnectionSettings.project_settings,
      },
    ],
    [challengeConnectionSettings],
  );

  const { visibleSections, hiddenSections } = useMemo(() => {
    const sortedSections = [...sections].sort(
      (a, b) => a.settings.display_order - b.settings.display_order,
    );

    return {
      visibleSections: sortedSections.filter(
        section => !section.settings.is_hidden,
      ),
      hiddenSections: sortedSections.filter(
        section => section.settings.is_hidden,
      ),
    };
  }, [sections]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleToggleVisibility = (title: string) => {
    const updatedSections = sections.map(section =>
      section.title === title
        ? {
            ...section,
            settings: {
              ...section.settings,
              is_hidden: !section.settings.is_hidden,
            },
          }
        : section,
    );
    onUpdateSettings(updatedSections);
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const reordered = [...visibleSections, ...hiddenSections].map(section => ({
      ...section,
      settings: { ...section.settings },
    }));

    const [movedSection] = reordered.splice(result.source.index, 1);
    reordered.splice(result.destination.index, 0, movedSection);

    const updatedSections = reordered.map((section, index) => ({
      ...section,
      settings: { ...section.settings, display_order: index },
    }));

    onUpdateSettings(updatedSections);
  };

  return (
    <Box>
      <Button
        variant='text'
        size='small'
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDown />}
      >
        Order & Visibility
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <Box padding={2}>
          <Typography variant='subtitle2'>Visible Sections</Typography>
        </Box>
        <Divider />
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId='visible-sections'>
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {visibleSections.map((section, index) => (
                  <Draggable
                    key={section.title}
                    draggableId={section.title}
                    index={index}
                  >
                    {provided => (
                      <MenuItem
                        sx={{ paddingX: 2 }}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        disableRipple
                      >
                        <DragHandle sx={{ marginRight: 1 }} />
                        <Typography variant='body2'>{section.title}</Typography>
                        <IconButton
                          size='small'
                          edge='end'
                          sx={{ marginLeft: 'auto' }}
                          onClick={() => handleToggleVisibility(section.title)}
                        >
                          <Visibility />
                        </IconButton>
                      </MenuItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {hiddenSections.length > 0 && (
          <>
            <Box padding={2}>
              <Typography variant='subtitle2'>Hidden Sections</Typography>
            </Box>
            <Divider />
            {hiddenSections.map(section => (
              <MenuItem key={section.title} disableRipple sx={{ paddingX: 2 }}>
                <Typography variant='body2'>{section.title}</Typography>
                <IconButton
                  size='small'
                  edge='end'
                  onClick={() => handleToggleVisibility(section.title)}
                  sx={{ marginLeft: 'auto' }}
                >
                  <VisibilityOff />
                </IconButton>
              </MenuItem>
            ))}
          </>
        )}
      </Menu>
    </Box>
  );
}
