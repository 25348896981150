import { useCallback, useContext, useMemo } from 'react';

import { useUpdateStartupListCategoryCustomDimensionsSchemaMutation } from 'apollo/generated/sdkInnovationManager';
import { useSnackbar } from 'notistack';
import { useUpdateStartupListActivities } from 'components/dashboard/startupList/useUpdateStartupListActivities';
import { StartupsTableContext } from './shared/StartupsTableContext';

type DIRECTION = 'left' | 'right';

export const useReorderCustomHeaders = (
  category:
    | {
        id: number;
        custom_dimensions_schema: { name: string }[];
      }
    | undefined,
) => {
  const [updateSchema] =
    useUpdateStartupListCategoryCustomDimensionsSchemaMutation();
  const { currentStartupList } = useContext(StartupsTableContext)!;
  const { logStartupListActivity } = useUpdateStartupListActivities();
  const { enqueueSnackbar } = useSnackbar();

  const categoryId = category?.id;
  const customDimensionsSchema = useMemo(
    () => category?.custom_dimensions_schema || [],
    [category?.custom_dimensions_schema],
  );
  const reorderColumnPlan = useCallback(
    (currentColumn: string, direction: DIRECTION) => {
      const currentColumnIndex = customDimensionsSchema
        .map(x => x.name)
        .indexOf(currentColumn);
      const indexToReplaceWith =
        direction === 'right' ? currentColumnIndex + 1 : currentColumnIndex - 1;

      return {
        currentColumnIndex,
        indexToReplaceWith,
        reorderAllowed:
          indexToReplaceWith >= 0 &&
          indexToReplaceWith < customDimensionsSchema.length,
      };
    },
    [customDimensionsSchema],
  );

  const reorderColumn = useCallback(
    async (currentColumn: string, direction: DIRECTION) => {
      const { currentColumnIndex, indexToReplaceWith, reorderAllowed } =
        reorderColumnPlan(currentColumn, direction);
      if (!reorderAllowed) {
        throw new Error('Cannot move column outside of bounds');
      }

      const orderedCustomDimensionsSchema = customDimensionsSchema.map(
        (x, i) => {
          if (i === currentColumnIndex) {
            return customDimensionsSchema[indexToReplaceWith];
          } else if (i === indexToReplaceWith) {
            return customDimensionsSchema[currentColumnIndex];
          } else {
            return x;
          }
        },
      );

      if (
        orderedCustomDimensionsSchema.length !== customDimensionsSchema?.length
      ) {
        throw new Error(
          'DataGrid column names does not include all custom dimensions fields',
        );
      }

      await updateSchema({
        variables: {
          project_category_id: categoryId!,
          custom_dimensions_schema: orderedCustomDimensionsSchema,
        },
        optimisticResponse: {
          update_project_categories_by_pk: {
            id: categoryId!,
            custom_dimensions_schema: orderedCustomDimensionsSchema,
          },
        },
      });

      enqueueSnackbar('Column order changed', { variant: 'success' });
      await logStartupListActivity({
        logs: [
          {
            action: 'updated',
            entityIds: [categoryId ? categoryId : 0],
            entityType: 'project_categories',
          },
        ],
        startupListId: currentStartupList.id,
      });
    },
    [
      updateSchema,
      categoryId,
      customDimensionsSchema,
      enqueueSnackbar,
      reorderColumnPlan,
      logStartupListActivity,
      currentStartupList.id,
    ],
  );

  return {
    reorderColumn,
    reorderColumnPlan,
  };
};
