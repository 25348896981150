import {
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';

type ColumnWithTypedRenderCell<V, R extends GridValidRowModel> = Omit<
  GridColDef<R, V>,
  'renderCell'
> & {
  renderCell?: (params: GridRenderCellParams<V, R>) => React.ReactNode;
};

// Utility function to create a single column with a typed renderCell
function createColumn<R extends GridValidRowModel, V>(
  col: ColumnWithTypedRenderCell<V, R>,
): GridColDef<R, V> {
  return col; // Directly return the column as the types are already correctly applied
}

export default createColumn;
