import {
  useCreateStartupListServiceMutation,
  useGetFavouritesListIdLazyQuery,
} from 'apollo/generated/sdkInnovationManager';
import { useCallback } from 'react';

export function useCreateFavorites() {
  const [createStartupList] = useCreateStartupListServiceMutation();
  const [getFavouriteList] = useGetFavouritesListIdLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const createFavourites = useCallback(async () => {
    const { data: favouritesListData } = await getFavouriteList();
    let favouritesListId =
      favouritesListData?.startup_lists?.[0]?.id || undefined;

    if (!favouritesListId) {
      const addedListData = await createStartupList({
        variables: {
          object: {
            title: `Your favourites`,
            source: 'favourites',
          },
        },
      });
      favouritesListId =
        addedListData.data?.create_startup_list?.startup_list?.id;
    }

    return favouritesListId;
  }, [getFavouriteList, createStartupList]);

  return { createFavourites };
}
