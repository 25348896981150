import {
  Box,
  Card,
  CardContent,
  Fade,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import {
  CalendarPicker,
  PickersDay,
  PickersDayProps,
} from '@mui/x-date-pickers';
import {
  BUSINESS_DAYS_TILL_FIRST_VALID_SOURCING_DEADLINE,
  EXPRESS_SOURCING_DELIVERY_DAYS,
  MAX_ALLOWED_EXPRESS_ORDERS_PER_MONTH,
  VW_MAX_ALLOWED_EXPRESS_ORDERS_PER_MONTH,
} from 'config';
import { addBusinessDays } from 'date-fns';
import { useFormikContext } from 'formik';
import { useSourcingOrdersInProgress } from 'hooks/useSourcingOrdersInProgress';
import OptionCard from 'pages/request-form/OptionCard';
import { useMemo } from 'react';
import { BORDER_COLOR } from 'theme/palette';
import { formatApiDate } from 'utils/datetime';
import DeliveryCalendarPicker, {
  baseNonSourcingDates,
} from './DeliveryCalendarPicker';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import { SourcingOrderState } from 'pages/request-form/sourcingOrderModel';

const DeliverySchedule: React.FC = () => {
  const { getFieldProps, setFieldValue } =
    useFormikContext<Pick<SourcingOrderState, 'due_date' | 'delivery_type'>>();

  const { uuid: organizationUUID } = useCurrentOrganizationFromContext();

  const expressOrderLimit = useMemo(
    () =>
      organizationUUID === 'dd464f0c-d376-47eb-ba17-9634b2b7ea4b' ||
      organizationUUID === '5cd45c82-f742-4264-b082-d6b9bf82953c'
        ? VW_MAX_ALLOWED_EXPRESS_ORDERS_PER_MONTH
        : MAX_ALLOWED_EXPRESS_ORDERS_PER_MONTH,
    [organizationUUID],
  );

  const {
    data: { expressAvailable },
  } = useSourcingOrdersInProgress();

  const { value: deliveryType = 'standard', ...sourcingDeliveryFieldProps } =
    getFieldProps('delivery_type');

  const calculatedExpressDeadline = useMemo(() => {
    const estimatedDate = addBusinessDays(
      new Date(),
      EXPRESS_SOURCING_DELIVERY_DAYS,
    );

    return baseNonSourcingDates(estimatedDate)
      ? addBusinessDays(estimatedDate, 1)
      : estimatedDate;
  }, []);

  return (
    <Card
      sx={{
        marginBottom: 2,
        boxShadow: 'none',
        borderRadius: 0,
      }}
    >
      <CardContent sx={{ padding: 0, paddingBottom: '0 !important' }}>
        <Typography gutterBottom variant='h5' sx={{ marginBottom: 2 }}>
          Delivery options
        </Typography>
        <RadioGroup
          aria-label='Delivery option'
          value={deliveryType}
          {...sourcingDeliveryFieldProps}
        >
          <Stack direction='row' gap={1}>
            <Tooltip title=''>
              <Box
                component='span'
                sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}
              >
                <OptionCard
                  sxProps={{ marginBottom: 2 }}
                  border={`1px solid ${BORDER_COLOR}`}
                  title={<Typography>Standard</Typography>}
                  content={`In ${BUSINESS_DAYS_TILL_FIRST_VALID_SOURCING_DEADLINE} business days`}
                  onClick={() => {
                    setFieldValue('due_date', 'standard');
                    setFieldValue('due_date', undefined);
                  }}
                >
                  <FormControlLabel
                    value='standard'
                    control={<Radio />}
                    label=''
                  />
                </OptionCard>
                <Fade
                  in={deliveryType === 'standard'}
                  mountOnEnter
                  unmountOnExit
                >
                  <Box>
                    <DeliveryCalendarPicker
                      deadlineValue={getFieldProps('due_date').value}
                      setDeadlineValue={value => {
                        setFieldValue('due_date', value);
                      }}
                    />
                  </Box>
                </Fade>
              </Box>
            </Tooltip>
            <Tooltip
              title={
                expressAvailable
                  ? ''
                  : `${expressOrderLimit} express delivery is allowed per month`
              }
            >
              <Box
                component='span'
                sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}
              >
                <OptionCard
                  border={`1px solid ${BORDER_COLOR}`}
                  title={
                    <Typography>
                      Express{' '}
                      <Typography
                        component='span'
                        variant='body2'
                        color={'text.secondary'}
                      >
                        ({expressOrderLimit} / month)
                      </Typography>
                    </Typography>
                  }
                  content={`In ${EXPRESS_SOURCING_DELIVERY_DAYS} business days`}
                  onClick={() => {
                    setFieldValue('due_date', 'express');
                    setFieldValue(
                      'due_date',
                      formatApiDate(calculatedExpressDeadline),
                    );
                  }}
                  disabled={!expressAvailable}
                >
                  <FormControlLabel
                    value='express'
                    control={<Radio />}
                    label=''
                  />
                </OptionCard>
                <Fade
                  in={deliveryType === 'express'}
                  mountOnEnter
                  unmountOnExit
                >
                  <Box>
                    <CalendarPicker
                      date={calculatedExpressDeadline}
                      shouldDisableDate={date =>
                        date.toDateString() !==
                        calculatedExpressDeadline.toDateString()
                      }
                      onChange={() => {}}
                      renderDay={renderDay}
                    />
                  </Box>
                </Fade>
              </Box>
            </Tooltip>
          </Stack>
        </RadioGroup>
      </CardContent>
    </Card>
  );
};

const renderDay = (
  date: Date,
  _selectedDates: (Date | null)[],
  pickerDayProps: PickersDayProps<Date>,
) => {
  const props = { ...pickerDayProps };

  const errorTooltip = baseNonSourcingDates(date);

  return (
    <Tooltip key={props.key} title={errorTooltip}>
      <span>
        <PickersDay {...props} />
      </span>
    </Tooltip>
  );
};

export default DeliverySchedule;
