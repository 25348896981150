import { HowToReg } from '@mui/icons-material';
import { Box, Button, Stack, Tooltip, Typography } from '@mui/material';
import BaseServerFilterAutocomplete from 'components/base/serverFilters/BaseServerFilterAutocomplete';
import {
  ServerFilter,
  useServerFiltersContext,
} from 'components/base/serverFilters/BaseServerFiltersContext';
import { usePersonContext } from 'contexts/PersonContext';
import { Fragment, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { PATH_ROOT } from 'routes/paths';
import TeamAvatar from './TeamAvatar';
import { useGetAllTeamsData } from './useGetAllTeamsData';

const GoToManageTeamsButton = () => {
  const navigate = useNavigate();

  return (
    <Button
      sx={{
        width: '100%',
        borderRadius: 0,
        whiteSpace: 'nowrap',
      }}
      onClick={() => navigate(PATH_ROOT.settings.teams)}
    >
      Manage Teams
    </Button>
  );
};

type TeamFilterScope = keyof Pick<
  ServerFilter,
  | 'listTableFilters'
  | 'projectAdoptionsFilters'
  | 'stakeholderFilters'
  | 'projectPoCFilters'
  | 'projectLeadsFilters'
>;

export const TeamFilter = ({ scope }: { scope: TeamFilterScope }) => {
  const { avatarUrlMap, loadingTeamsData, teamsData } = useGetAllTeamsData();
  const { person } = usePersonContext();
  const { filters, setFilters } = useServerFiltersContext(scope);

  const options =
    teamsData.map(t => ({
      label: t.name,
      value: t.id,
    })) || [];
  const value = options.filter(t =>
    filters.teams.team_id?._in?.includes(t.value),
  );
  const defaultValue = options?.filter(t => value.includes(t));
  const clearFilter = useCallback(() => setFilters('teams', {}), [setFilters]);

  useEffect(() => {
    // Clear filter in case we have deleted a team
    if (value.length && !defaultValue?.length) {
      clearFilter();
    }
  }, [clearFilter, defaultValue?.length, value.length]);

  const handleChange = useCallback((inputValue: typeof value) => {
    if (!inputValue.length) {
      clearFilter();
      return;
    }

    const teamIds = inputValue.map(team => team.value);

    setFilters('teams', { team_id: { _in: teamIds } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BaseServerFilterAutocomplete
      value={value}
      loading={loadingTeamsData}
      defaultValue={defaultValue}
      options={options}
      handleChange={values =>
        handleChange(
          values.map(v => ({ label: v.label, value: v.value, avatarUrl: '' })),
        )
      }
      placeholder='Team'
      sxProps={{ minWidth: 90 }}
      dataTestId='team-filter'
      renderTags={value =>
        value.map((option, index, array) => {
          if (index === 0) {
            return (
              <Stack key={option.value} direction='row' alignItems='center'>
                <TeamAvatar
                  src={avatarUrlMap?.[option.value] || ''}
                  alt={option.label}
                  sx={{ marginRight: 1 }}
                />
                <Typography whiteSpace='nowrap' key={index}>
                  {option.label}
                </Typography>
              </Stack>
            );
          }

          if (index === 1) {
            return (
              <Typography marginLeft={1} key={option.value}>
                +{array.length - 1}
              </Typography>
            );
          }

          return null;
        })
      }
      renderOption={(props, option) => {
        // @ts-expect-error: data-option-index is not a valid prop
        // eslint-disable-next-line react/prop-types
        const index = props?.['data-option-index'];

        return (
          <Fragment key={`option-team-filter-${option.value}`}>
            {index === 0 && <GoToManageTeamsButton />}
            <Box component='li' {...props}>
              {avatarUrlMap?.[option.value] && (
                <TeamAvatar
                  src={avatarUrlMap[option.value]}
                  alt={option.label}
                  sx={{ marginRight: 1 }}
                />
              )}
              {option.label}
              {person?.team?.id === option.value && (
                <Tooltip title='Your team' arrow>
                  <HowToReg sx={{ marginLeft: 1 }} fontSize='small' />
                </Tooltip>
              )}
            </Box>
          </Fragment>
        );
      }}
    />
  );
};
