import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { captureException } from '@sentry/react';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import { identifyAnonymousUser } from 'plugins/Analytics';
import { useState } from 'react';

const IdentifyPersonModal = ({
  isOpen,
  onClose,
  onConfirm,
}: {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (stakeholder: {
    email: string;
    fullName: string;
    department: string;
  }) => Promise<unknown>;
}) => {
  const { user } = useAuth();
  const currentOrganization = useCurrentOrganizationFromContext();
  const [submitting, setSubmitting] = useState(false);
  const [fullName, setFullName] = useState<string | null>(null);
  const [department, setDepartment] = useState<string | null>(null);
  const email = user.email;
  const { enqueueSnackbar } = useSnackbar();

  const hasFullNameError = fullName === '';
  const hasDepartmentError = department === '';
  const hasError =
    [fullName, department].includes(null) ||
    hasFullNameError ||
    hasDepartmentError;

  const onSubmit = async () => {
    setSubmitting(true);

    try {
      await onConfirm({
        fullName: fullName!,
        email: email!,
        department: department!,
      });

      identifyAnonymousUser(
        {
          email: email!,
          name: fullName!,
          department: department,
        },
        {
          subdomain: currentOrganization.subdomain,
          id: currentOrganization.id,
        },
      );
    } catch (error) {
      captureException(error);
      console.error(error);
      enqueueSnackbar('There was an error submitting the form', {
        variant: 'error',
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Dialog open={isOpen} data-test='voting-modal'>
      <DialogTitle sx={{ marginBottom: 3 }}>Contact details</DialogTitle>
      <DialogContent>
        <Typography variant='body2' marginBottom={2}>
          {'Please provide your contact details to continue.'}
        </Typography>
        <Stack spacing={2}>
          <TextField
            label='Your name'
            value={fullName || ''}
            onChange={e => setFullName(e.target.value)}
            data-test={'test-stakeholder-name'}
            error={Boolean(hasFullNameError)}
            helperText={hasFullNameError && 'Full name is required'}
          />
          <TextField
            label='Your email'
            value={email}
            disabled
            type='email'
            data-test={'test-stakeholder-email'}
          />
          <TextField
            label='Department'
            value={department || ''}
            onChange={e => setDepartment(e.target.value)}
            data-test={'test-stakeholder-department'}
            error={Boolean(hasDepartmentError)}
            helperText={hasDepartmentError && 'Department is required'}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          type='button'
          variant='outlined'
          color='inherit'
          onClick={onClose}
        >
          Cancel
        </Button>
        <Tooltip title={hasError ? 'Fill-in all fields before submitting' : ''}>
          <div>
            <LoadingButton
              type='submit'
              variant='contained'
              disabled={hasError}
              onClick={onSubmit}
              loading={submitting}
              loadingIndicator='Submitting...'
            >
              Submit
            </LoadingButton>
          </div>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default IdentifyPersonModal;
