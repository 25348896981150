const FORMAT_PNG = ['png'];
const FORMAT_JPG = ['jpg', 'jpeg'];
const FORMAT_PDF = ['pdf'];

export function getFileType(fileUrl: string = '') {
  return (fileUrl && fileUrl.split('.').pop()) || '';
}

const getIcon = (icon: string) => `/static/icons/upload/${icon}.svg`;

export function fileUploadedPreview(fileUrl: string) {
  let thumb;
  const format = fileUploadedPreviewFormat(fileUrl);

  switch (format) {
    case 'jpeg':
      thumb = getIcon('jpeg_preview');
      break;
    case 'png':
      thumb = getIcon('png_preview');
      break;
    case 'pdf':
      thumb = getIcon('pdf_preview');
      break;
    default:
      thumb = getIcon('default_preview');
  }
  return { thumb, format };
}

export function fileUploadedPreviewFormat(fileUrl: string | undefined) {
  let format;

  switch (fileUrl?.includes(getFileType(fileUrl))) {
    case FORMAT_PNG.includes(getFileType(fileUrl)):
      format = 'png';
      break;
    case FORMAT_JPG.includes(getFileType(fileUrl)):
      format = 'jpeg';
      break;
    case FORMAT_PDF.includes(getFileType(fileUrl)):
      format = 'pdf';
      break;
    default:
      format = getFileType(fileUrl);
  }

  return format;
}
