import { FormControlLabel, Stack, Switch } from '@mui/material';
import { GDLogo } from 'components/shared/GDLogo';
import { isEmpty } from 'lodash';
import { useCallback } from 'react';

import { captureAnalyticsEvent } from 'plugins/Analytics';
import { useServerFiltersContext } from 'components/base/serverFilters/BaseServerFiltersContext';

export const SourcingsOnlyFilter = () => {
  const { setFilters, filters } = useServerFiltersContext('listTableFilters');
  const currentFilter = filters.sourcingsOnly;

  const isChecked = isEmpty(currentFilter) ? false : true;

  const handleSwitchChange = useCallback(() => {
    if (isChecked) {
      setFilters('sourcingsOnly', {});
    } else {
      setFilters('sourcingsOnly', {
        sourcing_order: { id: { _is_null: false } },
      });
    }

    captureAnalyticsEvent('Sourcings-only filter used', { isChecked });
  }, [isChecked, setFilters]);

  return (
    <Stack direction='row' alignItems='center'>
      <GDLogo size={30} />
      <FormControlLabel
        sx={{ marginLeft: 0, whiteSpace: 'nowrap' }}
        checked={isChecked}
        onChange={handleSwitchChange}
        value='start'
        control={<Switch color='primary' />}
        label='Sourcings only'
        labelPlacement='start'
      />
    </Stack>
  );
};
