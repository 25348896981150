import { PlaylistRemove, Upgrade } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useUpdateCategorizedStartupInMatrixStateMutation } from 'apollo/generated/sdkInnovationManager';
import { useSnackbar } from 'notistack';
import { useCallback, useContext } from 'react';
import {
  DecoratedStartup,
  StartupsTableContext,
} from './shared/StartupsTableContext';
import { mapStageNameForOrganization } from 'utils/projectStageEnum';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import { useUpdateStartupListActivities } from 'components/dashboard/startupList/useUpdateStartupListActivities';

const MoveStartupActions = ({
  onRemoveStartup,
  id,
  startup,
}: {
  id: number | string;
  startup: DecoratedStartup;
  onRemoveStartup?: (startup: DecoratedStartup) => void;
}) => {
  const { subdomain: organizationSubdomain } =
    useCurrentOrganizationFromContext();
  const { enqueueSnackbar } = useSnackbar();
  const { category, currentStartupList } = useContext(StartupsTableContext)!;
  const [updateMatrixState] =
    useUpdateCategorizedStartupInMatrixStateMutation();
  const { logStartupListActivity } = useUpdateStartupListActivities();

  const isInMatrix = startup.is_in_matrix;
  const destination = isInMatrix ? 'longlist' : 'shortlist';
  const handleToggleMatrixState = useCallback(
    async (categorizedStartupId: number) => {
      try {
        await updateMatrixState({
          variables: {
            categorizedStartupId,
            isInMatrix: !isInMatrix,
            projectCategoryId: category.id,
          },
        });

        await logStartupListActivity({
          logs: [
            {
              action: 'updated',
              entityIds: categorizedStartupId,
              entityType: 'categorized_suppliers',
            },
          ],
          startupListId: currentStartupList.id,
        });

        enqueueSnackbar(
          `Successfully moved to ${mapStageNameForOrganization({
            name: destination,
            organizationSubdomain,
          })}`,
          {
            variant: 'success',
          },
        );
      } catch (e) {
        enqueueSnackbar(`Error moving to ${destination}`, {
          variant: 'error',
        });
      }
    },
    [
      updateMatrixState,
      isInMatrix,
      category.id,
      enqueueSnackbar,
      destination,
      organizationSubdomain,
      logStartupListActivity,
      currentStartupList.id,
    ],
  );

  return (
    <>
      <Tooltip title={`Move to ${destination}`}>
        <IconButton
          size='small'
          onClick={() => {
            handleToggleMatrixState(Number(id));
          }}
        >
          <Upgrade
            sx={{
              transform: isInMatrix ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
            fontSize='small'
            color='inherit'
          />
        </IconButton>
      </Tooltip>
      <Tooltip title='Remove from list'>
        <IconButton
          size='small'
          onClick={() => onRemoveStartup && onRemoveStartup(startup)}
        >
          <PlaylistRemove fontSize='small' color='inherit' />
        </IconButton>
      </Tooltip>
    </>
  );
};

export { MoveStartupActions };
