import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------
// eslint-disable-next-line
export default function Link(theme: Theme) {
  return {
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },

      styleOverrides: {
        root: {},
      },
    },
  };
}
