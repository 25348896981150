import { Chip, Stack, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { StartupFormState } from './useStartupDetailsFormik';

export const StartupTagsField = () => {
  const {
    values: { tags },
  } = useFormikContext<StartupFormState>();

  if (!tags || !tags.length) {
    return null;
  }

  return (
    <Stack gap={1}>
      <Typography color='text.secondary'>Startup Tags</Typography>
      <Stack direction='row' flexWrap='wrap' gap={1}>
        {tags.map((tag, index) => (
          <Chip key={`${tag}-${index}`} label={tag} />
        ))}
      </Stack>
    </Stack>
  );
};
