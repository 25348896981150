import { useGetOrganizationPortalConfigLazyQuery } from 'apollo/generated/sdkShared';
import { AboutPageState } from 'components/AboutPageBuilder/shared/AboutPageContext';
import LoadingScreen from 'components/LoadingScreen';
import { X_HASURA_ORGANIZATION_UUID } from 'config';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import useAuth from 'hooks/useAuth';
import { OrganizationPortalPage } from 'layouts/OrganizationPortalBuilderLayout';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router';
import { HomePageState } from '../useUpdateHomePageState';
import { CustomPagePortalConfig } from '../useUpdatePortalCustomSections';

type PortalConfig = {
  id: number;
  home_page_enabled: boolean;
  home_page_state: HomePageState;
  about_page_state: AboutPageState;
  about_page_enabled: boolean;
  searchbox_enabled: boolean;
  request_form_page_enabled: boolean;
  team_id?: number | null;
  team_slug?: string | null;
  custom_organization_portal_pages: CustomPagePortalConfig[];
  pitch_vcu_video_url?: string | null;
  pitch_vcu_copy?: string | null;
};

type OrganizationPortalState = {
  currentPortalConfig: PortalConfig;
  currentCustomPage: CustomPagePortalConfig;
};

type OrganizationPortalContextType = {
  currentPortalConfig: PortalConfig;
  setOrganizationPortalState: React.Dispatch<
    React.SetStateAction<OrganizationPortalState>
  >;
  getPortalConfigField: <K extends keyof PortalConfig>(
    field: K,
  ) => PortalConfig[K] | undefined;
  refetchOrganizationPortalConfig: () => void;
  currentCustomPage: CustomPagePortalConfig;
  setCurrentPathParam: Dispatch<
    SetStateAction<{
      teamSlug?: string;
      pageSlug?: OrganizationPortalPage;
    }>
  >;
};

const defaultState: OrganizationPortalState = {
  currentPortalConfig: {
    id: 0,
    home_page_enabled: false,
    home_page_state: {
      title: '',
      subtitle: '',
    },
    about_page_state: {
      'team-members': [],
      'paragraph-section-one': {
        content: '',
      },
      'process-flow-section': [],
      'button-section-one': {
        content: '',
        href: '',
        linkType: 'custom',
        variant: 'text',
        size: 'medium',
      },
      'paragraph-section-two': {
        content: '',
      },
      'button-section-two': {
        content: '',
        href: '',
        linkType: 'custom',
        variant: 'text',
        size: 'medium',
      },
      'paragraph-section-three': {
        content: '',
      },
    },
    pitch_vcu_video_url: '',
    pitch_vcu_copy: '',
    about_page_enabled: false,
    searchbox_enabled: false,
    request_form_page_enabled: false,
    custom_organization_portal_pages: [],
  },
  currentCustomPage: {
    id: 0,
    title: '',
    page_slug: '',
    page_sections: [],
  },
};

export const OrganizationPortalContext =
  createContext<OrganizationPortalContextType | null>(null);

export const OrganizationPortalContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [currentPathParam, setCurrentPathParam] = useState<{
    teamSlug?: string;
    pageSlug?: OrganizationPortalPage;
  }>({ teamSlug: 'default', pageSlug: 'home' });

  const [organizationPortalState, setOrganizationPortalState] =
    useState<OrganizationPortalState>(defaultState);

  const { token } = useAuth();
  const currentOrganization = useCurrentOrganizationFromContext();
  const context = !token
    ? {
        headers: {
          [X_HASURA_ORGANIZATION_UUID]: currentOrganization.uuid,
        },
      }
    : {};

  const [getOrganizationPortalConfig, { data, refetch }] =
    useGetOrganizationPortalConfigLazyQuery({
      variables: { teamSlug: currentPathParam.teamSlug },
      context,
    });

  const config = data?.organization_portal_configs[0];

  useEffect(() => {
    if (currentPathParam.teamSlug) {
      getOrganizationPortalConfig({
        variables: { teamSlug: currentPathParam.teamSlug },
      });
    }

    if (config?.id) {
      const currentCustomPage = config.custom_organization_portal_pages.find(
        page => page.page_slug === currentPathParam.pageSlug,
      );

      setOrganizationPortalState({
        currentPortalConfig: config,
        currentCustomPage: {
          id: currentCustomPage?.id ?? defaultState.currentCustomPage.id,
          title:
            currentCustomPage?.title ?? defaultState.currentCustomPage.title,
          page_slug:
            currentCustomPage?.page_slug ??
            defaultState.currentCustomPage.page_slug,
          page_sections:
            currentCustomPage?.page_sections ??
            defaultState.currentCustomPage.page_sections,
        },
      });
    }
  }, [currentPathParam, getOrganizationPortalConfig, config]);

  const contextValue = useMemo(() => {
    const getPortalConfigField = <K extends keyof PortalConfig>(
      field: K,
    ): PortalConfig[K] | undefined => {
      return organizationPortalState.currentPortalConfig[field];
    };

    return {
      currentPortalConfig: organizationPortalState.currentPortalConfig,
      currentCustomPage: organizationPortalState.currentCustomPage,
      setOrganizationPortalState,
      getPortalConfigField,
      refetchOrganizationPortalConfig: refetch,
      setCurrentPathParam,
    };
  }, [organizationPortalState, refetch]);

  if (!config?.id) return <LoadingScreen />;

  return (
    <OrganizationPortalContext.Provider value={contextValue}>
      {children}
    </OrganizationPortalContext.Provider>
  );
};

// Custom hook to use the OrganizationPortalContext
export const useOrganizationPortalContext = () => {
  const { teamSlug, pageSlug } = useParams<{
    teamSlug: string;
    pageSlug: OrganizationPortalPage;
  }>();

  const context = useContext(OrganizationPortalContext);
  if (!context) {
    throw new Error(
      'useOrganizationPortalContext must be used within an OrganizationPortalContextProvider',
    );
  }

  useEffect(() => {
    context.setCurrentPathParam({
      teamSlug,
      pageSlug: pageSlug === 'explore' ? 'home' : pageSlug,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamSlug, pageSlug]);

  return context;
};
