import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import { captureException } from '@sentry/react';
import {
  GetChallengesDocument,
  useArchiveChallengeMutation,
} from 'apollo/generated/sdkShared';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { PATH_ROOT } from 'routes/paths';

export const ArchiveChallengeModal = ({
  challengeId,
  title,
  onHide,
  navigateToChallenges = false,
}: {
  challengeId: number;
  title: string;
  onHide: () => void;
  navigateToChallenges?: boolean;
}) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [archiveChallenge] = useArchiveChallengeMutation({
    refetchQueries: [
      {
        query: GetChallengesDocument,
      },
    ],
  });

  const handleArchiveChallenge = async (challengeId: number) => {
    try {
      await archiveChallenge({ variables: { challengeId } });

      enqueueSnackbar('Challenge has been successfully deleted', {
        variant: 'success',
      });

      if (navigateToChallenges) {
        navigate(PATH_ROOT.challenges.root);
      }
    } catch (error) {
      captureException(error);
      enqueueSnackbar(`Couldn't archive ${title}, please try again later`, {
        variant: 'error',
      });
    }
  };
  return (
    <Dialog open fullWidth>
      <DialogContent sx={{ marginTop: 2, paddingBottom: 0 }}>
        <Typography>
          Are you sure you want to delete <b>{title}</b>?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant='text' color='inherit' onClick={onHide}>
          Cancel
        </Button>
        <Button
          onClick={() => handleArchiveChallenge(challengeId)}
          variant='contained'
          color='error'
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
