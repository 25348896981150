import { useEffect, useMemo, useState } from 'react';
import { matchPath, Params, useLocation } from 'react-router-dom';
import { INTERNAL_ROUTES } from 'routes/internalRoutes';
import { PATH_PARAMS } from 'routes/paths';
import { SHARED_ROUTES } from 'routes/sharedRoutes';

type PathParam = keyof typeof PATH_PARAMS;

const useMatchRoutePattern = <T extends PathParam>() => {
  const location = useLocation();
  const [match, setMatch] = useState<ReturnType<typeof matchPath> | null>(null);
  const ROUTE_PATTERNS = useMemo(
    () =>
      [
        ...SHARED_ROUTES.filter(route => route.path).map(route => route.path),
        ...INTERNAL_ROUTES.filter(route => route.path).map(route => route.path),
      ].filter(Boolean),
    [],
  );

  useEffect(() => {
    ROUTE_PATTERNS.forEach(routePattern => {
      if (!routePattern) return;

      const match = matchPath(routePattern, location.pathname);
      if (match) {
        setMatch(match);
      }
    });
  }, [ROUTE_PATTERNS, location.pathname]);

  return match?.pathname === location.pathname
    ? (match.params as Params<T>)
    : null;
};

export default useMatchRoutePattern;
