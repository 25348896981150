import { Typography } from '@mui/material';
import { dotDivider, smallTextStyles } from './typography/constants';

export const BaseStartupListStats = ({
  totalStartups,
  totalCategories,
  styles = {},
}: {
  totalStartups: number;
  totalCategories: number;
  styles?: object;
}) => {
  const startupText =
    totalStartups > 0
      ? `${totalStartups} ${totalStartups > 1 ? 'startups' : 'startup'}`
      : 'no startups';

  return (
    <>
      <Typography
        sx={{ ...smallTextStyles, ...styles }}
        marginRight={0.3}
        component='span'
      >
        {startupText}
      </Typography>
      {(totalCategories || 0) > 1 && (
        <>
          {dotDivider}
          <Typography sx={{ ...smallTextStyles, ...styles }} component='span'>
            {totalCategories}
            {' categories'}
          </Typography>
        </>
      )}
    </>
  );
};
