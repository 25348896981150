import {
  ProblemScope,
  SourcingOrderForBriefingForm,
} from 'components/dashboard/sourcingOrder/types';

// TODO: Move to appropriate folder
export type SourcingOrderState = ReturnType<typeof sourcingOrderModel> & {
  scope_files: ArrayLike<File> | null;
};

export const sourcingOrderModel = (
  startupListId: number,
  sourcingOrder?: SourcingOrderForBriefingForm,
  problemScope?: ProblemScope,
) => {
  return {
    startupListId,
    problemScopeId: problemScope?.id,
    id: sourcingOrder?.id || 0,
    title: sourcingOrder?.startup_list?.title || '',
    problem: problemScope?.problem || '',
    additional_info: problemScope?.additional_info || '',
    desired_solution: problemScope?.desired_solution || '',
    requested_result_type: sourcingOrder?.requested_result_type || 'benchmark',
    delivery_type: sourcingOrder?.delivery_type || 'standard',
    must_have_features: problemScope?.must_have_features || [],
    nice_to_have_features: problemScope?.nice_to_have_features || [],
    known_startups: problemScope?.known_startups || [],
    due_date: sourcingOrder?.due_date,
    department: sourcingOrder?.created_by?.person.department || '',
    public_uuid: sourcingOrder?.startup_list?.public_uuid,
    stakeholder: sourcingOrder?.created_by?.person,
    status: sourcingOrder?.status,
    updated_at: sourcingOrder?.updated_at,
    scope_files: [],
  };
};
