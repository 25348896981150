import { Add } from '@mui/icons-material';
import { Button, Divider, Stack, SxProps } from '@mui/material';
import { MouseEvent } from 'react';

const BaseAddButtonForAutocomplete = ({
  onClick,
  disabled,
  text,
  sxProps = {},
}: {
  onClick: (event: MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  text: string;
  sxProps?: SxProps;
}) => (
  <Stack>
    <Button
      disabled={disabled}
      sx={{
        width: '100%',
        borderRadius: 0,
        whiteSpace: 'nowrap',
        justifyContent: 'flex-start',
        paddingLeft: 2,
        ...sxProps,
      }}
      onClick={onClick}
      startIcon={<Add />}
    >
      {text}
    </Button>
    <Divider />
  </Stack>
);

export default BaseAddButtonForAutocomplete;
