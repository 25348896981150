import { useApolloClient } from '@apollo/client';
import { Autocomplete, TextField } from '@mui/material';
import { captureException } from '@sentry/react';
import { EnumTableInnovationThemesEnum } from 'apollo/generated/sdkInnovationManager';
import {
  PersonInnovationThemesFragmentDoc,
  useUpsertPersonInnovationThemesMutation,
  useGetPersonInnovationThemesQuery,
} from 'apollo/generated/sdkShared';
import { useSnackbar } from 'notistack';

type PersonInterestsOption = {
  value: string;
  name: string;
};

export const PersonInterestsAutocomplete = ({
  person,
}: {
  person: { id: number; full_name: string };
}) => {
  const personId = person.id;
  const { data } = useGetPersonInnovationThemesQuery({
    variables: { personId },
  });
  const { cache: apolloCache } = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();

  const personInnovationThemes = data?.people_by_pk?.innovation_themes;

  const innovationThemesOptions = data?.enum_table_innovation_themes || [];
  const value =
    personInnovationThemes?.map(theme => ({
      value: theme.innovation_theme,
      name: innovationThemesOptions.find(
        o => o.value === theme.innovation_theme,
      )?.name as string,
    })) || [];
  const options = innovationThemesOptions;

  const [upsertInterests] = useUpsertPersonInnovationThemesMutation();

  const onChange = (
    _e: unknown,
    newValue: PersonInterestsOption[] | undefined,
  ) => {
    upsertInterests({
      variables: {
        personId,
        personInnovationThemes:
          newValue?.map(theme => ({
            innovation_theme: theme.value as EnumTableInnovationThemesEnum,
            person_id: personId,
          })) || [],
      },
    }).catch(error => {
      captureException(error);
      enqueueSnackbar(
        'Interests update failed. Please refresh and try again.',
        {
          variant: 'error',
        },
      );
    });

    apolloCache.writeFragment({
      id: `people:${personId}`,
      fragment: PersonInnovationThemesFragmentDoc,
      data: {
        innovation_themes: newValue?.map(theme => ({
          __typename: 'person_innovation_themes',
          id: `${personId}-${theme.value}`,
          innovation_theme: theme.value as EnumTableInnovationThemesEnum,
        })),
      },
    });
  };

  return (
    <Autocomplete<PersonInterestsOption, true, false, false>
      multiple
      value={value}
      options={options}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      sx={{ width: '100%' }}
      autoHighlight
      renderInput={params => (
        <TextField
          {...params}
          label='Interests'
          variant='standard'
          placeholder='Choose interests'
          helperText={`Set interests to personalize what ${person.full_name} will see on their home page.`}
        />
      )}
      getOptionLabel={option => option.name}
      onChange={onChange}
    />
  );
};
