// material
import {
  Box,
  Card,
  Collapse,
  Container,
  List,
  ListItem,
  Stack,
  TextField,
  Typography,
  Zoom,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
// hooks
import useAuth from '../../hooks/useAuth';
// layouts
import AuthHeader from '../../components/auth/AuthHeader';
// components
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { captureException } from '@sentry/react';
import { useFindConnectionForUserMutation } from 'apollo/generated/sdkShared';
import { ConnectionButtons } from 'components/auth/ConnectionButtons';
import PoweredByGlassdollar from 'components/shared/PoweredByGlassdollar';
import {
  GLASSDOLLAR_SUPPORT_EMAIL,
  SEARCH_PARAMS,
  X_HASURA_ORGANIZATION_UUID,
} from 'config';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import { useSearchParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import Page from '../../components/Page';
import { LoginBackdrop } from './LoginBackdrop';
import { useGetLoginCopy } from './useGetLoginCopy';
import useMaintenanceMessage from './useMaintenanceMessage';
// ----------------------------------------------------------------------

// Tested with all the emails from our DB so far: 730+ emails
const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const RootStyle = styled(Page)(() => ({
  zIndex: 1000,
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 600,
  margin: 'auto',
  display: 'flex',
  position: 'relative',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const AcceptInvitationPage = () => {
  const { acceptInvitation } = useAuth();
  const [searchParams] = useSearchParams();
  const invitation = searchParams.get(SEARCH_PARAMS.invitation);
  const fullNameParam = searchParams.get(SEARCH_PARAMS.fullName) || '';
  const [fullName, setFullName] = useState(fullNameParam);
  const isValidName = fullName.trim().includes(' ');
  const hasNameError = fullName.trim().length > 0 && !isValidName;

  const handleAcceptInvitation = async () => {
    try {
      if (invitation) {
        await acceptInvitation({
          invitation,
          fullName: fullName.trim(),
        });
      }
    } catch (error) {
      captureException(error);
    }
  };

  return (
    <Stack direction='column' alignItems='center' spacing={2}>
      <Typography gutterBottom variant='h5' fontWeight={300}>
        Welcome to the team!
      </Typography>
      <TextField
        key='name'
        fullWidth
        label='Enter your full name to proceed'
        autoFocus
        value={fullName}
        onChange={e => setFullName(e.target.value)}
        error={hasNameError}
        helperText={hasNameError && 'Enter a valid full name'}
        onKeyUp={(event: React.KeyboardEvent<HTMLDivElement>) => {
          if (event.key === 'Enter' && isValidName) {
            handleAcceptInvitation();
          }
        }}
      />
      <Button
        fullWidth
        size='large'
        type='submit'
        variant='contained'
        data-testid='login-button'
        onClick={handleAcceptInvitation}
        disabled={!isValidName}
      >
        Accept Invitation
      </Button>
    </Stack>
  );
};

export default function LoginPage() {
  const [searchParams] = useSearchParams();
  const invitation = searchParams.get(SEARCH_PARAMS.invitation);
  const returnToParam = searchParams.get(SEARCH_PARAMS.returnTo);
  const returnTo =
    typeof returnToParam === 'string'
      ? decodeURIComponent(returnToParam)
      : undefined;

  const { loginPageType, startupListTitle } = useGetLoginCopy(returnTo);

  return (
    <>
      <LoginBackdrop
        loginPageType={loginPageType}
        startupListTitle={startupListTitle}
      />
      <RootStyle title='Login | GlassDollar' trackingTitle='Login'>
        <Container maxWidth='sm'>
          <ContentStyle>
            <Card sx={{ padding: 2 }}>
              {invitation ? (
                <AcceptInvitationPage />
              ) : (
                <EmailLoginPage returnTo={returnTo} />
              )}
            </Card>
            <Zoom
              in
              style={{
                transitionDelay: '800ms',
              }}
            >
              <Box
                sx={{
                  position: 'fixed',
                  bottom: 0,
                  left: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Card
                  sx={{
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    padding: 2,
                  }}
                >
                  <Stack
                    direction='row'
                    alignItems={'center'}
                    justifyContent='center'
                  >
                    <PoweredByGlassdollar />
                  </Stack>
                </Card>
              </Box>
            </Zoom>
          </ContentStyle>
        </Container>
      </RootStyle>
    </>
  );
}

const EmailLoginPage = ({ returnTo }: { returnTo?: string }) => {
  const [searchParams] = useSearchParams();
  const { login } = useAuth();

  const currentOrganization = useCurrentOrganizationFromContext();

  // Allow us to select a different login method from default method of the user
  // Username-Password-Authentication, email
  const searchParamsConnection = searchParams.get(SEARCH_PARAMS.connection);

  const { subtitle, title, loginPageType } = useGetLoginCopy(returnTo);

  useMaintenanceMessage({ loginPageType });

  const [email, setEmail] = useState('');
  const [error, setError] = useState({ email: false, validated: false });
  const [findConnectionForUser, { loading }] = useFindConnectionForUserMutation(
    {
      context: {
        headers: {
          [X_HASURA_ORGANIZATION_UUID]: currentOrganization.uuid,
        },
      },
    },
  );

  const handleLogin = async () => {
    try {
      if (!email) return;
      if (!emailRegex.test(email)) {
        setError({
          ...error,
          email: true,
        });
        return;
      }

      const { data } = await findConnectionForUser({ variables: { email } });

      const connection = data?.find_connection_for_user?.data?.connection;

      if (!connection || connection === 'invalid') {
        setError({
          ...error,
          validated: true,
        });
      } else {
        await login({
          email,
          returnTo,
          connection: searchParamsConnection || connection,
        });
      }
    } catch (error) {
      // TODO: Make sure users don't spam the login button
      // by returning a "too many attempts" login error from the validation
      captureException(error);
    }
  };

  const debounceHandleLogin = useDebouncedCallback(handleLogin, 1000, {
    leading: true,
    maxWait: 1000,
  });

  return (
    <Stack
      gap={1}
      component='form'
      autoComplete='on'
      onSubmit={(e: React.FormEvent) => e.preventDefault()}
    >
      <Stack gap={1} marginBottom={2} textAlign='center'>
        <Stack justifyContent='center' marginBottom={2}>
          <AuthHeader />
        </Stack>
        {title}
        {subtitle && subtitle}
      </Stack>
      <TextField
        autoFocus
        error={!!error.validated || !!error.email}
        onKeyUp={(event: React.KeyboardEvent<HTMLDivElement>) => {
          if (event.key === 'Enter') {
            debounceHandleLogin();
          }
        }}
        key='pass'
        autoComplete='username'
        name='username'
        value={email}
        onChange={e => {
          if (e.target.value.length > 2) {
            setError({ ...error, email: false, validated: false });
          }
          setEmail(e.target.value);
        }}
        fullWidth
        type='email'
        label={`${currentOrganization.name} email`}
        sx={{ caretColor: 'gray' }}
        InputProps={{
          endAdornment: (
            <LoadingButton
              type='submit'
              sx={{
                height: '100%',
                minWidth: '110px',
                marginLeft: 2,
              }}
              variant='contained'
              onClick={debounceHandleLogin}
              loadingIndicator={'Confirming...'}
              loading={loading}
            >
              Continue
            </LoadingButton>
          ),
        }}
      />
      <Collapse in={!!error.validated || !!error.email} sx={{ paddingX: 1 }}>
        {error.email && (
          <Typography color='error' variant='caption'>
            Please enter a valid email
          </Typography>
        )}
        {error.validated && <LoginErrorMessage />}
      </Collapse>

      <ConnectionButtons returnTo={returnTo} />
    </Stack>
  );
};

const SupportEmail = () => (
  <Typography
    component={'a'}
    color={'primary'}
    target='_blank'
    href={`mailto:${GLASSDOLLAR_SUPPORT_EMAIL}`}
    variant='caption'
    sx={{
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    }}
  >
    {GLASSDOLLAR_SUPPORT_EMAIL}
  </Typography>
);

const LoginErrorMessage = () => {
  const currentOrganization = useCurrentOrganizationFromContext();

  const allowedEmailDomains = currentOrganization.allowed_email_domains?.filter(
    d => d !== 'glassdollar.com',
  );

  const theme = useTheme();

  return (
    <>
      <Typography
        variant='caption'
        color={theme.palette.grey[900]}
        component={'div'}
      >
        <>
          {allowedEmailDomains && allowedEmailDomains.length > 0 && (
            <>
              You can login with an email address from:
              <List
                sx={{
                  listStyleType: 'disc',
                  paddingLeft: 4,
                  paddingTop: 0.3,
                  paddingBottom: 0.5,
                }}
                dense
              >
                {allowedEmailDomains.map(allowed_email_domain => (
                  <ListItem
                    key={allowed_email_domain}
                    sx={{ display: 'list-item', padding: 0 }}
                  >
                    {allowed_email_domain}
                  </ListItem>
                ))}
              </List>
            </>
          )}
          If you are unable to log in, please contact us at <SupportEmail /> or
          through our in-app support{' '}
          <Typography
            component='span'
            onClick={() => {
              // @ts-expect-error: TODO: FIXME
              window.$chatwoot.toggle();
            }}
            color={'primary'}
            variant='caption'
            sx={{
              '&:hover': {
                backgroundColor: 'transparent',
                textDecoration: 'underline',
                cursor: 'pointer',
              },
            }}
          >
            here
          </Typography>
        </>
      </Typography>
    </>
  );
};
