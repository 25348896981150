import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
} from '@mui/material';

export const BaseConfirmDeleteFileModal = ({
  title,
  body = 'Are you sure you want to delete this file?',
  onConfirm,
  onHide,
}: {
  title: string;
  body?: string;
  onConfirm: () => void;
  onHide: () => void;
}) => {
  return (
    <Dialog open fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ paddingBottom: 0 }}>
        <Typography sx={{ marginBottom: 1 }}>{body}</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant='text' color='inherit' onClick={onHide}>
          Cancel
        </Button>
        <Button onClick={onConfirm} variant='contained'>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
