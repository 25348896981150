import { EmailOutlined } from '@mui/icons-material';
import { Typography, Tooltip, Link } from '@mui/material';

export function EmailPersonCell({
  value,
}: {
  value:
    | {
        email: string;
        full_name?: string | null;
      }
    | null
    | undefined;
}) {
  if (!value) return <Typography variant='body2'>-</Typography>;

  return (
    <Tooltip title={`Send email to ${value.email}`}>
      <Link
        color='secondary'
        variant='body2'
        onClick={e => e.stopPropagation()}
        target='_blank'
        href={`mailto:${value.email}`}
        sx={({ spacing }) => ({
          position: 'relative',
          '&:hover': {
            '& svg': { opacity: 1, transform: 'scale(1)' },
            '& p': {
              transform: `translateX(${spacing(3)})`,
            },
          },
        })}
      >
        <EmailOutlined
          fontSize='small'
          sx={{
            opacity: 0,
            transition: 'all 0.2s ease-in-out',
            transitionDelay: '0.2s 0',
            transform: 'scale(0.6)',
            position: 'absolute',
            left: 0,
            top: '1px',
          }}
        />
        <Typography
          sx={{ transition: 'transform 0.2s ease-in-out' }}
          variant='body2'
        >
          {value.full_name || value.email}{' '}
        </Typography>
      </Link>
    </Tooltip>
  );
}
