import { useGetAllGeneralSettingsQuery } from 'apollo/generated/sdkShared';
import { ReactNode, createContext, useContext, useMemo } from 'react';

export const ABOUT_PAGE_TEMPLATE_KEY =
  'ORGANIZATION_PORTAL::ABOUT_PAGE_TEMPLATE';

type Settings = {
  VENTURE_CLIENTING_ACADEMY_LINK: string;
  HELP_CENTER_LINK: string;
  DISCOVERY_ITEM_NEW_LABEL_EXPIRY_DAYS: string;
  [ABOUT_PAGE_TEMPLATE_KEY]: string | null;
};

const defaultSettings: Settings = {
  VENTURE_CLIENTING_ACADEMY_LINK: '',
  HELP_CENTER_LINK: '',
  DISCOVERY_ITEM_NEW_LABEL_EXPIRY_DAYS: '',
  [ABOUT_PAGE_TEMPLATE_KEY]: null,
};

export const SettingsContext = createContext<{
  loading: boolean;
  settings: Settings;
}>({
  loading: true,
  settings: defaultSettings,
});

export const SettingsContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { data, loading } = useGetAllGeneralSettingsQuery();

  const normalizedSettings: Settings = useMemo(
    () =>
      (data?.general_settings || []).reduce((acc, { key, value }) => {
        if (defaultSettings.hasOwnProperty(key)) {
          acc[key as keyof Settings] = value;
        }

        return acc;
      }, defaultSettings),
    [data?.general_settings],
  );

  return (
    <SettingsContext.Provider
      value={{
        loading,
        settings: normalizedSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettingsContext = () => {
  const state = useContext(SettingsContext);

  if (!state) {
    throw new Error('using SettingsContext without the appropriate provider');
  }

  return state;
};
