import { Clear } from '@mui/icons-material';
import { Button, Chip, Stack, Typography } from '@mui/material';
import { useGetFeatureFlag } from 'contexts/FeatureFlagsContext';
import { useFeaturesUpdatesBanner } from 'contexts/FeaturesUpdatesBannerContext';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { PATH_ROOT } from 'routes/paths';
import { REQUESTS_IN_LEADS_STEP } from './utils';
import { BannerStyle } from './StyledBanner';

const UPDATE_TYPE = 'Aug-2024-Requested-Page-Moved';

export const FeaturesUpdateBanner = () => {
  const navigate = useNavigate();
  const { content: bannerContent, updateReleaseTimestamp } =
    useGetUpdatesReleaseBanner();

  const {
    isFeaturesUpdatesBannerVisible,
    setIsFeaturesUpdatesBannerVisible,
    setFeaturesTourNextStep,
  } = useFeaturesUpdatesBanner();

  if (
    !isFeaturesUpdatesBannerVisible ||
    window.localStorage.getItem(updateReleaseTimestamp)
  ) {
    return null;
  }

  const handleDismiss = () => {
    window.localStorage.setItem(updateReleaseTimestamp, 'true');
    setIsFeaturesUpdatesBannerVisible(false);
  };

  const handleTakeALook = () => {
    setFeaturesTourNextStep(REQUESTS_IN_LEADS_STEP);
    window.localStorage.setItem(updateReleaseTimestamp, 'true');
    setIsFeaturesUpdatesBannerVisible(false);
    navigate(PATH_ROOT.projectLeads.list);
  };

  return (
    <BannerStyle sx={({ palette }) => ({ background: palette.primary.main })}>
      {bannerContent}
      <Stack direction='row' gap={1} alignItems='center'>
        <Button
          variant='outlined'
          sx={{
            paddingY: 0.5,
            fontSize: '0.75rem',
            color: 'white',
            border: '1px solid white',
            '&:hover': {
              border: '1px solid white',
            },
          }}
          onClick={handleTakeALook}
        >
          Take a look
        </Button>
        <Button
          sx={{
            color: 'white',
            minWidth: '0 !important',
          }}
          onClick={handleDismiss}
        >
          <Clear fontSize='medium' />
        </Button>
      </Stack>
    </BannerStyle>
  );
};

export const useGetUpdatesReleaseBanner = () => {
  const { isEnabled } = useGetFeatureFlag('ff_new_features_released');

  const content = useMemo(
    () => (
      <Stack direction='row' gap={1}>
        <Chip
          label='NEW'
          size='small'
          sx={{
            backgroundColor: 'white',
            color: 'primary.main',
            fontWeight: 800,
            borderRadius: '0.25rem',
          }}
        />
        <Typography variant='body2' fontWeight='800' color='white'>
          Incoming requests now appear in leads!{' '}
          <span style={{ fontWeight: 400 }}>
            The request form is now in the Organization portal.
          </span>
        </Typography>
      </Stack>
    ),
    [],
  );

  const isUpdateReleased =
    isEnabled && !window.localStorage.getItem(UPDATE_TYPE);

  return {
    content,
    updateReleaseTimestamp: UPDATE_TYPE,
    isNewUpdateReleased: !!isUpdateReleased,
  };
};
