import { styles } from './styles';
import { Page, Text, View, Link, Image, StyleSheet } from '@react-pdf/renderer';
import { formatMoney } from '../../../../utils/formatNumber';
import { StartupForPDFExport } from './types';
import { truncateWithEllipsis } from 'utils/general';
import { Pager } from './Pager';
import { GREY } from 'theme/palette';
import { FALLBACK_IMAGE } from 'components/AvatarGroup';

const sectionStyles = StyleSheet.create({
  header: {
    fontSize: 14,
    marginBottom: 10,
    color: GREY[600],
  },
});

export const StartupPDFPage = ({
  currentStartup,
  categorizedStartupOrder,
  landscapeCategoriesTitles,
}: {
  currentStartup: StartupForPDFExport;
  categorizedStartupOrder: number | null;
  landscapeCategoriesTitles: string[] | null;
}) => {
  const category =
    landscapeCategoriesTitles && landscapeCategoriesTitles.length >= 1
      ? landscapeCategoriesTitles[0]
      : '';

  return (
    <Page
      size='A4'
      orientation='landscape'
      style={styles.contentPage}
      wrap={false}
    >
      <Pager />
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Text style={{ fontSize: 30, marginBottom: 20 }} fixed>
          Startup Profile
        </Text>
        <View
          fixed
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 20,
          }}
        >
          {!landscapeCategoriesTitles && categorizedStartupOrder && (
            <Text style={{ marginRight: 10, fontSize: 25, color: 'grey' }}>
              #{categorizedStartupOrder}
            </Text>
          )}
          <View style={{ display: 'flex', flexDirection: 'column' }}>
            <Link
              style={{ fontSize: 20, textDecoration: 'none' }}
              src={currentStartup.website || '#'}
            >
              <Text>{currentStartup.name}</Text>
            </Link>
          </View>
          <View style={{ marginRight: 10, marginLeft: 'auto' }}>
            <Image
              source={currentStartup.logo_url || FALLBACK_IMAGE}
              style={{ height: 50, maxWidth: 120, borderRadius: 6 }}
            />
          </View>
        </View>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <View style={{ flexGrow: 1, flexBasis: 0 }}>
            <Text style={sectionStyles.header}>COMPANY OVERVIEW</Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: 20,
              }}
            >
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  fontSize: 12,
                  color: 'grey',
                  marginRight: 50,
                }}
              >
                <Text style={{ marginBottom: 10 }}>Website</Text>
                <Text style={{ marginBottom: 10 }}>Crunchbase</Text>
                <Text style={{ marginBottom: 10 }}>Linkedin</Text>
                <Text style={{ marginBottom: 10 }}>Founded Year</Text>
                <Text style={{ marginBottom: 10 }}>HQ</Text>
                <Text style={{ marginBottom: 10 }}>Team Size</Text>
                <Text style={{ marginBottom: 10 }}>Total Funding</Text>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  fontSize: 12,
                  marginRight: 10,
                }}
              >
                <Link
                  src={currentStartup.website || '#'}
                  style={{ marginBottom: 10 }}
                >
                  <Text>
                    {truncateWithEllipsis(currentStartup.website || '', 35)}
                  </Text>
                </Link>
                {currentStartup.crunchbase_url ? (
                  <Link
                    style={{ marginBottom: 10 }}
                    src={currentStartup.crunchbase_url}
                  >
                    <Text>
                      {truncateWithEllipsis(currentStartup.crunchbase_url, 35)}
                    </Text>
                  </Link>
                ) : (
                  <Text style={{ marginBottom: 10 }}>-</Text>
                )}
                {currentStartup.linkedin_url ? (
                  <Link
                    style={{ marginBottom: 10 }}
                    src={currentStartup.linkedin_url}
                  >
                    <Text>
                      {truncateWithEllipsis(currentStartup.linkedin_url, 35)}
                    </Text>
                  </Link>
                ) : (
                  <Text style={{ marginBottom: 10 }}>-</Text>
                )}
                <Text style={{ marginBottom: 10 }}>
                  {currentStartup.founded_year || '-'}
                </Text>
                <Text style={{ marginBottom: 10 }}>
                  {currentStartup.hq || '-'}
                </Text>
                <Text style={{ marginBottom: 10 }}>
                  {currentStartup.employees_count || '-'}
                </Text>
                <Text style={{ marginBottom: 10 }}>
                  {currentStartup.funding
                    ? formatMoney(currentStartup.funding)
                    : '-'}
                </Text>
              </View>
            </View>
            {currentStartup.investors?.length && (
              <View wrap={false}>
                <Text style={sectionStyles.header}>INVESTORS</Text>
                <Text
                  style={{ fontSize: 12, marginBottom: 20, lineHeight: 1.5 }}
                >
                  {currentStartup.investors!.join(', ')}
                </Text>
              </View>
            )}
            {currentStartup.startup_corporate_customers?.length && (
              <View wrap={false}>
                <Text style={sectionStyles.header}>
                  CUSTOMERS / PILOTS / POCs
                </Text>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                  }}
                >
                  {currentStartup.startup_corporate_customers.map(customer => (
                    <View
                      key={`${category}-${currentStartup.id}-${customer.corporate_customer.domain}`}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginRight: 5,
                        marginBottom: 5,
                        alignItems: 'center',
                        border: '1px solid #F4F6F8',
                        borderRadius: 10,
                        backgroundColor: '#F4F6F8',
                        padding: 5,
                      }}
                    >
                      <Image
                        source={
                          customer.corporate_customer.logo_url || FALLBACK_IMAGE
                        }
                        style={{ height: 15, width: 15, marginRight: 2 }}
                      />
                      <Text style={{ fontSize: 12 }}>
                        {customer.corporate_customer.domain}
                      </Text>
                    </View>
                  ))}
                </View>
              </View>
            )}
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              flexBasis: 0,
            }}
          >
            {landscapeCategoriesTitles && (
              <View wrap={false}>
                <Text style={sectionStyles.header}>LANDSCAPE CATEGORY</Text>
                <Text style={{ fontSize: 12, marginBottom: 20 }}>
                  {landscapeCategoriesTitles.join(', ')}
                </Text>
              </View>
            )}

            <View wrap={false}>
              <Text style={sectionStyles.header}>SUMMARY</Text>
              <Text style={{ fontSize: 12 }}>
                {currentStartup.long_description}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
};
