import {
  Avatar as MuiAvatar,
  AvatarGroup as MuiAvatarGroup,
  Box,
  SxProps,
  Theme,
  Tooltip,
  useTheme,
  AvatarProps as MuiAvatarProps,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

type ClickHandler = (id?: number) => void;

/**
 * DEPRECATED
 */
const useStyles = makeStyles((theme: Theme) => ({
  avatar: ({
    size,
    hasSummary,
    tighten,
  }: {
    size: number;
    hasSummary: boolean;
    tighten: boolean;
  }) => {
    const styles = hasSummary
      ? {
          // Icon with the +XX customers text
          '&.MuiAvatarGroup-avatar:first-of-type': {
            background: 'transparent !important',
            marginLeft: '0 !important',
            color: `${theme.palette.grey[900]} !important`,
            border: '0 !important',
          },
          '&.MuiAvatarGroup-avatar:not(:first-of-type)': {
            justifyContent: 'flex-end',
            background: 'transparent !important',
            marginLeft: tighten ? '-8px' : undefined,
          },
          // First icon because of flex-direction: row-reverse
          '&.MuiAvatarGroup-avatar:last-of-type': {
            marginLeft: '0 !important',
          },
          '& .MuiAvatar-root': {
            marginLeft: '0 !important',
          },
        }
      : {
          '&.MuiAvatarGroup-avatar:not(:last-of-type)': {
            background: 'transparent !important',
            marginLeft: tighten ? '-8px !important' : undefined,
          },
          // First icon because of flex-direction: row-reverse
          '&.MuiAvatarGroup-avatar:last-of-type': {
            background: 'transparent !important',
            border: '0 !important',
          },
        };

    return {
      width: size,
      height: size,
      ...styles,
    };
  },
  group: {
    justifyContent: 'flex-end',
    display: 'inline-flex',
    marginLeft: '0px',
  },
}));

type AvatarData = {
  src: string | null | undefined;
  tooltipTitle: React.ReactNode;
  badge?: () => JSX.Element | null;
  id?: number | string;
  avatarOverrideStyles?: SxProps;
};

export const Avatar = ({
  avatarOverrideStyles,
  children,
  onClickHandler,
  clickId,
  fallbackSrc,
  ...others
}: {
  avatarOverrideStyles?: SxProps;
  onClickHandler?: ClickHandler;
  clickId?: number | string;
  fallbackSrc: string;
} & MuiAvatarProps) => {
  const avatarStyles = onClickHandler ? { cursor: 'pointer' } : {};
  const { palette } = useTheme();

  return (
    <MuiAvatar
      imgProps={{ loading: 'lazy' }}
      sx={{
        ...avatarStyles,
        border: `1px solid ${palette.grey[300]} !important`,
        width: '100%',
        height: '100%',
        ...(avatarOverrideStyles || {}),
        '& img': {
          objectFit: 'contain',
        },
      }}
      {...others}
    >
      <img
        onError={({ currentTarget }) => {
          currentTarget.src = fallbackSrc;
          currentTarget.onerror = null;
        }}
        alt={`avatar ${others.src}`}
        src={others.src || fallbackSrc}
        width={'100%'}
        onClick={e => {
          if (onClickHandler) {
            e.stopPropagation();
            onClickHandler && (clickId ?? onClickHandler(clickId));
          }
        }}
      />
      {children}
    </MuiAvatar>
  );
};

function AvatarWithTooltip({
  avatarData,
  fallbackSrc,
  className,
  // eslint-disable-next-line
  onClickHandler,
  ...others
}: {
  avatarData: AvatarData;
  fallbackSrc: string;
  onClickHandler?: ClickHandler;
} & MuiAvatarProps) {
  const { src, tooltipTitle, id, avatarOverrideStyles, badge } = avatarData;

  return (
    <Tooltip
      sx={({ palette }) => ({
        position: 'relative',
        color: palette.grey[900],
      })}
      title={tooltipTitle}
    >
      <Box
        sx={{
          position: 'relative',
        }}
        className={className}
      >
        <Avatar
          avatarOverrideStyles={avatarOverrideStyles}
          fallbackSrc={fallbackSrc}
          clickId={id}
          src={src || undefined}
          {...others}
        />
        {badge && badge()}
      </Box>
    </Tooltip>
  );
}

const AvatarGroup = ({
  avatarsData,
  fallbackSrc,
  size,
  max,
  spacing = 'medium',
  onClickHandler,
  gap = 0,
  tighten = false,
  keyHelper = '', // Utility to avoid repeated keys
}: {
  avatarsData: AvatarData[];
  gap?: number;
  fallbackSrc: string;
  size: number;
  max: number;
  spacing?: 'small' | 'medium' | number;
  onClickHandler?: ClickHandler;
  tighten?: boolean;
  keyHelper?: string;
}) => {
  const classes = useStyles({
    size,
    hasSummary: Array.isArray(avatarsData) && avatarsData.length > max,
    tighten,
  });

  if (!avatarsData || avatarsData.length < 1) return null;

  return max >= 2 ? (
    <MuiAvatarGroup
      classes={{
        root: classes.group,
        avatar: classes.avatar,
      }}
      max={max}
      spacing={spacing}
      // TODO: Make marginLeft depend on gap
      sx={{ gap, alignItems: 'center' }}
    >
      {avatarsData.map((avatarData, i) => (
        <AvatarWithTooltip
          onClickHandler={onClickHandler}
          fallbackSrc={fallbackSrc}
          key={`${i}-${keyHelper}-${avatarData.src}-${avatarData.id}`}
          avatarData={avatarData}
        />
      ))}
    </MuiAvatarGroup>
  ) : (
    <AvatarWithTooltip
      onClickHandler={onClickHandler}
      fallbackSrc={fallbackSrc}
      classes={{ root: classes.avatar }}
      avatarData={avatarsData[0]}
    />
  );
};

export const LogoAvatar = ({
  src,
  size,
  sx,
}: {
  src: string;
  size: number;
  sx?: SxProps;
}) => {
  const classes = useStyles({ size, hasSummary: false, tighten: false });
  return (
    <MuiAvatar
      classes={{ root: classes.avatar }}
      key={src}
      sx={sx}
      imgProps={{ sx: { objectFit: 'contain' } }}
    >
      <img alt={`avatar ${src}`} src={src} />
    </MuiAvatar>
  );
};

export const FALLBACK_IMAGE = '/static/default-img-placeholder.jpeg';

export const FALLBACK_SUPPLIER_LOGO_URL =
  '/static/icons/fallback_supplier_logo.svg';

export const FALLBACK_REFERENCED_CUSTOMER_LOGO_URL =
  '/static/icons/fallback_referenced_customer_logo.svg';

// TODO: Find reason that causes AvatarGroup to flicker and remove this
export default React.memo(
  AvatarGroup,
  function arePropsEqual(oldProps, newProps) {
    return (
      oldProps.avatarsData.length === newProps.avatarsData.length &&
      oldProps.avatarsData.every((oldPoint, index) => {
        const newPoint = newProps.avatarsData[index];
        return oldPoint.src === newPoint.src;
      })
    );
  },
);
