import { CopyAll } from '@mui/icons-material';
import {
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

const DetailsDrawerSkeleton = () => {
  return (
    <Stack gap={2}>
      <Stack marginTop={2} paddingX={2} direction='row' gap={2}>
        <Stack direction='row' justifyContent='space-between' width='100%'>
          <Stack gap={0.5} flex={1}>
            <Skeleton variant='text' width='140px' />
            <Skeleton variant='text' width='140px' />
          </Stack>
          <Stack gap={0.5} flex={1} alignItems='end'>
            <Skeleton variant='text' width='140px' />
            <Skeleton variant='text' width='140px' />
          </Stack>
        </Stack>
      </Stack>
      <Divider />
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        paddingX={2}
        marginBottom={5}
      >
        <Typography
          variant='body2'
          fontSize='0.8rem'
          display='flex'
          flexDirection='row'
          gap={1}
        >
          <Skeleton variant='text' width={130} />
          <IconButton size='small' color='secondary'>
            <CopyAll fontSize='small' />
          </IconButton>
        </Typography>
        <Tooltip title='2022-01-01'>
          <Typography
            variant='body2'
            fontSize='0.7rem'
            display='flex'
            flexDirection='row'
            gap={1}
          >
            Joined <Skeleton variant='text' width={60} />
          </Typography>
        </Tooltip>
      </Stack>
      <Stack gap={4} paddingX={2} paddingBottom={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField label='User type' select fullWidth variant='standard'>
              <MenuItem value=''>
                <Skeleton variant='text' width={200} />
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField label='Priority' select variant='standard' fullWidth>
              <MenuItem value=''>
                <Skeleton variant='text' width={200} />
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField label='Maturity' select fullWidth variant='standard'>
              <MenuItem value=''>
                <Skeleton variant='text' width={200} />
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField label='Interests' select fullWidth variant='standard'>
              <MenuItem value=''>
                <Skeleton variant='text' width={200} />
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <Typography>PoCs involved in</Typography>
              <Skeleton variant='text' width={200} />
              <Typography>Leads involved in</Typography>
              <Skeleton variant='text' width={200} />
              <Typography>Notes written</Typography>
              <Skeleton variant='text' width={200} />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack>
              <Skeleton variant='rectangular' width={200} />
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
};

export default DetailsDrawerSkeleton;
