import {
  Autocomplete,
  AutocompleteRenderGetTagProps,
  Chip,
  Skeleton,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { varFadeIn } from 'components/animate';
import { autocompleteSx } from 'components/base/serverFilters/BaseServerFiltersContext';
import { motion } from 'framer-motion';
import { isEmpty } from 'lodash';
import { HTMLAttributes, ReactNode } from 'react';

export type ServerFilterOption<T> = {
  label: string;
  value: T;
};

const BaseServerFilterAutocomplete = <T,>({
  options,
  value,
  defaultValue,
  handleChange,
  loading,
  placeholder = 'Select',
  sxProps = {},
  renderTags,
  dataTestId,
  renderOption = undefined,
}: {
  options: ServerFilterOption<T>[];
  value: ServerFilterOption<T>[];
  defaultValue: ServerFilterOption<T>[];
  handleChange: (newValue: ServerFilterOption<T>[]) => void;
  loading?: boolean;
  placeholder?: string;
  sxProps: SxProps;
  dataTestId?: string;
  renderTags?: (
    value: ServerFilterOption<T>[],
    getTagProps: AutocompleteRenderGetTagProps,
  ) => ReactNode;
  renderOption?: (
    props: HTMLAttributes<HTMLLIElement>,
    option: ServerFilterOption<T>,
  ) => ReactNode;
}) => {
  if (loading) {
    return (
      <motion.div {...varFadeIn}>
        <Skeleton
          variant='rectangular'
          sx={({ spacing }) => ({
            height: spacing(4),
            width: '90px',
            backgroundColor: 'grey.300',
          })}
        />
      </motion.div>
    );
  }

  return (
    <motion.div {...varFadeIn}>
      <Autocomplete
        disableListWrap
        autoHighlight
        openOnFocus
        disableCloseOnSelect
        multiple
        isOptionEqualToValue={(option, value) => option.value === value.value}
        options={options}
        getOptionLabel={option => option.label}
        value={value}
        defaultValue={defaultValue}
        noOptionsText={<Typography>No options</Typography>}
        onChange={(event, newValue) => handleChange(newValue)}
        data-testid={dataTestId}
        // @ts-expect-error: TODO: Fix this
        sx={{ ...autocompleteSx, ...sxProps }}
        componentsProps={{
          paper: { sx: { width: 'fit-content', whiteSpace: 'nowrap' } },
        }}
        renderOption={
          renderOption
            ? (props, option) => renderOption(props, option)
            : undefined
        }
        renderTags={
          renderTags
            ? renderTags
            : (value, getTagProps) =>
                value.map((option, index, array) =>
                  index === 0 ? (
                    <Chip
                      {...getTagProps({ index })}
                      key={index}
                      size='small'
                      label={option.label}
                      sx={({ spacing }) => ({
                        margin: 0,
                        minWidth: spacing(5),
                      })}
                    />
                  ) : index === 1 ? (
                    <Typography key={index}>+{array.length - 1}</Typography>
                  ) : null,
                )
        }
        renderInput={params => (
          <TextField
            {...params}
            sx={{ width: '100%' }}
            InputProps={{
              sx: { height: '100%' },
              ...params.InputProps,
            }}
            variant='standard'
            placeholder={isEmpty(value) ? placeholder : ''}
          />
        )}
      />
    </motion.div>
  );
};

export default BaseServerFilterAutocomplete;
