import { ReactNode, createContext, useCallback } from 'react';
// material
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useLocalStorage from 'hooks/useLocalStorage';

// ----------------------------------------------------------------------
type CollapseDrawerContextProps = {
  isCollapsed: boolean;
  shouldShowToggle: boolean;
  toggleCollapse: () => void;
};

const initialState: CollapseDrawerContextProps = {
  isCollapsed: false,
  shouldShowToggle: false,
  toggleCollapse: () => {},
};

const CollapseDrawerContext = createContext(initialState);

type CollapseDrawerProviderProps = {
  children: ReactNode;
};
// TODO: delete this
function CollapseDrawerProvider({ children }: CollapseDrawerProviderProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [_isCollapsed, setCollapse] = useLocalStorage(
    'menu-collapse',
    isMobile,
  );
  const isCollapsed = isMobile ? true : _isCollapsed;

  const toggleCollapse = useCallback(() => {
    setCollapse(prev => !prev);
  }, [setCollapse]);

  return (
    <CollapseDrawerContext.Provider
      value={{
        isCollapsed,
        shouldShowToggle: !isMobile,
        toggleCollapse,
      }}
    >
      {children}
    </CollapseDrawerContext.Provider>
  );
}
export { CollapseDrawerProvider, CollapseDrawerContext };
