import { createContext, ReactNode, useContext, useState } from 'react';

export type ViewingOrganizationPortalAs = 'admin' | 'stakeholder';

export type ViewingOrganizationPortalAsContextType = {
  viewingOrganizationPortalAs: ViewingOrganizationPortalAs;
  toggleViewingOrganizationPortalAs: () => void;
};

export const ViewingOrganizationPortalAsContext =
  createContext<ViewingOrganizationPortalAsContextType>({
    viewingOrganizationPortalAs: 'admin',
    toggleViewingOrganizationPortalAs: () => {},
  });

export const ViewingOrganizationPortalAsProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [viewingOrganizationPortalAs, setViewingOrganizationPortalAs] =
    useState<ViewingOrganizationPortalAs>('admin');

  const toggleViewingOrganizationPortalAs = () =>
    setViewingOrganizationPortalAs(prev =>
      prev === 'admin' ? 'stakeholder' : 'admin',
    );

  return (
    <ViewingOrganizationPortalAsContext.Provider
      value={{ viewingOrganizationPortalAs, toggleViewingOrganizationPortalAs }}
    >
      {children}
    </ViewingOrganizationPortalAsContext.Provider>
  );
};

export const useViewingOrganizationPortalAs =
  (): ViewingOrganizationPortalAsContextType => {
    const context = useContext(ViewingOrganizationPortalAsContext);
    if (!context) {
      throw new Error(
        'useViewingOrganizationPortalAs must be used within a ViewingOrganizationPortalAsProvider',
      );
    }
    return context;
  };
