import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';

const LightTooltip = styled(
  ({
    className,
    // eslint-disable-next-line
    noMaxWidth,
    ...props
  }: TooltipProps & { noMaxWidth?: boolean }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ),
)(({ theme, noMaxWidth }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    boxShadow: theme.shadows[2],
    ...(noMaxWidth ? { maxWidth: 'none' } : {}),
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
}));

export default LightTooltip;
