import { styled, Theme } from '@mui/material';
import { DataGridPro, DataGridProProps } from '@mui/x-data-grid-pro';

export const StartupsDataGrid = styled(
  ({
    // eslint-disable-next-line
    isDisabled,
    ...props
  }: DataGridProProps & { isDisabled?: boolean; theme: Theme }) => (
    <DataGridPro {...props} />
  ),
)(({ theme }) => ({
  '& .MuiDataGrid-renderingZone': {
    maxHeight: 'fit-content !important',
  },

  '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
    outline: 'none',
  },

  '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },

  '& .MuiDataGrid-cell': {
    maxHeight: 'fit-content !important',
    padding: '10px 5px',
    border: '1px solid rgba(145, 158, 171, 0.08) ',
    '&:nth-of-type(n+2):nth-of-type(-n+20):not([data-field = "name"])': {
      background: theme.palette.grey['50012'],
    },
  },

  '& .MuiDataGrid-columnHeader': {
    paddingLeft: '5px',
  },

  '& .MuiDataGrid-row': {
    maxHeight: 'none !important',
    height: theme.spacing(10),
  },

  '& .MuiDataGrid-cellCheckbox .MuiCheckbox-root.Mui-disabled': {
    color: theme.palette.primary.main,
  },

  '& .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },

  '& .MuiDataGrid-cell:hover .company-name': {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  '& .MuiDataGrid-pinnedColumnHeaders--right': {
    display: 'none !important',
  },

  '& .MuiDataGrid-pinnedColumns--right': {
    boxShadow: 'unset',
    background: 'transparent',

    '& .MuiDataGrid-row': {
      background: 'transparent !important',
    },

    '& .MuiDataGrid-cell': {
      borderLeft: 'none',
      padding: '0px !important',
      background: 'transparent',
    },
  },

  '& .company-details': {
    cursor: 'pointer',
    padding: '0px !important',

    '& > div': {
      display: 'flex',
      flex: 1,
      cursor: 'pointer',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      paddingLeft: theme.spacing(2),
    },
  },

  '& .company-name': {
    fontWeight: 500,
    color: `${theme.palette.secondary.main} !important`,
  },

  '& .MuiDataGrid-pinnedColumns': {
    boxShadow: 'unset',
  },
}));
