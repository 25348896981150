import { Document, Font } from '@react-pdf/renderer';
import { useMemo } from 'react';
import _ from 'lodash';
import { IntroPage } from './IntroPage';
import { PublicDescriptionPage } from './PublicDescriptionPage';
import { ComparisonMatrixPageGroup } from './ComparisonMatrixPageGroup';
import { AllStartupsTablePage } from './AllStartupsTablePage';
import { CategoryForPDFExport } from './types';
import { StartupListForDetail } from '../../../../@types/startupList';
import { StartupPDFPage } from './StartupPDFPage';

type StartupListForBenchmarkPDF = Pick<
  StartupListForDetail,
  'id' | 'title' | 'public_description' | 'public_uuid'
>;

export const BenchmarkPDF = ({
  startupList,
  organization,
  category,
  title,
  description,
}: {
  startupList: StartupListForBenchmarkPDF;
  organization: {
    logo_url: string;
  };
  category: CategoryForPDFExport;
  title: string;
  description: string | undefined | null;
}) => {
  // Make sure that words are not broken in the middle in the PDF
  Font.registerHyphenationCallback(word => [word]);

  if (!startupList.public_uuid) {
    throw new Error('Startup list does not have a public uuid');
  }

  const orderedShortlistCategorizedStartups = useMemo(() => {
    return _.orderBy(
      category.categorized_suppliers,
      ['matrix_table_score', 'id'],
      ['desc', 'asc'],
    )
      .filter(s => s.is_in_matrix)
      .map((startup, index) => {
        return { ...startup, order: index + 1 };
      });
  }, [category.categorized_suppliers]);

  const orderedAllStartups = useMemo(() => {
    return _.orderBy(
      category.categorized_suppliers,
      ['all_startups_table_score', 'id'],
      ['desc', 'asc'],
    ).map((startup, index) => {
      return { ...startup, order: index + 1 };
    });
  }, [category.categorized_suppliers]);

  return (
    <Document title={title} subject={title}>
      <IntroPage organizationLogoURL={organization.logo_url} title={title} />
      <PublicDescriptionPage description={description} />
      <ComparisonMatrixPageGroup
        startupList={startupList}
        startups={orderedShortlistCategorizedStartups}
        customColumnsSchema={category.custom_dimensions_schema}
      />
      <AllStartupsTablePage categorizedStartups={orderedAllStartups} />
      {orderedShortlistCategorizedStartups.map(categorizedStartup => (
        <StartupPDFPage
          key={categorizedStartup.id}
          currentStartup={categorizedStartup.startup}
          categorizedStartupOrder={categorizedStartup.order}
          landscapeCategoriesTitles={null}
        />
      ))}
    </Document>
  );
};
