import _ from 'lodash';
import { useMemo } from 'react';
import { CUSTOM_DIMENSION_HEADER_FIELD_PREFIX } from './useGetColumns';
import {
  CategorizedStartupForMatrix,
  DecoratedStartup,
} from './StartupsTableContext';

const useGetRows = ({
  categorizedStartups,
  filterMatrix = true,
}: {
  categorizedStartups: CategorizedStartupForMatrix[];
  filterMatrix?: boolean;
}): DecoratedStartup[] => {
  const decoratedCategorizedStartups = useMemo(() => {
    const startups = categorizedStartups
      // TODO: change so the request does the filtering and not the frontend
      .filter(s => {
        return !s.is_archived && (filterMatrix ? s.is_in_matrix : true);
      })
      .map(
        ({
          id,
          startup,
          custom_dimensions_json_v1,
          voters,
          is_shortlisted,
          is_in_matrix,
          is_recommended,
          matrix_table_score,
          all_startups_table_score,
        }) => ({
          ...startup,
          custom_dimensions_json_v1,
          ...Object.keys(custom_dimensions_json_v1).reduce(
            (acc, featureName) => {
              return {
                ...acc,
                [`${CUSTOM_DIMENSION_HEADER_FIELD_PREFIX}-${featureName}`]:
                  custom_dimensions_json_v1[featureName].value,
              };
            },
            {},
          ),
          voters,
          categorized_startup_id: id,
          is_shortlisted,
          is_recommended,
          is_in_matrix,
          all_startups_table_score,
          matrix_table_score,
        }),
      );

    return startups;
  }, [categorizedStartups, filterMatrix]);

  const sortedDecoratedCategorizedStartups = useMemo(() => {
    return _.orderBy(
      decoratedCategorizedStartups,
      ['matrix_table_score', 'categorized_startup_id'],
      ['desc', 'asc'],
    ).map((orderedStartup, index) => {
      return { ...orderedStartup, order: index + 1 };
    });
  }, [decoratedCategorizedStartups]);

  return sortedDecoratedCategorizedStartups;
};

export { useGetRows };
