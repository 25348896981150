import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const EmailAllCopy = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox='0 0 20 16'>
      <path
        d='M2.0321 15.6858C1.56903 15.6858 1.17708 15.5253 0.85625 15.2045C0.535417 14.8837 0.375 14.4918 0.375 14.0289V3.94556H1.75V14.0289C1.75 14.1111 1.77643 14.1786 1.82929 14.2315C1.88215 14.2843 1.94976 14.3108 2.0321 14.3108H16.3637V15.6858H2.0321ZM5.24044 12.4774C4.77737 12.4774 4.38542 12.317 4.06458 11.9962C3.74375 11.6753 3.58333 11.2835 3.58333 10.8206V1.75954C3.58333 1.29662 3.74375 0.904745 4.06458 0.583911C4.38542 0.263078 4.77737 0.102661 5.24044 0.102661H17.9679C18.431 0.102661 18.8229 0.263078 19.1438 0.583911C19.4646 0.904745 19.625 1.29662 19.625 1.75954V10.8206C19.625 11.2835 19.4646 11.6753 19.1438 11.9962C18.8229 12.317 18.431 12.4774 17.9679 12.4774H5.24044ZM11.6042 7.92229L18.25 3.12743C18.25 2.85488 18.2427 2.58431 18.228 2.31572C18.2133 2.04714 18.1913 1.78536 18.1618 1.53037L11.6042 6.24066L5.02892 1.54802C4.99943 1.803 4.98033 2.06333 4.97162 2.32901C4.96276 2.59454 4.95833 2.86068 4.95833 3.12743L11.6042 7.92229Z'
        fill='#919EAB'
      />
    </SvgIcon>
  );
};

export default EmailAllCopy;
