import {
  Tooltip,
  Typography,
  Box,
  useTheme,
  MenuItem,
  Link,
} from '@mui/material';
import { CategorizedStartupForHeader } from '../details/Header';
import { SharedCategorizedSupplier } from '../../../../@types/shared';
import { useListEnrichment } from 'components/admin/useBulkEnrichment';
import { AutoAwesome as AutoAwesomeIcon } from '@mui/icons-material';

type EnrichListMenuItemProps = {
  categorizedStartups:
    | CategorizedStartupForHeader[]
    | SharedCategorizedSupplier[];
  title: string;
  isListEmpty: boolean;
};

export default function EnrichListMenuItem({
  categorizedStartups,
  title,
  isListEmpty,
}: EnrichListMenuItemProps) {
  const { palette } = useTheme();

  const { enrichList, loading: isListEnrichmentLoading } = useListEnrichment(
    categorizedStartups,
    title,
  );

  return (
    <Tooltip
      title={
        isListEmpty
          ? 'Please add at least one startup to be able to enrich'
          : ''
      }
      placement='right'
    >
      <Box>
        <MenuItem
          sx={{ color: palette.secondary.main }}
          component={Link}
          onClick={() => enrichList()}
          disabled={isListEmpty || isListEnrichmentLoading}
        >
          <AutoAwesomeIcon fontSize='small' sx={{ marginRight: 0.5 }} />
          <Typography variant='body1'>Enrich list</Typography>
        </MenuItem>
      </Box>
    </Tooltip>
  );
}
