import { styled } from '@mui/material';
import BaseEllipsisTypographyOneLine from './BaseEllipsisTypographyOneline';

export const BaseStyledSmallText = styled(BaseEllipsisTypographyOneLine)(
  ({ theme: { palette } }) => ({
    color: palette.text.secondary,
    fontSize: '12px',
    fontWeight: 600,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  }),
);
