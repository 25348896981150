import { Theme, useTheme } from '@mui/material';
import { EnumTableChallengeStatusesEnum } from 'apollo/generated/sdkInnovationManager';
import { BaseStatusSelect } from 'components/base/BaseStatusSelect';
import { useMemo } from 'react';
import { useUpdateChallengeMutation } from 'apollo/generated/sdkShared';

const getChallengeStatusLabelStyleMap = (
  theme: Theme,
): Record<
  EnumTableChallengeStatusesEnum,
  { backgroundColor: string; color: string }
> => ({
  ['active' as const]: {
    backgroundColor: '#00AB55',
    color: 'white',
  },
  ['discarded' as const]: {
    backgroundColor: theme.palette.error.light,
    color: 'white',
  },
});

export const ChallengeStatusSelect = ({
  challengeId,
  challengeStatus,
  isReadonly,
}: {
  challengeId: number;
  challengeStatus: EnumTableChallengeStatusesEnum;
  isReadonly?: boolean;
}) => {
  const theme = useTheme();
  const [updateChallenge] = useUpdateChallengeMutation();

  const labelStyleMap = useMemo(
    () => getChallengeStatusLabelStyleMap(theme),
    [theme],
  );

  const labelStyle = labelStyleMap[challengeStatus] || {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  };

  const updateChallengeStatus = async (
    newStatus: EnumTableChallengeStatusesEnum,
  ) => {
    await updateChallenge({
      variables: {
        id: challengeId,
        payload: { status: newStatus },
      },
    });
  };

  const challengeStatusOptionLabelsMap = useMemo(
    () => ({
      active: 'Active',
      discarded: 'Discarded',
    }),
    [],
  );

  const challengeStatusOptions = useMemo(
    () => Object.keys(challengeStatusOptionLabelsMap),
    [challengeStatusOptionLabelsMap],
  );

  return (
    <BaseStatusSelect
      value={challengeStatus}
      options={challengeStatusOptions}
      optionLabelsMap={challengeStatusOptionLabelsMap}
      labelStyle={labelStyle}
      readonly={isReadonly}
      handleChange={newStatus => {
        if (isReadonly) {
          return new Promise(() => {});
        }

        return updateChallengeStatus(
          newStatus as EnumTableChallengeStatusesEnum,
        );
      }}
      sx={{ marginLeft: isReadonly ? 2 : 0 }}
    />
  );
};
