import { SxProps } from '@mui/material';

export const dotDivider = ` ${String.fromCharCode(8226)} `;

export const smallTextStyles = {
  fontSize: '12px',
  fontWeight: 600,
};

export const textButtonStyles: SxProps = {
  fontSize: '14px',
  fontWeight: 'normal',
  cursor: 'pointer',
  padding: '0 0.25rem',
  '&:hover': {
    background: 'transparent',
    textDecoration: 'underline',
  },
};

export const WARNING_TEXT_COLOR = '#DD9F03';
