import { VisibilityOff } from '@mui/icons-material';
import { MenuItem, Stack, Typography } from '@mui/material';
import MoreMenu from 'components/MoreMenu';

import { MainColumn } from '../ToggleColumns';
import { useUpdateListMatrixSettings } from './useUpdateListMatrixSettings';

export const MainColumnHeader = ({
  field,
  label,
  isReadonly,
}: {
  field: MainColumn;
  label: string;
  isReadonly: boolean;
}) => {
  const toggleListMatrixSettings = useUpdateListMatrixSettings();

  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      data-testid={`${field}-column-header`}
    >
      <Stack justifyContent='center'>
        <Typography
          variant='body2'
          fontWeight={600}
          sx={({ zIndex }) => ({ zIndex: zIndex.modal })}
        >
          {label}
        </Typography>
      </Stack>
      {!isReadonly && (
        <MoreMenu>
          <MenuItem
            onClick={() => {
              toggleListMatrixSettings({
                [field === 'startup_corporate_customers'
                  ? 'customers_column_enabled'
                  : 'description_column_enabled']: false,
              });
            }}
          >
            <Stack direction='row' spacing={0.5}>
              <Stack justifyContent='center'>
                <VisibilityOff fontSize='small' />
              </Stack>
              <Typography variant='body2'>Hide</Typography>
            </Stack>
          </MenuItem>
        </MoreMenu>
      )}
    </Stack>
  );
};
