import {
  CircularProgress,
  LinearProgress,
  Stack,
  Typography,
  linearProgressClasses,
  styled,
} from '@mui/material';
import { useAutoEnrichStartupMutation } from 'apollo/generated/sdkInnovationManager';
import { orderBy } from 'lodash';
import { SnackbarKey, useSnackbar } from 'notistack';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.light,
  },
}));

export const useBulkEnrichment = ({
  pendingMessage,
  successMessage,
}: {
  pendingMessage: ReactNode;
  successMessage: ReactNode;
}) => {
  const [progress, setProgress] = useState<{
    current: number;
    total: number;
  }>();
  const progressPercent = useMemo(
    () =>
      progress ? Math.round((progress.current / progress.total) * 100) : null,
    [progress],
  );
  const [snackbarKey, setSnackbarKey] = useState<SnackbarKey | null>(null);
  const [autoEnrichStartup] = useAutoEnrichStartupMutation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const updateProgress = useCallback((addCurrent: number) => {
    setProgress(progress =>
      !progress
        ? progress
        : {
            current: progress.current + addCurrent,
            total: progress.total,
          },
    );
  }, []);

  const bulkEnrichStartups = useCallback(
    async (startups: { domain: string }[]) => {
      setProgress({ current: 0, total: startups.length });
      const batchSize = 5;
      for (let i = 0; i < startups.length; i += batchSize) {
        const batch = startups.slice(i, i + batchSize);

        const promises = batch.map((startup: { domain: string }) =>
          autoEnrichStartup({
            variables: {
              domain: startup.domain,
              includeWebsiteImage: true,
              generateInHouseData: true,
              overwriteExistingData: ['all'],
            },
          }),
        );
        await Promise.all(promises);
        updateProgress(batchSize);
      }
    },
    [autoEnrichStartup, updateProgress],
  );

  useEffect(() => {
    // TODO: Revisit the condition
    if (progressPercent === null) return;
    if (snackbarKey) closeSnackbar(snackbarKey);
    if (progressPercent < 100) {
      setSnackbarKey(
        enqueueSnackbar(
          <PendingEnrichmentSnackbarMessage
            title={pendingMessage}
            progressPercent={progressPercent}
          />,
          {
            variant: 'info',
            persist: true,
            preventDuplicate: true,
          },
        ),
      );
    } else {
      setSnackbarKey(
        enqueueSnackbar(
          <Stack>
            <Typography variant='body2' maxWidth={300}>
              {successMessage}
            </Typography>
          </Stack>,
          {
            variant: 'success',
            autoHideDuration: 3000,
            preventDuplicate: true,
          },
        ),
      );
    }

    return () => {
      if (snackbarKey) closeSnackbar(snackbarKey);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progressPercent, enqueueSnackbar, setSnackbarKey]);

  return { bulkEnrichStartups };
};

const PendingEnrichmentSnackbarMessage = ({
  title,
  progressPercent,
}: {
  title: ReactNode;
  progressPercent?: number;
}) => {
  return (
    <Stack spacing={0.5}>
      <Stack
        direction='row'
        spacing={1}
        alignItems='center'
        justifyContent='center'
      >
        <Typography display='inline-block' variant='body2' maxWidth={200}>
          {title}
        </Typography>
        <CircularProgress size={16} />
      </Stack>
      <Stack
        direction='row'
        spacing={1}
        justifyContent='center'
        alignItems={'center'}
        width={200}
      >
        <BorderLinearProgress
          variant='determinate'
          color='primary'
          sx={{ width: '100%' }}
          value={progressPercent}
        />
        <Typography
          display='inline-block'
          variant='body2'
          color={'text.secondary'}
        >
          {progressPercent}%
        </Typography>
      </Stack>
    </Stack>
  );
};

export const useListEnrichment = (
  categorizedStartups: {
    startup: { domain: string };
  }[],
  listOrCategoryTitle: string,
) => {
  const [loading, setLoading] = useState(false);
  const { bulkEnrichStartups } = useBulkEnrichment({
    pendingMessage: (
      <>
        Enriching <b>{listOrCategoryTitle}</b>
      </>
    ),
    successMessage: (
      <>
        <b>{listOrCategoryTitle}</b> has been enriched
      </>
    ),
  });

  const sortedUniqueStartupDomains = useMemo(() => {
    const orderedCategorizedSuppliers = orderBy(
      categorizedStartups,
      ['matrix_table_score'],
      ['desc'],
    );
    const uniqueDomains = [
      ...new Set(orderedCategorizedSuppliers.map(s => s.startup.domain)),
    ];
    return uniqueDomains;
  }, [categorizedStartups]);

  const enrichList = useCallback(() => {
    setLoading(true);
    bulkEnrichStartups(
      sortedUniqueStartupDomains.map(domain => ({ domain })),
    ).finally(() => setLoading(false));
  }, [bulkEnrichStartups, sortedUniqueStartupDomains]);

  return { enrichList, loading };
};
