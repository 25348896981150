import {
  useFilesWithSignedUrlsQuery,
  useGetStartupListTeamQuery,
} from 'apollo/generated/sdkShared';
import { useMemo } from 'react';

export const useGetStartupListTeamAvatarUrl = ({
  startupListId,
}: {
  startupListId: number;
}) => {
  const { data: listData } = useGetStartupListTeamQuery({
    variables: { startupListId },
  });
  const fileId = listData?.startup_lists_by_pk?.team?.logo_file_id;
  const { data } = useFilesWithSignedUrlsQuery({
    variables: { files_ids: fileId ? [fileId] : [] },
    fetchPolicy: 'cache-and-network',
  });

  return useMemo(() => {
    return data?.files_with_signed_urls?.data[0]?.signed_url || null;
  }, [data]);
};
