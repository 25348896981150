/**
 * To be honest I got no idea of where to put this component. Adrian
 */

import StarIcon from '@mui/icons-material/Star';
import { Stack, Tooltip, useTheme } from '@mui/material';
import { useUpsertStartupLabelMutation } from 'apollo/generated/sdkShared';
import { AddContactModal } from 'components/organizationStartupContacts/AddContactModal';
import { useMemo, useState } from 'react';
import { StartupForSidePanel } from './StartupInfoSidePanel';
import { BaseStatusSelect } from 'components/base/BaseStatusSelect';
import { useSafeCallback } from 'components/base/useSafeCallback';

const StartupFunnelLabelOrder = [
  'Tested',
  'Testing',
  'Connected',
  'InLibrary',
] as const;

export type StartupFunnelLabel = (typeof StartupFunnelLabelOrder)[number];

export function getStartupFunnelLabelOrder(
  labels:
    | {
        value?: StartupFunnelLabel | null;
        selected?: boolean | null;
      }[]
    | null = [],
): StartupFunnelLabel | null | undefined {
  const selectedLabel = labels?.find(l => l.selected);

  if (selectedLabel?.value) return selectedLabel.value as StartupFunnelLabel;

  return (
    StartupFunnelLabelOrder.find(
      slo => labels?.findIndex(({ value }) => value === slo) !== -1,
    ) || null
  );
}

export const StartupLabelContent = ({
  label,
}: {
  label: StartupFunnelLabel;
}) => {
  switch (label) {
    case 'InLibrary':
      return <>In Library</>;
    case 'Connected':
      return <>Connected</>;
    case 'Testing':
      return <>Testing</>;
    case 'Tested':
      return (
        <Stack direction='row' alignItems='center' justifyContent='center'>
          <StarIcon
            sx={{
              fontSize: 'inherit',
              // FIXME: Needed to make the icon align with the text
              marginTop: 0.15,
              marginBottom: 0.25,
              marginRight: 0.25,
            }}
          />
          <span>Tested</span>
        </Stack>
      );
    default:
      return null;
  }
};

export default function StartupLabelChipSelector({
  label = 'InLibrary',
  currentStartup,
}: {
  label?: StartupFunnelLabel;
  currentStartup: StartupForSidePanel;
}) {
  const { id: startupId, name: startupName } = currentStartup;
  const theme = useTheme();
  const [isAddContactModalOpened, setIsAddContactModalOpened] = useState(false);

  const [upsertOrganizationStartupLabelMutation] =
    useUpsertStartupLabelMutation();

  const startupHasContacts = currentStartup.contacts.length > 0;

  const labelStyleMap: Record<
    StartupFunnelLabel,
    {
      backgroundColor: string;
      color: string;
    }
  > = {
    InLibrary: {
      backgroundColor: theme.palette.primary.luminous,
      color: theme.palette.primary.darker,
    },
    Connected: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    Testing: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },
    Tested: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },
  };

  const startupDecoratedLabelMap = useMemo(
    () => ({
      InLibrary: 'In Library',
      Connected: 'Connected',
      Testing: 'Testing',
      Tested: 'Tested',
    }),
    [],
  );

  const startupLabelTooltip: Record<StartupFunnelLabel, string> = useMemo(
    () => ({
      InLibrary: `${startupName} is in your library`,
      Connected: `${startupName} has a primary contact`,
      Testing: `${startupName} is currently being tested`,
      Tested: `${startupName} is registered as a supplier`,
    }),
    [startupName],
  );

  const handleSelect = useSafeCallback(
    async (newLabel: StartupFunnelLabel) => {
      if (newLabel !== label) {
        if (newLabel === 'Connected') {
          // Ask them for a contact is there are none to have some level of data consistency
          if (startupHasContacts) {
            await upsertOrganizationStartupLabelMutation({
              variables: {
                label: newLabel,
                startup_id: startupId,
              },
            });
          } else {
            setIsAddContactModalOpened(true);
          }
        } else {
          await upsertOrganizationStartupLabelMutation({
            variables: {
              label: newLabel,
              startup_id: startupId,
            },
          });
        }
      }
    },
    [
      label,
      startupHasContacts,
      startupId,
      upsertOrganizationStartupLabelMutation,
    ],
  );

  return (
    <>
      <Tooltip title={startupLabelTooltip[label]}>
        <BaseStatusSelect
          value={label}
          label={<StartupLabelContent label={label} />}
          optionLabelsMap={startupDecoratedLabelMap}
          options={['InLibrary', 'Connected', 'Testing', 'Tested']}
          labelStyle={labelStyleMap[label]}
          handleChange={handleSelect}
        />
      </Tooltip>
      <AddContactModal
        open={isAddContactModalOpened}
        onHide={() => setIsAddContactModalOpened(false)}
        startupId={startupId}
        onSubmit={async () => {
          await upsertOrganizationStartupLabelMutation({
            variables: {
              label: 'Connected',
              startup_id: startupId,
            },
          });
        }}
      />
    </>
  );
}
