import { AddComment, AddCommentOutlined } from '@mui/icons-material';
import { IconButton, Stack, Tooltip } from '@mui/material';
import { useGetStartupNotesQuery } from 'apollo/generated/sdkShared';
import { useStartupSidePanel } from 'contexts/StartupSidePanelContext';
import { usePersonContext } from 'contexts/PersonContext';
import { useCallback } from 'react';
import { StartupListForMatrix } from './StartupsTableContext';

export const AddNoteButtonShared = ({
  startupId,
  currentStartupList,
}: {
  startupId: number;
  currentStartupList: StartupListForMatrix;
}) => {
  const { setPersonModal, needPersonInformation } = usePersonContext();

  const { openStartupSidePanel, setIsNotesIconClicked } = useStartupSidePanel();

  const openStartupDrawer = useCallback(
    ({ startupId }: { startupId: number }) => {
      setIsNotesIconClicked(true);
      openStartupSidePanel(startupId, {
        startupListId: currentStartupList.id,
      });
    },
    [openStartupSidePanel, setIsNotesIconClicked, currentStartupList],
  );

  const { data: startupNotesData } = useGetStartupNotesQuery({
    variables: {
      startupId,
    },
  });

  const notesCount = startupNotesData?.organization_startup_notes.length;

  const isNotesEmpty = notesCount === 0;

  function showPersonModal() {
    setPersonModal({
      show: true,
      onConfirm: () => () => openStartupDrawer({ startupId }),
    });
  }

  return (
    <Tooltip
      arrow
      placement='bottom'
      title={'Share your notes/comments on this startup'}
    >
      <Stack
        direction='row'
        alignItems='center'
        data-testid='comment-container'
      >
        <IconButton
          size='small'
          sx={({ spacing }) => ({ width: spacing(4), height: spacing(4) })}
          onClick={() =>
            needPersonInformation
              ? showPersonModal()
              : openStartupDrawer({ startupId })
          }
        >
          {isNotesEmpty ? (
            <AddCommentOutlined fontSize='small' />
          ) : (
            <AddComment fontSize='small' />
          )}
        </IconButton>
        {notesCount}
      </Stack>
    </Tooltip>
  );
};
