import { Close } from '@mui/icons-material';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { LoginPageType } from './useGetLoginCopy';

const SnackbarAction = () => (
  <Stack>
    <Typography variant='body1' sx={{ fontWeight: 900 }} marginBottom={1}>
      Authentication system updated
    </Typography>
    <Typography>Thank you for your patience</Typography>
    <Typography>We now offer SSO!</Typography>
    <Stack direction='row' alignItems='center'>
      <Typography>Need help?</Typography>
      <Button
        sx={{
          padding: 0,
          marginLeft: 0.5,
          fontSize: 16,
          fontWeight: 300,
          lineHeight: '16px',
          '&:hover': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
          },
        }}
        variant='text'
        onClick={() => {
          // @ts-expect-error: TODO: FIXME!
          window.$chatwoot.toggle();
        }}
      >
        Contact us
      </Button>
    </Stack>
  </Stack>
);

const Action = () => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton
      onClick={() => {
        closeSnackbar();
      }}
    >
      <Close />
    </IconButton>
  );
};

const useMaintenanceMessage = ({
  loginPageType,
}: {
  loginPageType: LoginPageType;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const stopShowing = new Date('2023-11-17').getTime() < new Date().getTime();

  useEffect(() => {
    if (loginPageType !== 'regular') return;
    if (stopShowing) return;

    enqueueSnackbar(<SnackbarAction />, {
      variant: 'info',
      autoHideDuration: 1000000,
      action: <Action />,
    });
  }, [enqueueSnackbar, loginPageType, stopShowing]);
};

export default useMaintenanceMessage;
