import { Link, Stack, Typography } from '@mui/material';
import { GLASSDOLLAR_DEFAULT_REPLY_TO } from 'config';

const StandardErrorSection = ({ title }: { title: string }) => {
  return (
    <Stack justifyContent='center' width={400} spacing={2}>
      <Typography color='error'>{title}</Typography>
      <Typography variant='body2' color='text.secondary'>
        Please contact support on{' '}
        <Link
          href={`mailto:${GLASSDOLLAR_DEFAULT_REPLY_TO}`}
          color='text.secondary'
          target='_blank'
        >
          {GLASSDOLLAR_DEFAULT_REPLY_TO}
        </Link>
      </Typography>
    </Stack>
  );
};

export default StandardErrorSection;
