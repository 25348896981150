import Typography, { TypographyProps } from '@mui/material/Typography';

const BaseEllipsisTypographyOneLine = ({
  children,
  ...rest
}: TypographyProps) => {
  return (
    <Typography
      {...rest}
      sx={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ...rest.sx,
      }}
    >
      {children}
    </Typography>
  );
};

export default BaseEllipsisTypographyOneLine;
