import { Search } from '@mui/icons-material';
import { SxProps, useTheme } from '@mui/material';
import { InputAdornment, OutlinedInput, styled } from '@mui/material';

const StyledSearchInput = styled(OutlinedInput)(
  ({ theme: { transitions, customShadows, palette } }) => ({
    background: palette.grey[0],
    height: 60,
    transition: transitions.create(['box-shadow', 'width'], {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.shorter,
    }),
    '&.Mui-focused': { boxShadow: customShadows.z8 },

    '& fieldset': {
      borderWidth: `1px !important`,
      borderColor: `${palette.grey[500_32]} !important`,
    },
  }),
);

export default StyledSearchInput;

const StyledSearchBoxInput = styled(OutlinedInput)(
  ({ theme: { transitions, palette } }) => ({
    background: palette.grey[0],
    '& input': {
      width: '100%',
      transition: transitions.create(['box-shadow', 'width'], {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.shorter,
      }),
    },

    '& fieldset': {
      borderWidth: `1px !important`,
      borderColor: `${palette.grey[500_32]} !important`,
    },
  }),
);

export const StandardSearchBar = ({
  query,
  onQueryChange,
  placeholder = 'Search...',
  sx = {},
}: {
  query: string;
  onQueryChange: (value: string) => void;
  placeholder?: string;
  sx?: SxProps;
}) => {
  const { spacing } = useTheme();

  return (
    <StyledSearchBoxInput
      fullWidth={false}
      sx={{
        height: spacing(6),
        ...sx,
      }}
      value={query}
      onChange={e => onQueryChange(e.target.value)}
      placeholder={placeholder}
      startAdornment={
        <InputAdornment position='start'>
          <Search fontSize='small' sx={{ color: 'text.disabled' }} />
        </InputAdornment>
      }
    />
  );
};
