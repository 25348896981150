import { Box, SxProps, useTheme } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import Chart from 'react-apexcharts';
import { useDebouncedCallback } from 'use-debounce';

type ChartData = { x: string; y: number }[];
type Series = [Record<'data', ChartData>];

export const DEFAULT_TREND_HEIGHT = 40;

export default function TrendLineChart({
  series,
  sx,
  fixedWidth,
  height = DEFAULT_TREND_HEIGHT,
}: {
  series: Series;
  sx?: SxProps;
  fixedWidth?: string | number | undefined;
  height?: string | number | undefined;
}) {
  const containerRef = useRef<HTMLDivElement>(null);

  const [width, setWidth] = useState(0);
  const handleWindowResize = useDebouncedCallback(() => {
    setWidth(prevWidth => {
      if (!containerRef.current) return prevWidth;

      const newWidth = containerRef.current.clientWidth;

      if (prevWidth === newWidth) return prevWidth;

      return newWidth;
    });
  }, 200);

  useLayoutEffect(() => {
    handleWindowResize();
  }, [handleWindowResize]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [handleWindowResize]);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  const options: ApexOptions = useMemo(
    () => ({
      xaxis: {
        type: 'category' as const,
        labels: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
      },
      colors: [main],
      tooltip: {
        enabled: false,
      },
      grid: {
        padding: { bottom: 5, top: 5, left: 0, right: 0 },
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      chart: {
        toolbar: {
          show: false,
        },
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '70%',
        },
      },
    }),
    [main],
  );

  return (
    <Box
      sx={{
        position: 'relative',
        ...sx,
      }}
      ref={containerRef}
    >
      {!fixedWidth && width < 50 ? null : (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: 0,
            transform: 'translateY(-50%)',
          }}
        >
          <Chart
            options={options}
            series={series}
            type='line'
            width={fixedWidth || width}
            height={height}
          />
        </Box>
      )}
    </Box>
  );
}
