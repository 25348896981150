import { useGetChallengeDetailsQuery } from 'apollo/generated/sdkShared';
import { ChallengeType } from 'components/engagement/challenges/types';
import { useMemo } from 'react';

export const useGetChallengeAndConnections = ({
  challengeID,
  challengePublicUUID,
}: {
  challengeID?: number;
  challengePublicUUID?: string;
}) => {
  const {
    data: challengeData,
    error: challengeError,
    called,
    previousData: challengePreviousData,
    refetch,
  } = useGetChallengeDetailsQuery({
    variables: {
      ...(challengePublicUUID
        ? { challengeUUID: challengePublicUUID, challengeID: -1 }
        : { challengeID: challengeID, challengeUUID: '' }),
    },
  });

  const challenge = useMemo(
    () =>
      (challengeData?.challenge[0] ||
        challengePreviousData?.challenge[0])! as ChallengeType,
    [challengeData, challengePreviousData],
  );

  const loading = !challenge;

  return {
    challenge,
    loading,
    called,
    error: !!challengeError,
    errorData: challengeError,
    refetch,
  };
};
