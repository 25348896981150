import { Link, Text, View } from '@react-pdf/renderer';
import Html from 'react-pdf-html';
import { HtmlRenderer } from 'react-pdf-html/dist/render';

const STYLESHEET = {
  strong: {
    fontWeight: 'bold',
  },
  em: {
    fontStyle: 'italic',
  },
  h1: {
    fontWeight: 'bold',
  },
  h2: {
    fontWeight: 'bold',
  },
  h3: {
    fontWeight: 800,
  },
  h4: {
    fontWeight: 'bold',
  },
  h5: {
    fontWeight: 'bold',
  },
  h6: {
    fontWeight: 'bold',
  },
  p: {
    marginVertical: 0,
  },
  ul: {
    marginVertical: 0,
  },
  ol: {
    marginVertical: 0,
  },
};

const CustomHTML = ({ html, fontSize }: { html: string; fontSize: number }) => {
  const PDFLink: HtmlRenderer = props => {
    return (
      // eslint-disable-next-line
      <Link src={props.element.attributes.href}>
        {/* eslint-disable-next-line */}
        <Text>{props.element.innerHTML}</Text>
      </Link>
    );
  };

  const buildPDFTextComponent = ({
    fontSize,
    fontWeight,
  }: {
    fontSize: number;
    fontWeight: 400 | 500 | 600 | 700;
  }): HtmlRenderer => {
    const renderText: HtmlRenderer = props => {
      return (
        // eslint-disable-next-line
        <Text style={props.element.style}>
          <Html
            // eslint-disable-next-line
            stylesheet={props.stylesheets}
            style={{ fontSize, fontWeight }}
            renderers={renderers}
            collapse={false}
          >
            {/* eslint-disable-next-line */}
            {props.element.innerHTML}
          </Html>
        </Text>
      );
    };

    return renderText;
  };

  const buildPDFHeadingComponent = ({
    fontSize,
    fontWeight,
  }: {
    fontSize: number;
    fontWeight: 400 | 500 | 600 | 700;
  }): HtmlRenderer => {
    const renderHeading: HtmlRenderer = props => {
      return (
        // eslint-disable-next-line
        <Text style={{ ...props.element.style, marginBottom: 0 }}>
          <Html
            // eslint-disable-next-line
            stylesheet={props.stylesheets}
            style={{ fontSize, fontWeight }}
            renderers={renderers}
            collapse={false}
          >
            {/* eslint-disable-next-line */}
            {props.element.innerHTML}
          </Html>
        </Text>
      );
    };

    return renderHeading;
  };

  const PDFDiv: HtmlRenderer = props => (
    <View>
      <Html
        stylesheet={STYLESHEET}
        style={{ fontSize }}
        renderers={renderers}
        collapse={false}
      >
        {/* eslint-disable-next-line */}
        {props.element.innerHTML}
      </Html>
    </View>
  );

  const PDFList: HtmlRenderer = props => {
    return (
      <Html
        stylesheet={props.stylesheets}
        style={{ fontSize }}
      >{`<${props.element.tag}>${props.element.innerHTML}</${props.element.tag}>`}</Html>
    );
  };

  const renderers = {
    a: PDFLink,
    p: buildPDFTextComponent({ fontWeight: 400, fontSize }),
    div: PDFDiv,
    h1: buildPDFHeadingComponent({ fontWeight: 700, fontSize }),
    h2: buildPDFHeadingComponent({ fontWeight: 600, fontSize }),
    h3: buildPDFHeadingComponent({ fontWeight: 500, fontSize }),
    h4: buildPDFHeadingComponent({ fontWeight: 400, fontSize }),
    h5: buildPDFHeadingComponent({ fontWeight: 400, fontSize }),
    h6: buildPDFHeadingComponent({ fontWeight: 400, fontSize }),
    ul: PDFList,
    ol: PDFList,
  };

  return (
    <Html stylesheet={STYLESHEET} renderers={renderers} collapse={false}>
      {html}
    </Html>
  );
};

export default CustomHTML;
