import { styled } from '@mui/material/styles';
import { Typography, Box, BoxProps } from '@mui/material';
import { SxProps } from '@mui/material';

// ----------------------------------------------------------------------

const RootStyle = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(8, 2),
}));

// ----------------------------------------------------------------------

interface EmptyContentProps extends Omit<BoxProps, 'title'> {
  title?: string | JSX.Element;
  img?: string;
  description?: string;
  imageSx?: SxProps;
  headerVariant?: 'h4' | 'h5' | 'h6';
}

export default function EmptyContent({
  title,
  description,
  img,
  headerVariant = 'h5',
  imageSx = { height: 240, mb: 3 },
  ...other
}: EmptyContentProps) {
  return (
    <RootStyle {...other}>
      <>
        <Box
          component='img'
          alt='empty content'
          src={img || '/static/illustrations/illustration_empty_content.svg'}
          sx={imageSx}
        />

        {title && typeof title === 'string' ? (
          <Typography
            variant={headerVariant}
            sx={({ typography, palette }) => ({
              fontWeight: typography.fontWeightLight,
              color: palette.grey[700],
            })}
            gutterBottom
          >
            {title}
          </Typography>
        ) : (
          title
        )}

        {description && (
          <Typography variant='body2' sx={{ color: 'text.secondary' }}>
            {description}
          </Typography>
        )}
      </>
    </RootStyle>
  );
}
