import { captureException } from '@sentry/react';
import { useCreateProjectForListDetailsMutation } from 'apollo/generated/sdkShared';
import { DEFAULT_LEAD_TITLE_PREFIX } from 'components/projectLeads/useCreateLead';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import { captureAnalyticsEvent } from 'plugins/Analytics';

export const useCreateLeadForList = ({
  startupListId,
}: {
  startupListId: number;
}) => {
  const { user: currentUser } = useAuth();
  const [createProject] = useCreateProjectForListDetailsMutation();
  const { enqueueSnackbar } = useSnackbar();

  const handleCreateLead = async () => {
    try {
      const { data } = await createProject({
        variables: {
          object: {
            title: `${DEFAULT_LEAD_TITLE_PREFIX} ${currentUser.displayName}`,
            stage: 'scoping',
            source: 'self_created',
            type_of_solution: 'unknown',
            linked_startup_list_id: startupListId,
          },
        },
      });

      const projectId = data?.create_project?.project?.id;
      if (!projectId) {
        enqueueSnackbar('Failed to create new lead', { variant: 'error' });
      }

      const stage = data?.create_project?.project?.stage;
      captureAnalyticsEvent('Project Lead Created', {
        projectId: projectId!,
        source: 'Link list with lead',
      });

      return { projectId, stage };
    } catch (error) {
      captureException(error);
      enqueueSnackbar('Failed to create new lead', { variant: 'error' });
    }
  };

  return {
    handleCreateLead,
  };
};
