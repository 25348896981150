import {
  Box,
  Card,
  CardContent,
  Grid,
  Skeleton,
  Typography,
} from '@mui/material';
import { KnownStartupsField } from 'components/dashboard/startupList/shared/KnownStartupsField';
import { AdditionalInformationField } from 'components/requestFormFields/AdditionalInformationField';
import { DesiredSolutionField } from 'components/requestFormFields/DesiredSolutionField';
import { FeaturesField } from 'components/requestFormFields/FeaturesField';
import { ProblemField } from 'components/requestFormFields/ProblemField';
import { useFormikContext } from 'formik';
import { ReactNode, useMemo } from 'react';
import { RenderFooterProps } from './SourcingOrderPage';
import { ConfirmRequestFormLeave } from './confirmRequestFormLeave';
import { SourcingOrderState } from './sourcingOrderModel';
import { UploadScopeFile, UploadScopeFileEditable } from './useScopeFiles';

type FormProps = {
  state: SourcingOrderState;
  renderFooter?: (props: RenderFooterProps) => ReactNode;
  shownInModal?: boolean;
  isBUPage?: boolean;
};

const cardSkeletonStyle = {
  marginTop: 2,
  marginBottom: 1,
  width: '550px',
  marginX: 'auto',
};

const fieldSkeletonHeight = 60;
const FieldSkeleton = () => (
  <Skeleton
    variant='rectangular'
    height={fieldSkeletonHeight}
    animation='wave'
  />
);

const CardSkeleton = ({ count }: { count: number }) => (
  <Card sx={cardSkeletonStyle}>
    <CardContent sx={{ gap: 2 }}>
      {Array.from(new Array(count)).map((_, index) => (
        <FieldSkeleton key={index} />
      ))}
    </CardContent>
  </Card>
);

export const ProblemScopeFieldsSkeleton = () => (
  <>
    <CardSkeleton count={1} /> {/* ProblemField */}
    <CardSkeleton count={1} /> {/* DesiredSolutionField */}
    <CardSkeleton count={2} />
    {/* FeaturesField, must-have and nice-to-have features */}
    <CardSkeleton count={1} /> {/* KnownStartupsField */}
    <CardSkeleton count={1} /> {/* AdditionalInformationField */}
  </>
);

export default function ProblemScopeFields(props: FormProps) {
  const { state, renderFooter, isBUPage, shownInModal } = props;
  const { errors, touched, values, getFieldProps, setFieldValue } =
    useFormikContext<SourcingOrderState>();

  const formContent = useMemo(
    () => (
      <>
        <ConfirmRequestFormLeave isFormSaved={!!state.public_uuid} />
        <Box sx={{ gap: 3, display: 'flex', flexDirection: 'column' }}>
          <Box>
            <ProblemField
              {...{
                getFieldProps,
                setFieldValue,
                touched,
                errors,
              }}
            />
          </Box>
          <Box>
            <DesiredSolutionField
              {...{
                getFieldProps,
                setFieldValue,
                touched,
                errors,
              }}
            />
          </Box>
          <FeaturesField
            {...{
              must_have_features: values.must_have_features,
              nice_to_have_features: values.nice_to_have_features,
              setFieldValue,
              errors,
            }}
          />
          <Box>
            <KnownStartupsField
              {...{
                knownStartups: values.known_startups,
                handleSave: ({ fieldName, fieldValue }) =>
                  setFieldValue(fieldName, fieldValue),
              }}
            />
          </Box>
          <Box>
            <AdditionalInformationField
              {...{ getFieldProps, setFieldValue, touched, errors }}
            />
          </Box>
          <Box>
            <UploadScopeFile
              setFieldValue={setFieldValue}
              problemScopeId={state.problemScopeId}
            />
          </Box>
          {renderFooter &&
            renderFooter({
              errorMessage:
                Object.keys(errors).length > 0
                  ? // @ts-expect-error: TODO Fix this
                    errors[Object.keys(errors)[0]]
                  : '',
            })}
        </Box>
      </>
    ),
    [
      getFieldProps,
      renderFooter,
      setFieldValue,
      state.public_uuid,
      state.problemScopeId,
      values.known_startups,
      values.must_have_features,
      values.nice_to_have_features,
      errors,
      touched,
    ],
  );

  if (isBUPage) {
    return (
      <Grid container spacing={2} sx={{ width: '100%' }}>
        <Grid item xs={12} md={6} lg={4}>
          <Grid container direction='column' gap={2}>
            <Grid item>
              <ProblemField
                {...{
                  getFieldProps,
                  setFieldValue,
                  touched,
                  errors,
                  isEditMode: true,
                }}
              />
            </Grid>
            <Grid item>
              <DesiredSolutionField
                {...{
                  getFieldProps,
                  setFieldValue,
                  touched,
                  errors,
                  isEditMode: true,
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <FeaturesField
            {...{
              must_have_features: values.must_have_features,
              nice_to_have_features: values.nice_to_have_features,
              setFieldValue,
              errors,
              isEditable: true,
            }}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <Grid container direction='column' gap={2}>
            <Grid item>
              <KnownStartupsField
                {...{
                  knownStartups: values.known_startups,
                  handleSave: ({ fieldName, fieldValue }) =>
                    setFieldValue(fieldName, fieldValue),
                }}
              />
            </Grid>
            <Grid item>
              <AdditionalInformationField
                {...{
                  getFieldProps,
                  setFieldValue,
                  touched,
                  errors,
                  isEditMode: true,
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <Grid container direction='column' spacing={2}>
            <Grid item>
              <Typography
                variant='body2'
                sx={{ fontWeight: 900, paddingX: '4px' }}
              >
                Files
              </Typography>
              <UploadScopeFileEditable
                setFieldValue={setFieldValue}
                problemScopeId={state.problemScopeId}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return !shownInModal ? (
    <Card
      sx={{
        marginTop: 2,
        marginBottom: 1,
        width: '550px',
        marginX: 'auto',
      }}
    >
      <CardContent>{formContent}</CardContent>
    </Card>
  ) : (
    formContent
  );
}
