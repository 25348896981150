import { styled } from '@mui/material/styles';
// material
import { Box, Button, Typography, Container, useTheme } from '@mui/material';
// components
import Page from '../components/Page';
import { SeverErrorIllustration } from '../assets';
import { RouterLink } from 'components/RouterLink';

// ----------------------------------------------------------------------

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
}));

// ----------------------------------------------------------------------

export default function Page500({ embedded = false }: { embedded?: boolean }) {
  const theme = useTheme();

  return (
    <RootStyle
      title='500 Internal Server Error | GlassDollar'
      trackingTitle='500'
      paddingTop={embedded ? 2 : theme.spacing(15)}
      paddingBottom={embedded ? 4 : theme.spacing(10)}
    >
      <Container>
        <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
          <Typography variant='h3' paragraph>
            500 Internal Server Error
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            There was an error, please contact support or try again later.
          </Typography>

          <SeverErrorIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />

          <Button
            to='/'
            size='large'
            variant='contained'
            component={RouterLink}
          >
            Go to Home
          </Button>
        </Box>
      </Container>
    </RootStyle>
  );
}
