import numeral from 'numeral';

export const formatMoney = (
  money: number | null | undefined,
  currency?: string,
) => {
  if (!money) return '-';
  if (money >= 1_000_000_000) {
    return `${currency ?? '$'}${getDecimal(money / 1000000000)}B`;
  } else if (money >= 1_000_000) {
    return `${currency ?? '$'}${getDecimal(money / 1000000)}M`;
  } else if (money >= 1_000) {
    return `${currency ?? '$'}${getDecimal(money / 1_000)}K`;
  } else {
    return '-';
  }
};

export const formatMoneyToMillions = (
  money: number | null | undefined,
  currency?: string,
) => {
  if (!money) return '-';

  return `${currency ?? '$'} ${Math.ceil(money / 1000000)}M`;
};

const getDecimal = (num: number) => {
  return num === Math.floor(num) ? num.toFixed(0) : num.toFixed(1);
};

export const eurFormaterWholeNumbers = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
});

export function fData(number: string | number) {
  return numeral(number).format('0.0 b');
}
