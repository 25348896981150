import { Stack } from '@mui/material';
import { BusinessModelField } from './BusinessModelField';
import { FileField } from './FileField';
import { LabelAndInputField } from './LabelAndInputField';
import { ProductKpiField } from './ProductKpiField';
import { ReferencedCustomersField } from './ReferencedCustomersField';

export const FirstPartyDataFields = () => {
  return (
    <Stack gap={2}>
      <ProductKpiField />
      <ReferencedCustomersField label='Customers' />
      <Stack direction='row' justifyContent='space-between' gap={1}>
        <FileField documentType='customer_case_studies' />
        <FileField documentType='sales_material' />
      </Stack>
      <LabelAndInputField
        fieldType='text'
        fieldName='company_traction'
        label={`Revenue ${new Date().getFullYear() - 1}`}
      />
      <BusinessModelField label='Business Model' />
      <LabelAndInputField
        fieldType='text'
        fieldName='pricing'
        label='Pricing'
      />
    </Stack>
  );
};
