import { Box, Tooltip, Typography, TypographyTypeMap } from '@mui/material';
import { FocusEventHandler } from 'react';

const ContentEditableText = ({
  isEditable,
  text,
  onBlur,
  ...rest
}: TypographyTypeMap['props'] & {
  isEditable?: boolean;
  text: string;
  onBlur?: FocusEventHandler<HTMLSpanElement>;
}) => {
  return (
    <Tooltip title={isEditable ? 'Click to edit' : ''}>
      <Typography
        {...rest}
        sx={{
          ...(rest?.sx || {}),
          '&:hover': {
            color: isEditable ? 'secondary.main' : 'inherit',
            boxShadow: ({ shadows }) => (isEditable ? shadows[3] : 'none'),
          },
        }}
      >
        <Box
          component='span'
          suppressContentEditableWarning
          contentEditable={isEditable}
          onBlur={onBlur}
          onKeyDown={e => {
            if (e.key === 'Enter' || e.key === 'Tab') {
              e.preventDefault();
              e.currentTarget.blur();
              window?.getSelection()?.removeAllRanges();
            }

            if (e.key === 'Escape') {
              e.preventDefault();
              e.currentTarget.textContent = text;
              e.currentTarget.blur();
              window?.getSelection()?.removeAllRanges();
            }
          }}
          sx={{
            border: 'none',
            '&:focus': {
              outline: 'none',
            },
          }}
        >
          {text}
        </Box>
      </Typography>
    </Tooltip>
  );
};

export default ContentEditableText;
