import { captureException } from '@sentry/react';
import {
  FilePrefix,
  useCreateFileUploadUrlMutation,
  useInsertFilesOneMutation,
} from 'apollo/generated/sdkShared';
import axios from 'axios';
import { FileError } from 'errors';
import { useCallback } from 'react';

export const useFiles = ({
  filePrefix,
}: {
  filePrefix: FilePrefix | 'person_documents';
}) => {
  const [createFileUploadUrl] = useCreateFileUploadUrlMutation();
  const [insertFilesOne] = useInsertFilesOneMutation();

  /**
   * Error handling here is kind of hard because in can't fail
   * in many requests, and you may want to show different error messages.
   * Right now we always throw a error with a message set by us such that
   * the consumer can show this error
   */
  const uploadFile = useCallback(
    async (file: File, sizeInMB: number = 1) => {
      // Convert the size limit from megabytes to bytes
      const sizeLimitInBytes = sizeInMB * 1024 * 1024;

      if (file.size > sizeLimitInBytes) {
        throw new FileError(
          `Failed to upload file. File size must be up to ${sizeInMB} MB.`,
        );
      }

      try {
        const { data } = await createFileUploadUrl({
          variables: {
            input: {
              name: file.name,
              type: file.type,
              prefix: filePrefix as FilePrefix,
            },
          },
        });

        const { key, signed_request_url } =
          // eslint-disable-next-line
          data?.create_file_upload_url?.data!;

        const options = {
          headers: {
            'Content-Type': file.type,
          },
        };

        await axios.put(signed_request_url!, file, options);

        const res = await insertFilesOne({
          variables: {
            file: {
              key: key,
              content_type: file.type,
              name: file.name,
            },
          },
        });

        return res!.data!.insert_files_one!;
      } catch (error) {
        captureException(error);
        console.error(error);

        throw new FileError('Error uploading document');
      }
    },
    [createFileUploadUrl, filePrefix, insertFilesOne],
  );

  return { uploadFile };
};

export default useFiles;
