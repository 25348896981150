import { useGetOutboundRequestOwnersQuery } from 'apollo/generated/sdkShared';
import BaseServerFilterAutocomplete, {
  ServerFilterOption,
} from 'components/base/serverFilters/BaseServerFilterAutocomplete';
import { useServerFiltersContext } from 'components/base/serverFilters/BaseServerFiltersContext';
import { filter, includes, some, uniqBy } from 'lodash';
import { useCallback, useMemo } from 'react';

export const InitiatedByFilter = () => {
  const { data, loading } = useGetOutboundRequestOwnersQuery();

  const { setFilters, filters } = useServerFiltersContext(
    'outboundRequestsFilters',
  );
  const currentFilter = filters.initiatedBy;

  const options = useMemo(() => {
    if (!data) return [];
    if (!data.startup_connections) return [];

    const filteredConnectionRequesters = data.startup_connections
      .map(requester => requester.created_by?.person)
      .filter(person => person);

    return uniqBy(filteredConnectionRequesters, 'full_name').map(person => ({
      value: person!.full_name,
      label: person!.full_name,
    }));
  }, [data]);

  const value = useMemo(() => {
    const namesToFilter = currentFilter.created_by?._or?.map(condition =>
      condition.person?.full_name?._ilike?.replace(/%/g, '').toLowerCase(),
    );

    // Filter the options based on whether their full_name matches any of the namesToFilter, ignoring case
    return filter(options, option => {
      return some(namesToFilter, nameToFilter =>
        includes(option.value.toLowerCase(), nameToFilter),
      );
    });
  }, [currentFilter, options]);

  const defaultValue = options?.filter(t => value.includes(t));

  const handleChange = useCallback(
    (inputValue: ServerFilterOption<string>[]) => {
      if (!inputValue.length) {
        setFilters('initiatedBy', {});
        return;
      }

      setFilters('initiatedBy', {
        created_by: {
          _or: inputValue.map(option => ({
            person: { full_name: { _ilike: `%${option.value}%` } },
          })),
        },
      });
    },
    [setFilters],
  );

  return (
    <BaseServerFilterAutocomplete
      value={value}
      loading={loading}
      defaultValue={defaultValue}
      options={options}
      handleChange={values => handleChange(values)}
      placeholder='Initiated by'
      sxProps={{ minWidth: 140 }}
    />
  );
};
