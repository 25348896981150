import { Chip, Stack, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { StartupFormState } from './useStartupDetailsFormik';

const FIELD_NAME = 'investors';

export const InvestorsField = ({ label }: { label: string }) => {
  const { values } = useFormikContext<StartupFormState>();

  const fieldValue = values[FIELD_NAME];

  if (!fieldValue || !fieldValue.length) {
    return null;
  }

  return (
    <Stack gap={1}>
      <Typography color='text.secondary'>{label}</Typography>
      <Stack direction='row' flexWrap='wrap' gap={1}>
        {fieldValue.map((investor, i) => (
          <Chip key={`${investor}-${i}`} label={investor} />
        ))}
      </Stack>
    </Stack>
  );
};
