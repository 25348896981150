import { useEffect, useMemo } from 'react';
import { getWeek } from 'utils/datetime';
import { useGetSourcingOrdersInProgressQuery } from 'apollo/generated/sdkInnovationManager';
import { useSnackbar } from 'notistack';
import { captureException } from '@sentry/react';
import { getMonth } from 'date-fns';
import {
  MAX_ALLOWED_EXPRESS_ORDERS_PER_MONTH,
  VW_MAX_ALLOWED_EXPRESS_ORDERS_PER_MONTH,
} from 'config';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';

const TODAY = new Date().toISOString();

export const useSourcingOrdersInProgress = () => {
  const {
    data: inProgressSourcingOrders,
    loading: loadingSourcingOrders,
    error: loadSourcingOrdersError,
  } = useGetSourcingOrdersInProgressQuery({
    fetchPolicy: 'cache-and-network',
    variables: { startDate: TODAY },
  });
  const { enqueueSnackbar } = useSnackbar();

  const { uuid: organizationUUID } = useCurrentOrganizationFromContext();

  const ordersCountPerWeek = useMemo(() => {
    if (!inProgressSourcingOrders) return {};

    let _ordersCountPerWeek: Record<number, number> = {};
    inProgressSourcingOrders.sourcing_orders.forEach(order => {
      const week = getWeek(new Date(order.due_date!));

      _ordersCountPerWeek = {
        ..._ordersCountPerWeek,
        [week]: (_ordersCountPerWeek[week] || 0) + 1,
      };
    });
    return _ordersCountPerWeek;
  }, [inProgressSourcingOrders]);

  const expressAvailable = useMemo(() => {
    if (!inProgressSourcingOrders) return false;

    const expressOrders = inProgressSourcingOrders.sourcing_orders.filter(
      order => {
        return (
          // TODO: Make created_at not null
          getMonth(new Date(order.created_at!)) === getMonth(new Date()) &&
          order.delivery_type === 'express'
        );
      },
    );

    const expressOrderLimit =
      organizationUUID === 'dd464f0c-d376-47eb-ba17-9634b2b7ea4b' ||
      organizationUUID === '5cd45c82-f742-4264-b082-d6b9bf82953c'
        ? VW_MAX_ALLOWED_EXPRESS_ORDERS_PER_MONTH
        : MAX_ALLOWED_EXPRESS_ORDERS_PER_MONTH;

    return expressOrders.length < expressOrderLimit;
  }, [inProgressSourcingOrders, organizationUUID]);

  useEffect(() => {
    if (loadSourcingOrdersError) {
      captureException(
        loadSourcingOrdersError?.message ||
          'Error while loading projects for sourcing order datepicker',
      );
      enqueueSnackbar('Error has occurred while loading the project info.', {
        variant: 'error',
      });
      return;
    }
  }, [loadSourcingOrdersError, enqueueSnackbar]);

  return {
    data: { ordersCountPerWeek, expressAvailable },
    loading: loadingSourcingOrders,
    hasError: loadSourcingOrdersError,
  };
};
