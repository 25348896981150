import { Page, Text, View, Image } from '@react-pdf/renderer';
import moment from 'moment';
import { styles } from './styles';

export const IntroPage = ({
  organizationLogoURL,
  title,
}: {
  organizationLogoURL: string;
  title: string;
}) => {
  return (
    <Page
      size='A4'
      orientation='landscape'
      wrap={false}
      style={styles.introPage}
    >
      <View style={{ justifyContent: 'space-between' }}>
        <View>
          <Text style={styles.pageTitle}>Welcome</Text>
        </View>
        <View>
          <Text style={{ fontSize: 20 }}>Best companies at a glance for</Text>
          <Text style={{ fontSize: 50, fontWeight: 400 }}>{title}</Text>
        </View>
        <View style={{ color: 'grey' }}>
          <Text style={{ fontSize: 10 }}>
            Created on: {moment().format('Do MMM YYYY')}
          </Text>
        </View>
      </View>
      <View>
        <Image
          style={{
            height: 50,
            width: 50,
            position: 'absolute',
            right: 0,
            bottom: 0,
          }}
          source={{
            uri: organizationLogoURL,
            headers: { 'Cache-Control': 'no-cache' },
            method: 'GET',
            body: '',
          }}
        />
      </View>
    </Page>
  );
};
