import {
  GetProjectTimelineQuery,
  GetSelectedStartupsTimelineQuery,
  useGetProjectTimelineQuery,
  useGetSelectedStartupsTimelineQuery,
} from 'apollo/generated/sdkInnovationManager';
import { useMemo } from 'react';

export type ProjectTimeline = GetProjectTimelineQuery['project_timeline'];
export type StartupTimeline =
  GetSelectedStartupsTimelineQuery['startup_timeline'];

export const useGetProjectAndStartupTimelines = ({
  projectId,
}: {
  projectId: number;
}) => {
  const {
    loading: loadingProjectTimeline,
    data: projectTimelineData,
    error: projectTimelineError,
  } = useGetProjectTimelineQuery({
    variables: {
      projectId: projectId!,
    },
    skip: !projectId,
    fetchPolicy: 'cache-and-network',
  });

  const {
    loading: loadingStartupTimelines,
    data: startupTimelinesData,
    error: startupTimelinesError,
  } = useGetSelectedStartupsTimelineQuery({
    variables: {
      projectId: projectId!,
    },
    skip: !projectId,
    fetchPolicy: 'cache-and-network',
  });

  const projectTimeline = useMemo(
    () => projectTimelineData?.project_timeline || [],
    [projectTimelineData?.project_timeline],
  );

  const startupTimeline = useMemo(
    () => startupTimelinesData?.startup_timeline || [],
    [startupTimelinesData?.startup_timeline],
  );

  return {
    projectTimeline,
    startupTimeline,
    loading: loadingProjectTimeline || loadingStartupTimelines,
    error: !!projectTimelineError || !!startupTimelinesError,
    errorData: projectTimelineError
      ? projectTimelineError
      : startupTimelinesError,
  };
};
