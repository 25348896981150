import { SxProps } from '@mui/material';
import {
  useDeleteProjectTagMutation,
  useDeleteProjectsProjectTagMutation,
  useFindProjectTagByNameLazyQuery,
  useFindProjectTagsQuery,
  useInsertProjectTagMutation,
  useInsertProjectsProjectTagMutation,
  useUpdateProjectTagMutation,
} from 'apollo/generated/sdkShared';
import { Tag, ValueTag } from 'components/base/BaseMultiselect';
import { BaseTagsSelect } from 'components/base/BaseTagsSelect';
import { StartupListDetailPageContext } from 'contexts/ProjectScopeContext';
import { useContext, useState } from 'react';

export const useInsertProjectTag = ({
  onInsertComplete,
}: {
  onInsertComplete?: (tagId: number) => void;
}) => {
  const [insertTag] = useInsertProjectTagMutation();
  const [insertProjectTags] = useInsertProjectsProjectTagMutation();
  const [getTagByName] = useFindProjectTagByNameLazyQuery();

  const onInsert = async (name: string, projectId: number) => {
    const tag = await insertTag({
      variables: { name },
    });

    let newTagId = tag.data?.insert_project_tags_one?.id;

    if (!newTagId) {
      const res = await getTagByName({ variables: { name } });

      newTagId = res.data?.project_tags?.[0].id;
    }

    if (newTagId) {
      const res = await insertProjectTags({
        variables: {
          project_id: projectId,
          tag_id: newTagId,
        },
      });

      const projectTag = res.data?.insert_projects_project_tags_one;

      projectTag?.id && onInsertComplete && onInsertComplete(projectTag.id);
    }
  };

  return onInsert;
};

export const ProjectTagsSelect = ({ sx }: { sx?: SxProps }) => {
  const { data, loading } = useFindProjectTagsQuery();
  const { project } = useContext(StartupListDetailPageContext);
  const [insertProjectTags] = useInsertProjectsProjectTagMutation();
  const [deleteProjectTags] = useDeleteProjectsProjectTagMutation();
  const [deleteTag] = useDeleteProjectTagMutation();
  const [updateTag] = useUpdateProjectTagMutation();
  const onInsert = useInsertProjectTag({
    onInsertComplete: tagId => setAddedTagIds(prev => [...prev, tagId]),
  });

  const projectTags =
    project.project_tags
      .map(t => ({
        id: t.id,
        name: t.tag.name,
      }))
      .sort((a, b) => a.id - b.id) || [];

  const [addedTagIds, setAddedTagIds] = useState<number[]>(
    projectTags?.map(pt => pt.id),
  );

  const options: Tag[] = (data?.project_tags || []).map(t => ({
    id: t.id,
    value: t.name,
    description: t.name,
  }));

  const value: ValueTag[] = (projectTags || []).map(t => ({
    id: t.id,
    value: t.name,
    description: t.name,
    option_id: options.find(o => o.value === t.name)?.id,
  }));

  const onAdd = async (tag_name: string) => {
    const option = options.find(o => o.value === tag_name);
    if (!option) return;

    const res = await insertProjectTags({
      variables: {
        project_id: project.id,
        tag_id: option.id as number,
      },
    });

    const projectTag = res.data?.insert_projects_project_tags_one;
    projectTag?.id && setAddedTagIds(prev => [...prev, projectTag.id]);
  };

  const onRemove = async (tag: Tag) => {
    await deleteProjectTags({
      variables: {
        id: tag.id,
      },
      update: (cache, { data }) => {
        if (!data?.delete_projects_project_tags_by_pk?.id) {
          return;
        }

        cache.evict({
          id: cache.identify({
            __typename: 'projects_project_tags',
            id: data.delete_projects_project_tags_by_pk.id,
          }),
        });
        cache.gc();
      },
    });
  };

  const onDelete = async (tag_id: number) => {
    await deleteTag({
      variables: {
        id: tag_id,
      },
      update: (cache, { data }) => {
        if (!data?.delete_project_tags_by_pk?.id) {
          return;
        }

        cache.evict({
          id: cache.identify({
            __typename: 'project_tags',
            id: data.delete_project_tags_by_pk.id,
          }),
        });
        cache.gc();
      },
    });
  };

  const onUpdate = async (tag_id: number, name: string) => {
    await updateTag({
      variables: {
        id: tag_id,
        name: name,
      },
    });
  };

  return (
    <BaseTagsSelect
      loading={loading}
      options={options}
      value={value}
      onAdd={onAdd}
      onRemove={onRemove}
      // Tag management actions
      onInsert={name => onInsert(name, project.id)}
      onDelete={onDelete}
      onUpdate={onUpdate}
      sx={sx}
      addedTagIds={addedTagIds}
    />
  );
};
