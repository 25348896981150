import {
  Delete as DeleteIcon,
  DisabledByDefault as DiscardIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import MoreMenu from 'components/MoreMenu';
import DetailsHeaderTitle from 'components/dashboard/DetailsHeaderTitle';
import { forwardRef, useState } from 'react';
import { ChallengeType } from '../types';
import { useUpdateChallengeMutation } from 'apollo/generated/sdkShared';
import { ChallengeStatusSelect } from './ChallengeStatusSelect';
import { ArchiveChallengeModal } from '../ArchiveChallengeModal';
import ShareChallengeModal from './share/ShareChallengeModal';
import { captureAnalyticsEvent } from 'plugins/Analytics';

const ChallengeDetailsHeader = forwardRef<
  HTMLDivElement,
  { challenge: ChallengeType; isReadonly?: boolean }
>(({ challenge, isReadonly }, ref) => {
  const theme = useTheme();

  const [openArchiveChallengeModal, setOpenArchiveChallengeModal] =
    useState(false);

  const [openShareChallengeModal, setOpenShareChallengeModal] = useState(false);

  const [updateChallenge] = useUpdateChallengeMutation();

  const updateChallengeTitle = async (newTitle: string) => {
    await updateChallenge({
      variables: {
        id: challenge.id,
        payload: { title: newTitle },
      },
    });
  };

  const discardChallenge = async () => {
    await updateChallenge({
      variables: {
        id: challenge.id,
        payload: { status: 'discarded' },
      },
    });
  };

  return (
    <Box marginBottom={2}>
      <Stack
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'
        flexShrink={0}
        marginBottom={0.5}
        zIndex={theme.zIndex.drawer}
        bgcolor={theme.palette.background.paper}
        ref={ref}
      >
        <DetailsHeaderTitle
          {...(isReadonly
            ? { readonly: true }
            : {
                readonly: false,
                updateTitle: updateChallengeTitle,
              })}
          backToParentPath=''
          title={challenge.title || ''}
          sx={{ marginRight: 2 }}
        >
          <ChallengeStatusSelect
            challengeId={challenge.id}
            challengeStatus={challenge.status}
            isReadonly={isReadonly}
          />
        </DetailsHeaderTitle>
        {!isReadonly && (
          <Stack direction='row' justifyContent='end' spacing={1}>
            <Button
              variant='outlined'
              sx={{ whiteSpace: 'nowrap' }}
              onClick={() => {
                captureAnalyticsEvent('Challenge share button pressed', {
                  challengeId: challenge.id,
                });
                setOpenShareChallengeModal(true);
              }}
            >
              Share
            </Button>
            <MoreMenu>
              {challenge.status !== 'discarded' && (
                <MenuItem
                  sx={{ color: 'text.secondary' }}
                  onClick={() => discardChallenge()}
                >
                  <DiscardIcon fontSize='small' sx={{ marginRight: 0.5 }} />
                  <Typography variant='body1'>Discard</Typography>
                </MenuItem>
              )}
              <MenuItem
                sx={{ color: 'text.secondary' }}
                onClick={() => setOpenArchiveChallengeModal(true)}
              >
                <DeleteIcon fontSize='small' sx={{ marginRight: 0.5 }} />
                <Typography variant='body1'>Delete</Typography>
              </MenuItem>
            </MoreMenu>
          </Stack>
        )}
      </Stack>
      <ShareChallengeModal
        challenge={challenge}
        isOpen={openShareChallengeModal}
        onClose={() => setOpenShareChallengeModal(false)}
      />
      {openArchiveChallengeModal && (
        <ArchiveChallengeModal
          challengeId={challenge.id}
          title={challenge.title!}
          onHide={() => setOpenArchiveChallengeModal(false)}
          navigateToChallenges={true}
        />
      )}
    </Box>
  );
});

ChallengeDetailsHeader.displayName = 'ChallengeDetailsHeader';

export default ChallengeDetailsHeader;
