import { Page, Text, View, Image } from '@react-pdf/renderer';
import { formatMoney } from '../../../../utils/formatNumber';
import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  DataTableCell,
} from '@david.kucsai/react-pdf-table';
import { styles, tableCellStyle, headerCellStyle } from './styles';
import { removeBorders } from './utils';
import { OrderedCategorizedStartup } from './types';
import { truncateWithEllipsis } from 'utils/general';
import {} from './types';
import { Pager } from './Pager';
import { FALLBACK_IMAGE } from 'components/AvatarGroup';

export const AllStartupsTablePage = ({
  categorizedStartups,
}: {
  categorizedStartups: OrderedCategorizedStartup[];
}) => {
  return (
    <Page size='A4' orientation='landscape' style={styles.contentPage}>
      <Pager />
      <View>
        <View fixed>
          <Text style={styles.pageTitle}>All Startups</Text>
        </View>
        <Table data={categorizedStartups}>
          <TableHeader {...removeBorders}>
            <TableCell style={{ ...headerCellStyle, minWidth: 100 }}>
              Position & Company
            </TableCell>
            <TableCell style={{ ...headerCellStyle, minWidth: 100 }}>
              Description
            </TableCell>
            <TableCell style={headerCellStyle}>Total Funding</TableCell>
            <TableCell style={headerCellStyle}>Team Size</TableCell>
            <TableCell style={headerCellStyle}>Founded</TableCell>
            <TableCell style={headerCellStyle}>HQ</TableCell>
          </TableHeader>
          <TableBody {...removeBorders}>
            <DataTableCell
              style={[
                tableCellStyle,
                {
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  minWidth: 100,
                },
              ]}
              getContent={(categorizedStartup: OrderedCategorizedStartup) => (
                <>
                  <Text>{categorizedStartup.order}.</Text>
                  <Image
                    source={
                      categorizedStartup.startup.logo_url || FALLBACK_IMAGE
                    }
                    style={{ width: 20, marginLeft: 5 }}
                  />
                  <Text style={{ marginLeft: 2 }}>
                    {truncateWithEllipsis(
                      categorizedStartup.startup.name || '',
                      20,
                    )}
                  </Text>
                </>
              )}
            />
            <DataTableCell
              style={[tableCellStyle, { minWidth: 100 }]}
              getContent={(categorizedStartup: OrderedCategorizedStartup) =>
                truncateWithEllipsis(
                  categorizedStartup.startup.short_description || '',
                  100,
                )
              }
            />
            <DataTableCell
              style={tableCellStyle}
              getContent={(categorizedStartup: OrderedCategorizedStartup) =>
                categorizedStartup.startup.funding
                  ? formatMoney(categorizedStartup.startup.funding)
                  : '-'
              }
            />
            <DataTableCell
              style={tableCellStyle}
              getContent={(categorizedStartup: OrderedCategorizedStartup) =>
                categorizedStartup.startup.employees_count
                  ? categorizedStartup.startup.employees_count
                  : '-'
              }
            />
            <DataTableCell
              style={tableCellStyle}
              getContent={(categorizedStartup: OrderedCategorizedStartup) =>
                categorizedStartup.startup.founded_year
                  ? categorizedStartup.startup.founded_year
                  : '-'
              }
            />
            <DataTableCell
              style={tableCellStyle}
              getContent={(categorizedStartup: OrderedCategorizedStartup) =>
                categorizedStartup.startup.hq
                  ? categorizedStartup.startup.hq
                  : '-'
              }
            />
          </TableBody>
        </Table>
      </View>
    </Page>
  );
};
