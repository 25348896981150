import { Publish as PublishIcon } from '@mui/icons-material';
import { Button, Stack, Tooltip, Typography } from '@mui/material';
import { captureMessage } from '@sentry/react';
import {
  useSendReactMailMutation,
  useUpdateProjectMutation,
  useUpdateSourcingOrderMutation,
} from 'apollo/generated/sdkInnovationManager';
import { useCreateStartupListCollaboratorMutation } from 'apollo/generated/sdkShared';
import { REACT_EMAIL_TYPES } from 'config';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { PATH_ROOT } from 'routes/paths';
import { StartupListForDetail } from '../../@types/startupList';

const DeliverSourcing = ({
  currentStartupList,
  hasResults,
}: {
  currentStartupList: StartupListForDetail;
  hasResults: boolean;
}) => {
  const confirm = useConfirm();
  const onPublish = useOnPublish();

  return (
    <span>
      <Tooltip title={hasResults ? '' : 'Upload results to deliver'}>
        <Button
          variant='outlined'
          sx={{ minWidth: 130 }}
          startIcon={<PublishIcon />}
          disabled={!hasResults}
          color='secondary'
          onClick={async () => {
            await confirm({
              title: 'Deliver sourcing',
              content: (
                <Stack spacing={2} marginTop={2}>
                  <Typography variant='body2' color='text.secondary'>
                    Do you want to mark this sourcing as completed and notify
                    the client?
                  </Typography>

                  <Typography variant='body2' color='text.secondary'>
                    This action will send an email to the client ordered the
                    sourcing:{' '}
                    <b>
                      {currentStartupList.sourcing_order?.created_by?.person
                        ?.email || 'LIST HAS NO ASSIGNEE'}
                    </b>
                  </Typography>
                </Stack>
              ),
              confirmationText: 'Deliver',
              confirmationButtonProps: {
                variant: 'contained',
              },
              cancellationText: 'cancel',
              cancellationButtonProps: {
                color: 'inherit',
              },
            });

            await onPublish(currentStartupList);
          }}
        >
          Deliver
        </Button>
      </Tooltip>
    </span>
  );
};

const useOnPublish = () => {
  const [updateSourcingOrder] = useUpdateSourcingOrderMutation();
  const [upsertCollaborator] = useCreateStartupListCollaboratorMutation();
  const [sendMail] = useSendReactMailMutation();
  const { id } = useCurrentOrganizationFromContext();
  const [updateSourcingCompletedDate] = useUpdateProjectMutation();
  const { enqueueSnackbar } = useSnackbar();

  const onPublish = useCallback(
    async (currentStartupList: StartupListForDetail) => {
      const sourcingOrder = currentStartupList.sourcing_order;
      if (!sourcingOrder) {
        enqueueSnackbar('Sourcing order not found', { variant: 'error' });
        throw new Error('Sourcing order not found');
      }

      const { data } = await updateSourcingOrder({
        variables: {
          sourcing_order_id: sourcingOrder.id,
          updateData: {
            status: 'scouting_completed',
            delivered_date: new Date().toISOString(),
          },
        },
      });

      await updateSourcingCompletedDate({
        variables: {
          id: currentStartupList.id,
          payload: {
            sourcing_completed_at: new Date().toISOString(),
          },
        },
      });

      if (!data?.update_sourcing_orders_by_pk?.id) {
        enqueueSnackbar('Sourcing order update failed', { variant: 'error' });
        throw new Error('Sourcing order not found');
      }

      await upsertCollaborator({
        variables: {
          object: { role: 'editor', startup_list_id: currentStartupList.id },
        },
      });

      if (sourcingOrder.created_by) {
        const sourcer =
          sourcingOrder.venture_expert_json &&
          sourcingOrder.venture_expert_json.length > 0
            ? sourcingOrder.venture_expert_json[0]
            : undefined;

        await sendMail({
          variables: {
            emailPayload: {
              from: {
                name: 'GlassDollar Team',
                email: 'support@glassdollar.com',
              },
              reply_to: 'support@glassdollar.com',
              emailType: REACT_EMAIL_TYPES.deliverSourcingOrder,
              to: [sourcingOrder.created_by.person!.email],
              payload: {
                listId: currentStartupList.id,
                organizationId: id,
                recipientId: sourcingOrder.created_by.person?.id,
                sender: {
                  name: 'GlassDollar Team',
                  email: 'support@glassdollar.com',
                },
                ...(sourcer
                  ? {
                      sourcer: { name: sourcer?.name, email: sourcer?.email },
                    }
                  : {}),
                listUrl: PATH_ROOT.lists.details(currentStartupList.id),
              },
            },
          },
        });
      } else {
        captureMessage(
          `Assignee for list ${currentStartupList.title}/${currentStartupList.id} not found`,
        );
      }

      enqueueSnackbar('Sourcing has been completed!', { variant: 'success' });

      return data?.update_sourcing_orders_by_pk?.id;
    },
    [
      enqueueSnackbar,
      id,
      sendMail,
      updateSourcingCompletedDate,
      updateSourcingOrder,
      upsertCollaborator,
    ],
  );

  return onPublish;
};

export default DeliverSourcing;
