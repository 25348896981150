import { Box, Dialog, IconButton, Stack } from '@mui/material';
import { SeverErrorIllustration } from 'assets';
import StandardErrorSection from 'components/StandardErrorSection';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect } from 'react';
import { captureAnalyticsEvent } from 'plugins/Analytics';

const ErrorMessage = ({ title }: { title: string }) => {
  return (
    <Stack
      height='100%'
      padding={2}
      textAlign='center'
      alignItems='center'
      justifyContent='center'
    >
      <StandardErrorSection title={title} />
      <Box padding={8}>
        <SeverErrorIllustration />
      </Box>
    </Stack>
  );
};

const BaseErrorMessage = ({
  title = 'Something went wrong',
  isDialog = false,
  onClose,
}: {
  title?: string;
  isDialog?: boolean;
  onClose?: () => void;
}) => {
  useEffect(() => {
    captureAnalyticsEvent('BaseError', {
      url: window.location.href + window.location.search,
    });
  }, []);

  if (isDialog) {
    return (
      <Dialog open onClose={onClose}>
        <Box sx={{ position: 'relative' }}>
          <IconButton
            sx={{ position: 'absolute', right: 3, top: 3 }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
          <ErrorMessage title={title} />
        </Box>
      </Dialog>
    );
  }

  return <ErrorMessage title={title} />;
};

export default BaseErrorMessage;
