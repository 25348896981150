import { flatMap, orderBy } from 'lodash';
import Papa from 'papaparse';
import { CSV_FORMAT, downloadFile } from 'utils/downloadFile';

import { CustomDimensionCellJSON, CustomFeatureHeader } from '../@types/shared';
import {
  CategorizedSuppliers,
  ProjectCategories,
  Suppliers,
} from 'apollo/generated/sdkShared';

type StartupForExport = Pick<
  Suppliers,
  | 'id'
  | 'name'
  | 'domain'
  | 'website'
  | 'linkedin_url'
  | 'crunchbase_url'
  | 'short_description'
  | 'long_description'
  | 'hq'
  | 'founded_year'
  | 'employees_count'
  | 'funding'
  | 'investors'
  | 'tags'
> & {
  startup_corporate_customers: {
    corporate_customer: Pick<Suppliers, 'domain'>;
  }[];
};

export type CategoryForCSVExport = Pick<
  ProjectCategories,
  'id' | 'title' | 'short_description'
> & {
  custom_dimensions_schema: CustomFeatureHeader[];
  categorized_suppliers: (Pick<
    CategorizedSuppliers,
    | 'id'
    | 'is_archived'
    | 'is_in_matrix'
    | 'is_shortlisted'
    | 'is_selected'
    | 'matrix_table_score'
  > & {
    custom_dimensions_json_v1: Record<string, CustomDimensionCellJSON>;
    startup: StartupForExport;
  })[];
};

export const exportResultsToCSV = ({
  categories,
  projectTitle,
}: {
  categories: CategoryForCSVExport[];
  projectTitle: string;
}) => {
  if (categories.length === 1) {
    exportBenchmarkToCSV({ categoryToExport: categories[0], projectTitle });
  } else {
    exportLandscapeToCSV({ categoriesToExport: categories, projectTitle });
  }
};

export const exportBenchmarkToCSV = ({
  categoryToExport,
  projectTitle,
}: {
  categoryToExport: CategoryForCSVExport;
  projectTitle: string;
}) => {
  const categorizedStartups = categoryToExport.categorized_suppliers || [];

  const exportPayload = orderBy(
    categorizedStartups,
    ['matrix_table_score', 'id'],
    ['desc', 'asc'],
  ).map(categorizedStartup => {
    return {
      'Startup Name': categorizedStartup.startup.name,
      ...extractCoreStartupColumns(categorizedStartup.startup!),
      ...categoryToExport.custom_dimensions_schema.reduce(
        (customFeaturesByName, categorySchema) => {
          return {
            ...customFeaturesByName,
            [categorySchema.name]: normalizeCustomFeatureValue(
              (
                categorizedStartup.custom_dimensions_json_v1[
                  categorySchema.name
                ] || { value: null }
              ).value,
              categorySchema.type,
            ),
          };
        },
        {},
      ),

      'Added to Comparison': categorizedStartup.is_in_matrix ? 'Yes' : 'No',
      Shortlisted: categorizedStartup.is_shortlisted ? 'Yes' : 'No',
      'Selected for PoC': categorizedStartup.is_selected ? 'Yes' : 'No',
    };
  });

  const csvString = Papa.unparse(exportPayload, {
    delimiter: ',',
  });

  downloadFile({
    content: csvString,
    title: projectTitle,
    format: CSV_FORMAT,
  });
};

const exportLandscapeToCSV = ({
  categoriesToExport,
  projectTitle,
}: {
  categoriesToExport: CategoryForCSVExport[];
  projectTitle: string;
}) => {
  const categorizedStartupAndCategoryPairs = flatMap(
    categoriesToExport,
    category =>
      category.categorized_suppliers.map(
        categorizedStartup => [categorizedStartup, category] as const,
      ),
  );

  const exportPayload = categorizedStartupAndCategoryPairs.map(
    ([categorizedStartup, category]) => {
      if (!categorizedStartup.startup)
        throw Error(
          `Missing organization startup for categorized startup ID:${categorizedStartup.id}`,
        );

      return {
        'Startup Name': categorizedStartup.startup.name,
        'Category Title': category.title,
        'Category Description': category.short_description?.replace(
          /<[^>]*>/g,
          '',
        ),
        ...extractCoreStartupColumns(categorizedStartup.startup),
        Shortlisted: categorizedStartup.is_shortlisted ? 'Yes' : 'No',
        'Selected for PoC': categorizedStartup.is_selected ? 'Yes' : 'No',
      };
    },
  );

  const csvString = Papa.unparse(exportPayload, {
    delimiter: ',',
  });

  downloadFile({
    content: csvString,
    title: projectTitle,
    format: CSV_FORMAT,
  });
};

const normalizeCustomFeatureValue = (
  value: string | number | null,
  valueType: 'text' | 'tribool' | 'line' | 'number',
) => {
  if (valueType === 'text' || valueType === 'number') {
    return value;
  } else if (valueType === 'tribool') {
    return value === 'yes' ? 'Yes' : value === 'no' ? 'No' : 'N/A';
  } else if (valueType === 'line') {
    return value ? JSON.parse(value as string) : [];
  } else {
    throw Error(`Unknown value type: ${valueType}`);
  }
};

const extractCoreStartupColumns = (startup: StartupForExport) => {
  return {
    Domain: startup.domain,
    'Website URL': startup.website,
    'Linkedin URL': startup.linkedin_url,
    'Crunchbase URL': startup.crunchbase_url,
    Description: startup.short_description,
    'Long Description': startup.long_description,
    Location: startup.hq,
    'Founded Year': startup.founded_year,
    'Team Size': startup.employees_count,
    'Total Funding ($)': startup.funding,
    Customers: startup.startup_corporate_customers
      ?.map(scc => scc.corporate_customer?.domain)
      .join(','),
    Investors: startup.investors?.join(','),
    Tags: startup.tags?.join(','),
  };
};
