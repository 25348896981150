/**
 * To be honest I got no idea of where to put this component. Adrian
 */

import { Chip, Stack, SxProps, Tooltip, useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import {
  StartupLabelContent,
  StartupFunnelLabel,
} from 'components/dashboard/StartupInfoSidePanel/StartupLabelChipSelector';
import { useMemo } from 'react';

const StartupLabelCRMTableOrder = ['Tested', 'Testing', 'Connected'] as const;

export type StartupLabelCRMTableOrder =
  (typeof StartupLabelCRMTableOrder)[number];

export function getStartupFunnelLabelOrderWithoutInLibrary(
  labels: { label?: string | null; selected?: boolean | null }[] | null = [],
): StartupLabelCRMTableOrder | undefined {
  const selectedLabel = labels?.find(l => l.selected);

  if (selectedLabel?.label && selectedLabel.label !== 'InLibrary')
    return selectedLabel.label as StartupLabelCRMTableOrder;

  return StartupLabelCRMTableOrder.find(
    slo => labels?.findIndex(sl => sl.label === slo) !== -1,
  );
}

const Label = ({ label }: { label: StartupFunnelLabel }) => (
  <Stack sx={{ alignItems: 'center' }} direction='row'>
    <StartupLabelContent label={label} />
  </Stack>
);

export default function StartupFunnelLabelChip({
  label,
  startupName,
  sx,
}: {
  label?: StartupFunnelLabel | null;
  startupName: string;
  sx?: SxProps<Theme>;
}) {
  const theme = useTheme();

  const labelStyleMap: Record<
    StartupFunnelLabel,
    {
      backgroundColor: string;
      color: string;
    }
  > = {
    InLibrary: {
      backgroundColor: theme.palette.primary.luminous,
      color: theme.palette.primary.darker,
    },
    Connected: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    Testing: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },
    Tested: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },
  };

  const startupLabelTooltip: Record<StartupFunnelLabel, string> = useMemo(
    () => ({
      Inactive: `${startupName} is no longer in operation`,
      InLibrary: `${startupName} is in your library`,
      Connected: `${startupName} has a primary contact`,
      Testing: `${startupName} is currently being tested`,
      Tested: `${startupName} is registered as a supplier`,
      'GD Scouted': `${startupName} was previously sourced for a customer by our team of venture experts`,
      'GD Connected': `GlassDollar has a verified contact at ${startupName}`,
      'GD Supplier': `${startupName} has already run a Proof of Concept with GlassDollar`,
    }),
    [startupName],
  );

  if (!label) return null;

  return (
    <Tooltip title={startupLabelTooltip[label]}>
      <Chip
        label={<Label label={label} />}
        variant='filled'
        size='small'
        data-testid='startup-label-chip'
        style={{
          backgroundColor: labelStyleMap[label].backgroundColor,
          color: labelStyleMap[label].color,
          width: 'fit-content',
          fontWeight: 600,
          height: '18px',
          fontSize: '10px',
        }}
        sx={sx}
      />
    </Tooltip>
  );
}
