import * as Yup from 'yup';

export const STAKEHOLDER_VALIDATION_SCHEMA = {
  name: Yup.string().required('Full name is required'),
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
      'Enter a valid email',
    )
    .required('Email is required'),
  department: Yup.string().required('Department is required'),
};

export const validationSchema = () => ({
  'Briefing Form Validation': Yup.object().shape({
    problem: Yup.string().required(
      'Please describe your Need/Pain Point before submitting',
    ),
    desired_solution: Yup.string().required(
      'Please describe your Desired solution before submitting',
    ),

    additional_information: Yup.string(),
    title: Yup.string().required('Title is required'),
    due_date: Yup.string().when('orderSourcing', (orderSourcing, schema) => {
      return orderSourcing
        ? schema.required('Please select a delivery date to continue')
        : schema;
    }),
    delivery_type: Yup.string().when(
      'orderSourcing',
      (orderSourcing, schema) => {
        return orderSourcing
          ? schema.required('Please select a delivery option')
          : schema;
      },
    ),
  }),
});
