import { SEARCH_PARAMS } from 'config';
import Page500 from 'pages/Page500';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { buildBaseOrganizationUrl } from 'utils/url';

const RedirectToOrganizationLogin = () => {
  captureAnalyticsEvent('Redirect to Organization Login');
  const orgSubdomain = new URLSearchParams(window.location.search).get(
    SEARCH_PARAMS.organizationName,
  );

  const redirectToPath = new URLSearchParams(window.location.search).get(
    SEARCH_PARAMS.pathname,
  );

  if (!orgSubdomain) {
    return <Page500 />;
  }

  if (redirectToPath) {
    window.location.replace(
      `${buildBaseOrganizationUrl({
        subdomain: orgSubdomain,
      })}${redirectToPath}${window.location.search}`,
    );
  } else {
    window.location.replace(
      `${buildBaseOrganizationUrl({ subdomain: orgSubdomain })}/auth/login${
        window.location.search
      }`,
    );
  }
  return <></>;
};

export { RedirectToOrganizationLogin };
