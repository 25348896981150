import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { Icon } from '@iconify/react';
// material
import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// components
import {
  COLLAPSE_WIDTH,
  DRAWER_TRANSITION_LENGTH,
  DRAWER_WIDTH,
} from './MenuSidebar';
import NavbarContent from './NavbarContent';
import { UPDATES_BANNER_HEIGHT } from '.';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backgroundColor: theme.palette.background.default,
  transition: `width ${DRAWER_TRANSITION_LENGTH}`,
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
  },
  [theme.breakpoints.down('lg')]: {
    width: `calc(100% - ${COLLAPSE_WIDTH}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

type DashboardNavbarProps = {
  onOpenSidebar: VoidFunction;
  isNewUpdateReleased: boolean;
};

export default function DashboardNavbar({
  onOpenSidebar,
  isNewUpdateReleased,
}: DashboardNavbarProps) {
  const theme = useTheme();
  const { isCollapsed } = useCollapseDrawer();

  return (
    <RootStyle
      sx={{
        ...(isNewUpdateReleased && { top: UPDATES_BANNER_HEIGHT }),
        [theme.breakpoints.up('lg')]: {
          ...(isCollapsed && {
            width: `calc(100% - ${COLLAPSE_WIDTH}px)`,
          }),
        },
      }}
    >
      <ToolbarStyle>
        <IconButton
          onClick={onOpenSidebar}
          sx={{ mr: 1, color: 'text.primary' }}
        >
          <Icon icon={menu2Fill} />
        </IconButton>
        <NavbarContent />
        <Box sx={{ flexGrow: 1 }} />
      </ToolbarStyle>
    </RootStyle>
  );
}
