import { Clear } from '@mui/icons-material';
import { Button, Chip, Stack, Typography, useTheme } from '@mui/material';
import { useGetFeatureFlag } from 'contexts/FeatureFlagsContext';
import { useEffect, useState } from 'react';
import { BannerStyle } from './StyledBanner';

const UPDATE_TYPE = 'Oct-2024-Maintenance';

export const useIsMaintenanceBannerVisible = () => {
  const [isVisible, setState] = useState(
    !localStorage.getItem(UPDATE_TYPE) || false,
  );
  const { isEnabled } = useGetFeatureFlag('ff_maintenance');

  useEffect(() => {
    setState(!!isEnabled && !localStorage.getItem(UPDATE_TYPE));
  }, [isEnabled]);

  return {
    isVisible,
    setMaintenanceBannerVisible: () => {
      setState(false);
      localStorage.setItem(UPDATE_TYPE, 'true');
    },
  };
};

export const MaintenanceBanner = ({ onDismiss }: { onDismiss: () => void }) => {
  const { palette, zIndex } = useTheme();

  return (
    <BannerStyle
      sx={{
        background: palette.warning.main,
        top: 0,
        left: 0,
        zIndex: zIndex.modal,
      }}
    >
      <Stack direction='row' gap={2} alignItems='center'>
        <Chip
          label='Scheduled Maintenance'
          size='small'
          sx={{
            backgroundColor: palette.common.black,
            color: 'warning.main',
            fontWeight: 800,
            borderRadius: '0.25rem',
          }}
        />
        <Typography
          variant='body2'
          fontWeight='800'
          color={palette.common.black}
        >
          We will be performing maintenance on our servers during the weekend.
          Please expect some downtime. We apologize for any inconvenience.
        </Typography>
      </Stack>
      <Stack direction='row' gap={1} alignItems='center'>
        <Button
          sx={{ color: palette.common.black, minWidth: '0 !important' }}
          onClick={onDismiss}
        >
          <Clear fontSize='medium' />
        </Button>
      </Stack>
    </BannerStyle>
  );
};
