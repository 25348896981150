import { ReactNode } from 'react';
// material
import { Box, Stack, Typography } from '@mui/material';
//
import { SxProps, Theme } from '@mui/material';

// ----------------------------------------------------------------------

interface HeaderProps {
  rightAction?: ReactNode;
  titleAction?: ReactNode;
  heading: ReactNode;
  subHeading?: ReactNode;
  sx?: SxProps<Theme>;
}

export const Header = ({
  heading,
  sx,
}: {
  heading: string;
  sx?: SxProps<Theme>;
}) => {
  return (
    <Box sx={{ marginBottom: 5, ...sx }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant='h3' fontWeight={400} gutterBottom>
          {heading}
        </Typography>
      </Box>
    </Box>
  );
};

export default function HeaderBreadcrumbs({
  rightAction,
  titleAction,
  heading,
  subHeading,
  sx,
}: HeaderProps) {
  return (
    <Stack
      direction='row'
      alignItems='center'
      sx={{
        marginBottom: 5,
        ...sx,
      }}
    >
      <Stack
        gap={1}
        direction='row'
        alignItems='center'
        className='header'
        sx={{ flexGrow: 1 }}
      >
        <Stack>
          {typeof heading === 'string' ? (
            <Typography variant='h3' fontWeight={400}>
              {heading}
            </Typography>
          ) : (
            heading
          )}
          {subHeading && subHeading}
        </Stack>
        {titleAction && titleAction}
      </Stack>

      {rightAction && (
        <Box className='action' sx={{ flexShrink: 0 }}>
          {rightAction}
        </Box>
      )}
    </Stack>
  );
}
