import {
  Auth0ContextInterface,
  Auth0Provider,
  useAuth0,
} from '@auth0/auth0-react';
import { AppState } from '@auth0/auth0-react/dist/auth0-provider';
import { captureException } from '@sentry/react';
import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react';
// @types
import {
  Auth0ContextType,
  Auth0User,
  AuthUser,
  InvitationProps,
  LoginProps,
  UserType,
} from '../@types/authentication';
//
import { identity, merge, pickBy } from 'lodash';
import { reset } from 'plugins/PosthogAnalytics';
import { useNavigate } from 'react-router';
import { PATH_ROOT } from 'routes/paths';
import { getRootUrl } from 'utils/url';
import { GLASSDOLLAR_EMAIL_DOMAIN, NGROK_URL, auth0Config } from '../config';
import { useOrganizationUUIDContext } from './OrganizationUUIDContext';

// ----------------------------------------------------------------------

const AuthContext = createContext<Auth0ContextType | null>(null);

const AuthProviderLegacy = ({ children }: { children: ReactNode }) => {
  const auth0: Auth0ContextInterface<Auth0User> = useAuth0();

  const {
    isAuthenticated,
    user: auth0User,
    isLoading,
    getIdTokenClaims,
    handleRedirectCallback,
    loginWithRedirect,
    logout: auth0Logout,
    error,
    getAccessTokenSilently,
  } = auth0;
  const [token, setToken] = useState<string>();

  const logout = useCallback(
    (path: string) => {
      reset();
      // @ts-expect-error: TODO: FIXME!
      window.$chatwoot?.reset();
      auth0Logout({
        logoutParams: {
          returnTo: `${getRootUrl()}${path}`,
        },
      });
    },
    [auth0Logout],
  );

  useEffect(() => {
    const getToken = async () => {
      try {
        // https://auth0.com/blog/id-token-access-token-what-is-the-difference/
        const accessToken = await getAccessTokenSilently();

        if (!accessToken)
          throw Error('Missing accessToken for authorized session');

        setToken(accessToken);
      } catch (error) {
        console.log('[DEBUG LOGIN]', error);
        if (error instanceof Error) {
          if (error.message === 'Unknown or invalid refresh token.') {
            logout(PATH_ROOT.auth.login);
          } else {
            captureException(error);
          }
        }
      }
    };

    if (isAuthenticated) getToken();
  }, [
    isAuthenticated,
    handleRedirectCallback,
    getIdTokenClaims,
    getAccessTokenSilently,
    logout,
  ]);

  useEffect(() => {
    if (error) {
      console.log('[DEBUG]', error);
      captureException(error);

      alert('Something went wrong');
    }
  }, [error]);

  const acceptInvitation = async ({
    invitation,
    fullName,
  }: InvitationProps) => {
    await loginWithRedirect({
      authorizationParams: {
        invitation,
        fullName,
      },
      appState: { returnTo: `/` },
    });
  };

  const defaultReturnTo =
    window.location.pathname + window.location.search + window.location.hash;

  const login = async ({ email, returnTo, connection }: LoginProps) => {
    const prompt = ['Username-Password-Authentication', 'email'].includes(
      connection,
    )
      ? 'login'
      : undefined;

    await loginWithRedirect({
      authorizationParams: {
        prompt,
        // connection: 'email', For Passwordless
        // connection: 'Username-Password-Authentication', // Email and password
        connection,
        login_hint: email,
      },
      appState: {
        returnTo: returnTo || defaultReturnTo,
      },
    });
  };

  const isInitialized = !isLoading && (!isAuthenticated || Boolean(token));

  const providerValue: Auth0ContextType = {
    isAuthenticated: isAuthenticated && Boolean(token),
    isInitialized,
    method: 'auth0',
    /**
     * This property is annoying to Type because we know that the user is defined when isAuthenticated == true
     * but TS can't know that
     */
    user: mapAuth0UserToGd2User(auth0User),
    isStaff: getUserType(auth0User) === 'staff',
    token,
    login,
    acceptInvitation,
    logout,
  };

  return (
    <AuthContext.Provider value={providerValue}>
      {children}
    </AuthContext.Provider>
  );
};

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { organizationAuth0Id } = useOrganizationUUIDContext();

  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    // Helps the user to return to the page that they was originally requested before login
    navigate(appState?.returnTo || window.location.pathname, { replace: true });
  };

  if (!organizationAuth0Id) {
    return null;
  }

  return (
    <Auth0Provider
      clientId={auth0Config.clientId}
      domain={auth0Config.domain}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      cacheLocation='localstorage'
      authorizationParams={{
        redirect_uri: window.location.origin,
        organization: organizationAuth0Id,
        audience: auth0Config.audience,
        ngrok_url: import.meta.env.VITE_ENV === 'development' ? NGROK_URL : '',
      }}
    >
      <AuthProviderLegacy>{children}</AuthProviderLegacy>
    </Auth0Provider>
  );
};

const mapAuth0UserToGd2User = (
  auth0User: Auth0User | undefined,
  overrideProperties: Partial<AuthUser> = {},
): AuthUser => {
  const isContentEditor =
    auth0User?.['https://app.glassdollar.com'].app_metadata?.isContentEditor ||
    getUserType(auth0User) === 'staff';

  const userId = auth0User?.sub || '';

  const userProfile =
    auth0User?.['https://app.glassdollar.com/user-profile'].data;

  return merge(
    {
      id: userProfile?.id || 0,
      uid: userId,
      photoURL: auth0User?.picture || '',
      email: auth0User?.email || '',
      displayName: userProfile?.full_name || auth0User?.name || '',
      team: userProfile?.team || null,
      type: getUserType(auth0User),
      organizationId:
        auth0User?.['https://hasura.io/jwt/claims']?.[
          'x-hasura-organization-id'
        ],
      isContentEditor,
    },
    pickBy(overrideProperties, identity),
  );
};

export const isStaffEmail = (email: string) => {
  if (import.meta.env.VITE_ENV !== 'production') {
    return (
      email.includes(GLASSDOLLAR_EMAIL_DOMAIN) || email.includes('-admin@')
    );
  }

  return email.includes(GLASSDOLLAR_EMAIL_DOMAIN);
};

const getUserType = (user: Auth0User | undefined): UserType => {
  if (!user) return 'bu_member';

  if (user.email && isStaffEmail(user.email)) {
    return 'staff';
  } else {
    return (
      // This will only be available when using ngrok in development
      // It will always work in production
      user?.['https://app.glassdollar.com/user-profile']?.data?.type ||
      // This one will always work but it can cause the following issue in production
      // https://www.notion.so/glassdollar/Enable-SSO-integration-for-NEW-Q4-Supported-by-39b9a82bb9f44e7ab7e2b75890145480?pvs=4#6b68441e91d245468f65d1a548ffc995
      user['https://app.glassdollar.com'].user_type
    );
  }
};

export { AuthContext, AuthProvider };
