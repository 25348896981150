export const sizes = {
  buttonHeight: '36px',
} as const;

declare module '@mui/material/styles' {
  interface Theme {
    sizes: typeof sizes;
  }
  interface ThemeOptions {
    sizes?: typeof sizes;
  }
}
