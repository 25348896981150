import { useSharedPagesContext } from 'layouts/SharedPagesLayout';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { PATH_NAME } from 'routes/paths';

const useAlternativeNavigate = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isSharedPage } = useSharedPagesContext();

  const navigateTo = useCallback(
    (path: string) => {
      if (
        isSharedPage &&
        (path.includes(PATH_NAME.personProfile) ||
          path.includes(PATH_NAME.projectStageChange))
      ) {
        return;
      }

      if (path.includes(PATH_NAME.personProfile)) {
        const stakeholderId = path.split('/').pop();

        if (stakeholderId) {
          captureAnalyticsEvent('Stakeholder details viewed', {
            stakeholderId: +stakeholderId,
          });
        }
      }

      navigate(path, { state: { backgroundLocation: location } });
    },
    [isSharedPage, navigate, location],
  );

  return { navigateTo };
};

export default useAlternativeNavigate;
