import { Box, IconButton, SxProps, Tooltip, useTheme } from '@mui/material';
import {
  BaseStartupAvatar,
  BaseStartupAvatarSize,
} from 'components/base/BaseStartupAvatar';
import { customShadows } from 'theme/shadows';

export function StartupClickBox({
  onClick = () => {},
  startup,
  disabled = false,
  sxProps = {},
  avatarSize,
}: {
  onClick?: () => void;
  startup: {
    name: string;
    logo_url: string | null | undefined;
    domain: string;
  };
  disabled?: boolean;
  sxProps?: SxProps;
  imageSxProps?: SxProps;
  avatarSize: BaseStartupAvatarSize;
}) {
  const { palette } = useTheme();

  return (
    <Tooltip title={startup.name}>
      <Box component='span' sx={sxProps}>
        <IconButton
          onClick={e => {
            e.stopPropagation();
            onClick?.();
          }}
          disabled={disabled}
          sx={
            disabled
              ? {
                  ':disabled': {
                    border: '0.11rem solid',
                    borderColor: palette.primary.light,
                    boxShadow: customShadows.dark.z4,
                    transform: 'scale(1.05)',
                  },
                }
              : {
                  transition: 'all 0.3s ease-in-out',
                  '&:hover': {
                    boxShadow: customShadows.light.z1,
                    transform: 'scale(1.05)',
                  },
                }
          }
        >
          <BaseStartupAvatar
            startup={{
              name: startup.name,
              domain: startup.domain,
              logo_url: startup.logo_url,
            }}
            size={avatarSize}
          />
        </IconButton>
      </Box>
    </Tooltip>
  );
}
