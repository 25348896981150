import { useApolloClient } from '@apollo/client';
import { Delete as DeleteIcon } from '@mui/icons-material';
import {
  Button,
  Divider,
  IconButton,
  Stack,
  SxProps,
  Typography,
  Zoom,
} from '@mui/material';
import {
  OrganizationStartupPitches,
  useArchivePitchMutation,
  useGetPitchForStartupProfileQuery,
  useUpdatePitchStatusMutation,
  useUpdatePitchTitleMutation,
} from 'apollo/generated/sdkShared';
import Markdown from 'components/Markdown';
import BaseLabel from 'components/base/BaseLabel';
import FilePreview from 'components/base/upload/FilePreview';
import ContentEditableText from 'components/shared/ContentEditableText';
import { useSharedPagesContext } from 'layouts/SharedPagesLayout';
import { useConfirm } from 'material-ui-confirm';
import { useEffect, useState } from 'react';

type StartupPitch = Pick<
  OrganizationStartupPitches,
  | 'id'
  | 'startup_id'
  | 'title'
  | 'status'
  | 'contact_email'
  | 'elevator_pitch'
  | 'differentiation'
  | 'customer_references'
  | 'enterprise_pricing_model'
>;

export const StartupPitchDetailsTab = ({ pitchId }: { pitchId: number }) => {
  const { data } = useGetPitchForStartupProfileQuery({
    variables: { pitchId },
  });
  const { isSharedPage } = useSharedPagesContext();
  const couldEdit = !isSharedPage;

  const pitch = data?.organization_startup_pitches_by_pk;

  if (!pitch) {
    return null;
  }

  return (
    <Stack spacing={2}>
      <Stack direction='row' spacing={2} alignItems='center'>
        <EditableTitle pitch={pitch} editable={pitch.status !== 'rejected'} />
        {pitch.status === 'rejected' && (
          <BaseLabel
            sx={({ spacing }) => ({
              paddingX: 1,
              minWidth: spacing(5),
            })}
            color='error'
          >
            rejected
          </BaseLabel>
        )}
        {couldEdit && (
          <PitchCTAs sx={{ marginLeft: 'auto !important' }} pitch={pitch} />
        )}
      </Stack>
      <Stack>
        <Typography color='text.secondary'>Pitch</Typography>
        <MarkdownField content={pitch.elevator_pitch} />
      </Stack>
      <Stack>
        <Typography color='text.secondary'>Differentiation</Typography>
        <MarkdownField content={pitch.differentiation} />
      </Stack>
      {pitch.customer_references && (
        <Stack>
          <Typography color='text.secondary'>Referenced Customers</Typography>
          <MarkdownField content={pitch.customer_references} />
        </Stack>
      )}
      {pitch.enterprise_pricing_model && (
        <Stack>
          <Typography color='text.secondary'>
            Enterprise pricing model
          </Typography>
          <MarkdownField content={pitch.enterprise_pricing_model} />
        </Stack>
      )}
      {pitch.poc_experience && (
        <Stack>
          <Typography color='text.secondary'>PoC experience</Typography>
          <MarkdownField content={pitch.poc_experience} />
        </Stack>
      )}
      {pitch.documents.length > 0 && (
        <Stack spacing={2}>
          <Typography color='text.secondary'>Sales documents</Typography>
          <Stack direction='row' spacing={1}>
            {pitch.documents.map(document => (
              <FilePreview
                key={pitch.id}
                document={{
                  name: document.name,
                  url: document.url,
                }}
                readonly
              />
            ))}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

const PitchCTAs = ({ sx, pitch }: { sx?: SxProps; pitch: StartupPitch }) => {
  const { cache: apolloCache } = useApolloClient();
  const [updateStatus] = useUpdatePitchStatusMutation();
  const [archivePitch] = useArchivePitchMutation();

  const markAsAccepted = async () => {
    await updateStatus({
      variables: { pitchId: pitch.id, status: 'accepted' },
    });
  };

  const confirm = useConfirm();
  const handleAccept = () => {
    confirm({
      title: 'Do you want to mark this Pitch as Accepted?',
      content: 'The company pitch will become visible to all non-admin users.',
      contentProps: { color: 'text.secondary', sx: { marginTop: 2 } },
      confirmationText: 'Accept',
      confirmationButtonProps: { variant: 'contained', color: 'primary' },
      cancellationText: 'cancel',
      cancellationButtonProps: { color: 'inherit' },
    }).then(() => {
      markAsAccepted();
    });
  };
  const handleReject = () => {
    confirm({
      title: 'Do you want to mark this Pitch as Rejected?',
      confirmationText: 'Reject',
      confirmationButtonProps: { variant: 'contained', color: 'error' },
      cancellationText: 'cancel',
      cancellationButtonProps: { color: 'inherit' },
    }).then(() => {
      updateStatus({ variables: { pitchId: pitch.id, status: 'rejected' } });
    });
  };

  const handleDelete = async () => {
    await confirm({
      title: 'Do you want to delete this Pitch?',
      content: 'This action cannot be undone.',
      contentProps: { color: 'text.secondary', sx: { marginTop: 2 } },
      confirmationText: 'Delete',
      confirmationButtonProps: { variant: 'contained', color: 'error' },
      cancellationText: 'cancel',
      cancellationButtonProps: { color: 'inherit' },
    });
    await archivePitch({ variables: { pitchId: pitch.id } });

    apolloCache.modify({
      id: apolloCache.identify(pitch),
      fields: {
        archived_at: () => new Date().toISOString(),
      },
    });
  };

  return (
    <Stack direction='row' spacing={1} alignItems='center' sx={sx}>
      {pitch.status === 'new' ? (
        <>
          <Button onClick={handleAccept}>Accept</Button>
          <Divider orientation='vertical' />
          <Button variant='text' color='error' onClick={handleReject}>
            Reject
          </Button>
        </>
      ) : (
        <IconButton>
          <DeleteIcon onClick={handleDelete} />
        </IconButton>
      )}
    </Stack>
  );
};

const EditableTitle = ({
  editable,
  pitch: { id, title },
}: {
  editable: boolean;
  pitch: { id: number; title: string };
}) => {
  const [updateTitle] = useUpdatePitchTitleMutation();
  const [showAlert, setShowAlert] = useState(false);
  const handleUpdate = (newTitle: string) => {
    if (newTitle !== title) {
      updateTitle({ variables: { pitchId: id, title: newTitle || '' } }).then(
        () => {
          setShowAlert(true);
        },
      );
    }
  };
  useEffect(() => {
    if (showAlert) {
      setTimeout(() => setShowAlert(false), 2000);
    }
  }, [showAlert]);

  return (
    <Stack direction='row' spacing={1} alignItems='center'>
      <ContentEditableText
        variant='h5'
        isEditable={editable}
        text={title}
        onBlur={event => handleUpdate(event.target.textContent || '')}
      />
      {editable && (
        <Zoom in={showAlert}>
          <Typography color='primary' variant='caption'>
            Updated
          </Typography>
        </Zoom>
      )}
    </Stack>
  );
};

const MarkdownField = ({ content }: { content: string }) => {
  return (
    <Typography whiteSpace='pre-wrap' variant='body2' component={'div'}>
      {content.startsWith('<') ? <Markdown>{content}</Markdown> : content}
    </Typography>
  );
};
