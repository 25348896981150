import {
  EnumTableNotificationTypeEnum,
  useSendNotificationMutation,
} from 'apollo/generated/sdkInnovationManager';
import {
  GetStartupNotesDocument,
  useCreateStartupNoteMutation,
  useDeleteStartupNoteMutation,
  useGetStartupNotesQuery,
  useUpdateStartupNoteMutation,
} from 'apollo/generated/sdkShared';
import { NotesSection } from 'components/notes/NotesSection';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { useLocation } from 'react-router';
import { toAPIArray } from 'utils/general';

export const StartupInfoSidePanelNotes = ({
  startupId,
  startupName,
  startupLogoUrl,
  listId,
  listTitle,
}: {
  startupId: number;
  startupName: string;
  startupLogoUrl: string | null | undefined;
  listId: number | null;
  listTitle: string;
}) => {
  const location = useLocation();

  const { data: notesData, loading: notesLoading } = useGetStartupNotesQuery({
    variables: { startupId },
  });

  const [createNote, { loading: creatingNoteInProgress }] =
    useCreateStartupNoteMutation({
      refetchQueries: [GetStartupNotesDocument],
    });
  const [sendNotification] = useSendNotificationMutation();

  const [addAttachmentToNote] = useUpdateStartupNoteMutation({
    refetchQueries: [GetStartupNotesDocument],
  });

  const [_deleteNote] = useDeleteStartupNoteMutation({
    refetchQueries: [GetStartupNotesDocument],
  });

  const deleteNote = (noteId: number) => _deleteNote({ variables: { noteId } });

  const onCreateNote = async (body: string) => {
    const resp = await createNote({
      variables: {
        body: body,
        startupId,
        listId: listId ? listId : undefined,
      },
    });
    captureAnalyticsEvent('Startup Note Added', {
      startupListId: listId as number,
    });
    const type: EnumTableNotificationTypeEnum = 'STARTUP_NOTE_ADDED';

    // eslint-disable-next-line
    const noteId = resp.data?.insert_organization_startup_notes_one?.id!;

    await sendNotification({
      variables: {
        input: {
          type,
          payload: {
            listId,
            listTitle,
            startupId,
            startupName,
            startupLogoUrl,
            organizationStartupNoteId: noteId,
            organizationStartupNoteBody: body,
            pathToEvent:
              location.pathname + location.search + `&startup_tab=notes`,
          },
        },
      },
    });

    return noteId;
  };

  const onAddAttachment = async ({
    noteId,
    attachmentUrls,
  }: {
    noteId: number;
    attachmentUrls: string[];
  }) => {
    await addAttachmentToNote({
      variables: {
        id: noteId,
        attachment_urls: toAPIArray(attachmentUrls),
      },
    });
  };

  const notes = notesData?.organization_startup_notes;

  return (
    <NotesSection
      quilId={`startup-notes-${startupId}`}
      notes={notes?.map(note => ({
        ...note,
        isBuMember: note.created_by?.type === 'bu_member',
        attachments: [], // To be used in the future once startup notes are migrated to new files architecture
        DEPRECATED_attachment_urls: note.attachment_urls,
      }))}
      loading={notesLoading}
      createNote={onCreateNote}
      addAttachmentToNote={onAddAttachment}
      creatingNoteInProgress={creatingNoteInProgress}
      deleteNote={deleteNote}
    />
  );
};
