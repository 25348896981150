import { useUpdateCategorizedStartupMutation } from 'apollo/generated/sdkShared';
import { usePersonContext } from 'contexts/PersonContext';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { useContext, useState } from 'react';
import {
  DecoratedStartup,
  PersonUser,
  StartupsTableContext,
} from '../../shared/StartupsTableContext';
import useNotifyInnovationManagerForVote from './useNotifyListOwnerForVote';
import useAuth from 'hooks/useAuth';
import { VotingButtonBase } from './VotingButtonBase';
import { Tooltip } from '@mui/material';

export const VotingButtonShared = ({
  decoratedStartup,
}: {
  decoratedStartup: DecoratedStartup;
}) => {
  const { person, setPersonModal, needPersonInformation } = usePersonContext();

  function showPersonModal() {
    setPersonModal({
      show: true,
      onConfirm: () => toggleVote,
    });
  }

  const { currentStartupList, votingNotificationSent, setState } =
    useContext(StartupsTableContext)!;

  const { user } = useAuth();

  const [votingInProgress, setVotingInProgress] = useState(false);
  const hasVoted = decoratedStartup.voters.includes(user.email || '');

  const [updateCategorizedStartup] = useUpdateCategorizedStartupMutation();
  const [notifyInnovationManagerForVote] = useNotifyInnovationManagerForVote();

  const toggleVote = async (
    stakeholder: Pick<PersonUser, 'email' | 'fullName'>,
  ) => {
    setVotingInProgress(true);

    const toggledHasVoted = !hasVoted;
    const voters = toggledHasVoted
      ? [...decoratedStartup.voters, stakeholder.email]
      : decoratedStartup.voters.filter(email => email !== stakeholder.email);

    await updateCategorizedStartup({
      variables: {
        id: decoratedStartup.categorized_startup_id,
        payload: {
          voters,
        },
      },
    });

    if (!votingNotificationSent) {
      await notifyInnovationManagerForVote({
        startupList: currentStartupList,
        stakeholderFullName: stakeholder.fullName,
      });
      setState(state => ({
        ...state,
        votingNotificationSent: true,
      }));
    }

    captureAnalyticsEvent(
      toggledHasVoted ? 'Vote For Startup Added' : 'Vote For Startup Removed',
      {
        startupId: decoratedStartup.id,
        startupListId: currentStartupList.id,
      },
    );

    setVotingInProgress(false);
  };

  const votersCount = decoratedStartup.voters.length;

  return (
    <>
      <Tooltip
        placement='bottom'
        title={hasVoted ? 'Click to remove your vote' : 'Click to vote'}
      >
        <span>
          <VotingButtonBase
            onClick={() => {
              if (needPersonInformation) {
                showPersonModal();
              } else if (person) {
                toggleVote(person);
              } else {
                toggleVote({
                  fullName: user.displayName,
                  email: user.email,
                });
              }
            }}
            disabled={votingInProgress}
            userVoted={hasVoted}
            votesCount={votersCount}
          />
        </span>
      </Tooltip>
    </>
  );
};
