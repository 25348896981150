import {
  LinkedIn as LinkedInIcon,
  Link as WebsiteIcon,
} from '@mui/icons-material';
import { Box, Link, useTheme } from '@mui/material';
import { TooltipPreviewAndCopy } from 'components/base/TooltipPreviewAndCopy';
import { useCallback } from 'react';
import { addProtocolIfNotUrl } from 'utils/url';

export const SocialLinkIcon = ({
  fieldName,
  socialURL,
}: {
  fieldName: 'website' | 'linkedin' | 'crunchbase';
  socialURL: string;
}) => {
  const theme = useTheme();

  const onClick = useCallback(() => {
    const socialWindow = window.open();
    const socialURLWithProtocol = addProtocolIfNotUrl(socialURL);
    if (socialWindow) {
      socialWindow.opener = null;
      socialWindow.location.href = socialURLWithProtocol;
    } else {
      window.open(
        socialURLWithProtocol,
        '_blank',
        'noopener,noreferrer,resizable',
      );
    }
  }, [socialURL]);

  return (
    <TooltipPreviewAndCopy
      onTextClick={onClick}
      previewContent={socialURL}
      textToCopy={socialURL}
    >
      <Link
        onClick={onClick}
        sx={{
          cursor: 'pointer',
          alignItems: 'center',
          display: 'flex',
          color: theme.palette.grey[800],
        }}
      >
        <SocialIcon social={fieldName} />
      </Link>
    </TooltipPreviewAndCopy>
  );
};

const SocialIcon = ({
  social,
}: {
  social: 'website' | 'linkedin' | 'crunchbase';
}) => {
  switch (social) {
    case 'linkedin':
      return <LinkedInIcon sx={{ height: 22, width: 22 }} />;
    case 'crunchbase':
      return <CrunchbaseIcon />;
    default:
      return <WebsiteIcon sx={{ height: 22, width: 22, marginRight: 0.3 }} />;
  }
};

const CrunchbaseIcon = () => {
  return (
    <Box sx={{ paddingBottom: 0.2 }}>
      <img
        alt='magnet'
        src='/static/icons/cb-icon.svg'
        height={35}
        width={28}
      />
    </Box>
  );
};
