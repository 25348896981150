import { Verified as VerifiedIcon } from '@mui/icons-material';
import {
  Badge,
  Stack,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { ConnectedStartupLabel } from 'components/LabelStatus';
import LightTooltip from 'components/LightTooltip';
import TypographyWithEllipsis from 'components/TypographyWithEllipsis';
import CompanyDetails from 'components/shared/table/CompanyDetails';
import { useStartupSidePanel } from 'contexts/StartupSidePanelContext';
import { isEqual } from 'lodash';
import { memo, useContext, useMemo } from 'react';
import { DecoratedStartup, StartupsTableContext } from './StartupsTableContext';
import { useEnrichmentProgressSpinner } from 'components/enrichmentProgress/useEnrichmentProgressSpinner';
import { BaseStartupAvatar } from 'components/base/BaseStartupAvatar';

type Props = GridRenderCellParams<string, DecoratedStartup>['row'] & {
  isLongList: boolean;
  isRankEnabled: boolean;
};

const StyledStack = styled(Stack)({
  '& >:not(style)+:not(style)': {
    marginLeft: '0px !important',
  },
});

const NameColumn = (props: Props) => {
  const {
    website,
    employees_count,
    case_study_count,
    id,
    name,
    domain,
    founded_year,
    funding,
    hq,
    logo_url,
    is_in_matrix,
    is_recommended,
    order,
    connectionStatus,
    isLongList,
    isRankEnabled,
  } = useMemo(
    () => ({
      website: props.website,
      employees_count: props.employees_count,
      case_study_count: props.startup_corporate_customers.filter(
        rc => rc.case_study_url,
      ).length,
      id: props.id,
      name: props.name,
      founded_year: props.founded_year,
      funding: props.funding,
      domain: props.domain,
      hq: props.hq,
      logo_url: props.logo_url,
      is_in_matrix: props.is_in_matrix,
      is_recommended: props.is_recommended,
      order: props.order,
      connectionStatus: props.connection?.status,
      isLongList: props.isLongList,
      isRankEnabled: props.isRankEnabled,
    }),
    [props],
  );

  const theme = useTheme();
  const { isPublicPage, category, currentStartupList } =
    useContext(StartupsTableContext)!;
  const enrichSpinner = useEnrichmentProgressSpinner({
    startupDomain: domain,
  });

  const { openStartupSidePanel } = useStartupSidePanel();
  const shouldRenderConnected =
    connectionStatus === 'accepted' && !isPublicPage;

  const handleOpenStartupSidePanel = () => {
    openStartupSidePanel(id, {
      startupListId: currentStartupList.id,
      categoryId: category.id,
    });
  };

  return (
    <StyledStack direction='row' spacing={1} alignItems='center' width='100%'>
      <Stack
        direction='row'
        alignItems='center'
        alignContent='center'
        spacing={2}
        sx={{
          position: 'relative',
          marginRight: 2,
        }}
      >
        {isRankEnabled && is_in_matrix && !isLongList && (
          <Typography
            sx={{ margin: '5px' }}
            variant='h5'
            data-testid='list-order'
          >
            {order}
          </Typography>
        )}
        <Badge
          badgeContent={
            is_recommended && (
              <Tooltip title='Recommended' placement='top'>
                <VerifiedIcon color='primary' fontSize='medium' />
              </Tooltip>
            )
          }
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <BaseStartupAvatar
            startup={{ logo_url, name, domain }}
            size='small'
          />
        </Badge>
      </Stack>
      <Stack paddingLeft='5px'>
        <Stack
          direction='row'
          alignItems='center'
          data-test='startup-name-cell'
        >
          <TypographyWithEllipsis
            tooltipTitle={<Typography variant='body2'>{name}</Typography>}
            TooltipComponent={props => <LightTooltip {...props} />}
            variant='body2'
            sx={{
              color: `${theme.palette.secondary.main} !important`,
              cursor: 'pointer',
              fontWeight: 500,
              maxWidth: 150,
            }}
            className='company-name'
            onClick={() => handleOpenStartupSidePanel()}
          >
            {name}
          </TypographyWithEllipsis>
          {enrichSpinner ? (
            enrichSpinner
          ) : shouldRenderConnected && !isLongList ? (
            <ConnectedStartupLabel sx={{ marginLeft: 1 }} />
          ) : null}
        </Stack>
        <CompanyDetails
          company={{
            website,
            employees_count,
            founded_year,
            funding,
            hq,
            case_study_count,
          }}
          showWebsiteLink
        />
      </Stack>
    </StyledStack>
  );
};

const MemoizedNameColumn = memo(NameColumn, (prev, next) =>
  isEqual(prev, next),
);

export { MemoizedNameColumn, NameColumn };
