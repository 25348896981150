import { useGetViewStartupListEventPayloadLazyQuery } from 'apollo/generated/sdkShared';
import { captureAnalyticsEvent } from 'plugins/Analytics';
import { useEffect, useState } from 'react';

const useCaptureViewStartupEvent = ({
  startupId,
  startupDomain,
  listId,
  categoryId,
}: {
  startupId: number;
  startupDomain: string;
  listId: number | undefined;
  categoryId: number | undefined;
}) => {
  const [eventTracked, setEventTracked] = useState(false);
  const [fetchEventPayload] = useGetViewStartupListEventPayloadLazyQuery();

  useEffect(() => {
    if (!eventTracked && startupId) {
      fetchEventPayload({
        variables: {
          startupListId: listId!,
          categoryId: categoryId!,
        },
      }).then(response => {
        const { data } = response;
        const { startup_lists_by_pk: list, list_categories_by_pk: category } =
          data || {};

        captureAnalyticsEvent('View Startup', {
          startupId: startupId,
          startupDomain: startupDomain,
          listId: listId,
          listTitle: list?.title,
          listSource: list?.source,
          listType: list?.result_type as 'landscape' | 'benchmark',
          isDiscovery: Boolean(list?.is_readonly),
          discoveryItemIsImported: Boolean(list?.is_subscribed),
          categoryId: category?.id,
          categoryTitle: category?.title,
          assigneeUID: list?.assignee?.uid,
          assigneeEmail: list?.assignee?.person.email,
        });
      });

      setEventTracked(true);
    }
  }, [
    categoryId,
    listId,
    eventTracked,
    startupId,
    startupDomain,
    fetchEventPayload,
  ]);
};

export default useCaptureViewStartupEvent;
