import { LOCAL_STORAGE_KEYS } from 'config';
import Page500 from 'pages/Page500';
import { Component, ReactNode } from 'react';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ScoutStartupsErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): State {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.LAST_SCOUTED_STARTUPS);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.STARTUPS_SCOUTER_FILTERS);

    return { hasError: true };
  }

  public render() {
    if (this.state.hasError) {
      return <Page500 />;
    }

    return this.props.children;
  }
}

export default ScoutStartupsErrorBoundary;
