import { useApolloClient } from '@apollo/client';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
} from '@mui/material';
import { captureException } from '@sentry/react';
import { useRemoveChallengeStakeholderMutation } from 'apollo/generated/sdkShared';
import { useSnackbar } from 'notistack';

export const RemoveChallengeStakeholderModal = ({
  open,
  onHide,
  challengeStakeholderId,
  challengeStakeholderName,
}: {
  open: boolean;
  onHide: () => void;
  challengeStakeholderId: number;
  challengeStakeholderName: string;
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [removeChallengeStakeholder] = useRemoveChallengeStakeholderMutation();

  const { cache: apolloCache } = useApolloClient();

  const handleUnlinkChallenge = async () => {
    try {
      await removeChallengeStakeholder({
        variables: {
          stakeholderId: challengeStakeholderId,
        },
      });

      apolloCache.evict({
        id: apolloCache.identify({
          __typename: 'challenge_stakeholders',
          id: challengeStakeholderId,
        }),
      });

      apolloCache.gc();

      enqueueSnackbar('Stakeholder removed', {
        variant: 'success',
      });
      onHide();
    } catch (error) {
      captureException(error);
      enqueueSnackbar(`Stakeholder couldn't be removed`, {
        variant: 'error',
      });
    }
  };
  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Remove challenge stakeholder</DialogTitle>
      <DialogContent sx={{ pb: 0 }}>
        <Typography sx={{ marginTop: 2 }}>
          Are you sure you want to remove {challengeStakeholderName}?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant='text' color='inherit' onClick={onHide}>
          Cancel
        </Button>
        <Button
          onClick={() => handleUnlinkChallenge()}
          variant='contained'
          color='error'
        >
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};
