import { Box, SxProps, Typography, useTheme } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import { FALLBACK_SUPPLIER_LOGO_URL } from 'components/AvatarGroup';
import { useMemo } from 'react';
import Chart from 'react-apexcharts';
import { renderToString } from 'react-dom/server';

type ChartData = { x: string; y: number }[];
type Series = [Record<'data', ChartData>];

export function getTotalFundingChartDefinition(
  startups: Array<{
    founded_year?: number | null;
    funding?: number | null;
  }>,
): {
  series: Series;
  lineChartSeries: Series;
} {
  if (!startups)
    return {
      series: [{ data: [] }],
      lineChartSeries: [{ data: [] }],
    };

  const startupsFoundingYearMap = (
    ([...startups] || []).filter(
      s =>
        typeof s?.founded_year === 'number' &&
        s.founded_year > 1980 &&
        typeof s?.funding === 'number' &&
        s.funding > 0,
    ) as Array<{
      founded_year: number;
      funding: number;
      name: string;
      logo_url: string;
    }>
  )
    .sort((a, b) => a.founded_year - b.founded_year)
    .reduce(
      (
        prev: Record<
          string,
          {
            funding: number;
            startups: { name: string; logo_url: string; funding: number }[];
          }
        >,
        curr,
      ) => {
        const foundedYear = curr.founded_year.toString();
        const currFunding = curr.funding / 1000000;
        if (foundedYear in prev) {
          prev[foundedYear].funding += currFunding;
          prev[foundedYear].startups.push({
            name: curr.name,
            logo_url: curr.logo_url ?? FALLBACK_SUPPLIER_LOGO_URL,
            funding: currFunding,
          });
        } else {
          prev[foundedYear] = {
            funding: currFunding,
            startups: [
              {
                name: curr.name,
                logo_url: curr.logo_url ?? FALLBACK_SUPPLIER_LOGO_URL,
                funding: currFunding,
              },
            ],
          };
        }
        return prev;
      },
      {},
    );

  const seriesData = Object.keys(startupsFoundingYearMap).map(year => ({
    x: year,
    y: startupsFoundingYearMap[year].funding,
    startups: startupsFoundingYearMap[year].startups,
  }));

  if (seriesData.length === 0)
    return {
      series: [{ data: [] }] as Series,
      lineChartSeries: [{ data: [] }] as Series,
    };

  const currentYear = new Date().getFullYear();
  const oldestStartupYear = parseInt(seriesData[0].x);

  const lineChartSeriesData = [];
  for (let year = currentYear; year >= oldestStartupYear; year--) {
    const itemInSeries = seriesData.find(item => item.x === year.toString());
    // If itemInSeries y > 0
    if (itemInSeries)
      lineChartSeriesData.unshift({
        x: itemInSeries.x,
        y: itemInSeries.y,
        startups: itemInSeries.startups,
      });
    else
      lineChartSeriesData.unshift({ x: year.toString(), y: 0, startups: [] });
  }

  const oldestStartupYearDiff = new Date().getFullYear() - oldestStartupYear;
  const yearDiff = oldestStartupYearDiff < 10 ? 10 : 15;

  const last10to15yearsSeriesData = [];
  for (let year = currentYear; year > currentYear - yearDiff; year--) {
    const itemInSeries = seriesData.find(item => item.x === year.toString());
    // If itemInSeries y > 0
    // Hacky solutions such that we show a small bar when there are not startups in a year
    if (itemInSeries)
      last10to15yearsSeriesData.unshift({
        x: itemInSeries.x,
        y: itemInSeries.y,
        startups: itemInSeries.startups,
      });
    else
      last10to15yearsSeriesData.unshift({
        x: year.toString(),
        y: 0,
        startups: [],
      });
  }

  return {
    series: [{ data: last10to15yearsSeriesData }] as Series,
    lineChartSeries: [{ data: lineChartSeriesData }] as Series,
  };
}

export default function TotalFundingBarChart({
  series,
  sx,
  width = '40',
  height = '30',
}: {
  series: Series;
  sx?: SxProps;
  width?: string | number | undefined;
  height?: string | number | undefined;
}) {
  const {
    palette: {
      primary: { main },
    },
  } = useTheme();
  const options: ApexOptions = useMemo(
    () => ({
      xaxis: {
        type: 'category' as const,
        labels: {
          rotate: 70,
          rotateAlways: true,
          offsetY: 25,
          offsetX: 5,
          formatter(value) {
            return parseInt(value) % 5 === 0 ? value : '';
          },
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
        title: {
          text: 'In millions',
        },
      },
      grid: {
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      chart: {
        toolbar: {
          show: false,
        },
      },
      colors: [main],
      plotOptions: {
        bar: {
          columnWidth: '70%',
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        offsetY: -20,
        style: {
          colors: ['black'],
          fontWeight: 400,
        },
        formatter(val) {
          return val === 0 ? '' : (Number(val).toFixed(1) as unknown as string);
        },
      },
      tooltip: {
        enabled: true,
        shared: false,
        intersect: true,
        custom: ({ seriesIndex, dataPointIndex, w }) => {
          const data: {
            startups: {
              name: string;
              logo_url: string;
              funding: number;
            }[];
          } = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

          return renderToString(
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {data.startups.map(startup => (
                <div
                  key={startup.name}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px',
                    padding: '6px',
                    flexDirection: 'row',
                  }}
                >
                  <img
                    src={startup.logo_url}
                    alt={startup.name}
                    style={{
                      width: '20px',
                      height: '20px',
                      borderRadius: '50% !important',
                    }}
                  />
                  <Typography variant='body2' style={{}}>
                    {startup.name}:{' '}
                    <span
                      style={{
                        fontWeight: 800,
                        fontSize: '0.85rem',
                        marginLeft: '2px',
                      }}
                    >
                      {startup.funding.toFixed(1)}M
                    </span>
                  </Typography>
                </div>
              ))}
            </div>,
          );
        },
      },
    }),
    [main],
  );

  return (
    <Box sx={sx}>
      <Chart
        options={options}
        series={series}
        type='bar'
        width={width}
        height={height}
      />
    </Box>
  );
}
