import { StartupForCategoryReferencedCustomers } from 'components/dashboard/CategoryReferenceCustomers';
import { CorporateCustomer } from 'components/dashboard/StartupInfoSidePanel/useStartupDetailsFormik';
import { sortBy } from 'lodash';

export type CorporateCustomerInput = {
  name?: string | null;
  domain: string;
  logo_url?: string | null;
  type?: string;
};

const getReferencedCustomersAndSuppliers = ({
  startups,
  columnProvidedReferencedCustomers,
}: {
  startups: StartupForCategoryReferencedCustomers[];
  columnProvidedReferencedCustomers?: CorporateCustomer[];
}) => {
  const isMatrixOrTableColumn = !!columnProvidedReferencedCustomers;

  const referenceCustomers = () => {
    const corporateSuppliers: {
      [key: string]: { id: number; name: string; logo_url?: string | null }[];
    } = {};
    const items: CorporateCustomerInput[] = [];

    startups.forEach(startup => {
      const referencedCustomers = isMatrixOrTableColumn
        ? columnProvidedReferencedCustomers
        : startup.startup_corporate_customers?.map(c => c.corporate_customer);

      referencedCustomers?.forEach(refCustomer => {
        const customer: CorporateCustomerInput = {
          domain: refCustomer?.domain,
          name: refCustomer.name,
          logo_url: refCustomer.logo_url,
        };

        if (!corporateSuppliers[customer.domain]) {
          corporateSuppliers[customer.domain] = [];
        }

        if (
          !corporateSuppliers[customer?.domain].some(
            supplier => supplier.id === startup.id,
          )
        ) {
          const isDomainIncluded = startup.startup_corporate_customers?.some(
            scc => scc.corporate_customer?.domain === customer.domain,
          );

          if (isDomainIncluded) {
            corporateSuppliers[customer.domain].push({
              id: startup.id,
              name: startup.name,
              logo_url: startup.logo_url,
            });
          }
        }

        if (!items.some(ss => ss.domain === customer.domain)) {
          items.push(customer);
        }
      });
    });

    return {
      suppliers: corporateSuppliers,
      items,
    };
  };

  const { items, suppliers } = referenceCustomers();

  const referenceCustomersWithSuppliers = sortBy(
    items.map(customer => {
      return {
        ...customer,
        occurrence: suppliers[customer.domain as string]?.length || 0,
        suppliers: suppliers[customer.domain as string],
      };
    }),
    'occurrence',
  ).reverse();

  return {
    referenceCustomersWithSuppliers,
  };
};

export default getReferencedCustomersAndSuppliers;
