import { Stack } from '@mui/material';
import { EnumTableProjectStagesEnum } from 'apollo/generated/sdkInnovationManager';
import { useGetDataForProjectChangeQuery } from 'apollo/generated/sdkShared';
import { BaseDialog } from 'components/base/BaseDialog';
import { QuillEditor } from 'components/editor';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { PATH_PARAMS, PATH_ROOT } from 'routes/paths';
import { useChangeProjectStage } from './hooks/useChangeProjectStage';
import { captureAnalyticsEvent } from 'plugins/Analytics';

const reasonStages: EnumTableProjectStagesEnum[] = ['discarded', 'on_hold'];
export const commentSupportedStages = [
  'testing',
  'test_fail',
  'test_success',
  'discarded',
  'on_hold',
] as EnumTableProjectStagesEnum[];

export const ProjectStageChangeDialog = () => {
  const params = useParams();
  const projectId = params[PATH_PARAMS.projectId];
  const currentStage = params[PATH_PARAMS.currentStage];
  const nextStage = params[PATH_PARAMS.nextStage];

  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as { backgroundLocation?: Location };

  const handleClose = () => {
    if (state?.backgroundLocation) {
      navigate(-1);
    } else {
      navigate(PATH_ROOT.projects.pocs);
    }
  };

  if (!projectId || !nextStage || !currentStage) {
    return null;
  }

  return (
    <DrawerContent
      onClose={handleClose}
      projectId={Number(projectId)}
      currentStage={currentStage as EnumTableProjectStagesEnum}
      nextStage={nextStage as EnumTableProjectStagesEnum}
    />
  );
};

const DrawerContent = ({
  projectId,
  currentStage,
  nextStage,
  onClose,
}: {
  currentStage: EnumTableProjectStagesEnum;
  nextStage: EnumTableProjectStagesEnum;
  projectId: number;
  onClose: () => void;
}) => {
  const [comment, setComment] = useState('');
  const isReasonStage = reasonStages.includes(currentStage);
  const heading = `Provide ${isReasonStage ? 'a reason' : 'learning'} for the stage change`;
  const { handleSubmitChangeStage } = useChangeProjectStage();
  const { data, loading, previousData } = useGetDataForProjectChangeQuery({
    variables: { projectId },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    captureAnalyticsEvent('Project Stage Change Dialog Opened', {
      currentStage,
      nextStage,
      projectId: projectId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!previousData && data) {
      setComment(
        data.projects_by_pk?.project_stages.find(s => s.stage === currentStage)
          ?.comment ?? '',
      );
    }
  }, [data, currentStage, previousData]);

  const handleConfirm = async () => {
    const response = await handleSubmitChangeStage({
      fromStage: currentStage,
      toStage: nextStage,
      projectId,
      comment,
    });

    if (response) {
      onClose();
    }
  };

  return (
    <BaseDialog
      heading={heading}
      disableConfirmButton={loading}
      onConfirm={handleConfirm}
      onHide={() => {
        onClose();
      }}
    >
      <Stack>
        <QuillEditor
          simple
          value={comment}
          onChange={newValue => setComment(newValue)}
        />
      </Stack>
    </BaseDialog>
  );
};
