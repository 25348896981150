import { Box, Card, Stack, SxProps, Typography } from '@mui/material';
import { Link as LinkIcon } from '@mui/icons-material';

export const LinkConnectionCard = ({
  setShowLinkModal,
  sx,
}: {
  setShowLinkModal: (val: boolean) => void;
  sx?: SxProps;
}) => {
  return (
    <Box
      onClick={() => {
        setShowLinkModal(true);
      }}
    >
      <Card
        sx={{
          display: 'flex',
          justifyContent: 'center',
          height: 170,
          width: 262,
          border: '1px solid rgba(0, 0, 0, 0.1)',
          borderRadius: '6px',
          boxShadow: '0px 1px 6px 0px rgba(0, 0, 0, 0.08)',
          ':hover': {
            cursor: 'pointer',
          },
          ...sx,
        }}
      >
        <Stack direction='row' alignItems='center' spacing={0.5}>
          <LinkIcon color='primary' />
          <Typography variant='button' color='primary' fontSize={14}>
            Link
          </Typography>
        </Stack>
      </Card>
    </Box>
  );
};
