import { Box, SxProps, Typography, useTheme } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import { FALLBACK_SUPPLIER_LOGO_URL } from 'components/AvatarGroup';
import { useMemo } from 'react';
import Chart from 'react-apexcharts';
import { renderToString } from 'react-dom/server';

type ChartData = { x: string; y: number }[];
type Series = [Record<'data', ChartData>];

export function getFoundingYearChartDefinition(
  startups: Array<{
    founded_year?: number | null;
    name: string;
    logo_url?: string | null;
  }>,
): {
  series: Series;
  lineChartSeries: Series;
} {
  if (!startups)
    return {
      series: [{ data: [] }],
      lineChartSeries: [{ data: [] }],
    };

  const startupsFoundingYearMap = (startups || [])
    .filter(s => typeof s?.founded_year === 'number' && s.founded_year > 1980)
    .reduce(
      (
        prev: Record<
          string,
          { count: number; startups: { name: string; logo_url: string }[] }
        >,
        curr,
      ) => {
        const year = curr.founded_year as number;
        if (year in prev) {
          prev[year].count += 1;
          prev[year].startups.push({
            name: curr.name,
            logo_url: curr.logo_url ?? FALLBACK_SUPPLIER_LOGO_URL,
          });
        } else {
          prev[year] = {
            count: 1,
            startups: [
              {
                name: curr.name,
                logo_url: curr.logo_url ?? FALLBACK_SUPPLIER_LOGO_URL,
              },
            ],
          };
        }
        return prev;
      },
      {},
    );

  const seriesData = Object.keys(startupsFoundingYearMap).map(year => ({
    x: year,
    y: startupsFoundingYearMap[year].count,
    startups: startupsFoundingYearMap[year].startups,
  }));

  // Length is 0 after the filtering
  if (seriesData.length === 0)
    return {
      series: [{ data: [] }],
      lineChartSeries: [{ data: [] }],
    };

  const currentYear = new Date().getFullYear();
  const oldestStartupYear = parseInt(seriesData[0].x);

  const lineChartSeriesData = [];
  for (let year = currentYear; year >= oldestStartupYear; year--) {
    const itemInSeries = seriesData.find(item => item.x === year.toString());
    // If itemInSeries y > 0
    // Hacky solutions such that we show a small bar when there are not startups in a year
    if (itemInSeries)
      lineChartSeriesData.unshift({
        x: itemInSeries.x,
        y: itemInSeries.y,
        startups: itemInSeries.startups,
      });
    else lineChartSeriesData.unshift({ x: year.toString(), y: 0 });
  }

  const oldestStartupYearDiff = currentYear - oldestStartupYear;
  const yearDiff = oldestStartupYearDiff < 10 ? 10 : 15;

  const last10to15yearsSeriesData = [];
  for (let year = currentYear; year > currentYear - yearDiff; year--) {
    const itemInSeries = seriesData.find(item => item.x === year.toString());
    // If itemInSeries y > 0
    // Hacky solutions such that we show a small bar when there are not startups in a year
    if (itemInSeries)
      last10to15yearsSeriesData.unshift({
        x: itemInSeries.x,
        y: itemInSeries.y,
        startups: itemInSeries.startups,
      });
    else last10to15yearsSeriesData.unshift({ x: year.toString(), y: 0 });
  }

  return {
    series: [{ data: last10to15yearsSeriesData }] as Series,
    lineChartSeries: [{ data: lineChartSeriesData }] as Series,
  };
}

export default function FoundingYearBarChart({
  series,
  sx,
  width = '40',
  height = '30',
}: {
  series: Series;
  sx?: SxProps;
  width?: string | number | undefined;
  height?: string | number | undefined;
}) {
  const {
    palette: {
      primary: { main },
    },
  } = useTheme();
  const options: ApexOptions = useMemo(
    () => ({
      xaxis: {
        type: 'category' as const,
        labels: {
          rotate: 70,
          rotateAlways: true,
          offsetY: 25,
          offsetX: 5,
          formatter(value) {
            return parseInt(value) % 5 === 0 ? value : '';
          },
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
        title: {
          text: 'Founded',
        },
      },
      chart: {
        toolbar: {
          show: false,
        },
      },
      colors: [main],
      plotOptions: {
        bar: {
          columnWidth: '70%',
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        offsetY: -20,
        style: {
          colors: ['black'],
          fontWeight: 400,
        },
        formatter(val: number) {
          return val === 0 ? '' : val;
        },
      },
      tooltip: {
        enabled: true,
        custom: ({ seriesIndex, dataPointIndex, w }) => {
          const data: {
            startups: {
              name: string;
              logo_url: string;
            }[];
          } = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

          return renderToString(
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {data.startups.map(startup => (
                <div
                  key={startup.name}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px',
                    padding: '6px',
                    flexDirection: 'row',
                  }}
                >
                  <img
                    src={startup.logo_url}
                    alt={startup.name}
                    style={{
                      width: '20px',
                      height: '20px',
                      borderRadius: '50% !important',
                    }}
                  />
                  <Typography variant='body2'>{startup.name}</Typography>
                </div>
              ))}
            </div>,
          );
        },
      },
    }),
    [main],
  );

  return (
    <Box sx={sx}>
      <Chart
        options={options}
        series={series}
        type='bar'
        width={width}
        height={height}
      />
    </Box>
  );
}
