import RefreshBrowser from './RefreshBrowser';

const SystemMessages: React.FC = () => {
  return (
    <>
      <RefreshBrowser />
    </>
  );
};

export default SystemMessages;
