import {
  Divider,
  Skeleton,
  Slider,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useUpdateProjectForScopingMutation } from 'apollo/generated/sdkShared';
import BaseLabel from 'components/base/BaseLabel';
import { DifficultyField } from 'components/dashboard/projects/DifficultyField';
import { useProjectScopeContext } from 'contexts/ProjectScopeContext';
import { format } from 'date-fns';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import { ChangeEvent, ReactNode, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { formatMoney } from 'utils/formatNumber';
import {
  DECORATED_STAGE_MAPPING,
  LEAD_STAGES,
  mapStageNameForOrganization,
} from 'utils/projectStageEnum';
import { IMPACT_TYPE } from './BudgetAndImpactFields';
import { useGetTimeSpentIn } from './hooks/useGetTimeSpentIn';
import { ProjectSummaryStartupsAvatars } from './ProjectSummaryStartupsAvatars';
import { EnumTableProjectStagesEnum } from 'apollo/generated/sdkInnovationManager';
import { useCurrentOrganizationFromContext } from 'contexts/CurrentOrganizationContext';
import { ProjectTimeline } from 'hooks/useGetProjectAndStartupTimelines';

const thumbSliderStyle = makeStyles(() => ({
  slider: {
    '& .MuiSlider-thumb': {
      display: 'none',
    },
    '&:hover .MuiSlider-thumb': {
      display: 'block',
      width: 12,
      height: 12,
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
    },
  },
}));

export const initialProjectStages = [
  'sourcing',
  'sourcing_completed',
  'scoping',
];

const getStageSpentInLabel = (
  projectStage: EnumTableProjectStagesEnum,
  organizationSubdomain: string,
) => {
  const stageName = mapStageNameForOrganization({
    name: DECORATED_STAGE_MAPPING[projectStage],
    organizationSubdomain,
  });

  switch (projectStage) {
    case 'assessment':
      return 'In assessment since';
    case 'purchasing':
      return `In ${stageName} since`;
    case 'testing':
      return `In ${stageName} since`;
    case 'test_success':
      return 'Completed since';
    case 'test_fail':
      return 'Failed since';
    case 'discarded':
      return 'Discarded since';
    case 'on_hold':
      return 'On hold since';
    case 'adopted':
    case 'scaling':
    case 'adoption':
    case 'pilot_completed':
    case 'scaling_completed':
      return 'In implementation since';
    default:
      return '';
  }
};

export const getStartAndEndOfPoC = (projectTimeline: ProjectTimeline) => {
  const start = projectTimeline.find(
    pt =>
      pt.step_node.project_stage === 'testing' &&
      pt.step_node.name === 'PoC Kickoff',
  )?.completed_at;

  const end = projectTimeline.find(
    pt =>
      pt.step_node.project_stage === 'testing' &&
      pt.step_node.name === 'PoC Conclusion',
  )?.completed_at;

  return [
    ...(start ? [format(new Date(start!), 'MMM')] : []),
    ...(end ? [format(new Date(end!), 'MMM yy')] : []),
  ];
};

const ProjectSummary = () => {
  const { isStaff } = useAuth();
  const { subdomain: organizationSubdomain } =
    useCurrentOrganizationFromContext();
  const { enqueueSnackbar } = useSnackbar();
  const classes = thumbSliderStyle();
  const { project, projectTimeline, impactMetrics } = useProjectScopeContext();
  const { id: projectId } = project;

  const [updateProject] = useUpdateProjectForScopingMutation();

  const [adoptionProbabilityValue, setAdoptionProbabilityValue] =
    useState<number>(project?.poc_adoption_probability || 0);

  const { daysIn } = useGetTimeSpentIn({
    projectId,
    projectStage: project.stage,
  });

  const handleAdoptionProbabilityChange = (
    e: Event,
    newValue: number | number[],
  ) => {
    setAdoptionProbabilityValue(newValue as number);
    saveAdoptionProbability();
  };

  const [projectDifficulty, setProjectDifficulty] = useState<string>(
    String(project?.difficulty) || '0',
  );

  const handleProjectDifficultyChange = (
    event: ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const newDifficultyValue = event.target.value.toString().replace(',', '.');

    setProjectDifficulty(newDifficultyValue);
    saveProjectDifficulty();
  };

  const [pocStart, pocEnd] = getStartAndEndOfPoC(projectTimeline);

  const isAtInitialStages = initialProjectStages.includes(project.stage);
  const projectStage = [...LEAD_STAGES].filter(s => s === project.stage).length
    ? null
    : project.stage;

  const isAtLaterStages = !isAtInitialStages;

  const saveAdoptionProbability = useDebouncedCallback(
    async () =>
      await updateProject({
        variables: {
          id: projectId,
          payload: {
            poc_adoption_probability: adoptionProbabilityValue,
          },
        },
      }).then(() => enqueueSnackbar('Saved', { variant: 'success' })),
    500,
  );

  const saveProjectDifficulty = useDebouncedCallback(
    async () =>
      await updateProject({
        variables: {
          id: projectId,
          payload: {
            difficulty: Number(projectDifficulty),
          },
        },
      }).then(() => enqueueSnackbar('Saved', { variant: 'success' })),
    500,
  );

  return (
    <>
      <ProjectSummaryStartupsAvatars />
      <Divider sx={{ margin: '16px 0' }} />
      {isAtLaterStages && (
        <SummaryField
          label='Project dates'
          value={
            !pocStart && !pocEnd
              ? 'TBD'
              : `${pocStart || ''} - ${pocEnd || 'TBD'}`
          }
        />
      )}
      {isAtLaterStages && project.pilot_budget && (
        <SummaryField
          label='Available budget'
          value={formatMoney(project.pilot_budget, '€')}
        />
      )}
      {daysIn != null &&
        (daysIn as number) >= 0 &&
        (projectStage ? (
          <SummaryField
            label={getStageSpentInLabel(projectStage, organizationSubdomain)}
            value={
              daysIn === 0
                ? 'less than 1 day'
                : `${daysIn} day${Number(daysIn) > 1 ? 's' : ''}`
            }
          />
        ) : (
          <SummaryField
            label={'Created'}
            value={
              daysIn === 0
                ? 'less than 1 day ago'
                : `${daysIn} day${Number(daysIn) > 1 ? 's ago' : 'ago'}`
            }
          />
        ))}
      {isAtLaterStages && impactMetrics.length > 0 && (
        <SummaryField
          label='Impact metrics'
          value={impactMetrics.map(im => {
            return (
              <>
                {im.name}
                <br />
              </>
            );
          })}
        />
      )}
      {isAtInitialStages && project.business_impact_type && (
        <SummaryField
          label='Impact type'
          value={IMPACT_TYPE[project.business_impact_type]}
        />
      )}
      {project.business_impact_estimate && (
        <SummaryField
          label={'Estimated impact'}
          value={formatMoney(project.business_impact_estimate, '€')}
        />
      )}

      {isAtLaterStages && project.poc_cost && (
        <SummaryField
          label='Cost of project'
          value={formatMoney(project.poc_cost, '€')}
        />
      )}
      <Stack marginY={0.5} direction='row' alignItems='center'>
        <Typography
          flexBasis='100%'
          variant='body2'
          fontSize={theme => theme.spacing(1.6875)}
          fontWeight={500}
          color={({ palette }) => palette.grey['600']}
        >
          PoC probability
        </Typography>
        <Slider
          sx={{ height: '3px', width: '40%' }}
          value={adoptionProbabilityValue}
          valueLabelFormat={value => `${value}%`}
          aria-label='Default'
          valueLabelDisplay='auto'
          onChange={handleAdoptionProbabilityChange}
          className={classes.slider}
        />
      </Stack>
      {isStaff && (
        <DifficultyField
          difficultyValue={projectDifficulty}
          handleDifficultyChange={handleProjectDifficultyChange}
        />
      )}
    </>
  );
};

const SummaryField = ({
  label,
  value,
}: {
  label: string;
  value: string | number | ReactNode;
}) => {
  if (!value) {
    return (
      <Stack direction='row' margin={1} gap={1} alignItems='center'>
        <Typography
          flexWrap='nowrap'
          flexGrow={1}
          minWidth={0}
          // flexBasis='50%'
          variant='body2'
          fontSize={theme => theme.spacing(1.5625)}
          fontWeight={500}
          color={({ palette }) => palette.grey['600']}
        >
          {label}
        </Typography>
        <Skeleton
          animation={false}
          variant='text'
          width='59%'
          sx={{
            background: ({ palette }) => palette.grey[300],
          }}
        />
      </Stack>
    );
  }

  return (
    <Stack direction='row' alignItems='center' marginY={1} gap={1}>
      <Typography
        flexWrap='nowrap'
        flexGrow={1}
        minWidth={0}
        variant='body2'
        fontSize={theme => theme.spacing(1.6875)}
        fontWeight={500}
        color={({ palette }) => palette.grey['600']}
      >
        {label}
      </Typography>
      {label === 'Department' ? (
        <BaseLabel
          sx={{
            lineHeight: 1.75,
          }}
        >
          <Tooltip title={value}>
            <span
              style={{
                display: 'inline-block',
                textOverflow: 'ellipsis',
                marginLeft: 'auto',
                overflow: 'hidden',
              }}
            >
              {value}
            </span>
          </Tooltip>
        </BaseLabel>
      ) : (
        <Typography
          variant='body2'
          marginLeft='auto'
          color={({ palette }) => palette.text.primary}
          fontWeight={500}
          fontSize={13.5}
          textAlign='end'
        >
          {value}
        </Typography>
      )}
    </Stack>
  );
};

export default ProjectSummary;
