import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import Login from 'pages/authentication/LoginPage';
import { PATH_ROOT } from '../routes/paths';

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { isAuthenticated } = useAuth();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const invitation = urlParams.get('invitation');

  if (invitation) {
    return <Login />;
  }

  if (isAuthenticated) {
    return <Navigate to={PATH_ROOT.root} />;
  }

  return <>{children}</>;
}
