import { View, Text } from '@react-pdf/renderer';

export const Pager = () => (
  <View
    style={{ position: 'absolute', right: 10, bottom: 10, fontSize: '12px' }}
    fixed
  >
    {/* we don't show this on the intro page so counting should start from the second page hence subtracting 1 */}
    <Text
      render={({ pageNumber, totalPages }) =>
        `${pageNumber - 1} / ${totalPages - 1}`
      }
    />
  </View>
);
